import React, { Component, createRef } from 'react';
import { Column } from '@ant-design/charts';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Page extends Component {
    ref = createRef();

    render() {
        let { data } = this.props.details;
        var config = {
            forceFit: true,
            padding: 30,
            statistic: {
                style: {
                    fontFamily: "Futura Std Medium Condensed",
                }
            },
            title: {
                visible: true,
                text: this.props.details.title,
                style: {
                    fontSize: 14,
                    fill: '#252525',
                    fontFamily: "Futura Std Medium Condensed",
                }
            },
            data: data,
            xField: this.props.details.xField,
            yField: this.props.details.yField,
            label: {
                visible: true,
                position: 'top',
                adjustColor: true,
                style: {
                    fill: '#0D3660',
                    fontFamily: "Futura Std Medium Condensed",
                    opacity: 1,
                },
            },
            columnStyle: {
                shadowBlur: 10,
                shadowOffsetX: 5,
                shadowOffsetY: 5,
                cursor: 'pointer',
            },
            yAxis: {
                min: 0,
                max: 100,
                style: {
                    fontFamily: "Futura Std Medium Condensed",
                }
            },
            xAxis: {
                style: {
                    fontFamily: "Futura Std Medium Condensed",
                }
            },
            columnBackground: {
                style: {
                    fill: 'rgba(0,0,0,0.1)',
                },
            },
        };

        return (<Column {...config} chartRef={this.ref} />);
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Page));
