import React, { Component, createRef } from 'react'
import { Select, Button, Tag, Row, Col } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CourseHome from '../../coordinator/CourseHome.js'
import { CloseOutlined, EnterOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
const { Option } = Select;

class CoursesHome extends Component {
    scrollDiv = createRef();

    state = {
        loading: true,
        COURSE_CODE: '', COURSE_NAME: '', courses: [], flag: 0
    }

    async componentDidMount() {
        await this.setState({ loading: true })
        let sendData = {
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/admin/course/getcourses', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    this.setState({ courses: res.data.courses })
            });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ loading: true });
        this.setState({ [label]: value });
        let data = this.state.courses.find(v => v.COURSE_CODE === value);
        this.setState({ COURSE_NAME: data.COURSE_NAME });
        this.setState({ COURSE_CODE: data.COURSE_CODE });
    }

    reSetButton = (e) => {
        this.setState({
            COURSE_CODE: '', COURSE_NAME: '', flag: 0
        });
    };

    submitButton = (e) => {
        this.setState({ flag: 1 });
    }

    render() {
        let { COURSE_CODE, COURSE_NAME, courses, flag } = this.state;
        return (
            <div>
                <Row gutter={[16, 16]} justify="start" className='mb-2'>
                    <Col xs={18}>
                        <Select id="COURSE_CODE" placeholder="Select Course" style={{ width: "100%" }} onChange={this.handleChangeSelect.bind(this, "COURSE_CODE")} value={this.state.COURSE_CODE}
                            showSearch
                        >
                            <Option value="" disabled>Search Course by Code</Option>
                            {
                                courses.map(
                                    (item, val) => {
                                        return (
                                            <Option key={val} value={item.COURSE_CODE}>
                                                <Tag color="blue">{item.COURSE_NAME.toUpperCase()}</Tag>
                                                <Tag color="magenta">{item.COURSE_CODE}</Tag>
                                                <Tag color="green">{"SEM " + item.SEMESTER}</Tag>
                                            </Option>
                                        )
                                    }
                                )
                            }
                        </Select>
                    </Col>
                    <Col xs={3}>
                        <Button type="primary" block icon={<EnterOutlined />} disabled={!COURSE_NAME || flag} onClick={this.submitButton}>SUBMIT</Button>
                    </Col>
                    <Col xs={3}>
                        <Button type="primary" block icon={<CloseOutlined />} danger disabled={!COURSE_NAME} onClick={this.reSetButton}>RESET</Button>
                    </Col>
                    <Col span={24}>
                        {
                            COURSE_CODE && flag ? <CourseHome COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} /> : null
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CoursesHome));