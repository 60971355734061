import React, { Component } from 'react'
import { Button, Input, Space, Table, message, Select, Row, Col, Tag, Spin } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownloadOutlined, EnterOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
const { Option } = Select;

class FullReport extends Component {
    state = {
        loading: false,
        result: [],
        SEMESTER: "",
        emptyCourses: []
    }

    async componentDidMount() {
    }

    handleChangeSelect = (value) => {
        this.setState({ SEMESTER: parseInt(value) });
    }

    onLoad = async () => {
        if (this.state.SEMESTER) {
            this.setState({ loading: true, result: [] });
            let data = {
                KEY: config.key,
                SEMESTER: parseInt(this.state.SEMESTER),
            }
            axios.post(config.curriculaserver + '/curricula/admin/report/serverreport', data)
                .then(res => {
                    if (res.data.Status === 1) {
                        let emptyCourses = res.data.emptycourses || [];
                        let result = res.data.result || [];
                        for (let i = 0; i < emptyCourses.length; i++) {
                            let COURSE_CODE = emptyCourses[i].COURSE_CODE;
                            let temp = emptyCourses[i].status === 12 ? "-" : emptyCourses[i].status === 11 ? "*" : "EMPTY";
                            let index = result.findIndex(x => x._id === COURSE_CODE);
                            result[index].result = {
                                "INTERNAL_MARKS": {
                                    "UNIT1": temp,
                                    "UNIT2": temp,
                                    "UNIT3": temp,
                                    "UNIT4": temp,
                                    "UNIT5": temp,
                                },
                            }
                        }
                        this.setState({ result: result, loading: false });
                    }
                    else {
                        message.error("Something went wrong ! Contact Admin !");
                        this.setState({ loading: false });
                    }
                });
        }
        else
            message.error("Please select semester !");
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    EmptyCoursesColor = (text) => {
        if (text === "-") return <Tag color="red">SESSION COUNT EMPTY</Tag>
        else if (text === "*") return <Tag color="orange" style={{ minWidth: "147px" }}>USER COUNT EMPTY</Tag>
        else return text;
    }

    render() {
        let { result, loading } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'COURSE_CODE',
                width: 0.5,
                render: (text) => (result.findIndex(i => i.COURSE_CODE === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Course Code',
                dataIndex: 'COURSE_CODE',
                key: 'COURSE_CODE',
                ...this.getColumnSearchProps('COURSE_CODE'),
            },
            {
                title: 'Course Name',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
                ...this.getColumnSearchProps('COURSE_NAME'),
            },
            {
                title: 'Internal Mark Unit 1 (Avg)',
                dataIndex: ['result', 'INTERNAL_MARKS', 'UNIT1'],
                key: 'SEMESTER',
                render: (text) => (this.EmptyCoursesColor(text)),
            },
            {
                title: 'Internal Mark Unit 2 (Avg)',
                dataIndex: ['result', 'INTERNAL_MARKS', 'UNIT2'],
                key: 'SEMESTER',
                render: (text) => (this.EmptyCoursesColor(text)),
            },
            {
                title: 'Internal Mark Unit 3 (Avg)',
                dataIndex: ['result', 'INTERNAL_MARKS', 'UNIT3'],
                key: 'SEMESTER',
                render: (text) => (this.EmptyCoursesColor(text)),
            },
            {
                title: 'Internal Mark Unit 4 (Avg)',
                dataIndex: ['result', 'INTERNAL_MARKS', 'UNIT4'],
                key: 'SEMESTER',
                render: (text) => (this.EmptyCoursesColor(text)),
            },
            {
                title: 'Internal Mark Unit 5 (Avg)',
                dataIndex: ['result', 'INTERNAL_MARKS', 'UNIT5'],
                key: 'SEMESTER',
                render: (text) => (this.EmptyCoursesColor(text)),
            },

        ];

        let header_list = [
            { label: "Course Code", key: "COURSE_CODE" },
            { label: "Course Name", key: "COURSE_NAME" },
            { label: "Internal Mark Unit 1", key: 'result.INTERNAL_MARKS.UNIT1' },
            { label: "Internal Mark Unit 2", key: 'result.INTERNAL_MARKS.UNIT2' },
            { label: "Internal Mark Unit 3", key: 'result.INTERNAL_MARKS.UNIT3' },
            { label: "Internal Mark Unit 4", key: 'result.INTERNAL_MARKS.UNIT4' },
            { label: "Internal Mark Unit 5", key: 'result.INTERNAL_MARKS.UNIT5' },
        ];

        return (<>

            <Row gutter={[16, 16]} type="flex" justify="start">
                <Col xs={24}>
                    <h2>Overall Report - Course Wise & Unit Wise </h2>
                </Col>
                <Col xs={24} lg={8}>
                    <Select style={{ width: "100%" }} onChange={this.handleChangeSelect} value={this.state.SEMESTER} placeholder="Choose Semester">
                        <Option value="" disabled>Choose Semester</Option>
                        <Option value="1">Semester 1</Option>
                        <Option value="2">Semester 2</Option>
                        <Option value="3">Semester 3</Option>
                        <Option value="4">Semester 4</Option>
                        <Option value="5">Semester 5</Option>
                        <Option value="6">Semester 6</Option>
                        <Option value="7">Semester 7</Option>
                        <Option value="8">Semester 8</Option>
                        <Option value="9">Semester 9</Option>
                        <Option value="10">Semester 10</Option>
                    </Select>
                </Col>

                <Col xs={24} lg={4}>
                    <Button loading={loading} icon={<EnterOutlined />} disabled={this.state.SEMESTER ? false : true} block type="primary" onClick={this.onLoad}>SUBMIT</Button>
                </Col>

                <Col xs={24}>
                    <Spin tip="Please wait, we're processing your request.." spinning={loading} >
                        <Space className='mt-1 mb-1'>
                            <CSVLink data={result} headers={header_list} filename={config.toolname + "-all-coursewise-sections-list.csv"}>
                                <Button type="primary" disabled={result.length ? false : true} icon={<DownloadOutlined />} size="small">
                                    Download EXCEL
                                </Button>
                            </CSVLink>
                        </Space>
                        <Table size="small" bordered className='mt-1' dataSource={result} columns={columns} />
                    </Spin>
                </Col>
            </Row>
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(FullReport));