import React, { Component } from 'react'
import { Row, Col, message, Tabs, Result } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CourseStructure from './CourseStructure'
import CourseLearningObjective from './CourseLearningObjective'
import { SettingOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

class CourseInfo extends Component {

    state = {
        loading: false,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_CATEGORY: '',
        DEPARTMENT: '',
        L: 0, T: 0, P: 0, C: 0,
        error: 0
    }

    componentDidMount() {
        this.getCoursestructure(this.props.COURSE_CODE);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: false, key: 1 });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCoursestructure(props.COURSE_CODE);
    }

    getCoursestructure = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "course_structure"
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (record)
                            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
                        else
                            this.setState({
                                COURSE_CATEGORY: '',
                                DEPARTMENT: '',
                                L: 0, T: 0, P: 0, C: 0,
                            })
                    }
                    this.setState({ loading: false });
                });
        }
        else message.error("Course Structure is Empty !");
    }

    render() {
        let { COURSE_NAME, COURSE_CODE } = this.state;
        let tabName = ["Course Structure", "Course Outcomes (CO)"];

        return (
            <div>
                {this.state.error === 0 ?
                    (
                        <Row style={{ overflow: "auto" }}>

                            <Col xs={24} className="mt-1 mb-1">
                                <Row gutter={[8, 8]}>
                                    <Col xs={24}>
                                        <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false}>
                                            <TabPane tab={
                                                <span>
                                                    <SettingOutlined />
                                                    {tabName[0]}
                                                </span>
                                            } key="1">
                                                <CourseStructure COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                            </TabPane>
                                            <TabPane tab={
                                                <span>
                                                    <SafetyCertificateOutlined />
                                                    {tabName[1]}
                                                </span>
                                            } key="2">
                                                <CourseLearningObjective COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                            </TabPane>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseInfo));