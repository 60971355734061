import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, Popconfirm, notification, Table, Tooltip, Tag, Alert } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { EditOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { Option } = Select;
const { Title, Text } = Typography;
const topic = "researchdomain";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class Research extends Component {
    state = {
        researchInterest: '',
        title: '',
        domain: '',
        status: '',
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/research/researchdomain/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/research/researchdomain/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/research/researchdomain/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/research/researchdomain/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });

                }
            });
    }

    onClickSubmitBtn = async () => {
        let { researchInterest, title, domain, status, _id, key } = this.state;
        let errorMessages = [];
        if (!researchInterest) errorMessages.push("Invalid Research Interest");
        else if (!title) errorMessages.push("Invalid Title");
        else if (!domain) errorMessages.push("Invalid Domain");
        else if (!status) errorMessages.push("Invalid Status");


        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,

            data: {
                researchInterest: researchInterest,
                title: title,
                domain: domain,
                status: status,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/research/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/research/researchdomain/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    await this.setState({ edit: false, update: false });
                    await this.setState(this.initialState);
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }
    reset = async () => {
        let data = this.state.data;
        await this.setState(this.initialState);
        await this.setState({ data: data })
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }


    render() {
        let { researchInterest, title, domain, status, edit, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Research Interest',
                dataIndex: 'researchInterest',
                key: 'researchInterest',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 2,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 1,
            },
            {
                title: 'Verified',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 2,
            },
            {
                title: 'Domain',
                dataIndex: 'domain',
                key: 'domain',
                width: 1,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 2,
            },
        ];


        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End
        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Research Domain / Projects Information
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='researchInterest' addonBefore="Research Interest" onChange={ this.onChange } value={ researchInterest } />
                    </Col>

                    <Col xs={ 24 } md={ 16 }>
                        <Input id='title' addonBefore="Title" onChange={ this.onChange } value={ title } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="domain" addonBefore="Domain" onChange={ this.onChange } value={ domain } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 10 }>
                                    <Input defaultValue="* Status" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 14 }>
                                    <Select id="status" style={ { width: "100%" } } placeholder="Status" onChange={ this.handleChangeSelect.bind(this, "status") } value={ status } >
                                        <Option value="INPROGRESS">Inprogress</Option>
                                        <Option value="COMPLETED">Completed</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } >
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Research));