import React, { Component } from 'react'
import { Table, Descriptions, Select, Tag } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
const { Option } = Select;
const style = {
    "width": "88px",
    "textAlign": "center"
}

class LPStatus extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        UNIT: 1,
        SESSION: 1,
        SESSION_COUNT: 0,
        SLO1: 1011,
        SLO2: 1012,
        sessions: [],
        key: 0
    }

    async componentDidMount() {
        let sendData = {
            key: config.key,
            COURSE_INFO: this.props.COURSE_INFO,
        };

        await this.getSession(sendData.COURSE_INFO.COURSE_CODE);

        await axios.post(config.curriculaserver + '/curricula/admin/report/worksheetstatus', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ marks: res.data.studentTable, loading: false });
                }
            });
    }

    getSession = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else
                        this.setState({ sessions: record, UNIT: 1, SESSION_COUNT: record[0].SESSIONCOUNT, error: 0 });
                }
            });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ loading: true })
        this.setState({ [label]: parseInt(value) });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            let SLO1 = (value * 100) + this.state.SESSION + "" + 1;
            let SLO2 = (value * 100) + this.state.SESSION + "" + 2;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT, SESSION: 1, SLO1, SLO2 });
        }
        else {
            let SLO1 = (this.state.UNIT * 100) + value + "" + 1;
            let SLO2 = (this.state.UNIT * 100) + value + "" + 2;
            this.setState({ SLO1, SLO2 });
        }
        this.setState({ loading: false })
    }

    render() {
        let { marks, UNIT, SESSION, SESSION_COUNT } = this.state;
        let SC = []; for (let i = 1; i <= SESSION_COUNT; i++) SC.push(i);
        let success = <Tag style={style} color="success">Completed</Tag>;
        let error = <Tag color="error">Not Submitted</Tag>;
        let pending = <Tag style={style} color="warning">Pending</Tag>;
        const columns = [
            {
                title: 'S.No',
                dataIndex: '_id',
                width: 1,
                render: (text) => (marks.findIndex(i => i._id === text) + 1),
                fixed: 'left',
            },
            {
                title: 'USER',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                render: (text, record) => (record.FULL_NAME + " (" + text + ")")
            },
            {
                title: 'SLO 1',
                render: (text, record) => (
                    (record[this.state.SLO1] === 2 ? success : record[this.state.SLO1] === 1 ? pending : error)

                )
            },
            {
                title: 'SLO 2',
                render: (text, record) => (
                    (record[this.state.SLO2] === 2 ? success : record[this.state.SLO2] === 1 ? pending : error)
                )
            }
        ];
        return (
            <div>
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                    className="mb-1"
                    size="small"
                >

                    <Descriptions.Item label="Choose Unit">
                        <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                            onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                        </Select>
                    </Descriptions.Item>

                    <Descriptions.Item label="Choose Session">
                        <Select id="SESSION" style={{ width: "100%" }} value={SESSION}
                            onChange={this.handleChangeSelect.bind(this, "SESSION")}>
                            {
                                parseInt(this.state.SESSION_COUNT) > 0 ?
                                    (SC.map(
                                        (item) => {
                                            return (
                                                <Option key={item} value={item}>{item}</Option>
                                            );
                                        }
                                    )
                                    ) : ''
                            }
                        </Select>
                    </Descriptions.Item>
                </Descriptions>
                <Table size="middle" bordered style={{ width: "100%" }} dataSource={marks} columns={columns} loading={this.state.loading} className="mt-1" />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LPStatus));