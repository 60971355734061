import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Input, Button, Tag, Typography, Space, message, Select, Descriptions, Table, Alert } from 'antd';
import axios from 'axios';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { CSVLink } from "react-csv";
import {
    DownloadOutlined, SearchOutlined
} from '@ant-design/icons';
const { Option } = Select;
const InputGroup = Input.Group;
const { Text } = Typography;
const departments = config.departments;

class HodReport extends Component {

    state = {
        collapsed: false,
        data: [],
        topic: '',
        dept: '',
        topic_selected: '',
        banner: false,
        searchText: '',
        searchedColumn: '',
    }

    handleChangeSelect = (label, value, x) => {
        this.setState({ [label]: value, data: [], loading: true, open: true, topic_selected: x.children, searchText: '', searchedColumn: '' });

        let dept;
        if (this.props.common.user.ROLE !== "HOD") dept = this.state.dept;
        else dept = this.props.common.user.DEPARTMENT;

        let data = {
            info: this.props.common.user,
            key: config.key,
            topic: value,
            dept: dept
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/hodreport/getreport', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result) {
                    this.headListGenerate(res.data.result[0]);
                    let result = res.data.result, key = 0;
                    for (let i = 0; i < result.length; ++i) {
                        result[i].key = ++key;
                    }
                    this.setState({ data: res.data.result, loading: false, banner: true });
                    message.success('Information loaded');
                }
            });
    }

    handleChangeSelectDept = (label, value) => {
        this.setState({ [label]: value, topic: "" });
    }

    headListGenerate = (data) => {
        let header_list = [];
        if (data)
            Object.keys(data).forEach(function (key) {
                header_list.push({ label: key, key: key });
            })
        this.setState({ header_list: header_list })
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "default" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={ { padding: 8 } }>
                <Input
                    ref={ node => {
                        this.searchInput = node;
                    } }
                    placeholder={ `Search ${dataIndex}` }
                    value={ selectedKeys[0] }
                    onChange={ e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                    onPressEnter={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                    style={ { width: 188, marginBottom: 8, display: 'block' } }
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                        icon={ <SearchOutlined /> }
                        size="small"
                        style={ { width: 90 } }
                    >
                        Search
                    </Button>
                    <Button onClick={ () => this.handleReset(clearFilters) } size="small" style={ { width: 90 } }>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={ { color: filtered ? '#1890ff' : undefined } } />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={ { backgroundColor: '#ffc069', padding: 0 } }
                    searchWords={ [this.state.searchText] }
                    autoEscape
                    textToHighlight={ text.toString() }
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {

        let { data, dept } = this.state;

        let column1 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "STUDENT_ID",
                key: "STUDENT_ID",
                width: 1,
                ...this.getColumnSearchProps('STUDENT_ID'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "STUDENT_NAME",
                key: "STUDENT_NAME",
                ...this.getColumnSearchProps('STUDENT_NAME'),
                width: 1
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                width: 1,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) } key={ text }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
                fixed: 'right',
            },
        ];

        let column2 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "Employee ID",
                dataIndex: "addedBy",
                key: "addedBy",
                width: 1,
                ...this.getColumnSearchProps('addedBy'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "FULL_NAME",
                key: "FULL_NAME",
                ...this.getColumnSearchProps('FULL_NAME'),
                width: 1
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                width: 1,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) } key={ text }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
                fixed: 'right',
            },
        ];

        let column = column1;
        if (this.state.topic === "book" || this.state.topic === "outreachsocial") column = column2;
        else column = column1;

        return (
            <Row style={ { overflow: "auto" } }>

                <Col xs={ 24 } className="mt-1 mb-1" style={ { padding: "1%" } }>
                    <Descriptions bordered>
                        <Descriptions.Item label={

                            this.props.common.user.ROLE !== 'HOD' ?
                                (<Select showSearch id="dept" style={ { width: "100%" } } placeholder="Department" value={ dept } onChange={ this.handleChangeSelectDept.bind(this, "dept") }>
                                    <Option value="" disabled>Choose Department</Option>
                                    {
                                        this.props.common.user.ROLE === 'SA' ? <Option key={ 31 } value={ this.props.common.user.DEPARTMENT }>{ this.props.common.user.DEPARTMENT }</Option> :
                                        
                                        departments.map(
                                            (newItem, newVal) => {
                                                return (
                                                    newItem !== "ALL" ?
                                                        <Option key={ newVal } value={ newItem }>
                                                            { newItem }
                                                        </Option> : <Option value=".*">All</Option>
                                                )
                                            }
                                        )
                                     }
                                </Select>) : "Select Topic"
                        }>
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 24 }>
                                        <Select showSearch id="topic" style={ { width: "100%" } } value={ this.state.topic }
                                            onChange={ this.handleChangeSelect.bind(this, "topic") }>
                                            <Option key={ 51 } value="" disabled>Choose Topic</Option>
                                            <Option key={ 52 } value="certification">Certification</Option>
                                            <Option key={ 53 } value="award">Award</Option>
                                            <Option key={ 54 } value="membership">Membership</Option>
                                            <Option key={ 55 } value="cep">CEP</Option>
                                            <Option key={ 56 } value="book">Professional Publications</Option>
                                            <Option key={ 57 } value="admissiondetails">Admission Entry Details</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>

                <Col xs={ 24 } style={ { padding: "1%" } }>
                    <CSVLink data={ data } headers={ this.state.header_list } filename={ this.state.topic + "-full-report.csv" }>
                        <Row gutter={ [16, 16] } >
                            {
                                this.state.banner ?
                                    (<Col xs={ 24 }>
                                        <Alert message={ this.state.topic_selected + " Information Excel file is ready" } type="success" showIcon closable />
                                    </Col>) : ''
                            }
                            <Col xs={ 24 }>
                                <Button icon={ <DownloadOutlined /> } disabled={ !this.state.header_list ? true : false }>
                                    Download EXCEL
                                </Button>
                            </Col>
                        </Row>
                    </CSVLink>
                    <Table className="mt-1" loading={ this.state.loading } dataSource={ this.state.data } size="small" columns={ column } scroll={ { x: 1500 } }
                        bordered
                    />
                </Col>

            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(HodReport));