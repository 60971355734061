import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import Domain from './Research'
import ResearchIdentity from './ResearchIdentity'

const { Title } = Typography;
const { TabPane } = Tabs;

class ResearchActivities extends Component {
    state = {
        loading: false,
    };

    render() {

        let tabName = ["Research Guideship", "Research Guidance", "Reviewer", "Domain / Projects", "Research Identity"];

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Research Activities</Title>
                    </Col>
                    <Col xs={ 24 }>
                        <Tabs defaultActiveKey="0" tabPosition="top" style={ { height: "100%", textAlign: "left" } } animated={ false } >
                            {
                            /*
                            <TabPane tab={tabName[0]} key="1"><ResearchGuideship /> </TabPane>
                            <TabPane tab={tabName[1]} key="2"><ResearchGuidance /> </TabPane>
                            <TabPane tab={ tabName[2] } key="3"><Reviewer /> </TabPane>
                            */
                            }
                            <TabPane tab={ tabName[3] } key="4"><Domain /> </TabPane>
                            <TabPane tab={ tabName[4] } key="5"><ResearchIdentity /> </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ResearchActivities));