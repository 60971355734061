import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import Journal from './Publication/Journal';
import Conference from './Publication/Conference';
const { Title } = Typography;
const { TabPane } = Tabs;

class ResearchConsultancy extends Component {
    state = {
        loading: false,
    };

    render() {

        let tabName = ["Journal", "Conference"];

        return (
            <div style={{ padding: "24px" }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Title level={3}>Publication Information</Title>
                    </Col>
                    <Col xs={24}>
                        <Tabs defaultActiveKey="0" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                            <TabPane tab={tabName[0]} key="1"><Journal /> </TabPane>
                            <TabPane tab={tabName[1]} key="2"><Conference /> </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ResearchConsultancy));