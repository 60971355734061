import React, { Component } from 'react'
import { Spin, Avatar, List, Row, Col, } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import nl2br from 'react-nl2br';

class Faq extends Component {

    state = {
        loading: false, open: false,
    }

    componentDidMount() {
        let data = {
            key: config.key
        }
        axios.post(config.curriculaserver + '/curricula/faq', data)
            .then(async res => {
                await this.setState({ data: res.data, loading: false });
            });
    }

    render() {
        let loading = this.state.loading;
        return (

            <Spin spinning={this.state.loading}>

                <Row type="flex" justify="start" align="middle" gutter={[16, 16]}>
                    <Col span={24}>
                        <List
                            itemLayout="horizontal"
                            loading={loading}
                            dataSource={this.state.data}
                            renderItem={(item, val) => (
                                <List.Item>
                                    <List.Item.Meta
                                        style={{ textAlign: "justify" }}
                                        avatar={<Avatar> {val + 1}</Avatar>}
                                        title={item.question}
                                        description={nl2br(item.answer)}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>

            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Faq));