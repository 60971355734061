import React, { Component } from 'react'
import { Card, Collapse, Typography, Pagination, List, Button, Progress, Space, Badge, message, Descriptions, Row, Col, Checkbox, Tag, Empty, Radio, BackTop, Tooltip, Input } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactPlayer from 'react-player'
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import CKEditor from 'ckeditor4-react';
import Iframe from 'react-iframe'
import { RedoOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
CKEditor.editorUrl = process.env.PUBLIC_URL + '/ckeditor4/ckeditor.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const { Panel } = Collapse;
const { Text } = Typography;
const CKEditorconfig = {
    mathJaxLib: config.mathJaxLib,
    allowedContent: true,
    removePlugins: 'ckeditor_wiris',
    toolbarGroups: [],
};

class Sessions extends Component {

    state = {
        loading: true,
        ListLoading: false,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        LOCK: this.props.COURSE_INFO.LOCK,
        SESSION: this.props.SESSION,
        USER_ID: this.props.USER_ID,
        FULL_NAME: this.props.FULL_NAME,
        DEPARTMENT: this.props.DEPARTMENT,
        SLOT: this.props.SLOT,
        numPages1: 0,
        pageNumber1: 1,
        numPages2: 0,
        pageNumber2: 1,
        SLCFile1: "",
        SLCFile2: "",
        video: [],
        duration: {},
        plan: {},
        SO: '',
        MCQvalue: [],
        MCQ: [], SQ: [], LQ: [],
        attemptedMCQ: [],
        resultMCQ: 0,
        resultStatus: 0,
        folderid: "",
        filename: "",
        fileStatus: [],
        previousMCQ: 0,
        practiceStatus: {},
        practiceLink: {},
        assessment: [],
        error: 0,
        studentPractice1Link: "", studentPractice2Link: ""
    }

    async componentDidMount() {
        let { SESSION } = this.props;
        let UNIT = Math.round(SESSION / 100);
        let SESSIONNO = parseInt(SESSION) - (UNIT * 100);
        this.setState({ UNIT: UNIT, SESSIONNO: SESSIONNO });

        await this.getSessionStatus(SESSION);
        await this.getSLCFile(SESSION, 1);
        await this.getSLCFile(SESSION, 2);
        await this.getQuestion(SESSION, 1);
        //console.log(pdfjs.GlobalWorkerOptions.workerSrc)

    }

    async UNSAFE_componentWillReceiveProps(props) {
        await this.setState({ loading: true, key: 1 });
        await this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME, SESSION: props.SESSION })

        let SESSION = parseInt(props.SESSION);
        let UNIT = Math.round(SESSION / 100);
        let SESSIONNO = parseInt(SESSION) - (UNIT * 100);
        await this.setState({ UNIT: UNIT, SESSIONNO: SESSIONNO });

        await this.getSessionStatus(SESSION);
        await this.getSLCFile(SESSION, 1);
        await this.getSLCFile(SESSION, 2);
        await this.getQuestion(SESSION, 1);
    }

    getSessionStatus = async (SESSION) => {
        let sendData = {
            USER_ID: this.state.USER_ID,
            FULL_NAME: this.state.FULL_NAME,
            DEPARTMENT: this.props.DEPARTMENT,
            COURSE_INFO: this.props.COURSE_INFO,
            SESSION: SESSION,
            key: config.key,
        };
        let flag = 1;
        Object.keys(sendData).forEach(function (key) {
            if (!sendData[key]) {
                flag = 0;
            }
        });
        if (flag === 0) {
            message.error('Invalid Entry 2!');
            return false;
        }

        await axios.post(config.curriculaserver + '/curricula/student/session/getsessionstatus', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({
                        previousMCQ: res.data.result.MCQ[SESSION] ? res.data.result.MCQ[SESSION] : 0,
                        practiceStatus: res.data.result.PRACTICE,
                        practiceLink: res.data.result.SLOLINK || {}
                    })
                }
            });
    }

    getQuestion = (SESSION, type) => {
        if (type === 1) this.setState({ loading: true })
        else if (type === 2) this.setState({ ListLoading: true })

        let sendData = {
            USER_ID: this.state.USER_ID,
            FULL_NAME: this.state.FULL_NAME,
            DEPARTMENT: this.props.DEPARTMENT,
            COURSE_INFO: this.props.COURSE_INFO,
            SESSION: SESSION,
            key: config.key,
            MCQ: 5,
            SQ: 2,
            LQ: 1
        };

        axios.post(config.curriculaserver + '/curricula/student/session/getquestions', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ MCQ: res.data.mcq || [], SQ: res.data.sq || [], LQ: res.data.lq || [], video: res.data.video || [], SO: res.data.slo || {}, duration: res.data.sp.duration || {}, plan: res.data.sp.plan || {}, loading: false, assessment: res.data.assessment || [], MCQvalue: [], resultMCQ: 0, resultStatus: 0, attemptedMCQ: [], ListLoading: false, });
                }
            });
    }

    onDocumentLoadSuccess1 = (document) => {
        const { numPages } = document;
        this.setState({
            numPages1: numPages,
        });
    };

    onDocumentLoadSuccess2 = (document) => {
        const { numPages } = document;
        this.setState({
            numPages2: numPages,
        });
    };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onPageChange1 = (pageNumber1) => {
        this.setState({ pageNumber1 });
    }

    onPageChange2 = (pageNumber2) => {
        this.setState({ pageNumber2 });
    }

    getSLCFile = (SESSION, SLO) => {
        let filename = parseInt(SESSION) + "" + SLO + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/slc";

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error("Learning Content Empty !");
                else if (res.data.Status === 1) {
                    if (res.data.result.path) {
                        this.setState({ [`SLCFile${SLO}`]: res.data.result.path })
                    }
                }
            });
    }

    DownloadSLCFile = (SESSION) => {
        window.open(config.curriculaserver + "/uploads/data/coordinator/" + this.props.COURSE_CODE + "/slc/" + SESSION + ".pdf");
    }

    FunctionCheckboxGroup = (id) => {
        let P, html = [];
        let CDIO = ["", "Conceive", "Design", "Implement", "Operate"]
        for (let i = 1; i <= 4; i++) {
            P = "P" + i;
            html.push(<Checkbox key={P + id} disabled value={CDIO[i]}>{CDIO[i]}</Checkbox>);
        }
        return html;
    }

    getSP = (NAME, N, T, P, DEFAULT_TIME) => {
        let html = <Descriptions.Item label={NAME} span={2}>
            <Space>
                <Tag color="blue" style={{ padding: T < 10 ? "0px 18px" : "0px 14px" }}> {T || DEFAULT_TIME} Min.</Tag>
                <Checkbox.Group className="ml-5"
                    style={{ width: '100%' }} value={P}>
                    {this.FunctionCheckboxGroup(N)}
                </Checkbox.Group>
            </Space>
        </Descriptions.Item>
        return html;
    }

    reloadQuestion = (event) => {
        event.stopPropagation();
        this.getQuestion(this.state.SESSION, 2);
        this.setState({
            MCQvalue: [], resultMCQ: 0, resultStatus: 0
        });
    }

    displayQuestion = (Q, qNo) => {
        qNo = 0;
        let html =
            Q.length > 0 ?
                (<List
                    itemLayout="horizontal"
                    loading={this.state.ListLoading}
                    dataSource={Q}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={index + 1 + "."}
                                title={
                                    <CKEditor
                                        type="inline"
                                        data={item.QUESTION_DESC}
                                        config={CKEditorconfig}
                                        readOnly={true}
                                    />
                                }
                                description={<CKEditor
                                    type="inline"
                                    data={item.ANSWER}
                                    config={CKEditorconfig}
                                    readOnly={true}
                                />}
                            />
                            <Tooltip title={config.bloomstaxonomy[item.LEVEL]}><Tag>Level {item.LEVEL}</Tag></Tooltip>
                        </List.Item>
                    )}
                />) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No questions found !" />
        return html;
    }

    displayMCQ = (Q) => {
        let html =
            Q.length > 0 ?
                (<><List
                    itemLayout="horizontal"
                    loading={this.state.ListLoading}
                    dataSource={Q}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={index + 1 + "."}
                                title={
                                    <CKEditor
                                        type="inline"
                                        data={item.QUESTION_DESC}
                                        config={CKEditorconfig}
                                        readOnly={true}
                                    />
                                }
                                description={
                                    <Radio.Group onChange={this.onChangeMCQ.bind(this, index, item.ANSWER)} value={this.state.MCQvalue[index]} disabled={this.state.resultStatus === 1 ? true : false}>
                                        <Space className="mcq">
                                            <Space><Radio value={1} style={{ top: "-8px" }}>
                                            </Radio><CKEditor type="inline" data={item.OPT_1} config={CKEditorconfig} readOnly={true} /></Space>
                                            <Space><Radio value={2} style={{ top: "-8px" }}></Radio><CKEditor type="inline" data={item.OPT_2} config={CKEditorconfig} readOnly={true} /></Space>
                                            <Space><Radio value={3} style={{ top: "-8px" }}></Radio><CKEditor type="inline" data={item.OPT_3} config={CKEditorconfig} readOnly={true} /></Space>
                                            <Space><Radio value={4} style={{ top: "-8px" }}></Radio><CKEditor type="inline" data={item.OPT_4} config={CKEditorconfig} readOnly={true} /></Space>
                                        </Space>
                                    </Radio.Group>
                                }
                            />
                            <Tooltip title={config.bloomstaxonomy[item.LEVEL]}><Tag>Level {item.LEVEL}</Tag></Tooltip>
                        </List.Item>
                    )}
                />

                    <Row justify="end" className="mb-2">
                        <Col span={20} align="end">
                            {
                                this.state.LOCK === 1 ? <Tag color="red">This course is currently locked. Any changes will not be saved to the database  </Tag> : null
                            }
                        </Col>
                        <Col span={4}
                            className={this.props.common.user.ROLE !== 'S' ? "hide" : ""}>
                            <Button className="mt-1 mb-1" style={{ width: "100%" }} type="primary" disabled={this.state.attemptedMCQ.length >= this.state.MCQ.length ? false : true} onClick={this.mcqButtonClick}>Submission</Button>
                        </Col>
                        <Col span={24} className="mt-2" align="center">

                            <Space>
                                <Descriptions
                                    bordered
                                    size="small"
                                    column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                                >
                                    {this.state.resultStatus === 1 ?
                                        <Descriptions.Item label="Current Score">
                                            <Progress type="circle" percent={parseFloat((this.state.resultMCQ / this.state.MCQ.length) * 100).toFixed(2)} width={80} />
                                        </Descriptions.Item>
                                        : ''
                                    }
                                </Descriptions>
                            </Space>

                        </Col>
                    </Row>
                </>) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No questions found !" />
        return html;
    }

    mcqButtonClick = () => {
        if (this.state.LOCK !== 1) {
            let sendData = {
                key: config.key,
                session: this.state.SESSION,
                course_code: this.state.COURSE_CODE,
                course_name: this.state.COURSE_NAME,
                USER_ID: this.state.USER_ID,
                FULL_NAME: this.state.FULL_NAME,
                DEPARTMENT: this.props.DEPARTMENT,
                SLOT: this.props.SLOT,
                mcq: (this.state.resultMCQ / this.state.MCQ.length) * 100
            }

            axios.post(config.curriculaserver + '/curricula/student/session/mcq', sendData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        message.success(res.data.msg);
                    } else
                        message.error(res.data.msg);
                });
        }

        this.setState({
            previousMCQ: (this.state.resultMCQ / this.state.MCQ.length) * 100, resultStatus: 1, attemptedMCQ: []
        });
    }

    onChangeMCQ = (index, ANSWER, e) => {
        let MCQvalue = [...this.state.MCQvalue];
        MCQvalue[index] = e.target.value;
        let attemptedMCQ = [...this.state.attemptedMCQ];
        attemptedMCQ.push(e.target.value);
        this.setState({ MCQvalue, attemptedMCQ });
        let resultMCQ = this.state.resultMCQ + 1;
        if (ANSWER === e.target.value)
            this.setState({ resultMCQ: resultMCQ });
    };

    setButtonLink = (SESSION, SLO) => {
        let filename = parseInt(SESSION) + "" + SLO + ".docx";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/slp";
        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    setButtonLinkPDF = (SESSION, SLO) => {
        let filename = parseInt(SESSION) + "" + SLO + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/slppdf";
        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }


    setSession = (SESSION, SLO) => {
        let filename = SESSION + "" + SLO + ".pdf";
        let folderlink = "data/student/" + this.state.USER_ID + "/" + this.state.COURSE_CODE + "/slp";
        this.setState({ folderid: folderlink, filename: filename });
    }

    getSessionLearningPractice = (SESSION, SLO) => {
        let html = "";
        /*
       const params = {
           key: config.key,
           path: this.state.folderid,
           filename: this.state.filename,
           session: SESSION + "" + SLO,
           course_code: this.state.COURSE_CODE,
           course_name: this.state.COURSE_NAME,
           BATCH_ID: this.props.COURSE_INFO.BATCH_ID,
           USER_ID: this.state.USER_ID,
           FULL_NAME: this.state.FULL_NAME,
           DEPARTMENT: this.props.DEPARTMENT,
       }
      
        let props = {
            accept: ".pdf",
            name: 'file',
            multiple: false,
            action: config.curriculaserver + '/curricula/student/session/upload',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    // console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        return { fileStatus: fileStatus };
                    });
                    let practiceStatus = this.state.practiceStatus;
                    practiceStatus[params.session] = 1;
     
                    let SLONUMBER = SESSION + "" + SLO;
                    let practiceLink = this.state.practiceLink;
                    practiceLink[SLONUMBER] = info.file.response.link || '#';
                    this.setState({ practiceStatus: practiceStatus, practiceLink: practiceLink })
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                const PDF = file.type === 'application/pdf';
                if (!PDF) {
                    message.error('You can only upload PDF file!');
                }
                const isLt1M = file.size / 1024 / 1024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return PDF && isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };
     
        html =
            <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            >
                <Descriptions.Item label="Worksheet">
                    <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, SESSION, SLO)}> Download</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Submission">
                    <Space>
                        <Upload {...props} >
                            <Button size="small" onClick={this.setSession.bind(this, SESSION, SLO)}>
                                <UploadOutlined /> Click to Upload
                            </Button>
                        </Upload>
                        {this.getSubmittedPracticeFile(SESSION, SLO)}
                    </Space>
                </Descriptions.Item>
            </Descriptions>;
            */


        //For SRM LONG STUDENTS - STUDNETS ONLY GIVE LINK FOR WORKSHEET
        let id = (SLO === 1) ? "studentPractice1Link" : "studentPractice2Link";
        html =
            <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            >
                <Descriptions.Item label="Worksheet">
                    <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, SESSION, SLO)}> Download Docx</Button> &nbsp;
                    <Button type="primary" danger icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLinkPDF.bind(this, SESSION, SLO)}> Download PDF</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Submission">
                    <Row gutter={[16, 16]}>
                        <Col span={12}
                            className={this.props.common.user.ROLE !== 'S' ? "hide" : ""}>
                            <Input id={id} style={{ width: "100%" }} addonBefore="Link" placeholder="Paste the worksheet answer pdf link" onChange={this.handleChange} />
                        </Col>
                        <Col span={6}
                            className={this.props.common.user.ROLE !== 'S' ? "hide" : ""}>
                            <Button disabled={!this.state[id] ? true : false} icon={<UploadOutlined />} danger onClick={this.updatePracticeFileLink.bind(this, SESSION, SLO)} type="primary" className='mt-1'>UPDATE</Button>
                        </Col>
                        <Col span={6}>
                            {
                                this.getSubmittedPracticeFile(SESSION, SLO)
                            }
                        </Col>
                    </Row>
                </Descriptions.Item>
            </Descriptions>;

        return html;
    }

    updatePracticeFileLink = (SESSION, SLO) => {
        let sendData = {
            view: SLO === 1 ? this.state.studentPractice1Link : this.state.studentPractice2Link,
            download: SLO === 1 ? this.state.studentPractice1Link : this.state.studentPractice2Link,
            fileId: 0,
            session: SESSION + "" + SLO,
            SESSION: SESSION,
            SLO: SLO,
            course_code: this.state.COURSE_CODE,
            course_name: this.state.COURSE_NAME,
            BATCH_ID: this.props.COURSE_INFO.BATCH_ID,
            USER_ID: this.state.USER_ID,
            FULL_NAME: this.state.FULL_NAME,
            DEPARTMENT: this.props.DEPARTMENT,
        }

        axios.post(config.curriculaserver + '/curricula/student/session/submitlink', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1) {
                    let practiceStatus = this.state.practiceStatus;
                    practiceStatus[sendData.session] = 1;
                    let SLONUMBER = SESSION + "" + SLO;
                    let practiceLink = this.state.practiceLink;
                    practiceLink[SLONUMBER] = res.data.link || '#';
                    this.setState({ practiceStatus: practiceStatus, practiceLink: practiceLink })
                    message.success(res.data.msg);
                }
            });
    }

    LPStatus = (score) => {
        let html = "";
        if (score === 2) html = <Tag color="success">Verified</Tag>;
        else if (score === 1) html = <Tag color="warning">Pending</Tag>;
        else if (score === -1) html = <Tag color="error">Resubmission</Tag>;
        else html = <Tag color="error">Not Completed</Tag>;
        return html;
    }

    getSubmittedPracticeFile = (SESSION, SLO) => {
        let SLOLINK = this.state.practiceLink;
        let SLONUMBER = SESSION + "" + SLO;
        let link = SLOLINK[SLONUMBER] ? SLOLINK[SLONUMBER].view : 0;

        if (link) return (<Button target="_blank" className='mt-1' type="dashed" icon={<DownloadOutlined />} href={link}> Download</Button>);
        else return (<Tag className='mt-4' color="error">File not found</Tag>)
    }

    getLearningMaterial = (UNIT) => {
        let filename = (UNIT * 100) + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/unit";

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    render() {
        let { LOCK, UNIT, SESSIONNO, SESSION, pageNumber1, numPages1, pageNumber2, numPages2, video, duration, plan, SO, assessment } = this.state;
        return (
            <Card type="inner" bordered={false} bodyStyle={{ padding: "0px" }}
                title={<> Learning Unit : {UNIT} & Session : {SESSIONNO} </>} className="mt-1 mb-2"
                style={{ width: "100%" }}
                loading={this.state.loading}
                extra={<> <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>}><br />
                <BackTop duration={1000} />
                <Row justify="end">
                    <p>Learning Outcome Status</p>
                </Row>

                <Descriptions
                    bordered
                    size="small"
                    column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                >
                    <Descriptions.Item label="SLR / SLO">
                        <Collapse defaultActiveKey={['0']} ghost>
                            <Panel header="Session Learning Rationale / Outcomes" key="1" extra={<Tag color="success">Achieved</Tag>}>
                                {Object.keys(SO).length !== 0 ?
                                    (<>
                                        <span className="ml-3">
                                            <Text>SLR : The purpose of learning this session is to:</Text>
                                            <ol className="ml-3">
                                                <li>{SO.SRO1}</li>
                                                <li>{SO.SRO2}</li>
                                            </ol>
                                        </span>
                                        <span className="ml-3">
                                            <Text>SLO : At the end of this session, you will able to:</Text>
                                            <ol className="ml-3">
                                                <li>{SO.SLO1}</li>
                                                <li>{SO.SLO2}</li>
                                            </ol>
                                        </span>
                                    </>) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                            <Panel header="Learning Plan" key="2">
                                {
                                    Object.keys(duration).length !== 0 && Object.keys(plan).length !== 0 ?
                                        (<Descriptions
                                            bordered
                                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                            className="mb-1 mt-2"
                                            size="middle"
                                        >
                                            {this.getSP("Recap / Introduction", 1, duration.T1, plan.P1, 5)}
                                            {this.getSP("SLO 1 Content", 2, duration.T2, plan.P2, 10)}
                                            {this.getSP("SLO 1 Practice", 3, duration.T3, plan.P3, 10)}
                                            {this.getSP("SLO 2 Content", 4, duration.T4, plan.P4, 10)}
                                            {this.getSP("SLO 2 Practice", 5, duration.T5, plan.P5, 10)}
                                            {this.getSP("Summary", 6, duration.T6, plan.P6, 5)}
                                        </Descriptions>) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="SLO 1">
                        <Collapse ghost accordion>

                            <Panel header="Learning Content" key="1" extra={<Tag onClick={this.DownloadSLCFile.bind(this, (SESSION + "1"))} icon={<DownloadOutlined />} color="blue">Download</Tag>}>
                                {
                                    this.state.SLCFile1 ?
                                        (
                                            <Row className="cnot" gutter={[16, 16]} justify="center">
                                                <Col span={20} align="center" className="mb-2">
                                                    <Document
                                                        style={{ zoom: "90%" }}
                                                        file={this.state.SLCFile1}
                                                        onLoadSuccess={this.onDocumentLoadSuccess1}
                                                    >
                                                        <Page pageNumber={pageNumber1} width={750} />
                                                    </Document>

                                                </Col>
                                                {
                                                    <Col span={24} align="center">
                                                        <Pagination className="ml-3" simple current={pageNumber1} total={numPages1 * 10} onChange={this.onPageChange1} />
                                                    </Col>
                                                }

                                            </Row>
                                        ) : ''
                                }
                            </Panel>

                            {video[SESSION + "1"] ?
                                (<Panel header="Learning Content (Video)" key="2">
                                    <Row gutter={[16, 16]} justify="center">
                                        <Col span={24} align="center" className="mb-1">
                                            <ReactPlayer url={video[SESSION + "1"]} />
                                        </Col>
                                    </Row>
                                </Panel>) : ''
                            }

                            {LOCK !== 1 &&
                                SESSION ?
                                (<Panel header="Learning Practice" key="4" extra={Object.keys(this.state.practiceStatus).length !== 0 ?
                                    (this.LPStatus(this.state.practiceStatus[SESSION + "1"])) : <Tag color="error">Empty</Tag>
                                }>
                                    {
                                        assessment.includes(SESSION + "1") ? (
                                            <Row justify="center" align="middle">
                                                <Col span={24} align="center">
                                                    <Iframe is="x-frame-bypass" url={config.curriculaserver + "/uploads/data/coordinator/" + this.state.COURSE_CODE + "/assessment/" + SESSION + "1/index.html?USER_ID=" + this.state.USER_ID + "&COURSE_ID=" + this.state.COURSE_CODE + "&SESSION=" + SESSION + "1"}
                                                        sandbox="allow-forms allow-pointer-lock allow-popups allow-scripts allow-top-navigation"
                                                        width="600px"
                                                        height="600px"
                                                        display="initial"
                                                        position="relative"
                                                        frameBorder="0" scrolling="no"
                                                        styles={{ backgroundColor: "red" }}
                                                        allowFullScreen />
                                                </Col>
                                            </Row>)
                                            : this.getSessionLearningPractice(SESSION, 1)
                                    }

                                </Panel>) : ''
                            }
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="SLO 2">
                        <Collapse ghost accordion>
                            <Panel header="Learning Content" key="1" extra={<Tag onClick={this.DownloadSLCFile.bind(this, (SESSION + "2"))} icon={<DownloadOutlined />} color="blue">Download</Tag>}>
                                {
                                    this.state.SLCFile2 ?
                                        (<Row className="cnot" gutter={[16, 16]} justify="center">
                                            <Col span={20} align="center" className="mb-2">
                                                <Document
                                                    style={{ zoom: "90%" }}
                                                    file={this.state.SLCFile2}
                                                    onLoadSuccess={this.onDocumentLoadSuccess2}
                                                >
                                                    <Page pageNumber={pageNumber2} width={750} />
                                                </Document>
                                            </Col>
                                            <Col span={24} align="center">
                                                <Pagination className="ml-3" simple current={pageNumber2} total={numPages2 * 10} onChange={this.onPageChange2} />
                                            </Col>
                                        </Row>) : ''
                                }
                            </Panel>
                            {video[SESSION + "2"] ?
                                (<Panel header="Learning Content (Video)" key="2">
                                    <Row gutter={[16, 16]} justify="center">
                                        <Col span={24} align="center" className="mb-1">
                                            <ReactPlayer url={video[SESSION + "2"]} />
                                        </Col>
                                    </Row>
                                </Panel>) : ''
                            }

                            {
                                LOCK !== 1 &&
                                    SESSION ?
                                    (<Panel header="Learning Practice" key="3" extra={Object.keys(this.state.practiceStatus).length !== 0 ?
                                        (this.LPStatus(this.state.practiceStatus[SESSION + "2"])) : <Tag color="error">Empty</Tag>
                                    }>
                                        {
                                            assessment.includes(SESSION + "2") ? (
                                                <Row justify="center" align="middle">
                                                    <Col span={24} align="center">
                                                        <Iframe is="x-frame-bypass" url={config.curriculaserver + "/uploads/data/coordinator/" + this.state.COURSE_CODE + "/assessment/" + SESSION + "2/index.html?USER_ID=" + this.state.USER_ID + "&COURSE_ID=" + this.state.COURSE_CODE + "&SESSION=" + SESSION + "2"}
                                                            sandbox="allow-forms allow-pointer-lock allow-popups allow-scripts allow-top-navigation"
                                                            width="600px"
                                                            height="600px"
                                                            display="initial"
                                                            position="relative"
                                                            frameBorder="0" scrolling="no"
                                                            styles={{ backgroundColor: "red" }}
                                                            allowFullScreen />
                                                    </Col>
                                                </Row>)
                                                : this.getSessionLearningPractice(SESSION, 2)
                                        }

                                    </Panel>) : ''
                            }
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="Assessment" className="cnot">
                        <Collapse accordion ghost>
                            <Panel header="Multiple Choice Questions" key="1" extra={
                                <Space>
                                    <Tag color={this.state.previousMCQ > 1 ? "success" : "error"} > Last Score : {parseFloat(this.state.previousMCQ).toFixed(2) || 0}% </Tag>
                                    <Tooltip title="Reload Questions"><RedoOutlined onClick={this.reloadQuestion} /></Tooltip>
                                </Space>
                            }>
                                {
                                    this.state.MCQ.length > 0 ?
                                        (this.displayMCQ(this.state.MCQ)) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                            <Panel header="Short Questions" key="2" extra={<Tooltip title="Reload Questions"><RedoOutlined onClick={this.reloadQuestion} /></Tooltip>}>
                                {
                                    this.state.SQ.length > 0 ?
                                        (this.displayQuestion(this.state.SQ, 0)) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                            <Panel header="Long Questions" key="3" extra={<Tooltip title="Reload Questions"><RedoOutlined onClick={this.reloadQuestion} /></Tooltip>}>
                                {
                                    this.state.LQ.length > 0 ?
                                        (this.displayQuestion(this.state.LQ, 0)) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="Learning Material">
                        <Button type="dashed" icon={<DownloadOutlined />} className="mt-1 mb-1 ml-1" onClick={this.getLearningMaterial.bind(this, (UNIT))}>
                            Download  Unit {UNIT} Learning Material
                        </Button>
                    </Descriptions.Item>
                </Descriptions>

                <br />
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Sessions));