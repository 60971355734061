import React, { Component } from 'react'
import { Row, Col, Descriptions, message, Spin, Statistic, Space, Empty, List, Button } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { DownloadOutlined } from '@ant-design/icons';
import config from '../../config';
const page_name = "course_structure";

class CourseStructure extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_CATEGORY: '',
        DEPARTMENT: '',
        L: 0, T: 0, P: 0, C: 0,
        open: false,
        loading: true
    }

    componentDidMount() {
        this.getCoursestructure(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCoursestructure(props.COURSE_CODE);
    }

    getCoursestructure = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (record) {
                            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }));
                            if (record.COURSE_CATEGORY) this.setState({ open: true })
                            else this.setState({ open: false })
                        }
                        else
                            this.setState({
                                COURSE_CATEGORY: '',
                                DEPARTMENT: '',
                                L: 0, T: 0, P: 0, C: 0,
                            })
                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("Course Structure is Empty");
    }

    nl2br = (str, is_xhtml) => {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    setButtonLink = (filename) => {
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/syllabus";

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    createMarkup = (text) => {
        return {
            __html: this.nl2br(text)
        };
    };

    render() {
        let { COURSE_NAME, COURSE_CODE } = this.state;
        return (
            <Spin spinning={this.state.loading}>

                <Row gutter={[16, 16]} className="mt-2" justify="center">

                    {
                        this.state.open ?
                            (<Col xs={24}>
                                <Descriptions
                                    bordered
                                    column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                                >
                                    <Descriptions.Item label="Course Code">
                                        {COURSE_CODE}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Course Name">
                                        {COURSE_NAME}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Category">
                                        {this.state.COURSE_CATEGORY}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Structure">
                                        <Space size="large">
                                            <Statistic title="Lecture" value={this.state.L} />
                                            <Statistic title="Tutorial" value={this.state.T} />
                                            <Statistic title="Practice" value={this.state.P} />
                                            <Statistic title="Credit" value={this.state.C} />
                                        </Space>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Requisites">
                                        <Descriptions
                                            bordered
                                            column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                                            size="small"
                                        >
                                            <Descriptions.Item label="Pre-Requisite Course">
                                                {this.state.PRE_COURSE_NAME + " (" + this.state.PRE_COURSE_CODE + ")"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Co-Requisite Course">
                                                {this.state.CO_COURSE_NAME + " (" + this.state.CO_COURSE_CODE + ")"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Post-Requisite Course">
                                                {this.state.POST_COURSE_NAME + " (" + this.state.POST_COURSE_CODE + ")"}
                                            </Descriptions.Item>
                                        </Descriptions>

                                    </Descriptions.Item>

                                    <Descriptions.Item label="Offering Department">
                                        {this.state.DEPARTMENT}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Data Book/Codes/Standards">
                                        {this.state.DATA_BOOK}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Course Learning Rationale (CLR)">
                                        <div dangerouslySetInnerHTML={this.createMarkup(this.state.CLR)} />
                                    </Descriptions.Item>

                                    <Descriptions.Item label="References">
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={this.state.REFERENCES}
                                            renderItem={(item, index) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={"[" + (index + 1) + "]"}
                                                        title={item.TYPE.replaceAll('_', ' ')}
                                                        description={item.DESC}
                                                    />
                                                    <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, (index + 1) + ".pdf")}>Download</Button>
                                                </List.Item>
                                            )}
                                        />
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Syllabus">
                                        <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, COURSE_CODE + ".pdf")}>Download</Button>
                                    </Descriptions.Item>

                                </Descriptions>
                            </Col>
                            ) :
                            <Empty />
                    }
                </Row>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseStructure));