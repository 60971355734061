import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Select, Input, Typography, Button, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
const departments = config.departments;
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const topic = "admissiondetails";

class AdmissionDetails extends Component {
    state = {
        USER_ID: this.props.common.user.ROLE !== 'S' ? this.props.record.USER_ID : this.props.common.user.USER_ID,
        FULL_NAME: this.props.common.user.ROLE !== 'S' ? this.props.record.USER_ID : this.props.common.user.FULL_NAME,
        RegisterNumberX: 0,
        RegisterNumberXII: 0,
        MarksX: 0,
        MarksXII: 0,
        GENDER: '',
        ADDRESS: '',
        BOARDING: '',
        COMMUNITY: '',
        FIRST_GRADUATE: '',
        FATHER_OCCUPATION: '',
        FAMILY_INCOME: '',
        PROGRAM_OFFERED: '',
        CASTE: '',
        REFERENCE: '',
        SCHOLARSHIP: '',
        ADMISSION_QUOTA: '',
        COUNSELLING_INFORMATION: ''
    }

    componentDidMount() {
        let data = {
            info: {
                USER_ID: this.state.USER_ID
            },
            topic: topic,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result) { Object.entries(res.data.result).map(([key, value]) => this.setState({ [key]: value })); }
            });
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };


    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
    }

    submitBtn = () => {
        let datatemp = this.state;
        let error = 0;

        Object.keys(datatemp).forEach(function (key) {
            if (!datatemp[key]) { error = 1; }
        });

        if (error) {
            message.error("Invalid Entry !"); return false;
        }
        else if (datatemp.PREFERRED_COURSE_1 === datatemp.PREFERRED_COURSE_2) {
            message.error("Preferred Courses should not be a same !"); return false;
        }
        else {
            let data = {
                key: config.key,
                topic: topic,
                data: datatemp,
                info: this.props.common.user,
            }
            axios.post(config.curriculaserver + '/curricula/studentcircle/profile/updateadmissiondetails', data)
                .then(async res => {
                    if (res.data.Status === 1) {
                        message.success("Information Updated");
                    } else if (res.data.Status === 0)
                        await message.error(res.data.msg);
                });
        }
    }

    render() {
        let { RegisterNumberX, RegisterNumberXII, MarksX, MarksXII, ADDRESS, MAIL_ID, MOBILE, GENDER, BOARDING, COMMUNITY, FIRST_GRADUATE, FATHER_OCCUPATION, FAMILY_INCOME, PROGRAM_OFFERED, CASTE, REFERENCE, SCHOLARSHIP, USER_ID, FULL_NAME, PREFERRED_COURSE_1, PREFERRED_COURSE_2, ADMISSION_QUOTA, COUNSELLING_INFORMATION } = this.state;
        return (
            <Row gutter={ [16, 16] }>
                <Col xs={ 24 }>
                    <Title level={ 3 }> Admission Information </Title>
                </Col>
                <Col xs={ 24 } md={ 12 }>
                    <Input id="USER_ID" disabled addonBefore="* Aadhaar Number / Username" onChange={ this.onChange } value={ USER_ID } />
                </Col>
                <Col xs={ 24 } md={ 12 }>
                    <Input id="FULL_NAME" disabled addonBefore="* Full Name" onChange={ this.onChange } value={ FULL_NAME } />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input id="RegisterNumberX" addonBefore="* Register Number (10th)" onChange={ this.onChange } value={ RegisterNumberX } />
                </Col>
                <Col xs={ 24 } md={ 12 }>
                    <Input id="MarksX" addonBefore="* Marks Scored (10th)" type="number" onChange={ this.onChange } value={ MarksX } />
                </Col>
                <Col xs={ 24 } md={ 12 }>
                    <Input id="RegisterNumberXII" addonBefore="* Register Number (12th)" onChange={ this.onChange } value={ RegisterNumberXII } />
                </Col>
                <Col xs={ 24 } md={ 12 }>
                    <Input id="MarksXII" addonBefore="* Marks Scored (12th)" type="number" onChange={ this.onChange } value={ MarksXII } />
                </Col>


                <Col xs={ 24 } md={ 12 }>
                    <Input.Group compact>
                        <Input className="FontBook" style={ { width: '20%', color: "#262626", background: "#FAFAFA" } } defaultValue="* Gender" readOnly disabled />
                        <Select style={ { width: "80%" } } onChange={ this.handleChangeSelect.bind(this, "GENDER") } placeholder="Select Gender" value={ GENDER } id="GENDER" >
                            <Option value="" disabled>{ 'Choose One' }</Option>
                            <Option value={ 'MALE' } >MALE</Option>
                            <Option value={ 'FEMALE' }>FEMALE</Option>
                            <Option value="TRANSGENDER">TRANSGENDER</Option>
                        </Select>
                    </Input.Group>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input.Group compact>
                        <Input className="FontBook" style={ { width: '20%', color: "#262626", background: "#FAFAFA" } } defaultValue="* Boarding" readOnly disabled />
                        <Select style={ { width: "80%" } } onChange={ this.handleChangeSelect.bind(this, "BOARDING") } placeholder="Select Boarding" value={ BOARDING }>
                            <Option value="" disabled>{ 'Choose One' }</Option>
                            <Option value={ 'DAYSCHOLAR' } >DAYSCHOLAR</Option>
                            <Option value={ 'COLLEGE_BUS' }>COLLEGE_BUS</Option>
                            <Option value="HOSTELLER">HOSTELLER</Option>
                        </Select>
                    </Input.Group>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input value={ COMMUNITY } addonBefore="* Community" id="COMMUNITY" onChange={ this.onChange } />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input value={ CASTE } addonBefore="* Caste" id="CASTE" onChange={ this.onChange } />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input value={ MAIL_ID } addonBefore="* Communication Mail ID" id="MAIL_ID" onChange={ this.onChange } />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input value={ MOBILE } addonBefore="* Communication Mobile" id="MOBILE" onChange={ this.onChange } type="number" />
                </Col>


                <Col xs={ 24 }>
                    <Text>* Communication Address</Text>
                    <TextArea id="ADDRESS" showCount maxLength={ 200 } onChange={ this.onChange } placeholder="* Communication Address" value={ ADDRESS } />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input.Group compact>
                        <Input className="FontBook" style={ { width: '20%', color: "#262626", background: "#FAFAFA" } } defaultValue="* First Graduate" readOnly disabled />
                        <Select style={ { width: "80%" } } onChange={ this.handleChangeSelect.bind(this, "FIRST_GRADUATE") } placeholder="Select Boarding" value={ FIRST_GRADUATE }>
                            <Option value="" disabled>{ 'Choose One' }</Option>
                            <Option value={ 'YES' } >YES</Option>
                            <Option value={ 'NO' }>NO</Option>
                        </Select>
                    </Input.Group>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input value={ FATHER_OCCUPATION } addonBefore="* Father Occupation" id="FATHER_OCCUPATION" onChange={ this.onChange } />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input value={ FAMILY_INCOME } addonBefore="* Family Annual Income" id="FAMILY_INCOME" onChange={ this.onChange } type="number" />
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Input.Group compact>
                        <Input className="FontBook" style={ { width: '20%', color: "#262626", background: "#FAFAFA" } } defaultValue="* Program Preferred" readOnly disabled />
                        <Select style={ { width: "80%" } } onChange={ this.handleChangeSelect.bind(this, "PROGRAM_OFFERED") } placeholder="Program Preferred" value={ PROGRAM_OFFERED }>
                            <Option value="" disabled>{ 'Choose One' }</Option>
                            <Option value={ 'BE' } >BE</Option>
                            <Option value={ 'BTECH' }>BTECH</Option>
                            <Option value={ 'LATER_ENTRY' }>LATER_ENTRY</Option>
                            <Option value={ 'ME' }>ME</Option>
                            <Option value={ 'MBA' }>MBA</Option>
                            <Option value={ 'MCA' }>MCA</Option>
                        </Select>
                    </Input.Group>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Text>* Preferred Course Choice 1</Text>
                    <Select showSearch id="PREFERRED_COURSE_1" style={ { width: "100%" } }

                        onChange={ this.handleChangeSelect.bind(this, "PREFERRED_COURSE_1") } value={ PREFERRED_COURSE_1 }>
                        <Option value="" disabled>{ 'Choose One' }</Option>
                        {
                            departments.map(
                                (newItem, newVal) => {
                                    return (
                                        newItem !== "ALL" ?
                                            (<Option key={ newVal } value={ newItem }>
                                                { newItem }
                                            </Option>) : ''
                                    )
                                }
                            )
                        }
                    </Select>
                </Col>

                <Col xs={ 24 } md={ 12 }>
                    <Text>* Preferred Course Choice 2</Text>
                    <Select showSearch id="PREFERRED_COURSE_2" style={ { width: "100%" } }
                        onChange={ this.handleChangeSelect.bind(this, "PREFERRED_COURSE_2") } value={ PREFERRED_COURSE_2 }>
                        <Option value="" disabled>{ 'Choose One' }</Option>
                        {
                            departments.map(
                                (newItem, newVal) => {
                                    return (
                                        newItem !== "ALL" ?
                                            (<Option key={ newVal } value={ newItem }>
                                                { newItem }
                                            </Option>) : ''
                                    )
                                }
                            )
                        }
                    </Select>
                </Col>

                <Col xs={ 24 } md={ 24 }>
                    <Input.Group compact>
                        <Input className="FontBook" style={ { width: '20%', color: "#262626", background: "#FAFAFA" } } defaultValue="* Admission Quota" readOnly disabled />
                        <Select style={ { width: "80%" } } onChange={ this.handleChangeSelect.bind(this, "ADMISSION_QUOTA") } value={ ADMISSION_QUOTA }>
                            <Option value="" disabled>{ 'Choose One' }</Option>
                            <Option value={ 'MANAGEMENT' } >MANAGEMENT</Option>
                            <Option value={ 'COUNSELLING' }>COUNSELLING</Option>
                        </Select>
                    </Input.Group>
                </Col>

                <Col xs={ 24 }>
                    <Text>* Counselling Information (if any)</Text>
                    <TextArea id="COUNSELLING_INFORMATION" showCount maxLength={ 300 } onChange={ this.onChange } placeholder="Type here" value={ COUNSELLING_INFORMATION } />
                </Col>

                <Col xs={ 24 }>
                    <Text>* Admission Reference Information</Text>
                    <TextArea id="REFERENCE" showCount maxLength={ 300 } onChange={ this.onChange } placeholder="Type here" value={ REFERENCE } />
                </Col>

                <Col xs={ 24 }>
                    <Text>* Scholarship if any</Text>
                    <TextArea id="SCHOLARSHIP" showCount maxLength={ 200 } onChange={ this.onChange } placeholder="Type here" value={ SCHOLARSHIP } />
                </Col>


                <Col xs={ 24 } md={ 4 } className="mt-1 mb-1">
                    <Button type="primary" style={ { width: "100%" } } htmlType="submit" onClick={ this.submitBtn } disabled={ this.props.common.user.ROLE === 'S' ? true : false }>
                        Submit
                    </Button>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(AdmissionDetails));