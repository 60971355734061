import React, { Component } from 'react'
import { Input, Descriptions, Result, InputNumber, Button, message, Spin, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
const page_name = "course_learning_session";

class CourseLearningSession extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        details: [
            { _id: 1, UNITNAME: "", SESSIONCOUNT: 0, },
            { _id: 2, UNITNAME: "", SESSIONCOUNT: 0, },
            { _id: 3, UNITNAME: "", SESSIONCOUNT: 0, },
            { _id: 4, UNITNAME: "", SESSIONCOUNT: 0, },
            { _id: 5, UNITNAME: "", SESSIONCOUNT: 0, }
        ],
        disable: false,
        loading: true,
    }

    componentDidMount() {
        this.getCourseLC(this.state.COURSE_CODE);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCourseLC(props.COURSE_CODE);
    }

    getCourseLC = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (Array.isArray(record)) {
                            this.setState({ details: record, disable: true });
                        } else {
                            this.setState({
                                details: [
                                    { _id: 1, UNITNAME: "", SESSIONCOUNT: 0, },
                                    { _id: 2, UNITNAME: "", SESSIONCOUNT: 0, },
                                    { _id: 3, UNITNAME: "", SESSIONCOUNT: 0, },
                                    { _id: 4, UNITNAME: "", SESSIONCOUNT: 0, },
                                    { _id: 5, UNITNAME: "", SESSIONCOUNT: 0, }
                                ],
                                disable: false
                            });
                        }
                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("Course Not Selected");
    }

    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details[id];
        if (name === "UNITNAME") e = e.target.value;
        data[name] = e;
        details[id] = data;
        this.setState({ details: details })
    }

    onClickAdd = async () => {
        let { COURSE_CODE, details } = this.state;

        let flag = 1;
        for (let i = 0; i < details.length; i++) {
            if (!details[i].UNITNAME || !details[i].SESSIONCOUNT) {
                flag = 0;
                break;
            }
        };

        if (flag === 0) {
            message.error('Invalid Entry!');
            return false;
        }

        let sendData = {
            data: { course_learning_session: details },
            COURSE_CODE: COURSE_CODE,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/admin/course/addcourseinfo', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
    }


    render() {
        let { details, disable } = this.state;
        return (
            <Spin spinning={this.state.loading}>
                {
                    disable ?
                        <Result
                            status="success"
                            title="Successfully Completed Course Learning Session !"
                            subTitle="Unit Name & Session Count information updated"
                        />
                        : <Result
                            status="error"
                            title="Course Learning Session not Completed !"
                            subTitle="Kindly, fill Unit Name & Session Count information"

                        />

                }

                <Alert message="The unit name and session count information, once entered, cannot be modified or edited. Please ensure you double-check and enter the correct details before submitting." type="info" showIcon />
                <br />

                <Descriptions
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    title="Unit or Module Name & Session Count"
                >
                    {
                        details.length > 0 ?
                            (details.map(
                                (item, val) => {
                                    return (
                                        <Descriptions.Item label={"Unit / Module " + (val + 1)} key={val}>
                                            <Input.Group compact>
                                                <Input placeholder="Unit / Module Name" style={{ width: '70%' }} value={item.UNITNAME} onChange={this.onChangedetails.bind(this, val, "UNITNAME")} />
                                                <InputNumber placeholder="Session Count" style={{ width: '30%' }} value={item.SESSIONCOUNT} onChange={this.onChangedetails.bind(this, val, "SESSIONCOUNT")} min={1} max={30} disabled={disable} />
                                            </Input.Group>
                                        </Descriptions.Item>
                                    );
                                }
                            )
                            ) : ''
                    }

                    {disable ? null :
                        <Descriptions.Item label="Action">
                            <Button type="primary" onClick={this.onClickAdd} disabled={disable}>Submit</Button>
                        </Descriptions.Item>
                    }

                </Descriptions>

            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseLearningSession));