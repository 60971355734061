import React, { Component } from 'react'
import { message, Result, Space, Progress, Table, Card, Tag, Badge, Descriptions, Statistic, Row, Col, Popover, Button, List } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

class CourseStatus extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        error: 1,
        status: {},
        slcArray: [], slczipArray: [],
        slpArray: [], slppdfArray: [], ispringzipArray: [], addresourcesArray: [], slpOverallArray: [], slpSolutionOverallArray: [],
        assessmentArray: [],
        lpsolutionArray: [],
        mcqArray: [],
        sqArray: [],
        loading: true,
        statusPoint: 0,
        statusTotal: 21,
        videocount: 0,
        unit1Status: 0, unit2Status: 0, unit3Status: 0, unit4Status: 0, unit5Status: 0,
        unit1StatusInfo: 0, unit2StatusInfo: 0, unit3StatusInfo: 0, unit4StatusInfo: 0, unit5StatusInfo: 0
    }

    componentDidMount() {
        this.getStatus(this.state.COURSE_CODE);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true, videocount: 0 });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME });
        this.getStatus(props.COURSE_CODE);
    }

    getStatus = async (COURSE_CODE) => {
        if (COURSE_CODE) {
            let flag = 0;
            this.setState({ loading: true });
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "course_learning_session"
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (Object.keys(record).length === 0) {
                            flag = 1;
                            this.setState({ error: 1, loading: false });
                        }
                        else
                            this.setState({ sessions: record, error: 0 });
                    }
                });


            if (flag === 0) {
                sendData = {
                    COURSE_CODE: COURSE_CODE,
                    key: config.key
                };

                axios.post(config.curriculaserver + '/curricula/admin/coursereport/getcoursestatus', sendData)
                    .then(async res => {
                        //console.log(res.data.result)
                        let videocount = 0;
                        if (res.data.Status === 1) {
                            await this.setState({ status: res.data.result });
                            await this.SLC();
                            await this.SLCZIP();
                            await this.ISPRINGZIP();
                            await this.ADDRESOURCESZIP();
                            await this.SLP();
                            await this.SLPOVERALL();
                            await this.SLPPDF();
                            await this.ASSESSMENT();
                            await this.SLPSOLUTION();
                            await this.SLPSOLUTIONOVERALL();
                            await this.MCQ();
                            await this.SQ();
                            await this.LQ();
                            await Object.keys(res.data.result.video).forEach(function (key) { if (res.data.result.video[key]) { videocount++; } });
                            await this.STATUSPOINT(res.data.result, 1, 'unit1Status', 'unit1StatusInfo');
                            await this.STATUSPOINT(res.data.result, 2, 'unit2Status', 'unit2StatusInfo');
                            await this.STATUSPOINT(res.data.result, 3, 'unit3Status', 'unit3StatusInfo');
                            await this.STATUSPOINT(res.data.result, 4, 'unit4Status', 'unit4StatusInfo');
                            await this.STATUSPOINT(res.data.result, 5, 'unit5Status', 'unit5StatusInfo');
                            await this.setState({ videocount, loading: false });
                        }
                    });
            }
        }
        else
            message.error("Course Not Selected");
    }

    statusDisplay = (unitStatusInfo) => {
        let statusArray = [];
        let unitFullStatus = this.state[unitStatusInfo];

        //console.log(unitFullStatus);

        unitFullStatus.CLO ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Course Learning Objective </Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Course Learning Objective</Tag>);

        unitFullStatus.SESSION ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Course Learning Session </Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Course Learning Session</Tag>);

        unitFullStatus.SLOSRO ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Session Learning Objective </Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Session Learning Objective</Tag>);

        unitFullStatus.SP ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Session Plan </Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Session Plan</Tag>);

        unitFullStatus.SLC ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Content PDF</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Content</Tag>);

        unitFullStatus.SLCZIP ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Content PPTx ZIP</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Content PPTx ZIP</Tag>);

        //unitFullStatus.ISPRINGZIP ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">iSpring Source ZIP</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">iSpring Source ZIP</Tag>);

        //unitFullStatus.ADDRESOURCESZIP ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Resources ZIP</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Additional Resources ZIP</Tag>);

        //unitFullStatus.SLP ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Practice DOCX</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Practice DOCX</Tag>);

        //unitFullStatus.SLPPDF ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Practice PDF </Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Practice PDF</Tag>);

        unitFullStatus.SLP ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Practice Overall</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Practice Overall</Tag>);

        unitFullStatus.LPSOLUTION ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Practice Solutions Overall</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Practice Solutions Overall</Tag>);

        //unitFullStatus.LPSOLUTION ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Practice Solutions DOCX</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Practice Solutions DOCX</Tag>);

        unitFullStatus.LM ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Material PDF</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Material PDF</Tag>);

        unitFullStatus.LMDOCX ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Learning Material DOCX</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Learning Material DOCX</Tag>);

        unitFullStatus.MCQ ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Multiple Choice Questions</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Multiple Choice Questions</Tag>);

        unitFullStatus.SQ ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Short Questions </Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Short Questions</Tag>);

        unitFullStatus.LQ ? statusArray.push(<Tag icon={<CheckCircleOutlined />} color="success">Long Questions</Tag>) : statusArray.push(<Tag icon={<CloseCircleOutlined />} color="error">Long Questions</Tag>);

        return <List
            size="small"
            className="statusInfo"
            dataSource={statusArray}
            renderItem={item => <List.Item>{item}</List.Item>}
        />;

    }

    STATUSPOINT = (result, unit, statusname, statusInfoName) => {
        let point = 0;
        let sessionCount = 0;
        let percentage = 0;
        let statusDetails = {
            CLO: 0,
            SESSION: 0,
            SLOSRO: 0,
            SP: 0,
            SLC: 0,
            SLCZIP: 0,
            SLP: 0,
            LPSOLUTION: 0,
            LM: 0,
            LMDOCX: 0,
            MCQ: 0,
            SQ: 0,
            LQ: 0
        };
        if (result.sessionCount.length) {
            let newArray = result.sessionCount.filter(element => element._id === unit);
            sessionCount = newArray[0].SESSIONCOUNT;
        }
        let SLO = sessionCount * 2;

        if (result.course_learning_objective) { point++; statusDetails.CLO = 1; }
        if (result.course_learning_session) { point++; statusDetails.SESSION = 1; }
        if (result.session_learning_objectives.length) {
            let newArray = result.session_learning_objectives.filter(element => element.UnitNo === unit && element.value === 1);
            if (newArray.length) { point++; statusDetails.SLOSRO = 1; }
        }
        if (result.session_plan.length) {
            let newArray = result.session_plan.filter(element => element.UnitNo === unit && element.count > 99);
            if (newArray.length) { point++; statusDetails.SP = 1; }
        }
        if (result.slc.length) {
            let newArray = result.slc.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
            if (newArray.length === SLO) { point++; statusDetails.SLC = 1; }
        }
        if (result.slczip.length) {
            let newArray = result.slczip.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
            if (newArray.length === SLO) { point++; statusDetails.SLCZIP = 1; }
        }
        /*
        if (result.ispringzip.length) {
            let newArray = result.ispringzip.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
            if (newArray.length === SLO) { point++; statusDetails.ISPRINGZIP = 1; }
        }
        if (result.addresources.length) {
            let newArray = result.addresources.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
            if (newArray.length === SLO) { point++; statusDetails.ADDRESOURCESZIP = 1; }
        }
        
       if (result.slp.length) {
           let newArray = result.slp.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
           if (newArray.length === SLO) { point++; statusDetails.SLP = 1; }
       }
       if (result.slppdf.length) {
           let newArray = result.slppdf.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
           if (newArray.length === SLO) { point++; statusDetails.SLPPDF = 1; }
       }
       if (result.lpsolution.length) {
           let newArray = result.lpsolution.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
           if (newArray.length === SLO) { point++; statusDetails.LPSOLUTION = 1; }
       }
       */
        if (result.slpPractice.length) {
            let newArray = result.slpPractice.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
            if (newArray.length === SLO) { point++; statusDetails.SLP = 1; }
        }
        if (result.slpSolutionOverall.length) {
            let newArray = result.slpSolutionOverall.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
            if (newArray.length === SLO) { point++; statusDetails.LPSOLUTION = 1; }
        }
        if (result.unit.length) {
            let newArray = result.unit.filter(element => element === unit);
            if (newArray.length) { point++; statusDetails.LM = 1; }
        }
        if (result.unitdocx.length) {
            let newArray = result.unitdocx.filter(element => element === unit);
            if (newArray.length) { point++; statusDetails.LMDOCX = 1; }
        }
        if (result.mcq.length) {
            let newArray = result.mcq.filter(element => element.UnitNo === unit && element.Count > 4);
            if (newArray.length >= sessionCount) { point++; statusDetails.MCQ = 1; }
        }
        if (result.sq.length) {
            let newArray = result.sq.filter(element => element.UnitNo === unit && element.Count > 1);
            if (newArray.length >= sessionCount) { point++; statusDetails.SQ = 1; }
        }
        if (result.lq.length) {
            let newArray = result.lq.filter(element => element.UnitNo === unit && element.Count > 0);
            if (newArray.length >= sessionCount) { point++; statusDetails.LQ = 1; }
        }
        percentage = parseFloat((point / 13) * 100).toFixed(2);
        this.setState({ [statusname]: percentage, [statusInfoName]: statusDetails });
    }

    SLOSRO = (value) => {
        let status = this.state.status;
        if (Object.keys(status.session_learning_objectives).length !== 0) {
            let temp = status.session_learning_objectives.filter((o) => { return o.UnitNo === value });
            if (temp.length) return <Tag color="success">Unit {value}</Tag>
            else return <Tag color="error">Unit {value}</Tag>;
        }
        else return <Tag color="error">Unit {value}</Tag>;
    }

    SESSIONPLAN = (value) => {
        let status = this.state.status;
        let temp = status.session_plan.filter((o) => { return o.UnitNo === value });
        if (temp.length) {
            return <Space direction="vertical" align="center"><Progress type="circle" percent={temp[0].count} width={60} /> Unit{value} </Space>;
        }
        else {
            return <Space direction="vertical" align="center"><Progress type="circle" percent={0} width={60} /> Unit{value} </Space>;
        }
    }

    SLC = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slc = status.slc;
        let slcArray = [];
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    slcArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    slcArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    )
                }
            });

        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ slcArray, slcColumns: columns });
    }

    SLCZIP = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slc = status.slczip;
        let slczipArray = [];
        let UNIT, SESSION_ID;
        //console.log(sessions, slc)
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    slczipArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    slczipArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    )
                }
            });

        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ slczipArray, slcColumns: columns });
    }

    ISPRINGZIP = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slc = status.ispringzip;
        let ispringzipArray = [];
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    ispringzipArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    ispringzipArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    )
                }
            });

        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ ispringzipArray, slcColumns: columns });
    }

    ADDRESOURCESZIP = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slc = status.addresources;
        let addresourcesArray = [];
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    addresourcesArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    addresourcesArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: slc.includes(SESSION_ID)
                        }
                    )
                }
            });

        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ addresourcesArray, slcColumns: columns });
    }

    SLP = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slp = status.slp;
        let slpArray = [];
        let UNIT, SESSION_ID;

        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    slpArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: slp.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    slpArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: slp.includes(SESSION_ID)
                        }
                    )
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ slpArray, slpColumns: columns });
    }

    SLPOVERALL = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slp = status.slpPractice;

        let DOCX = status.slp;
        let PDF = status.slppdf;
        let ISPRING = status.assessment;

        let slpOverallArray = [];
        let UNIT, SESSION_ID;

        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    slpOverallArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            DOCX: DOCX.includes(SESSION_ID),
                            PDF: PDF.includes(SESSION_ID),
                            ISPRING: ISPRING.includes(SESSION_ID),
                            STATUS: slp.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    slpOverallArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            DOCX: DOCX.includes(SESSION_ID),
                            PDF: PDF.includes(SESSION_ID),
                            ISPRING: ISPRING.includes(SESSION_ID),
                            STATUS: slp.includes(SESSION_ID)
                        }
                    )
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'DOCX',
                dataIndex: 'DOCX',
                key: 'DOCX',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
            {
                title: 'PDF',
                dataIndex: 'PDF',
                key: 'PDF',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
            {
                title: 'ISPRING',
                dataIndex: 'ISPRING',
                key: 'ISPRING',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ slpOverallArray, slpOverallArrayColumns: columns });
    }

    SLPPDF = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let slp = status.slppdf;
        let slppdfArray = [];
        let UNIT, SESSION_ID;

        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    slppdfArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: slp.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    slppdfArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: slp.includes(SESSION_ID)
                        }
                    )
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ slppdfArray, slpColumns: columns });
    }

    SLPSOLUTION = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let lpsolution = status.lpsolution;
        let lpsolutionArray = [];
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    lpsolutionArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: lpsolution.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    lpsolutionArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: lpsolution.includes(SESSION_ID)
                        }
                    )
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ lpsolutionArray, lpsolutionColumns: columns });
    }

    SLPSOLUTIONOVERALL = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let lpsolution = status.slpSolutionOverall;
        let slpSolutionOverallArray = [];
        let UNIT, SESSION_ID;


        let LPSOLUTION = status.lpsolution;
        let ISPRINGZIP = status.ispringzip;

        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 1);
                    slpSolutionOverallArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            LPSOLUTION: LPSOLUTION.includes(SESSION_ID),
                            ISPRINGZIP: ISPRINGZIP.includes(SESSION_ID),
                            STATUS: lpsolution.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = parseInt((UNIT * 100) + SESSION + "" + 2);
                    slpSolutionOverallArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            LPSOLUTION: LPSOLUTION.includes(SESSION_ID),
                            ISPRINGZIP: ISPRINGZIP.includes(SESSION_ID),
                            STATUS: lpsolution.includes(SESSION_ID)
                        }
                    )
                }
            });

        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'DOCX / ZIP',
                dataIndex: 'LPSOLUTION',
                key: 'LPSOLUTION',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
            {
                title: 'ISPRINGZIP (Source)',
                dataIndex: 'ISPRINGZIP',
                key: 'ISPRINGZIP',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ slpSolutionOverallArray, slpSolutionOverallColumns: columns });
    }

    ASSESSMENT = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let assessment = status.assessment;
        let assessmentArray = [];
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = ((UNIT * 100) + SESSION + "" + 1);
                    assessmentArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 1,
                            STATUS: assessment.includes(SESSION_ID)
                        }
                    );
                    SESSION_ID = ((UNIT * 100) + SESSION + "" + 2);
                    assessmentArray.push(
                        {
                            key: SESSION_ID,
                            UNIT: UNIT,
                            SESSION: SESSION,
                            SLO: 2,
                            STATUS: assessment.includes(SESSION_ID)
                        }
                    )
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
            },
            {
                title: 'Status',
                dataIndex: 'STATUS',
                key: 'STATUS',
                render: (text) => (text ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        this.setState({ assessmentArray, assessmentColumns: columns });
    }

    UNIT = (value) => {
        let status = this.state.status;
        if (status.unit.includes(value)) return <Tag color="success">Unit {value}</Tag>
        else return <Tag color="error">Unit {value}</Tag>
    }

    UNITDOCX = (value) => {
        let status = this.state.status;
        if (status.unitdocx.includes(value)) return <Tag color="success">Unit {value}</Tag>
        else return <Tag color="error">Unit {value}</Tag>
    }

    MCQ = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let mcq = status.mcq;
        let mcqArray = mcq;
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION);
                    // eslint-disable-next-line no-loop-func
                    if (!mcq.some(i => i._id === SESSION_ID)) {
                        mcqArray.push(
                            {
                                key: SESSION_ID,
                                Count: 0,
                                SessionId: SESSION_ID,
                                UnitNo: UNIT,
                                _id: SESSION_ID,
                            }
                        );
                    }
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UnitNo',
                key: 'UnitNo',
            },
            {
                title: 'Session',
                dataIndex: 'SessionId',
                key: 'SessionId',
                render: (text) => (Math.round(text % 100)),
            },
            {
                title: 'Count',
                dataIndex: 'Count',
                key: 'Count',
            },
            {
                title: 'Status',
                key: '_id',
                render: (text, record) => (record.Count >= 5 ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        mcqArray.sort((a, b) => parseInt(a.SessionId) - parseInt(b.SessionId));
        this.setState({ mcqArray, mcqColumns: columns });
    }

    SQ = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let sq = status.sq;
        let sqArray = sq;
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION);
                    // eslint-disable-next-line no-loop-func
                    if (!sq.some(i => i._id === SESSION_ID)) {
                        sqArray.push(
                            {
                                key: SESSION_ID,
                                Count: 0,
                                SessionId: SESSION_ID,
                                UnitNo: UNIT,
                                _id: SESSION_ID,
                            }
                        );
                    }
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UnitNo',
                key: 'UnitNo',
            },
            {
                title: 'Session',
                dataIndex: 'SessionId',
                key: 'SessionId',
                render: (text) => (Math.round(text % 100)),
            },
            {
                title: 'Count',
                dataIndex: 'Count',
                key: 'Count',
            },
            {
                title: 'Status',
                key: '_id',
                render: (text, record) => (record.Count >= 2 ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        sqArray.sort((a, b) => parseInt(a.SessionId) - parseInt(b.SessionId));
        this.setState({ sqArray, sqColumns: columns });
    }

    LQ = () => {
        let sessions = this.state.sessions;
        let status = this.state.status;
        let lq = status.lq;
        let lqArray = lq;
        let UNIT, SESSION_ID;
        if (sessions)
            sessions.forEach(function (entry) {
                UNIT = entry._id;
                for (let SESSION = 1; SESSION <= entry.SESSIONCOUNT; SESSION++) {
                    SESSION_ID = parseInt((UNIT * 100) + SESSION);
                    // eslint-disable-next-line no-loop-func
                    if (!lq.some(i => i._id === SESSION_ID)) {
                        lqArray.push(
                            {
                                key: SESSION_ID,
                                Count: 0,
                                SessionId: SESSION_ID,
                                UnitNo: UNIT,
                                _id: SESSION_ID,
                            }
                        );
                    }
                }
            });
        const columns = [
            {
                title: 'Unit',
                dataIndex: 'UnitNo',
                key: 'UnitNo',
            },
            {
                title: 'Session',
                dataIndex: 'SessionId',
                key: 'SessionId',
                render: (text) => (Math.round(text % 100)),
            },
            {
                title: 'Count',
                dataIndex: 'Count',
                key: 'Count',
            },
            {
                title: 'Status',
                key: '_id',
                render: (text, record) => (record.Count >= 1 ? <img alt="Done" height="20" src={require('../../../images/check.svg').default} /> : <img alt="Done" height="20" src={require('../../../images/close.svg').default} />),
            },
        ];
        lqArray.sort((a, b) => parseInt(a.SessionId) - parseInt(b.SessionId));
        this.setState({ lqArray, lqColumns: columns });
    }

    render() {
        let { COURSE_CODE, COURSE_NAME, status } = this.state;
        let done = <img alt="Done" height="20" src={require('../../../images/check.svg').default} />
        let notCompleted = <img alt="Done" height="20" src={require('../../../images/close.svg').default} />
        
        return (
            <Card type="inner"
                title={"Course Status"} className="mt-1 mb-1"
                style={{ width: "100%" }}
                loading={this.state.loading}
                extra={<><Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>}> <br />
                {
                    !this.state.loading && this.state.error === 0 ?
                        (
                            <>
                                <Row gutter={[16, 16]} className="mt-1 mb-2" justify="center">
                                    <Col xs={24} align="middle">
                                        <h1>{COURSE_NAME + " (" + COURSE_CODE + ") - Detailed Status"}</h1></Col>
                                    <Col xs={24} md={4}>
                                        <Space direction="vertical" align="center">
                                            <Progress type="circle" percent={parseFloat(this.state.unit1Status)} />
                                            <Popover content={this.statusDisplay('unit1StatusInfo')} title="Completion Status" placement="bottom">
                                                <Button type="primary">Unit 1</Button>
                                            </Popover>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Space direction="vertical" align="center">
                                            <Progress type="circle" percent={parseFloat(this.state.unit2Status)} />
                                            <Popover content={this.statusDisplay('unit2StatusInfo')} title="Completion Status" placement="bottom">
                                                <Button type="primary">Unit 2</Button>
                                            </Popover>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Space direction="vertical" align="center">
                                            <Progress type="circle" percent={parseFloat(this.state.unit3Status)} />
                                            <Popover content={this.statusDisplay('unit3StatusInfo')} title="Completion Status" placement="bottom">
                                                <Button type="primary">Unit 3</Button>
                                            </Popover>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Space direction="vertical" align="center">
                                            <Progress type="circle" percent={parseFloat(this.state.unit4Status)} />
                                            <Popover content={this.statusDisplay('unit4StatusInfo')} title="Completion Status" placement="bottom">
                                                <Button type="primary">Unit 4</Button>
                                            </Popover>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Space direction="vertical" align="center">
                                            <Progress type="circle" percent={parseFloat(this.state.unit5Status)} />
                                            <Popover content={this.statusDisplay('unit5StatusInfo')} title="Completion Status" placement="bottom">
                                                <Button type="primary">Unit 5</Button>
                                            </Popover>
                                        </Space>
                                    </Col>
                                </Row>

                                <Descriptions
                                    bordered
                                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                    className="mb-1"
                                    size="small"
                                >
                                    <Descriptions.Item label="Course Code">{COURSE_CODE}</Descriptions.Item>
                                    <Descriptions.Item label="Course Name">{COURSE_NAME}</Descriptions.Item>
                                    <Descriptions.Item label="Course Structure" span={2}>
                                        {status.course_structure ? done : notCompleted}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Course Syllabus" span={2}>
                                        {status.syllabus ? done : notCompleted}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Course Outcomes (CO)" span={2}>
                                        {status.course_learning_objective ? done : notCompleted}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Course Learning Session" span={2}>
                                        {status.course_learning_session ? done : notCompleted}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="SLO & SLR" span={2}>
                                        {this.SLOSRO(1)}
                                        {this.SLOSRO(2)}
                                        {this.SLOSRO(3)}
                                        {this.SLOSRO(4)}
                                        {this.SLOSRO(5)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Session Learning Plan" span={2}>
                                        <Space size="large">
                                            {this.SESSIONPLAN(1)}
                                            {this.SESSIONPLAN(2)}
                                            {this.SESSIONPLAN(3)}
                                            {this.SESSIONPLAN(4)}
                                            {this.SESSIONPLAN(5)}
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Learning Content PDF" span={2}>
                                        <Table loading={this.state.slcArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.slcArray} columns={this.state.slcColumns} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Learning Content PPTx (ZIP)" span={2}>
                                        <Table loading={this.state.slczipArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.slczipArray} columns={this.state.slcColumns} />
                                    </Descriptions.Item>
                                    {
                                        /*
                                        <Descriptions.Item label="Learning Practice DOCX" span={2}>
                                            <Table loading={this.state.slpArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.slpArray} columns={this.state.slpColumns} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Learning Practice PDF" span={2}>
                                            <Table loading={this.state.slppdfArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.slppdfArray} columns={this.state.slpColumns} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="iSpring Assessment" span={2}>
                                            <Table loading={this.state.assessmentArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.assessmentArray} columns={this.state.assessmentColumns} />
                                        </Descriptions.Item>
                                        */
                                    }
                                    <Descriptions.Item label="Learning Practice Overall Status" span={2}>
                                        <Table loading={this.state.slpOverallArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.slpOverallArray} columns={this.state.slpOverallArrayColumns} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Learning Practice Solutions Overall Status" span={2}>
                                        <Table loading={this.state.slpSolutionOverallArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.slpSolutionOverallArray} columns={this.state.slpSolutionOverallColumns} />
                                    </Descriptions.Item>
                                    {
                                        /*
                                        <Descriptions.Item label="Learning Practice Solutions DOCX" span={2}>
                                            <Table loading={this.state.lpsolutionArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.lpsolutionArray} columns={this.state.lpsolutionColumns} />
                                        </Descriptions.Item>
                                    
                                        <Descriptions.Item label="iSpring Source ZIP" span={2}>
                                            <Table loading={this.state.ispringzipArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.ispringzipArray} columns={this.state.slcColumns} />
                                        </Descriptions.Item>
                                        */
                                    }
                                    <Descriptions.Item label="Learning Material PDF" span={2}>
                                        {this.UNIT(1)}
                                        {this.UNIT(2)}
                                        {this.UNIT(3)}
                                        {this.UNIT(4)}
                                        {this.UNIT(5)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Learning Material Source DOCX" span={2}>
                                        {this.UNITDOCX(1)}
                                        {this.UNITDOCX(2)}
                                        {this.UNITDOCX(3)}
                                        {this.UNITDOCX(4)}
                                        {this.UNITDOCX(5)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="MCQ" span={2}>
                                        <Table loading={this.state.mcqArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.mcqArray} columns={this.state.mcqColumns} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Short Questions" span={2}>
                                        <Table loading={this.state.sqArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.sqArray} columns={this.state.sqColumns} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Long Questions" span={2}>
                                        <Table loading={this.state.lqArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.lqArray} columns={this.state.lqColumns} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Video Materials" span={2}>
                                        <Statistic title="Total Videos" value={this.state.videocount} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Reference Materials" span={2}>
                                        <Statistic title="Total References" value={status.reference} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Resources ZIP" span={2}>
                                        <Table loading={this.state.addresourcesArray.length === 0 ? true : false} className="mt-1" size="small" dataSource={this.state.addresourcesArray} columns={this.state.slcColumns} />
                                    </Descriptions.Item>
                                </Descriptions>
                            </>
                        ) : ''
                }
                {
                    this.state.error === 1 ? (
                        <Result
                            status="404"
                            title="Course Learning Session is Empty"
                            subTitle="Unit and Session information are empty"
                        />
                    ) : ''
                }
            </Card>
        )
    }
}
const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseStatus));