import React, { Component } from 'react'
import { Row, Col, Card, Button, Select, Input, Empty, DatePicker, Tooltip, Tag, Table, notification, Alert, Popconfirm } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { SearchOutlined, UploadOutlined, DeleteOutlined, MinusOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;
const dateFormat = ['DD/MM/YYYY'];
const topic = "patent", subtopic = "patent";
const subjects = ["AGRICULTURAL_SCIENCES", "ARTS_AND_HUMANITIES", "BIOLOGICAL_SCIENCES", "CHEMICAL_SCIENCES", "ENGINEERING_AND_TECHNOLOGY", "MEDICAL_AND_HEALTH_SCIENCES", "PHYSICAL_SCIENCES", "SOCIAL_SCIENCES"];
const roleList = ["INVENTOR", "COINVENTOR"];
const statusList = ["PROVISIONAL_REGISTRATION", "COMPLETE_REGISTRATION", "PUBLISHED", "EXAMINATION_PROCESS", "GRANTED", "REJECTED"];

const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class Patent extends Component {
    state = {
        title: '',
        field: '',
        subjectCategory: '',
        particulars: '',
        country: '',
        filingDate: '01/01/2020',
        details: [],
        applicationNumber: '',
        status: '',
        journalNumber: '',
        dateOfPublication: '01/01/2020',
        dateOfAvailability: '01/01/2020',
        validUpto: '01/01/2020',
        patentNumber: '',
        dateOfGranted: '01/01/2020',
        resaonForRejection: '',
        staffCount: 0,
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }


    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            subtopic: subtopic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/getresearchtable', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        if (e.target.id === "staffCount") {
            var details = [
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME: this.props.common.user.FIRST_NAME + " " + this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: config.institutionName
                }
            ]
            for (let i = 1; i < e.target.value; i++) {
                let newDetail = {
                    _id: details.length + 1,
                    EMPLOYEEID: "",
                    ROLE: "",
                    NAME: "",
                    DEPARTMENT: "",
                    INSTITUTION: ""
                }
                details.push(newDetail);
            }
            this.setState({ details: details })
        }
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickSubmitBtn = () => {
        let { title, journalNumber, applicationNumber, dateOfAvailability, dateOfPublication, country, patentNumber, status, subjectCategory, filingDate, resaonForRejection, dateOfGranted, details, validUpto } = this.state;
        let errorMessages = [];

        if (!title) errorMessages.push("Invalid Title");
        else if (!subjectCategory) errorMessages.push("Invalid Subject Category");
        else if (!country) errorMessages.push("Invalid Filing Country");
        else if (!filingDate) errorMessages.push("Invalid Filing Date");
        else if (details.length < 1) errorMessages.push("Invalid Details");
        else if (details.length > 0) {
            for (let i = 0; i < details.length; i++) {
                if (!details[i].EMPLOYEEID || !details[i].ROLE || !details[i].NAME || !details[i].DEPARTMENT || !details[i].INSTITUTION) {
                    errorMessages.push("Invalid Authors Information");
                    break;
                }
            };
        }
        else if (!applicationNumber) errorMessages.push("Invalid Application Number");
        else if (!status) errorMessages.push("Select Status");
        else if (status === "GRANTED" || status === "PUBLISHED" || status === "EXAMINATION_PROCESS") {
            if (!journalNumber) errorMessages.push("Invalid Journal Number");
            else if (!dateOfPublication) errorMessages.push("Invalid Publication Date");
            else if (!dateOfAvailability) errorMessages.push("Invalid Date of Availability");
            else if (!validUpto) errorMessages.push("Invalid Validity Date");
        }
        else if (status === "GRANTED") {
            if (!patentNumber) errorMessages.push("Invalid Patent Number");
            else if (!dateOfGranted) errorMessages.push("Invalid dateOfGranted");
        }
        else if (status === "REJECTED") {
            if (!resaonForRejection) errorMessages.push("Invalid Resaon for Rejection");
        }

        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            subtopic: subtopic,
            data: {
                title: this.state.title,
                field: this.state.field,
                subjectCategory: this.state.subjectCategory,
                particulars: this.state.particulars,
                country: this.state.country,
                filingDate: this.state.filingDate,
                staffCount: this.state.staffCount,
                details: this.state.details,
                applicationNumber: this.state.applicationNumber,
                status: this.state.status,
                journalNumber: this.state.journalNumber,
                dateOfPublication: this.state.dateOfPublication,
                dateOfAvailability: this.state.dateOfAvailability,
                validUpto: this.state.validUpto,
                patentNumber: this.state.patentNumber,
                dateOfGranted: this.state.dateOfGranted,
                resaonForRejection: this.state.resaonForRejection,
                _id: this.state._id,
                key: this.state.key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/research/addresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/research/patent/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/researchupload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End



                    //update this state.data
                    let dataSet = this.state.data;

                    if (this.state.key === '')
                        dataSet.push(res.data.value)
                    else {
                        for (let i = 0; i < dataSet.length; i++) {
                            let element = dataSet[i];
                            if (element._id === res.data.key) {
                                dataSet[i] = res.data.value;
                                break;
                            };
                        }
                    }
                    await this.setState(this.initialState);
                    await this.setState({ data: dataSet, errorMessages: ["success"], update: false });
                    //update this.state.data ends 
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/personal/research/patent/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/personal/research/patent/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/personal/research/patent/' + record.key + ".pdf"
        });
        //File get End
    }


    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = async (record) => {
        let dataSet = this.state.data;
        dataSet = dataSet.filter(element => element.key !== record.key);
        let data = {
            _id: record._id,
            data: record,
            topic: topic,
            subtopic: subtopic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/research/patent/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/deleteresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: dataSet });
                }
            });
    }

    onAdddetails = () => {
        let details = this.state.details;
        let staffCount = parseInt(this.state.staffCount);
        if (staffCount > 6) return false;
        if (!staffCount) staffCount = 0;
        if (staffCount === 0 || details.length === 0)
            details.push(
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME:
                        this.props.common.user.FIRST_NAME +
                        " " +
                        this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: config.institutionName
                });
        else {
            let newDetail = {
                _id: details.length + 1,
                EMPLOYEEID: "",
                ROLE: "",
                NAME: "",
                DEPARTMENT: "",
                INSTITUTION: ""
            };
            details.push(newDetail);
        }
        staffCount++;
        this.setState({ errorMessages: [], details, staffCount });
    };

    onRemovedetails = () => {
        let details = this.state.details;
        let staffCount = this.state.staffCount;
        if (staffCount === 0 || !staffCount) return false;
        details.pop();
        staffCount--;
        this.setState({ details, staffCount, disable: false });
    };

    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = e.target.value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    onChangedetailsSelect = (id, name, value) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    searchEmployee = (id, employeeid) => {
        let data = {
            employeeId: employeeid,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getusername', data)
            .then(res => {
                if (res.data.result) {
                    let result = res.data.result;
                    var details = this.state.details;
                    let data = details[id];
                    data.NAME = result.employeeName;
                    data.DEPARTMENT = result.department;
                    data.INSTITUTION = result.institution;
                    details[id] = data;
                    this.setState({ errorMessages: [], details: details, disable: false })
                }
            })
    }


    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { title, journalNumber, applicationNumber, staffCount, dateOfAvailability, dateOfPublication, particulars, field, country, patentNumber, status, subjectCategory, filingDate, resaonForRejection, edit, data, validUpto } = this.state;
        let columns = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 4,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 2,
            },
            {
                title: 'Verified',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 3,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 3,
            },
        ];


        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }> IPR / Patent
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>
                    <Col xs={ 24 } md={ 24 }>
                        <Input id="title" addonBefore="Title" onChange={ this.onChange } value={ title } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="field" addonBefore="Field of Innovation" onChange={ this.onChange } value={ field } />
                    </Col>
                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 6 }>
                                    <Input defaultValue="* Particulars" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 18 }>
                                    <TextArea id="particulars" placeholder="Type Here" onChange={ this.onChange } value={ particulars } rows={ 1 } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Category" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select showSearch id="subjectCategory" style={ { width: "100%" } } placeholder="* Type" onChange={ this.handleChangeSelect.bind(this, "subjectCategory") } value={ subjectCategory }>
                                        {
                                            subjects.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={ val } value={ item }>{ item.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>



                    <Col xs={ 24 } md={ 8 }>
                        <Input id="country" addonBefore="* Filing Country" onChange={ this.onChange } value={ country } />
                    </Col>


                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 9 }>
                                    <Input defaultValue="* Filing Date" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 15 }>
                                    <DatePicker id="filingDate" placeholder="* Filing Date" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "filingDate") } value={ moment(filingDate, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="applicationNumber" addonBefore="* Application Number" onChange={ this.onChange } value={ applicationNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Status" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select showSearch id="status" style={ { width: "100%" } } placeholder="* Status" onChange={ this.handleChangeSelect.bind(this, "status") } value={ status }>
                                        {
                                            statusList.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={ val } value={ item }>{ item.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    {
                        status === "GRANTED" || status === "PUBLISHED" || status === "EXAMINATION_PROCESS" ?
                            (<>

                                <Col xs={ 24 } md={ 8 }>
                                    <Input id="journalNumber" addonBefore="* Journal Number" onChange={ this.onChange } value={ journalNumber } />
                                </Col>


                                <Col xs={ 24 } md={ 8 }  >
                                    <InputGroup>
                                        <Row>
                                            <Col xs={ 24 } md={ 12 }>
                                                <Input defaultValue="* Published Date" disabled className="newLabel" />
                                            </Col>
                                            <Col xs={ 24 } md={ 12 }>
                                                <DatePicker id="dateOfPublication" placeholder="* Date of Publication" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "dateOfPublication") } value={ moment(dateOfPublication, dateFormat) } />
                                            </Col>
                                        </Row>
                                    </InputGroup>
                                </Col>

                                <Col xs={ 24 } md={ 8 }  >
                                    <InputGroup>
                                        <Row>
                                            <Col xs={ 24 } md={ 12 }>
                                                <Input defaultValue="* Availability Date" disabled className="newLabel" />
                                            </Col>
                                            <Col xs={ 24 } md={ 12 }>
                                                <DatePicker id="dateOfAvailability" placeholder="* Date of Availability" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "dateOfAvailability") } value={ moment(dateOfAvailability, dateFormat) } />
                                            </Col>
                                        </Row>
                                    </InputGroup>
                                </Col>

                                <Col xs={ 24 } md={ 8 }  >
                                    <InputGroup>
                                        <Row>
                                            <Col xs={ 24 } md={ 12 }>
                                                <Input defaultValue="* Valid Upto" disabled className="newLabel" />
                                            </Col>
                                            <Col xs={ 24 } md={ 12 }>
                                                <DatePicker id="validUpto" placeholder="* Valid Upto" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "validUpto") } value={ moment(validUpto, dateFormat) } />
                                            </Col>
                                        </Row>
                                    </InputGroup>
                                </Col>

                                {
                                    status === "GRANTED" ?
                                        (
                                            <>
                                                <Col xs={ 24 } md={ 8 }>
                                                    <Input id="patentNumber" addonBefore="* Patent Number" onChange={ this.onChange } value={ patentNumber } />
                                                </Col>

                                            </>
                                        ) : ''
                                }


                            </>
                            ) : status === "REJECTED" ?
                                (

                                    <Col xs={ 24 } md={ 16 }  >
                                        <InputGroup>
                                            <Row>
                                                <Col xs={ 24 } md={ 8 }>
                                                    <Input defaultValue="* State reason for Rejection" disabled className="newLabel" />
                                                </Col>
                                                <Col xs={ 24 } md={ 16 }>
                                                    <TextArea id="resaonForRejection" onChange={ this.onChange } value={ resaonForRejection } rows={ 1 } autoSize={ true } />
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                    </Col>

                                ) : ''
                    }

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='staffCount' type="number" disabled addonBefore=" * Number of Authors" onChange={ this.onChange } value={ staffCount } />
                    </Col>

                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="Project Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>New</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.details.length > 0 ?
                                    (this.state.details.map(
                                        (item, val) => {
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-1">
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ (val + 1) + ". Employee ID" } id={ val } addonAfter={ <SearchOutlined onClick={ this.searchEmployee.bind(this, val, (item.EMPLOYEEID)) } /> } value={ item.EMPLOYEEID } onChange={ this.onChangedetails.bind(this, val, "EMPLOYEEID") }
                                                            disabled={ val === 0 ? true : false }
                                                        />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <InputGroup>
                                                            <Row style={ { width: "100%" } }>
                                                                <Col xs={ 24 } md={ 6 }>
                                                                    <Input defaultValue="Role" disabled className="newLabel" />
                                                                </Col>
                                                                <Col xs={ 24 } md={ 18 }>
                                                                    <Select style={ { width: "100%" } } id={ val } onChange={ this.onChangedetailsSelect.bind(this, val, "ROLE") } value={ item.ROLE }>
                                                                        <Option value="" disabled>Select Role</Option>
                                                                        {
                                                                            roleList.map(
                                                                                (newItem, newVal) => {
                                                                                    return (
                                                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ "Name" } id={ val } value={ item.NAME } onChange={ this.onChangedetails.bind(this, val, "NAME") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Department" } id={ val } value={ item.DEPARTMENT } onChange={ this.onChangedetails.bind(this, val, "DEPARTMENT") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Institution" } id={ val } value={ item.INSTITUTION } onChange={ this.onChangedetails.bind(this, val, "INSTITUTION") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>


                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } >
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } bordered
                            size="middle" columns={ columns } scroll={ { x: 1000 } } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Patent));