import React from 'react';
import { Layout, Tag, Card, PageHeader, Typography, Row, Col, Image, Button, Modal, Carousel, message, Avatar, notification } from 'antd';
import Image1 from '../../images/wall6.gif'
import Finger from '../../images/145.svg'
import Login from './Login1'
import config from "../../config";
import { MailOutlined, ArrowRightOutlined, RightSquareTwoTone } from '@ant-design/icons';
import CollegeLogo from '../../images/college/EM.jpg'
import axios from "axios";
import { color } from 'd3';
const { Content } = Layout;
const { Text, Title } = Typography;
const contentStyle = {
    height: '160px',
    color:'black',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#AFC8FF',
};

class App extends React.Component {

    state = {
        loginModal: false,
        loginInfo: {},
        eserverstatus: 0
    }

    componentDidMount() {
        this.detec();
        let data = {
            key: config.key
        };

        //Server Status 
        axios.post(config.curriculaserver + "/curricula/checkstatus", data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ flags: res.data.flag });
                    localStorage.setItem("flags", JSON.stringify(res.data.flag));
                }
                else this.setState({ eserverstatus: 1 });
            })
            .catch(err => {
                this.setState({ eserverstatus: 1 });
            });
    }

    detec = async () => {
        let a;
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            a = true;
        } else {
            a = false;
        }

        if (a) {
            notification.open({
                message: 'Mobile Device Detected',
                description:
                    'Open the website in chrome browser. Tap on the menu options in the top right side and select the checkbox against the Desktop site.',
                duration: 10,
            });
        }
        else {
            if (!!window.chrome === false) message.warning("This browser is not fully supported yet, For best results please use Chrome.", 10);
            else message.success("Google Chrome browser v95+ is fully supported.");
        }
        const res = await axios.get('https://geolocation-db.com/json/'); this.setState({ loginInfo: res.data });
        message.info('Your IP Address ' + res.data.IPv4 + " Logged !");
    }

    render() {
        let { loginModal, eserverstatus } = this.state;
        let toolname = config.toolname + " " + config.totalYear;

        return (
            <Layout>
                <Layout className="site-layout mainpage">
                <Row>
                 <Col md={8} lg={8}>
                    {" "}
                      <div>
                          <img alt="logo" className="XYZ" src={CollegeLogo} />
                    </div>{" "}
                 </Col>
                

                    <PageHeader
                        style={{ marginTop: 15 }}
                        className="site-page-header"
                        // avatar={{ src: CollegeLogo }}
                        title={<>
                            <span>{config.offerDeptName} </span> </>}
                        // extra={[
                        //     <Button key="1" target="_blank" href={"mailto:" + config.supportMailID} className="hide overline" icon={<MailOutlined />} type="primary">
                        //         {config.supportMailID}
                        //     </Button>,
                            
                        // ]
                        // }
                    />
                 
                </Row>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '-20px 24px 16px',
                            padding: 24,
                            minHeight: 280,
                        }}
                    >
                        <Card  bordered={true} style={{ width: "100%" }}>
                            <Row justify="center" align="middle" gutter={[16, 16]} style={{ marginTop: "2%", marginBottom: "2%" }}>
                            <Col md={8} lg={8} align="middle">
                            <Title level={4}>
                                        <Avatar size={60} src={Finger} /> {config.toolShortname}
                                    </Title>
                                    <Image
                                        preview={false}
                                        width={300}
                                        src={Image1}
                                    />
                                </Col>
                                <Col md={14} lg={14} style={{ textAlign: "justify", paddingLeft: "5%" }}>
                                   

                                    <Title level={1}>
                                        {toolname}
                                    </Title>

                                    <Text style={{ fontSize: "14px" }}> {config.about}</Text>
                                    <br />
                                    <br />
                                    <Text style={{ fontSize: "14px" }}> {config.about1}</Text>
                                    <br />
                                    <br />
                                    {
                                        eserverstatus === 1 ?
                                            <>
                                                <Button type="primary" danger icon={<ArrowRightOutlined />} size="large">SERVER OFFLINE</Button>
                                                <Text className='ml-2' style={{ fontSize: "12px", fontWeight: 700 }}>  ITMS undergoing a bit of scheduled maintenance !! We will be back soon, stay tuned!</Text>
                                            </> :
                                            <Button type="primary" icon={<RightSquareTwoTone />} size="large" onClick={() => { this.setState({ loginModal: true }) }}>Enter</Button>
                                    }
                                </Col>

                                
                            </Row>
                        </Card>
                    </Content>

                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '0px 24px 6px',
                            padding: 24,
                        }}
                    >
                        <Carousel autoplay effect={"fade"} easing >
                            {
                                config.firstPageSlogans.map((entry, val) => {
                                    return <div key={val}> <h3 style={contentStyle}>{entry}</h3> </div>
                                })
                            }
                        </Carousel>
                    </Content>

                    <Modal className="courseView" visible={loginModal} footer={null} closable={false}
                        bodyStyle={{ textAlign: "center" }} destroyOnClose
                        onCancel={() => { this.setState({ loginModal: false }) }}
                    >
                        <Row justify="center" align="middle" gutter={[16, 16]}>
                            <Col span={24} align="middle">
                                <Login />
                            </Col>
                        </Row>
                    </Modal>

                    
                </Layout>
            </Layout >
        );
    }
}

export default App;