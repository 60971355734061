import React, { Component } from 'react'
import { Button, InputNumber, message, Select, Result, Row, Col, DatePicker, Card, Tag, Badge, Descriptions, Empty, Input, List, Space, Divider, Radio, Typography } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import CurveChart from '../../../chart/CurveChart';
import Donut from '../../../chart/Donut';
import moment from 'moment';
import { EditOutlined, MinusOutlined, PlusOutlined, DownloadOutlined, AppstoreOutlined, ControlOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

class QuestionPaper extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        MCQ_COUNT: 0, SQ_COUNT: 0, LQ_COUNT: 0, key: 0,
        MCQ: [], SQ: [], LQ: [], curve: '', details: '', questionType: '',
        MCQ_MARKS: 1, SQ_MARKS: 1, LQ_MARKS: 1,
        TEST_NAME: "Unit Test Question Paper, August 2020",
        DATE: moment().format("DD/MM/YYYY"),
        _html: "",
        loading2: 0,
        file: "",
        path: "",
        error: 0, errorMsg: [],
        DURATION: 1,
        NOTICE: [{
            _id: 1, DESC: "",
        }],
        SQ_MANDATORY: "", LQ_MANDATORY: "", LQ_TYPE: 0,
        loading: false,
        StatusError: 0,
    }

    componentDidMount() {
        this.getStatus(this.state.COURSE_CODE);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: false });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME });
        this.getStatus(props.COURSE_CODE);
    }

    getStatus = (COURSE_CODE) => {
        if (COURSE_CODE) {

            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "course_learning_session"
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (Object.keys(record).length === 0) {
                            this.setState({ StatusError: 1 })
                        }
                        this.setState({ loading: false });
                    }
                });
        }
        else message.error("Course Not Selected");
    }

    onChange = (label, value) => {
        this.setState({ [label]: value, key: 1 });
        let MCQ = [...this.state.MCQ];
        let SQ = [...this.state.SQ];
        let LQ = [...this.state.LQ];
        if (label === "MCQ_COUNT") {
            if (this.state.MCQ.length < value) {
                for (let i = this.state.MCQ.length; i < value; i++) {
                    let temp = {
                        _id: i,
                        type: "mcq",
                        unit: 1,
                        level: 1,
                        key: i + 1
                    }
                    MCQ.push(temp);
                }
            }
            else
                for (let i = value; i > this.state.MCQ.length; i--)
                    MCQ.pop();
            this.setState({ MCQ });
        }
        else if (label === "SQ_COUNT") {
            if (this.state.SQ_COUNT < value) {
                for (let i = this.state.SQ.length; i < value; i++) {
                    let temp = {
                        _id: i,
                        type: "sq",
                        unit: 1,
                        level: 1,
                        key: i + 1
                    }
                    SQ.push(temp);
                }

            }
            else
                for (let i = value; i > this.state.SQ.length; i--)
                    SQ.pop();
            this.setState({ SQ });
        }
        else if (label === "LQ_COUNT") {
            if (this.state.LQ_COUNT < value) {
                for (let i = this.state.LQ.length; i < value; i++) {
                    let temp = {
                        _id: i,
                        type: "lq",
                        unit: 1,
                        level: 1,
                        key: i + 1
                    }
                    LQ.push(temp);
                }

            }
            else
                for (let i = value; i > this.state.LQ.length; i--)
                    LQ.pop();
            this.setState({ LQ });
        }
    }

    onChangeDatePicker = (date, dateString) => {
        let timeStamp = (moment(date).unix());
        this.setState({ DATE: timeStamp });
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onChangeUnit = async (id, name, type, value) => {
        var TYPE = this.state[type];
        let data = TYPE[id];
        data[name] = parseInt(value);
        data["key"] = id + 1;
        TYPE[id] = data;
        await this.setState({ [type]: TYPE, loading2: 0 });
        await this.getCurve();
    }

    onChangeSelect = (type, value) => {
        this.setState({ [type]: value });
    }

    getCurve = () => {
        let data = [], count = 1, unit = [];

        let MCQ = [...this.state.MCQ];
        MCQ.forEach(function (entry) {
            data.push({ Question: "Question " + count++, Level: entry.level })
            unit.push(entry.unit);
        });
        let SQ = [...this.state.SQ];
        SQ.forEach(function (entry) {
            data.push({ Question: "Question " + count++, Level: entry.level })
            unit.push(entry.unit);
        });
        let LQ = [...this.state.LQ];
        LQ.forEach(function (entry) {
            data.push({ Question: "Question " + count++, Level: entry.level })
            unit.push(entry.unit);
        });

        let curve = {
            data: data,
            title: "Bloom’s Thinking Levels",
            xField: "Question",
            yField: "Level"
        };

        var newdata = data.sort((a, b) => b.Level - a.Level);
        let result = Array
            .from(newdata.keys())
            .sort((a, b) => b % 2 - a % 2 || (a % 2 ? b - a : a - b))
            .map(i => newdata[i]);
        newdata = result;

        let gauCurve = {
            data: newdata,
            title: "Bloom’s Thinking Levels",
            xField: "Question",
            yField: "Level"
        };

        let details = {
            data: [
                { Unit: 'Unit 1', Count: unit.filter(x => x === 1).length || 0 },
                { Unit: 'Unit 2', Count: unit.filter(x => x === 2).length || 0 },
                { Unit: 'Unit 3', Count: unit.filter(x => x === 3).length || 0 },
                { Unit: 'Unit 4', Count: unit.filter(x => x === 4).length || 0 },
                { Unit: 'Unit 5', Count: unit.filter(x => x === 5).length || 0 },
            ],
            title: "Unit-wise Weightage",
            xField: "Unit",
            yField: "Count"
        };
        let questionType = {
            data: [
                { Type: 'MCQ', Count: this.state.MCQ_COUNT },
                { Type: 'Short Questions', Count: this.state.SQ_COUNT },
                { Type: 'Long Questions', Count: this.state.LQ_COUNT },
            ],
            title: "Question-type Weightage",
            xField: "Type",
            yField: "Count"
        };
        this.setState({ curve, details, questionType, gauCurve });
    }

    generateQuestionPaper = () => {
        this.setState({ loading2: 1, errorMsg: [] });
        let { MCQ, SQ, LQ, COURSE_CODE, COURSE_NAME, MCQ_MARKS, SQ_MARKS, LQ_MARKS, DATE, TEST_NAME, DURATION, NOTICE, LQ_TYPE, SQ_MANDATORY, LQ_MANDATORY } = this.state;
        let sendData = {
            COURSE_CODE: COURSE_CODE,
            COURSE_NAME: COURSE_NAME,
            MCQ: MCQ,
            SQ: SQ,
            LQ: LQ,
            MCQ_MARK: MCQ_MARKS,
            SQ_MARK: SQ_MARKS,
            LQ_MARK: LQ_MARKS,
            DATE: DATE,
            TEST_NAME: TEST_NAME,
            server: config.curriculaserver,
            client: config.curriculaclient,
            DURATION: DURATION,
            NOTICE: NOTICE,
            LQ_TYPE: LQ_TYPE,
            SQ_MANDATORY: SQ_MANDATORY,
            LQ_MANDATORY: LQ_MANDATORY,
            college: config.collegeName,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/questionpaper/get', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    this.setState({ _html: res.data.html, path: res.data.htmlPath, loading2: 2, error: 0 })
                else if (res.data.Status === 0)
                    this.setState({ errorMsg: res.data.msg, error: 1, loading2: 3 })
            });
    }

    setButtonLink = () => {
        let folderlink = "questionpaper";

        let sendData = {
            path: folderlink,
            filename: this.state.COURSE_CODE + ".html",
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    onChangedetails = (id, name, e) => {
        var NOTICE = this.state.NOTICE;
        let data = NOTICE[id];
        data[name] = e.target.value;
        NOTICE[id] = data;
        this.setState({ NOTICE: NOTICE })
    }

    onAdddetails = () => {
        let NOTICE = this.state.NOTICE;
        if (NOTICE.length === 0)
            NOTICE.push(
                {
                    _id: 1, DESC: "",
                });
        else {
            let newNotice = {
                _id: NOTICE.length + 1, DESC: "",
            };
            NOTICE.push(newNotice);
        }
        this.setState({ NOTICE });
    };

    onRemovedetails = () => {
        let NOTICE = this.state.NOTICE;
        NOTICE.pop();
        this.setState({ NOTICE });
    };

    onChange4 = e => {
        this.setState({ LQ_TYPE: e.target.value, });
    };

    render() {
        //console.log(this.state)
        let { MCQ_COUNT, SQ_COUNT, LQ_COUNT, key, loading2, error, errorMsg } = this.state;
        return (
            <Card type="inner"
                title={ <><EditOutlined className="mr-1" /> Question Paper Generation</> }
                className="mt-1 mb-1"
                style={ { width: "100%" } }
                loading={ this.state.loading }
                extra={ <><Badge color="red" /><Badge color="yellow" /><Badge color="green" /></> }> <br />
                {
                    !this.state.loading && !this.state.StatusError ?
                        (
                            <Row justify="center" className="mb-2">
                                <Col span={ 24 } align="middle">
                                    <img alt="TEL" height="340" src={ require('../../../images/questionpaper.svg').default } />
                                </Col>
                                <Col span={ 24 } className="mt-1">
                                    <Descriptions
                                        title={ <><AppstoreOutlined className="mr-1" />Select Question Pattern</> }
                                        bordered
                                        size="middle"
                                        column={ { lg: 3, md: 3, sm: 1, xs: 1 } }
                                    >
                                        <Descriptions.Item label="MCQ">
                                            <InputNumber min={ 0 } max={ 20 } defaultValue={ 0 }
                                                style={ { width: "100%" } } value={ MCQ_COUNT }
                                                onChange={ this.onChange.bind(this, "MCQ_COUNT") } />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Short Questions">
                                            <InputNumber min={ 0 } max={ 20 } defaultValue={ 0 }
                                                style={ { width: "100%" } } value={ SQ_COUNT }
                                                onChange={ this.onChange.bind(this, "SQ_COUNT") } />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Long Questions">
                                            <InputNumber min={ 0 } max={ 20 } defaultValue={ 0 }
                                                style={ { width: "100%" } } value={ LQ_COUNT }
                                                onChange={ this.onChange.bind(this, "LQ_COUNT") } />
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>

                                { key ?
                                    (<Col span={ 24 }>
                                        <Descriptions
                                            className="mt-2"
                                            title={ <><ControlOutlined className="mr-1" />Question Difficulty Level</> }
                                            bordered
                                            size="middle"
                                            column={ { lg: 1, md: 1, sm: 1, xs: 1 } }
                                        >
                                            { MCQ_COUNT ?
                                                (<Descriptions.Item label="MCQ">
                                                    <Row gutter={ [16, 16] } align="middle" justify="center">
                                                        <Col span={ 18 }>
                                                            {
                                                                Array.from({ length: MCQ_COUNT }, (item, value) => (
                                                                    <Row gutter={ [16, 16] } className="mt-1" align="middle" key={ value }>
                                                                        <Col span={ 4 }>
                                                                            <Tag>Question { value + 1 }</Tag>
                                                                        </Col>
                                                                        <Col span={ 8 }>
                                                                            <Select style={ { width: 200 } } onChange={ this.onChangeUnit.bind(this, value, "unit", "MCQ") } defaultValue="0">
                                                                                <Option value="0" disabled>Choose Unit</Option>
                                                                                <Option value="1">Unit 1</Option>
                                                                                <Option value="2">Unit 2</Option>
                                                                                <Option value="3">Unit 3</Option>
                                                                                <Option value="4">Unit 4</Option>
                                                                                <Option value="5">Unit 5</Option>
                                                                            </Select>
                                                                        </Col>
                                                                        <Col span={ 8 }>
                                                                            <Select style={ { width: 200 } } onChange={ this.onChangeUnit.bind(this, value, "level", "MCQ") } defaultValue="0">
                                                                                <Option value="0" disabled>Choose Level</Option>
                                                                                <Option value="1"><Tag color="blue">1</Tag><Tag color="magenta">Remember</Tag></Option>
                                                                                <Option value="2"><Tag color="blue">2</Tag><Tag color="magenta">Understand</Tag></Option>
                                                                                <Option value="3"><Tag color="blue">3</Tag><Tag color="magenta">Apply</Tag></Option>
                                                                                <Option value="4"><Tag color="blue">4</Tag><Tag color="magenta">Analyze</Tag></Option>
                                                                                <Option value="5"><Tag color="blue">5</Tag><Tag color="magenta">Evaluate</Tag></Option>
                                                                                <Option value="6"><Tag color="blue">6</Tag><Tag color="magenta">Create</Tag></Option>
                                                                            </Select>
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </Col>
                                                        <Col span={ 6 }>
                                                            <Tag>Max. Marks</Tag>
                                                            <Select style={ { width: 100 } } onChange={ this.onChangeSelect.bind(this, "MCQ_MARKS") } defaultValue={ 0 }>
                                                                <Option key={ 0 } value={ 0 } disabled>Choose </Option>
                                                                { Array.from({ length: 20 }, (v, k) => <Option key={ k + 1 } value={ k + 1 }>{ k + 1 }</Option>) }
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    { !MCQ_COUNT ? <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } /> : '' }
                                                </Descriptions.Item>
                                                ) : ''
                                            }
                                            { SQ_COUNT ?
                                                (<Descriptions.Item label="Short Questions">
                                                    <Row gutter={ [16, 16] } align="middle" justify="center">
                                                        <Col span={ 18 }>
                                                            {
                                                                Array.from({ length: SQ_COUNT }, (item, value) => (
                                                                    <Row gutter={ [16, 16] } className="mt-1" align="middle" key={ value }>
                                                                        <Col span={ 4 }>
                                                                            <Tag>Question { MCQ_COUNT + value + 1 }</Tag>
                                                                        </Col>
                                                                        <Col span={ 8 }>
                                                                            <Select style={ { width: 200 } } onChange={ this.onChangeUnit.bind(this, value, "unit", "SQ") } defaultValue="0">
                                                                                <Option value="0" disabled>Choose Unit</Option>
                                                                                <Option value="1">Unit 1</Option>
                                                                                <Option value="2">Unit 2</Option>
                                                                                <Option value="3">Unit 3</Option>
                                                                                <Option value="4">Unit 4</Option>
                                                                                <Option value="5">Unit 5</Option>
                                                                            </Select>
                                                                        </Col>
                                                                        <Col span={ 8 }>
                                                                            <Select style={ { width: 200 } } onChange={ this.onChangeUnit.bind(this, value, "level", "SQ") } defaultValue="0">
                                                                                <Option value="0" disabled>Choose Level</Option>
                                                                                <Option value="1"><Tag color="blue">1</Tag><Tag color="magenta">Remember</Tag></Option>
                                                                                <Option value="2"><Tag color="blue">2</Tag><Tag color="magenta">Understand</Tag></Option>
                                                                                <Option value="3"><Tag color="blue">3</Tag><Tag color="magenta">Apply</Tag></Option>
                                                                                <Option value="4"><Tag color="blue">4</Tag><Tag color="magenta">Analyze</Tag></Option>
                                                                                <Option value="5"><Tag color="blue">5</Tag><Tag color="magenta">Evaluate</Tag></Option>
                                                                                <Option value="6"><Tag color="blue">6</Tag><Tag color="magenta">Create</Tag></Option>
                                                                            </Select>
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </Col>
                                                        <Col span={ 6 }>
                                                            <Tag>Max. Marks</Tag>
                                                            <Select style={ { width: 100 } } onChange={ this.onChangeSelect.bind(this, "SQ_MARKS") } defaultValue="0">
                                                                <Option key={ 0 } value={ 0 } disabled>Choose</Option>
                                                                { Array.from({ length: 20 }, (v, k) => <Option key={ k + 1 } value={ k + 1 }>{ k + 1 }</Option>) }
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    { !SQ_COUNT ? <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } /> : '' }

                                                </Descriptions.Item>
                                                ) : ''
                                            }

                                            { LQ_COUNT ?
                                                (<Descriptions.Item label="Long Questions">
                                                    <Row gutter={ [16, 16] } align="middle" justify="center">
                                                        <Col span={ 18 }>
                                                            {
                                                                Array.from({ length: LQ_COUNT }, (item, value) => (
                                                                    <Row gutter={ [16, 16] } className="mt-1" align="middle" key={ value }>
                                                                        <Col span={ 4 }>
                                                                            <Tag>Question { MCQ_COUNT + SQ_COUNT + value + 1 }</Tag>
                                                                        </Col>
                                                                        <Col span={ 8 }>
                                                                            <Select style={ { width: 200 } } onChange={ this.onChangeUnit.bind(this, value, "unit", "LQ") } defaultValue="0">
                                                                                <Option value="0" disabled>Choose Unit</Option>
                                                                                <Option value="1">Unit 1</Option>
                                                                                <Option value="2">Unit 2</Option>
                                                                                <Option value="3">Unit 3</Option>
                                                                                <Option value="4">Unit 4</Option>
                                                                                <Option value="5">Unit 5</Option>
                                                                            </Select>
                                                                        </Col>
                                                                        <Col span={ 8 }>
                                                                            <Select style={ { width: 200 } } onChange={ this.onChangeUnit.bind(this, value, "level", "LQ") } defaultValue="0">
                                                                                <Option value="0" disabled>Choose Level</Option>
                                                                                <Option value="1"><Tag color="blue">1</Tag><Tag color="magenta">Remember</Tag></Option>
                                                                                <Option value="2"><Tag color="blue">2</Tag><Tag color="magenta">Understand</Tag></Option>
                                                                                <Option value="3"><Tag color="blue">3</Tag><Tag color="magenta">Apply</Tag></Option>
                                                                                <Option value="4"><Tag color="blue">4</Tag><Tag color="magenta">Analyze</Tag></Option>
                                                                                <Option value="5"><Tag color="blue">5</Tag><Tag color="magenta">Evaluate</Tag></Option>
                                                                                <Option value="6"><Tag color="blue">6</Tag><Tag color="magenta">Create</Tag></Option>
                                                                            </Select>
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </Col>
                                                        <Col span={ 6 }>
                                                            <Tag>Max. Marks</Tag>
                                                            <Select style={ { width: 100 } } onChange={ this.onChangeSelect.bind(this, "LQ_MARKS") } defaultValue="0">
                                                                <Option key={ 0 } value={ 0 } disabled>Choose</Option>
                                                                { Array.from({ length: 20 }, (v, k) => <Option key={ k + 1 } value={ k + 1 }>{ k + 1 }</Option>) }
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    { !LQ_COUNT ? <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } /> : '' }
                                                </Descriptions.Item>
                                                ) : ''
                                            }

                                            <Descriptions.Item label="Charts">
                                                <Row gutter={ [16, 16] } className="mt-2 mb-2" justify="center">
                                                    <Col span={ 12 } align="middle">
                                                        <Text strong>Unit-Wise Pie Chart</Text>
                                                        { this.state.details ? <Donut details={ this.state.details } /> : ''
                                                        }
                                                    </Col>
                                                    <Col span={ 12 } align="middle">
                                                        <Text strong>Question-Type Pie Chart</Text>
                                                        { this.state.questionType ? <Donut details={ this.state.questionType } /> : ''
                                                        }
                                                    </Col>
                                                    <Col span={ 24 } align="middle">
                                                        <br /> <br />
                                                        <Text strong>Curve Chart</Text>
                                                        { this.state.curve ? <CurveChart details={ this.state.curve } /> : '' }
                                                    </Col>
                                                    {
                                                        Object.keys(this.state.curve).length ?
                                                            <Col span={ 24 } align="middle">
                                                                <br /> <br />
                                                                <Text strong>Gaussian Distribution Chart</Text>
                                                                <CurveChart details={ this.state.gauCurve } />
                                                            </Col>
                                                            : ''
                                                    }
                                                </Row>
                                            </Descriptions.Item>

                                            <Descriptions.Item label="Configuration">
                                                <Row gutter={ [16, 16] } justify="start" className="mt-1 mb-1">
                                                    <Col span={ 24 }>
                                                        <Divider className="mt-1" orientation="left">Header Information</Divider>
                                                    </Col>
                                                    <Col span={ 12 }>
                                                        <Input addonBefore="Course Code" value={ this.state.COURSE_CODE } disabled />
                                                    </Col>
                                                    <Col span={ 12 }>
                                                        <Input addonBefore="Course Name" value={ this.state.COURSE_NAME } disabled />
                                                    </Col>
                                                    <Col span={ 12 } align="middle">
                                                        <Input id="TEST_NAME" addonBefore="Test Name" placeholder="Unit Test Question Paper, Month 2020" value={ this.state.TEST_NAME } onChange={ this.handleChange } />
                                                    </Col>
                                                    <Col span={ 6 } align="middle">
                                                        <DatePicker defaultValue={ moment() } onChange={ this.onChangeDatePicker } style={ { width: "100%" } } />
                                                    </Col>
                                                    <Col span={ 6 } align="middle">
                                                        <Input id="DURATION" addonBefore="Duration" placeholder="(in Minutes)" value={ this.state.DURATION } onChange={ this.handleChange } type="number" />
                                                    </Col>
                                                    <Col span={ 12 } align="middle">
                                                        <Radio.Group buttonStyle="solid" style={ { width: "100%" } } onChange={ this.onChange4 }>
                                                            <Radio.Button style={ { width: "50%" } } value="1">Long Questions With OR Type</Radio.Button>
                                                            <Radio.Button style={ { width: "50%" } } value="0">Long Questions Without OR Type</Radio.Button>
                                                        </Radio.Group>
                                                    </Col>
                                                    <Col span={ 6 }>
                                                        <Select style={ { width: "100%" } } placeholder="Short Question Mandatory" onChange={ this.onChangeSelect.bind(this, "SQ_MANDATORY") }>
                                                            <Option value="All">All</Option>
                                                            { Array.from({ length: SQ_COUNT - 1 }, (v, k) => <Option key={ k } value={ k + 1 }>{ k + 1 }</Option>) }
                                                        </Select>
                                                    </Col>
                                                    <Col span={ 6 }>
                                                        <Select style={ { width: "100%" } } placeholder="Long Question Mandatory" onChange={ this.onChangeSelect.bind(this, "LQ_MANDATORY") }>
                                                            <Option value="All">All</Option>
                                                            { Array.from({ length: LQ_COUNT - 1 }, (v, k) => <Option key={ k } value={ k + 1 }>{ k + 1 }</Option>) }
                                                        </Select>
                                                    </Col>
                                                    <Col span={ 24 }>
                                                        <Divider className="mt-1" orientation="left">Notices</Divider>

                                                        <Space className="mb-1 mt-1">
                                                            <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>Add</Button>
                                                            <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails } >Remove</Button>
                                                        </Space>
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={ this.state.NOTICE }
                                                            renderItem={ (item, val) => (
                                                                <List.Item>
                                                                    <List.Item.Meta
                                                                        avatar={ "[" + (val + 1) + "]" }
                                                                        description={ <TextArea style={ { marginTop: "3px" } } rows={ 1 } autoSize value={ item.DESC } onChange={ this.onChangedetails.bind(this, val, "DESC") } placeholder="Description" /> }
                                                                    />
                                                                </List.Item>
                                                            ) }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Action">
                                                <Row className="mt-1 mb-1" justify="center">
                                                    <Col span={ 24 } align="middle">
                                                        <Button type="primary" disabled={ MCQ_COUNT || SQ_COUNT || LQ_COUNT ? false : true } onClick={ this.generateQuestionPaper }
                                                            loading={ loading2 === 1 ? true : false }
                                                        >Generate Question Paper</Button>
                                                    </Col>
                                                    {
                                                        loading2 >= 2 ?
                                                            (<Col span={ 24 }>
                                                                <Result
                                                                    status={ error ? "warning" : "success" }
                                                                    title={ error ? "There are some problems with your questions" : "Question Paper Successfully Genderated!" }
                                                                    subTitle={ error ? (
                                                                        <List
                                                                            style={ { lineHeight: "0.4", textAlign: "center" } }
                                                                            split={ false }
                                                                            className="mt-3"
                                                                            size="small"
                                                                            pagination
                                                                            dataSource={ errorMsg }
                                                                            renderItem={ item => <List.Item style={ { padding: "2px 16px", display: "flow-root" } }>
                                                                                <Tag color="error">{ item.msg }</Tag>
                                                                            </List.Item> }
                                                                        />
                                                                    ) : '' }
                                                                    extra={ [
                                                                        <Button type="primary" key="console" icon={ <DownloadOutlined /> } onClick={ this.setButtonLink } disabled={ loading2 === 2 ? false : true }>
                                                                            Download
                                                                        </Button>,
                                                                    ] }
                                                                />
                                                            </Col>) : ''
                                                    }
                                                </Row>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    ) : ''
                                }
                            </Row>
                        ) : ''
                }
                {
                    this.state.StatusError === 1 ? (
                        <Result
                            status="404"
                            title="Course Learning Session is Empty"
                            subTitle="Unit and Session information are empty"
                        />
                    ) : ''
                }

            </Card>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(QuestionPaper));