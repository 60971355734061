import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MyProfile from './MyProfile'

class Profile extends Component {
    state = {
        key: '',
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <MyProfile record={ this.props.common.user } />
            </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Profile));