import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UpdatePO from './UpdatePO'
import UpdatePSO from './UpdatePSO'
import UpdatePEO from './UpdatePEO'
import ViewPO from './ViewPO'
import {
    CloudUploadOutlined, EyeOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;

class ManageFaculty extends Component {
    state = {
        loading: false,
    };

    render() {
        let tabName = ["", "Program Outcomes", "Program Specific Outcomes", "Program Educational Outcomes", "View Outcomes"];

        return (
            <Row style={{ overflow: "auto" }}>
                <Col xs={24} className="mt-1 mb-1">
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                                <TabPane tab={
                                    <span>
                                        <CloudUploadOutlined />
                                        {tabName[1]}
                                    </span>
                                } key="1"><UpdatePO /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <CloudUploadOutlined />
                                        {tabName[2]}
                                    </span>
                                } key="2"><UpdatePSO /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <CloudUploadOutlined />
                                        {tabName[3]}
                                    </span>
                                } key="3"><UpdatePEO /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <EyeOutlined />
                                        {tabName[4]}
                                    </span>
                                } key="4"><ViewPO /></TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ManageFaculty));