import React, { Component } from 'react'
import { Select, Button, Input, Row, Col, Descriptions, message, Spin, Space, Upload, Tag, List } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import { DownloadOutlined, UploadOutlined, MinusOutlined, PlusOutlined, EnterOutlined, DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;
const page_name = "course_structure";
const lables = ['Course Category', 'Lecture', 'Tutorial', 'Practical', 'Credit', 'Pre-Requisite Course Code', 'Pre-Requisite Course Name', 'Co-Requisite Course Code', 'Co-Requisite Course Name', 'Post-Requisite Course Code', 'Post-Requisite Course Name', 'Department', 'Databook', 'CLR', 'References']

class CourseStructure extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_CATEGORY: '',
        DEPARTMENT: '',
        L: 0, T: 0, P: 0, C: 0,
        loading: true,
        folderid: "",
        filename: "",
        REFERENCES: [{
            _id: 1, TYPE: "", DESC: "",
        }],
        fileStatus: [],
    }

    componentDidMount() {
        this.getCoursestructure(this.state.COURSE_CODE);
        this.handleFileStatus(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCoursestructure(props.COURSE_CODE);
        this.handleFileStatus(props.COURSE_CODE)
    }

    getCoursestructure = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (record)
                            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
                        else
                            this.setState({
                                COURSE_CATEGORY: '',
                                DEPARTMENT: '',
                                L: 0, T: 0, P: 0, C: 0,
                            })
                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("Course Not Selected");
    }

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onClickAdd = async () => {
        let { COURSE_CODE, L, T, P, C, COURSE_CATEGORY, DEPARTMENT, PRE_COURSE_CODE, PRE_COURSE_NAME, CO_COURSE_CODE, CO_COURSE_NAME, POST_COURSE_CODE, POST_COURSE_NAME, DATA_BOOK, CLR, REFERENCES } = this.state;

        let data = {
            COURSE_CATEGORY: COURSE_CATEGORY,
            L: L,
            T: T,
            P: P,
            C: C,
            PRE_COURSE_CODE: PRE_COURSE_CODE,
            PRE_COURSE_NAME: PRE_COURSE_NAME,
            CO_COURSE_CODE: CO_COURSE_CODE,
            CO_COURSE_NAME: CO_COURSE_NAME,
            POST_COURSE_CODE: POST_COURSE_CODE,
            POST_COURSE_NAME: POST_COURSE_NAME,
            DEPARTMENT: DEPARTMENT,
            DATA_BOOK: DATA_BOOK,
            CLR: CLR,
            REFERENCES: REFERENCES,
        }

        let flag = -1;
        for (let key in data) {
            if (key !== 'REFERENCES') {
                flag++;
                if (!data[key] && data[key] !== 0) {
                    message.error('Invalid ' + lables[flag]);
                    return false;
                }
            }
        }

        let sendData = {
            data: { course_structure: data },
            COURSE_CODE: COURSE_CODE,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/admin/course/addcourseinfo', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
    }

    setSession = (filename) => {
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/syllabus";
        this.setState({ folderid: folderlink, filename: filename });
    }

    checkFileStatus = (filename) => {
        filename=parseInt(filename);
        if (this.state.fileStatus.includes(filename)) {
            return <Tag color="green">Available</Tag>;
        }
        else
            return <Tag style={{ padding: "0px 20px" }} color="red">Empty</Tag>;
    }

    setButtonLink = (filename) => {
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/syllabus";

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    deleteFile = (filename) => {
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/syllabus/" + filename + ".pdf";
        let sendData = {
            path: folderlink,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/deletefile', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    message.info("File Deleted !");
                }
                else message.error("Operation Failed !");
            });
    }


    handleFileStatus = (COURSE_CODE) => {
        let folderlink = "data/coordinator/" + COURSE_CODE + "/syllabus";
        let sendData = {
            server: config.curriculaserver,
            path: folderlink,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/file/getallfilenames', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ fileStatus: res.data.result });
                }
                else
                    this.setState({ fileStatus: [] });
                this.setState({ loading: false });
            });
    }

    onChangedetails = (id, name, e) => {
        var REFERENCES = this.state.REFERENCES;
        let data = REFERENCES[id];
        data[name] = e.target.value;
        REFERENCES[id] = data;
        this.setState({ REFERENCES: REFERENCES })
    }

    onAdddetails = () => {
        let REFERENCES = this.state.REFERENCES;
        if (REFERENCES.length === 0)
            REFERENCES.push(
                {
                    _id: 1, TYPE: "", DESC: "",
                });
        else {
            let newReference = {
                _id: REFERENCES.length + 1, TYPE: "", DESC: "",
            };
            REFERENCES.push(newReference);
        }
        this.setState({ REFERENCES });
    };

    onRemovedetails = () => {
        let REFERENCES = this.state.REFERENCES;
        REFERENCES.pop();
        this.setState({ REFERENCES });
    };

    onChangedetailsSelect = (id, name, value) => {
        var REFERENCES = this.state.REFERENCES;
        let data = REFERENCES[id];
        data[name] = value;
        REFERENCES[id] = data;
        this.setState({ REFERENCES })
    }

    render() {
        let { COURSE_CODE } = this.state;
        const params = {
            key: config.key,
            path: this.state.folderid,
            filename: this.state.filename,
        }

        let props = {
            accept: ".pdf",
            name: 'file',
            multiple: false,
            action: config.curriculaserver + '/curricula/admin/file/upload',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        return { fileStatus: fileStatus };
                    });
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                const PDF = file.type === 'application/pdf';
                if (!PDF) {
                    message.error('You can only upload PDF file!');
                }
                const isLt3M = file.size / 1024 / 1024 < 0.5;
                if (!isLt3M) {
                    message.error('File must smaller than 500 KB!');
                }
                return PDF && isLt3M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (
            <Spin spinning={this.state.loading}>

                <Row gutter={[16, 16]}>

                    <Col xs={24} >
                        <Descriptions
                            bordered
                            column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Category">
                                <Select id="COURSE_CATEGORY" placeholder="Select Category" style={{ width: "100%" }} onChange={this.handleChangeSelect.bind(this, "COURSE_CATEGORY")} value={this.state.COURSE_CATEGORY}>
                                    <Option value="" disabled>Choose One</Option>
                                    {
                                        config.courseCategory.map(
                                            (item, val) => {
                                                return (
                                                    <Option key={val} value={item.toUpperCase()}>{item}</Option>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </Descriptions.Item>

                            <Descriptions.Item label="Structure">
                                <Input.Group compact>
                                    <Input id="L" addonBefore="Lecture (L)" style={{ width: '25%' }} value={this.state.L} onChange={this.handleChange} />
                                    <Input id="T" addonBefore="Tutorial (T)" style={{ width: '25%' }} value={this.state.T} onChange={this.handleChange} />
                                    <Input id="P" addonBefore="Practice (P)" style={{ width: '25%' }} value={this.state.P} onChange={this.handleChange} />
                                    <Input id="C" addonBefore="Credit (C)" style={{ width: '25%' }} value={this.state.C} onChange={this.handleChange} />
                                </Input.Group>
                            </Descriptions.Item>

                            <Descriptions.Item label="Requisites">
                                <Row gutter={[16, 16]}>

                                    <Col xs={24}>
                                        <Input.Group compact>
                                            <Input id="PRE_COURSE_CODE" placeholder="Course Code" style={{ width: '30%' }} value={this.state.PRE_COURSE_CODE} onChange={this.handleChange} />
                                            <Input id="PRE_COURSE_NAME" placeholder="Pre-Requisite Course Name" style={{ width: '70%' }} value={this.state.PRE_COURSE_NAME} onChange={this.handleChange} />
                                        </Input.Group>
                                    </Col>

                                    <Col xs={24}>
                                        <Input.Group compact>
                                            <Input id="CO_COURSE_CODE" placeholder="Course Code" style={{ width: '30%' }} value={this.state.CO_COURSE_CODE} onChange={this.handleChange} />
                                            <Input id="CO_COURSE_NAME" placeholder="Co-Requisite Course Name" style={{ width: '70%' }} value={this.state.CO_COURSE_NAME} onChange={this.handleChange} />
                                        </Input.Group>
                                    </Col>

                                    <Col xs={24}>
                                        <Input.Group compact>
                                            <Input id="POST_COURSE_CODE" placeholder="Course Code" style={{ width: '30%' }} value={this.state.POST_COURSE_CODE} onChange={this.handleChange} />
                                            <Input id="POST_COURSE_NAME" placeholder="Post-Requisite Course Name" style={{ width: '70%' }} value={this.state.POST_COURSE_NAME} onChange={this.handleChange} />
                                        </Input.Group>
                                    </Col>
                                </Row>

                            </Descriptions.Item>

                            <Descriptions.Item label="Offering Department">
                                <Select id="DEPARTMENT" placeholder="Offering Department" style={{ width: "100%" }} value={this.state.DEPARTMENT}
                                    onChange={this.handleChangeSelect.bind(this, "DEPARTMENT")}>
                                    <Option value="" disabled>Choose One</Option>
                                    {
                                        config.departments.map(
                                            (item, val) => {
                                                return (
                                                    item !== "ALL" ?
                                                        (<Option key={val} value={item.toUpperCase()}>{item}</Option>) : ''
                                                )
                                            }
                                        )
                                    }
                                </Select>

                            </Descriptions.Item>

                            <Descriptions.Item label="Data Book/Codes/Standards">
                                <Input id="DATA_BOOK" value={this.state.DATA_BOOK} onChange={this.handleChange} placeholder="Data Book/Codes/Standards" style={{ width: '100%' }} />
                            </Descriptions.Item>

                            <Descriptions.Item label="Course Learning Rationale (CLR)">
                                <TextArea id="CLR" value={this.state.CLR} onChange={this.handleChange} placeholder="Type Here" allowClear autoSize />
                            </Descriptions.Item>

                            <Descriptions.Item label={"Syllabus"}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} lg={12}>
                                        <Space>
                                            <Upload {...props} >
                                                <Button size="small" onClick={this.setSession.bind(this, COURSE_CODE + ".pdf")}>
                                                    <UploadOutlined /> Click to Upload
                                                </Button>
                                            </Upload>
                                            {this.checkFileStatus(COURSE_CODE + "")}
                                            <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, COURSE_CODE + ".pdf")}> View</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Descriptions.Item>

                            <Descriptions.Item label="References">
                                <Space className="mb-1">
                                    <Button type="primary" icon={<PlusOutlined />} size="small" onClick={this.onAdddetails}>Add</Button>
                                    <Button type="danger" icon={<MinusOutlined />} size="small" onClick={this.onRemovedetails} >Remove</Button>
                                </Space>

                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.REFERENCES}
                                    renderItem={(item, val) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={"[" + (val + 1) + "]"}
                                                title={
                                                    <Space>
                                                        <Select size="small" style={{ width: 140 }} onChange={this.onChangedetailsSelect.bind(this, val, "TYPE")} value={item.TYPE} placeholder="Choose one">
                                                            <Option value="" disabled>Select Type</Option>
                                                            <Option value="Text_Book">Text Book</Option>
                                                            <Option value="Reference_Book">Reference Book</Option>
                                                            <Option value="Journal">Journal</Option>
                                                            <Option value="Thesis">Thesis</Option>
                                                            <Option value="learning_Material">learning Material</Option>
                                                        </Select>
                                                        <Upload {...props} >
                                                            <Button size="small" onClick={this.setSession.bind(this, (val + 1) + ".pdf")}>
                                                                <UploadOutlined /> Click to Upload
                                                            </Button>
                                                        </Upload>
                                                        {this.checkFileStatus((val + 1) + ".pdf")}
                                                        <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, (val + 1) + ".pdf")}> View</Button>
                                                        <Button danger icon={<DeleteOutlined />} size={"small"} onClick={this.deleteFile.bind(this, (val + 1))}>Delete File</Button>
                                                    </Space>}
                                                description={<TextArea style={{ marginTop: "3px" }} rows={1} autoSize value={item.DESC} onChange={this.onChangedetails.bind(this, val, "DESC")} placeholder="Description" />}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Submit">
                                <Button type="primary" icon={<EnterOutlined />} onClick={this.onClickAdd}>Submit</Button>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseStructure));