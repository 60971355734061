import React, { Component } from 'react'
import { Button, Row, Col, Input, Typography, Descriptions, Tag, message, Spin } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import { EnterOutlined } from '@ant-design/icons';
const { Text } = Typography;
const page_name = "session_learning_objectives";

class SLOSROInfo extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        details: {},
        SESSIONCOUNT: parseInt(this.props.SESSIONCOUNT),
        UNIT: parseInt(this.props.UNIT),
        loading: true
    }

    componentDidMount() {
        let SO = [];
        for (let i = 1; i <= this.state.SESSIONCOUNT; i++) {
            SO.push({ SESSION: i, SLO1: "", SLO2: "", SRO1: "", SRO2: "" })
        }

        let details = {
            _id: this.state.UNIT,
            unitNo: this.state.UNIT,
            SO: SO
        }
        this.setState({ details: details });

        let sendData = {
            data: {
                COURSE_CODE: this.props.COURSE_CODE,
                title: page_name
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                //console.log(res.data)
                if (res.data.Status === 1) {
                    //let record = res.data.selectedCourseData[this.state.UNIT - 1];
                    if (Object.keys(res.data.selectedCourseData).length) {
                        let record = res.data.selectedCourseData.find(element => element.unitNo === this.state.UNIT);
                        if (record) this.setState({ details: record });
                    }
                }
                this.setState({ loading: false });
            });
    }

    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details.SO[id];
        data[name] = e.target.value;
        details.SO[id] = data;
        this.setState({ details: details })
    }

    onClickAdd = async () => {
        this.setState({ loading: true });

        let { COURSE_CODE, details } = this.state;

        let sendData = {
            title: page_name,
            details: details,
            COURSE_CODE: COURSE_CODE,
            unit: parseInt(this.state.UNIT),
            key: config.key
        };

        let flag = 1;
        details.SO.forEach(function (entry) {
            Object.keys(entry).forEach(function (key) {
                if (entry[key].length < 1) {
                    flag = 0;
                }
            });
        });

        if (flag === 0) {
            this.setState({ loading: false });
            message.error('Invalid Entry!');
            return false;
        }

        await axios.post(config.curriculaserver + '/curricula/admin/course/addslo', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);

                this.setState({ loading: false });
            });
    }

    render() {
        let { details } = this.state;
        return (
            <div className="padding-2">
                <Spin spinning={this.state.loading}>

                    <Descriptions
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    >
                        <Descriptions.Item label={"Session"}>
                            <Row gutter={[8, 8]}>

                                <Col xs={24} md={12}>
                                    <Tag style={{ width: "100%" }} color="blue">At the end of this session, my learners will be able to :</Tag>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Tag style={{ width: "100%" }} color="blue">The purpose of learning this session is to :</Tag>
                                </Col>
                            </Row>
                        </Descriptions.Item>

                        {
                            Object.getOwnPropertyNames(details).length !== 0 ?
                                (details.SO.map(
                                    (item, val) => {
                                        return (
                                            <Descriptions.Item key={val} label={"Session " + (val + 1)}>

                                                <Row gutter={[8, 8]}>
                                                    <Col xs={24} md={12}>
                                                        <Text keyboard>SLO 1</Text>
                                                        <Input style={{ marginTop: "5px", width: "98%" }} placeholder="Type Here" value={item.SLO1} onChange={this.onChangedetails.bind(this, val, "SLO1")} />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Text keyboard>SLR 1</Text>
                                                        <Input style={{ marginTop: "5px", width: "98%" }} placeholder="Type Here" value={item.SRO1} onChange={this.onChangedetails.bind(this, val, "SRO1")} />
                                                    </Col>

                                                </Row>
                                                <Row gutter={[8, 8]} className="mt-1">

                                                    <Col xs={24} md={12}>
                                                        <Text keyboard>SLO 2</Text>
                                                        <Input style={{ marginTop: "5px", width: "98%" }} placeholder="Type Here" value={item.SLO2} onChange={this.onChangedetails.bind(this, val, "SLO2")} />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <Text keyboard>SLR 2</Text>
                                                        <Input style={{ marginTop: "5px", width: "98%" }} placeholder="Type Here" value={item.SRO2} onChange={this.onChangedetails.bind(this, val, "SRO2")} />
                                                    </Col>
                                                </Row>
                                            </Descriptions.Item>
                                        );
                                    }
                                )
                                ) : ''
                        }
                        <Descriptions.Item label="Submit">
                            <Button icon={<EnterOutlined />} type="primary" onClick={this.onClickAdd}>Submit</Button>
                        </Descriptions.Item>

                    </Descriptions>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(SLOSROInfo));