import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, Tag, Popconfirm, Table, Tooltip, Alert, notification } from 'antd';
import { message } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const InputGroup = Input.Group;
const { Title, Text } = Typography;
const topic = "family";
const relations = ["Aunt", "Brother", "Daughter", "Daughter-in-Law", "Father", "Grand-Daughter", "Grand-Father", "Grand-Mother", "Grand-Son", "Husband", "Mother", "Others", "Sister", "Son", "Son-in-Law", "Uncle", "Wife"];
const maleRelations = ["Brother", "Father", "Grand-Father", "Grand-Son", "Husband", "Others", "Son", "Son-in-Law", "Uncle"];
const femaleRelations = ["Aunt", "Daughter", "Daughter-in-Law", "Grand-Daughter", "Grand-Mother", "Mother", "Others", "Sister", "Wife"];
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

class Family extends Component {
    state = {
        gender: '',
        name: '',
        relationship: '',
        mobileNumber: '',
        aadhaarNumber: '',
        relations: relations,
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: []
    }
    initialState = this.state;

    reset = async () => {
        let data = this.state.data
        await this.setState(this.initialState)
        await this.setState({ data: data })
    }
    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        if (label === 'gender') this.setState({ relationship: '' })
        if (value === 'MALE') this.setState({ relations: maleRelations })
        else if (value === 'FEMALE') this.setState({ relations: femaleRelations })
        else this.setState({ relations: relations })
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickSubmitBtn = () => {
        let { name, gender, relationship, aadhaarNumber } = this.state;

        let errorMessages = [];
        if (!name || !/^[a-zA-Z. ]+$/i.test(name)) errorMessages.push("Invalid Name");
        else if (!gender) errorMessages.push("Select Gender");
        else if (!relationship) errorMessages.push("Select Relationship");
        //else if (isNaN(mobileNumber) || mobileNumber.length !== 10) errorMessages.push("Invalid Mobile Number");
        //else if (isNaN(aadhaarNumber) || aadhaarNumber.length !== 12) errorMessages.push("Invalid Aadhaar Number");

        this.setState({ errorMessages: errorMessages, disable: true });
        let oldData = this.state.data;
        if (!this.state.update)
            for (let i = 0; i < oldData.length; i++) {
                if (oldData[i].aadhaarNumber === aadhaarNumber) {
                    errorMessages.push("Aadhaar already added");
                    break;
                }
            }
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                gender: this.state.gender,
                name: this.state.name,
                relationship: this.state.relationship,
                mobileNumber: this.state.mobileNumber,
                aadhaarNumber: this.state.aadhaarNumber,
                relations: this.state.relations,
                _id: this.state._id,
                key: this.state._key
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState(this.initialState);
                    await this.setState({ data: res.data.result });
                    await this.setState({ errorMessages: ["success"] });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false, });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
    }

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    this.setState({ data: res.data.result });
                }
            });
    }


    render() {
        let { name, gender, relationship, mobileNumber, aadhaarNumber, edit, data } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 40,
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
            },
            {
                title: 'Relationship',
                dataIndex: 'relationship',
                render: (text) => (
                    <Tag color="green" key={ text }>
                        { text.toUpperCase() }
                    </Tag>
                ),
                width: 20,
            },
            {
                title: 'Mobile Number',
                dataIndex: 'mobileNumber',
                key: 'mobileNumber',
                width: 20,
            },
            {
                title: 'Aadhaar Number',
                dataIndex: 'aadhaarNumber',
                key: 'aadhaarNumber',
                width: 20,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Family Information
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                            <Tag className="ml-1" color="success">Self-Verified</Tag>
                        </Title>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='name' addonBefore="* Name" onChange={ this.onChange } value={ name } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Gender" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="gender" style={ { width: "100%" } } placeholder="* Gender" value={ gender }
                                        onChange={ this.handleChangeSelect.bind(this, "gender") }>
                                        <Option value="MALE">Male</Option>
                                        <Option value="FEMALE">Female</Option>
                                        <Option value="TRANSGENDER">Transgender</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Relationship" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="relationship" style={ { width: "100%" } } value={ relationship }
                                        onChange={ this.handleChangeSelect.bind(this, "relationship") }>
                                        {
                                            this.state.relations.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={ val } value={ item }>{ item }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    <Col xs={ 24 } md={ 8 }>
                        <Input id='mobileNumber' addonBefore="Mobile Number" onChange={ this.onChange } value={ mobileNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='aadhaarNumber' addonBefore="Aadhaar Number" onChange={ this.onChange } value={ aadhaarNumber } />
                    </Col>


                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            showIcon
                                            closable
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } disabled={ !this.state.edit } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>
                <Row gutter={ [16, 16] } className="mt-2">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } bordered />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Family));