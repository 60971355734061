import React, { Component } from 'react'
import { Select, Descriptions, Input, Spin, Button } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const { Option } = Select;
const { TextArea } = Input;

class Query extends Component {

    state = {
        loading: false, open: false,

    }

    componentDidMount() {

    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Descriptions
                    bordered
                    column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                    size="middle"
                    className="justify">
                    <Descriptions.Item label="User" span={2}>
                        {this.props.common.user.FULL_NAME + " (" + this.props.common.user.USER_ID + ")"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Category" span={2}>
                        <Select
                            showSearch
                            style={{ width: 400 }}
                            placeholder="Choose One"
                            filterOption={(input, Option) =>
                                Option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="Website_Not_Working">Website Not Working</Option>
                            <Option value="General_Assistance">General Assistance</Option>
                            <Option value="Feature_Request">Feature Request</Option>
                            <Option value="Feedback">Feedback</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label={"Additinal Information"} span={2}>
                        <TextArea rows={5} />
                    </Descriptions.Item>
                    <Descriptions.Item label={"Action"} span={2}>
                        <Button type="primary">SUBMIT</Button>
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Query));