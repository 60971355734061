import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Descriptions, Select, List, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
const { Option } = Select;

class UserCreate extends Component {
    state = {
        key: 0,
        loading: false,
        data: {}, PLO: [], PSO: [], PEO: [],
        DEPARTMENT: '',
    };

    handleChangeSelect = (value) => {
        this.setState({ DEPARTMENT: value.value });

        let data = {
            info: this.props.common.user,
            key: config.key,
            dept: value.value
        };

        axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState({ PLO: res.data.result.PLO, PSO: res.data.result.PSO, PEO: res.data.result.PEO, loading: false });
                    await message.success('Information Loaded !');
                }
                else {
                    await this.setState({ PLO: res.data.result.PLO, PSO: res.data.result.PSO, PEO: res.data.result.PEO, loading: false });
                    message.error('Information Not Found !');
                }
            })
    }

    render() {
        let { PLO, PSO, PEO } = this.state;
        return (<>
            <div style={{ padding: '0px', minHeight: "100%", marginTop: "20px" }}>
                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label={"Departments List"}>
                        <Select labelInValue style={{ width: "100%" }} onChange={this.handleChangeSelect} placeholder="Select Department">
                            {
                                config.departments.map(
                                    (item, value) => {
                                        return (item !== "ALL" ? <Option key={value} value={item}>{item}</Option> : '')
                                    }
                                )
                            }
                        </Select>
                    </Descriptions.Item>

                    <Descriptions.Item label={"Program Outcomes"}>
                        <List
                            itemLayout="horizontal"
                            dataSource={PLO}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={"PO" + (index + 1)}
                                        description={item}
                                    />
                                </List.Item>
                            )}
                        />
                    </Descriptions.Item>

                    <Descriptions.Item label={"Program Specific Outcomes"}>
                        <List
                            itemLayout="horizontal"
                            dataSource={PSO}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={"PSO" + (index + 1)}
                                        description={item}
                                    />
                                </List.Item>
                            )}
                        />
                    </Descriptions.Item>

                    <Descriptions.Item label={"Program Educational Outcomes"}>
                        <List
                            itemLayout="horizontal"
                            dataSource={PEO}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={"PEO" + (index + 1)}
                                        description={item}
                                    />
                                </List.Item>
                            )}
                        />
                    </Descriptions.Item>

                </Descriptions>
            </div>
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(UserCreate));