import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, Tooltip, Tag, message, Alert, Descriptions, Checkbox } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { EditOutlined } from '@ant-design/icons';
import lists from '../lists'
const InputGroup = Input.Group;
const { Option } = Select;
const { Title } = Typography;
const topic = "communication";
const states = lists.states;
const districts = lists.districts;
var districtsList = [];
var districtsList1 = [];

class Communication extends Component {

    state = {
        doorNumber: '',
        street: '',
        place: '',
        city: '',
        district: '',
        statename: '',
        country: 'INDIA',
        pinCode: '',
        doorNumber1: '',
        street1: '',
        place1: '',
        city1: '',
        district1: '',
        statename1: '',
        country1: 'INDIA',
        pinCode1: '',
        mobileNumber: '',
        landlineNumber: '',
        emailID: '',
        facebookID: '',
        twitterID: '',
        instagramID: '',
        linkedInID: '',
        whatsappNumber: '',
        skypeID: '',
        emergencyContactPerson: '',
        emergencyContactRelation: '',
        emergencyContactMobile: '',
        emergencyContactLandline: '',
        errorMessages: [],
        disable: true,
        edit: false
    }
    initialState = this.state;

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    Object.entries(res.data.result).map(([key, value]) => this.setState({ [key]: value, update: true }))
            })
            .then(() => {
                districtsList = districts.filter(row => row['state'] === this.state.statename);
                districtsList1 = districts.filter(row => row['state'] === this.state.statename1);
            })
    }

    validateEmail = (email) => {// eslint-disable-next-line
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    onClickSubmitBtn = () => {
        let { doorNumber, street, place, city, district, statename, country, pinCode,
            doorNumber1, street1, place1, city1, district1, statename1, country1, pinCode1,
            mobileNumber, emailID, emergencyContactPerson, emergencyContactMobile } = this.state;

        let errorMessages = [];

        if (!doorNumber) errorMessages.push("Invalid Door Number / House Name");
        else if (!street) errorMessages.push("Invalid Address Line 1");
        else if (!place) errorMessages.push("Invalid Address Line 2");
        else if (!city || !/^[a-zA-Z ]+$/i.test(city)) errorMessages.push("Invalid City");
        else if (!district || !/^[a-zA-Z ]+$/i.test(district)) errorMessages.push("Invalid District");
        else if (!statename || !/^[a-zA-Z ]+$/i.test(statename)) errorMessages.push("Invalid State");
        else if (!country || !/^[a-zA-Z ]+$/i.test(country)) errorMessages.push("Invalid Country");
        else if (isNaN(pinCode) || pinCode.length !== 6) errorMessages.push("Invalid Pincode");
        else if (!doorNumber1) errorMessages.push("Invalid Door Number / House Name");
        else if (!street1) errorMessages.push("Invalid Address Line 1");
        else if (!place1) errorMessages.push("Invalid Address Line 2");
        else if (!city1 || !/^[a-zA-Z ]+$/i.test(city1)) errorMessages.push("Invalid City");
        else if (!district1 || !/^[a-zA-Z ]+$/i.test(district1)) errorMessages.push("Invalid District");
        else if (!statename1 || !/^[a-zA-Z ]+$/i.test(statename1)) errorMessages.push("Invalid State");
        else if (!country1 || !/^[a-zA-Z ]+$/i.test(country1)) errorMessages.push("Invalid Country");
        else if (isNaN(pinCode1) || pinCode1.length !== 6) errorMessages.push("Invalid Pincode");
        else if (isNaN(mobileNumber) || mobileNumber.length !== 10) errorMessages.push("Invalid Mobile Number");
        else if (!this.validateEmail(emailID)) errorMessages.push("Invalid Email ID");
        else if (!emergencyContactPerson || !/^[a-zA-Z ]+$/i.test(emergencyContactPerson)) errorMessages.push("Invalid Emergency Contact Person");
        else if (isNaN(emergencyContactMobile) || emergencyContactMobile.length !== 10) errorMessages.push("Invalid Emergency Contact Mobile Number");

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                doorNumber: this.state.doorNumber,
                street: this.state.street,
                place: this.state.place,
                city: this.state.city,
                district: this.state.district,
                statename: this.state.statename,
                country: this.state.country,
                pinCode: this.state.pinCode,
                doorNumber1: this.state.doorNumber1,
                street1: this.state.street1,
                place1: this.state.place1,
                city1: this.state.city1,
                district1: this.state.district1,
                statename1: this.state.statename1,
                country1: this.state.country1,
                pinCode1: this.state.pinCode1,
                mobileNumber: this.state.mobileNumber,
                landlineNumber: this.state.landlineNumber,
                emailID: this.state.emailID,
                facebookID: this.state.facebookID,
                twitterID: this.state.twitterID,
                instagramID: this.state.instagramID,
                linkedInID: this.state.linkedInID,
                whatsappNumber: this.state.whatsappNumber,
                skypeID: this.state.skypeID,
                emergencyContactPerson: this.state.emergencyContactPerson,
                emergencyContactRelation: this.state.emergencyContactRelation,
                emergencyContactMobile: this.state.emergencyContactMobile,
                emergencyContactLandline: this.state.emergencyContactLandline,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/update', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState({ edit: false });
                    await this.setState({ errorMessages: ["success"] });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        if (label === 'statename') {
            districtsList = districts.filter(row => row['state'] === value);
            this.setState({ district: '' });
        }
        else if (label === 'statename1') {
            districtsList1 = districts.filter(row => row['state'] === value);
            this.setState({ district1: '' });
        }
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    reset = () => {
        this.setState(this.initialState)
    }

    onChangeCheckBox = (e) => {
        if (e.target.checked) {
            this.setState({
                doorNumber1: this.state.doorNumber,
                street1: this.state.street,
                place1: this.state.place,
                city1: this.state.city,
                district1: this.state.district,
                statename1: this.state.statename,
                country1: this.state.country,
                pinCode1: this.state.pinCode
            }
            );
        } else {
            this.setState({
                doorNumber1: '',
                street1: '',
                place1: '',
                city1: '',
                district1: '',
                statename1: '',
                country1: '',
                pinCode1: '',
            }
            );
        }
    }

    render() {
        let { doorNumber, street, place, city, district, statename, country, pinCode,
            doorNumber1, street1, place1, city1, district1, statename1, country1, pinCode1,
            mobileNumber, landlineNumber, emailID, facebookID, whatsappNumber, skypeID, twitterID, instagramID, linkedInID, emergencyContactPerson, emergencyContactRelation, emergencyContactMobile, emergencyContactLandline, edit } = this.state;
        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1" >
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Communication Information
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                            <Tag className="ml-1" color="success">Self-Verified</Tag>
                        </Title>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 }>
                        <Descriptions
                            bordered
                            column={ { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 } }
                        >
                            <Descriptions.Item label="Correspondance Address">
                                <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="doorNumber" addonBefore="* Door / Flat / House / Apartments No" onChange={ this.onChange } value={ doorNumber } />
                                    </Col>

                                    <Col xs={ 24 } md={ 16 }>
                                        <Input id="street" addonBefore="* Address Line 1" onChange={ this.onChange } value={ street } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="place" addonBefore="* Address Line 2" onChange={ this.onChange } value={ place } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="city" addonBefore="* Place / City" onChange={ this.onChange } value={ city } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="country" addonBefore="* Country" onChange={ this.onChange } value={ country } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <InputGroup>
                                            <Row>
                                                <Col xs={ 24 } md={ 8 }>
                                                    <Input defaultValue="* State Name" disabled className="newLabel" />
                                                </Col>
                                                <Col xs={ 24 } md={ 16 }>
                                                    <Select showSearch id="statename" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "statename") } value={ statename }>
                                                        {
                                                            states.map(
                                                                (item, val) => {
                                                                    return (
                                                                        <Option key={ val } value={ item }>{ item }</Option>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <InputGroup>
                                            <Row>
                                                <Col xs={ 24 } md={ 8 }>
                                                    <Input defaultValue="* District" disabled className="newLabel" />
                                                </Col>
                                                <Col xs={ 24 } md={ 16 }>
                                                    <Select showSearch id="district" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "district") } value={ district }>
                                                        {
                                                            districtsList.map(
                                                                (item, val) => {
                                                                    return (
                                                                        <Option key={ val } value={ item.district }>{ item.district }</Option>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="pinCode" addonBefore="* Postal Code" onChange={ this.onChange } value={ pinCode } />
                                    </Col>
                                </Row>
                            </Descriptions.Item>


                            <Descriptions.Item label="Permanent Address">
                                <Row gutter={ [16, 16] } style={ { margin: 0 } }>

                                    <Col xs={ 24 }>
                                        <Checkbox onChange={ this.onChangeCheckBox }>Same as Correspondance Address</Checkbox>
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="doorNumber1" addonBefore="* Door / Flat / House / Apartments No" onChange={ this.onChange } value={ doorNumber1 } />
                                    </Col>

                                    <Col xs={ 24 } md={ 16 }>
                                        <Input id="street1" addonBefore="* Address Line 1" onChange={ this.onChange } value={ street1 } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="place1" addonBefore="* Address Line 2" onChange={ this.onChange } value={ place1 } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="city1" addonBefore="* Place / City" onChange={ this.onChange } value={ city1 } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="country1" addonBefore="* Country" onChange={ this.onChange } value={ country1 } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <InputGroup>
                                            <Row>
                                                <Col xs={ 24 } md={ 8 }>
                                                    <Input defaultValue="* State Name" disabled className="newLabel" />
                                                </Col>
                                                <Col xs={ 24 } md={ 16 }>
                                                    <Select showSearch id="statename1" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "statename1") } value={ statename1 }>
                                                        {
                                                            states.map(
                                                                (item, val) => {
                                                                    return (
                                                                        <Option key={ val } value={ item }>{ item }</Option>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <InputGroup>
                                            <Row>
                                                <Col xs={ 24 } md={ 8 }>
                                                    <Input defaultValue="* District" disabled className="newLabel" />
                                                </Col>
                                                <Col xs={ 24 } md={ 16 }>
                                                    <Select showSearch id="district1" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "district1") } value={ district1 }>
                                                        {
                                                            districtsList1.map(
                                                                (item, val) => {
                                                                    return (
                                                                        <Option key={ val } value={ item.district }>{ item.district }</Option>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="pinCode1" addonBefore="* Postal Code" onChange={ this.onChange } value={ pinCode1 } />
                                    </Col>
                                </Row>
                            </Descriptions.Item>

                            <Descriptions.Item label="Social Network">
                                <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="mobileNumber" addonBefore="* Mobile Number" onChange={ this.onChange } value={ mobileNumber } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="landlineNumber" addonBefore="Landline Number" onChange={ this.onChange } value={ landlineNumber } type="number" />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="whatsappNumber" addonBefore="Whatsapp Number" onChange={ this.onChange } value={ whatsappNumber } type="number" />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="emailID" addonBefore="* Personal E-mail ID" onChange={ this.onChange } value={ emailID } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="facebookID" addonBefore="Facebook ID" onChange={ this.onChange } value={ facebookID } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="skypeID" addonBefore="Skype ID" onChange={ this.onChange } value={ skypeID } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="twitterID" addonBefore="Twitter ID" onChange={ this.onChange } value={ twitterID } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="instagramID" addonBefore="Instagram ID" onChange={ this.onChange } value={ instagramID } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="linkedInID" addonBefore="LinkedIn ID" onChange={ this.onChange } value={ linkedInID } />
                                    </Col>
                                </Row>
                            </Descriptions.Item>

                            <Descriptions.Item label="Emergency Contact">
                                <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                    <Col xs={ 24 } md={ 16 }>
                                        <Input id="emergencyContactPerson" addonBefore="* Person Name" onChange={ this.onChange } value={ emergencyContactPerson } />
                                    </Col>
                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="emergencyContactRelation" addonBefore="Relationship" onChange={ this.onChange } value={ emergencyContactRelation } />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="emergencyContactMobile" addonBefore="* Mobile" onChange={ this.onChange } value={ emergencyContactMobile } type="number" />
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="emergencyContactLandline" addonBefore="Landline" onChange={ this.onChange } value={ emergencyContactLandline } />
                                    </Col>
                                </Row>
                            </Descriptions.Item>

                            <Descriptions.Item label="Action">
                                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                                    {
                                        this.state.errorMessages.map(
                                            (item, val) => {
                                                return (
                                                    <Col xs={ 24 } key={ val }>
                                                        <Alert
                                                            message={ item === "success" ? "Information Updated" : item }
                                                            showIcon
                                                            closable
                                                            type={ item === "success" ? "success" : "error" }
                                                        />
                                                    </Col>
                                                )
                                            }
                                        )
                                    }
                                </Row>

                                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                                    <Col xs={ 24 } md={ 4 }>
                                        <Button type="primary" style={ { width: "100%" } } htmlType="submit" onClick={ this.onClickSubmitBtn }
                                            disabled={ !this.state.edit }>
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Communication));