import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import Communication from './Communication'
import Social from './Social'
import BankDetails from './BankDetails'
const { Title } = Typography;
const { TabPane } = Tabs;

class Contact extends Component {
    state = {
        loading: false,
    };

    render() {

        let tabName = ["Communication", "Social", "Bank Details"];

        return (
            <div>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Title level={3}>Contact</Title>
                    </Col>
                    <Col xs={24}>
                        <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                            <TabPane tab={tabName[0]} key="1"><Communication /> </TabPane>
                            <TabPane tab={tabName[1]} key="2"><Social /> </TabPane>
                            <TabPane tab={tabName[2]} key="3"><BankDetails /> </TabPane>
                        </Tabs>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Contact));