import React, { Component } from 'react'
import { Input, Row, Col, Table, Space, Button, message, Card, Switch, Select, Typography, Image, Modal, List, Tag } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import { UserOutlined, SearchOutlined, EnterOutlined, DownloadOutlined, CheckOutlined, CloseOutlined, ClearOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import CourseStatus from './CourseStatus';
import CourseUploads from './CourseUploads';
import BulkCoursesCreate from './BulkCoursesCreate';
import { CSVLink } from "react-csv";
import BatchAdminFlow from '../../../images/BatchAdminFlow.png';
const { Option } = Select;
const { Title } = Typography;

class CreateCourses extends Component {
    state = {
        COURSE_CODE: "",
        COURSE_NAME: "",
        SELECTED_COURSE_CODE: "",
        SELECTED_COURSE_NAME: "",
        courses: [],
        loading: true,
        key: 0,
        searchText: '',
        searchedColumn: '',
        Switchloading: false,
        isModalOpen: false,
        SEMESTER: "",
        header_list: [
            { label: "Course Code", key: "COURSE_CODE" },
            { label: "Course Name", key: "COURSE_NAME" },
            { label: "Department", key: "DEPARTMENT" },
            { label: "Enabled", key: "ENABLE" },
            { label: "Semester", key: "SEMESTER" },
        ],
        status: [],
    }

    componentDidMount() {
        let sendData = {
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourses', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    this.setState({ courses: res.data.courses, loading: false })
            });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleChangeSelect = (value) => {
        this.setState({ SEMESTER: parseInt(value) });
    }

    onClickAdd = async () => {
        let temp = prompt("Enter key", "");
        if (temp === "createnewcourse") {
            this.setState({ loading: true });

            let { COURSE_CODE, COURSE_NAME, SEMESTER } = this.state;
            let courses = [...this.state.courses];

            if (!COURSE_CODE || !COURSE_NAME || !SEMESTER) {
                message.error('Invalid Entry!');
                this.setState({
                    COURSE_CODE: "",
                    COURSE_NAME: "",
                    loading: false
                });
                return false;
            }

            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE.toUpperCase().trim(),
                    COURSE_NAME: COURSE_NAME.toUpperCase().trim(),
                    SEMESTER: SEMESTER
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/course/create', sendData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        courses.push(res.data.course);
                        this.setState({
                            courses: courses, COURSE_CODE: "",
                            COURSE_NAME: "", SEMESTER: ""
                        });
                        message.success(res.data.msg);
                    } else {
                        this.setState({
                            COURSE_CODE: "",
                            COURSE_NAME: "", SEMESTER: ""
                        });
                        message.error(res.data.msg);
                    }
                });
            this.setState({ loading: false });
        }
        else message.error("Wrong Key !");
    }

    StatusBtn = (course) => {
        this.setState({ SELECTED_COURSE_CODE: course.COURSE_CODE, SELECTED_COURSE_NAME: course.COURSE_NAME, key: 1 });
    }

    UploadsBtn = (course) => {
        this.setState({ SELECTED_COURSE_CODE: course.COURSE_CODE, SELECTED_COURSE_NAME: course.COURSE_NAME, key: 2 });
    }

    onChangeSwitch = async (course, flag) => {
        this.setState({ Switchloading: true });
        this.setState({ loading: true });

        let data = {
            COURSE_CODE: course.COURSE_CODE.toUpperCase(),
            ENABLE: flag,
            key: config.key,
        }

        await axios.post(config.curriculaserver + '/curricula/admin/course/setenable', data)
            .then(async res => {
                this.setState({ Switchloading: false });
                if (res.data.Status === 1) {
                    let courses = this.state.courses;
                    let index = courses.findIndex(x => x._id === course._id);
                    courses[index].ENABLE = flag;
                    this.setState({ courses: courses, loading: false });
                    message.success("Information Updated");

                } else
                    message.error("Operation Failed !");
            });
    }

    statusSetAllCourses = (type) => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        this.setState({ loading: true });

        let data = {
            ENABLE: type === 1 ? true : false,
            key: config.key
        }

        axios.post(config.curriculaserver + '/curricula/admin/course/setenableforallcourses', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let courses = [...this.state.courses]
                    courses.forEach((obj) => {
                        obj.ENABLE = data.ENABLE;
                    });
                    this.setState({ courses: courses, loading: false });
                    type ? message.success("All Courses are Enabled Successfully !") : message.success("All Courses are Disabled Successfully !");
                }
                else {
                    message.error("Operation Failed !");
                }
            });
    }


    deleteBtn = (course) => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        let temp = prompt("Enter password", "");
        if (temp === "072017") {
            this.setState({ loading: true });
            let data = {
                COURSE_CODE: course.COURSE_CODE.toUpperCase(),
                key: config.key
            }
            axios.post(config.curriculaserver + '/curricula/admin/course/delete', data)
                .then(res => {
                    if (res.data.Status === 1) {
                        let courses = [...this.state.courses]
                        courses = courses.filter((obj) => {
                            return obj.COURSE_CODE !== course.COURSE_CODE;
                        });
                        this.setState({ courses: courses, loading: false, status: res.data.result });
                        message.success("Course Deleted Successfully !");
                        this.setState({ isModalOpen: true })
                    }
                    else {
                        message.error("Operation Failed !");
                    }
                });
        }

    }
    handleOk = () => {
        this.setState({ isModalOpen: false })
    };

    handleCancel = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        let { courses, SELECTED_COURSE_CODE, SELECTED_COURSE_NAME, Switchloading, isModalOpen, handleOk, status } = this.state;

        const columns = [
            {
                title: 'SNO',
                dataIndex: 'key',
                width: 0.5,
                render: (text) => (courses.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'COURSE CODE',
                dataIndex: 'COURSE_CODE',
                fixed: 'left',
                key: 'COURSE_CODE',
                width: 0.5,
                ...this.getColumnSearchProps('COURSE_CODE'),
            },
            {
                title: 'COURSE NAME',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
                width: 1,
                ...this.getColumnSearchProps('COURSE_NAME'),
                render: (text) => (text.toUpperCase()),
            },
            {
                title: 'SEMESTER',
                dataIndex: 'SEMESTER',
                key: 'SEMESTER',
                width: 0.5,
                ...this.getColumnSearchProps('SEMESTER'),
                render: (text) => (text)
            },
            {
                title: 'STUDENTS TO OPEN',
                dataIndex: 'ENABLE',
                key: 'ENABLE',
                width: 1,
                render: (text, record) => (
                    <Switch disabled={this.props.common.user.ROLE !== 'A' && this.props.common.user.ROLE !== 'B' ? true : false} loading={Switchloading} checkedChildren="Enabled" unCheckedChildren="Disabled" checked={text ? true : false} onChange={this.onChangeSwitch.bind(this, record)} />
                )
            },
            {
                title: 'DEPARTMENT',
                dataIndex: 'DEPARTMENT',
                key: 'DEPARTMENT',
                width: 3,
                ...this.getColumnSearchProps('DEPARTMENT'),
            },
            {
                title: 'ACTION',
                fixed: 'right',
                width: 1,
                render: (text, record) => (
                    <>
                        <Button icon={<SearchOutlined />} type="primary" size="small" onClick={this.StatusBtn.bind(this, record)}>STATUS</Button>
                        {
                            /*
                            this.props.common.user.ROLE === 'A' ?
                                <Button icon={<UploadOutlined />} type="dashed" size="small" onClick={this.UploadsBtn.bind(this, record)}>BULK UPLOAD</Button> : ''
                            */

                            this.props.common.user.ROLE === 'A' ?
                                <Button className='ml-1' danger icon={<ClearOutlined />} type="primary" size="small" onClick={this.deleteBtn.bind(this, record)}>DELETE </Button> : ''
                        }
                    </>
                )
            },
        ];

        return (<>
            <Row gutter={[16, 16]} type="flex" justify="start">
                <Col xs={24} align="middle" className='hide'>
                    <Image
                        width={"25%"}
                        src={BatchAdminFlow}
                        className="mt-1"
                    />
                </Col>
                <Col xs={24}>
                    {
                        this.props.common.user.ROLE === 'A' || this.props.common.user.ROLE === 'B' ?
                            <Card
                                title="New Single Course Creation" size="small" loading={this.state.loading}
                                bodyStyle={{ padding: "2%" }} className='mb-2'
                                style={{
                                    boxShadow: "none", border: "1px solid #d9d9d9"
                                }}>
                                <Row gutter={[16, 16]} type="flex" justify="start">

                                    <Col xs={24} lg={4}>
                                        <Select style={{ width: "100%" }} onChange={this.handleChangeSelect} value={this.state.SEMESTER}>
                                            <Option value="" disabled>Choose Semester</Option>
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                            <Option value="6">6</Option>
                                            <Option value="7">7</Option>
                                            <Option value="8">8</Option>
                                            <Option value="9">9</Option>
                                            <Option value="10">10</Option>
                                        </Select>
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Input
                                            id="COURSE_CODE"
                                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="Course Code" autoComplete="new-password"
                                            value={this.state.COURSE_CODE} onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Input
                                            id="COURSE_NAME"
                                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="Course Name" autoComplete="new-password"
                                            value={this.state.COURSE_NAME} onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={24} lg={4}>
                                        <Button icon={<EnterOutlined />} disabled={!this.state.COURSE_CODE || !this.state.COURSE_NAME ? true : false} block type="primary" onClick={this.onClickAdd}>SUBMIT</Button>
                                    </Col>
                                </Row>
                            </Card>
                            : ''
                    }

                    <BulkCoursesCreate />
                </Col>
            </Row>
            <br />
            <Title level={3}>List of Created Courses</Title>

            <Row gutter={[16, 16]} type="flex" justify="start" style={{ marginTop: "5px" }}>

                <Col xs={24}>
                    <Space>
                        <CSVLink data={courses} headers={this.state.header_list} filename={config.toolSubName + "-course-list.csv"}>
                            <Button type="dashed" icon={<DownloadOutlined />} size="small" disabled={!courses.length ? true : false}>
                                Download EXCEL
                            </Button>
                        </CSVLink>
                        {
                            this.props.common.user.ROLE !== 'R' && this.props.common.user.ROLE !== 'H' ?
                                <>
                                    <Button type="primary" icon={<CheckOutlined />} onClick={this.statusSetAllCourses.bind(this, 1)} size="small"
                                        disabled={!courses.length ? true : false}>
                                        Enable All Courses
                                    </Button>
                                    <Button type="primary" danger icon={<CloseOutlined />} onClick={this.statusSetAllCourses.bind(this, 0)} size="small" disabled={!courses.length ? true : false}>
                                        Disable All Courses
                                    </Button>
                                </> : null
                        }
                    </Space>
                </Col>

                <Col xs={24}>
                    <Table bordered size='middle' dataSource={courses} columns={columns} loading={this.state.loading} pagination />
                </Col>

                {
                    this.state.key === 1 ?
                        <Col xs={24}>
                            <CourseStatus className="mt-1" COURSE_CODE={SELECTED_COURSE_CODE} COURSE_NAME={SELECTED_COURSE_NAME} />
                        </Col>
                        :
                        this.state.key === 2 ?
                            <Col xs={24}>
                                <CourseUploads className="mt-1" COURSE_CODE={SELECTED_COURSE_CODE} COURSE_NAME={SELECTED_COURSE_NAME} />
                            </Col> : ''
                }
            </Row >

            <Modal title="Course Deletion" footer={null} visible={isModalOpen} destroyOnClose onOk={handleOk} onCancel={this.handleCancel}>
                <List
                    className={status.length > 0 ? '' : 'hide'}
                    pagination
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={status}
                    renderItem={item => (
                        <List.Item style={{ padding: "0px 16px" }}>
                            <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                        </List.Item>
                    )}
                />
            </Modal>
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CreateCourses));