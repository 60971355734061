import React, { Component } from 'react'
import { Row, Col, Button, Input, DatePicker, Tooltip, Table, notification, Alert, Popconfirm, Tag } from 'antd';
import { Upload, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import {
    UploadOutlined, EditOutlined, DeleteOutlined, PlusOutlined
} from '@ant-design/icons';
const InputGroup = Input.Group;
const { Title, Text } = Typography;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const dateFormat = ['DD/MM/YYYY'];
const topic = "certification";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class Certification extends Component {
    state = {
        certificationName: '',
        certificationAgency: '',
        from: '01/01/2020',
        to: '01/01/2020',
        duration: '',
        dateObtained: '01/01/2020',
        certificationNumber: '',
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }


    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }


    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });

        if (this.state.from && label === 'to') {
            this.getDuration(this.state.from, dateString)
        }
        else {
            this.setState({ errorMessages: ["Please select certification start Date "], disable: true });
        }

    }

    getDuration(a, b) {
        let momentJoining = moment(a, "DD/MM/YYYY");
        let momentRelieving = moment(b, "DD/MM/YYYY");
        let now = moment();

        if (momentRelieving > momentJoining && momentRelieving <= now && momentJoining <= now) {
            let dateOfJoining = [momentJoining.year(), momentJoining.month(), momentJoining.date()];
            let dateOfRelieving = [momentRelieving.year(), momentRelieving.month(), momentRelieving.date()];
            this.setState({ duration: (moment(dateOfRelieving).diff(moment(dateOfJoining), 'days', true)) + 1 });
        }
        else if (a === b) {
            this.setState({ duration: 1 })
        }
        else {
            this.setState({ duration: 0, errorMessages: ["Invalid Dates"] });
        }
    }




    onClickSubmitBtn = () => {
        let { certificationName, certificationAgency, from, to, dateObtained, } = this.state;
        let errorMessages = [];

        if (!certificationName) errorMessages.push("Invalid Certification Name");
        else if (!certificationAgency) errorMessages.push("Invalid Organized By");
        else if (!from) errorMessages.push("Select From Date");
        else if (!to) errorMessages.push("Select To Date");
        else if (!dateObtained) errorMessages.push("Invalid Date Obtained");
        //  else if (!certificationNumber) errorMessages.push("Select Certification Number");

        //File check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File check End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;
        let data = {
            key: config.key,
            topic: topic,
            data: {
                certificationName: this.state.certificationName,
                certificationAgency: this.state.certificationAgency,
                from: this.state.from,
                to: this.state.to,
                duration: this.state.duration,
                dateObtained: this.state.dateObtained,
                certificationNumber: this.state.certificationNumber,
                _id: this.state._id,
                key: this.state.key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/professional/certification/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        await axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    await this.setState(this.initialState);
                    await this.setState({ update: false });
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/certification/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/certification/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/certification/' + record.key + ".pdf"
        });
        //File get End
    }


    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/professional/certification/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });
                }
            });
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { certificationName, certificationAgency, from, to, duration, dateObtained, certificationNumber, edit, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Certification Name',
                dataIndex: 'certificationName',
                key: 'certificationName',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 20,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Date Obtained',
                dataIndex: 'dateObtained',
                key: 'dateObtained',
                width: 20,
            },
            {
                title: 'Certification Number',
                dataIndex: 'certificationNumber',
                key: 'certificationNumber',
                width: 50,
            },
            {
                title: 'Action',
                key: 'certificateNumber',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Additional Qualifications
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='certificationName' addonBefore="Certification Name" onChange={ this.onChange } value={ certificationName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='certificationAgency' addonBefore="Certifying Agency" onChange={ this.onChange } value={ certificationAgency } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* From" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id='from' placeholder="From" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "from") } value={ moment(from, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* To" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id='to' placeholder="To" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "to") } value={ moment(to, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='duration' addonBefore="Duration" disabled onChange={ this.onChange } value={ duration } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Date Obtained" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id='dateObtained' placeholder="Date Obtained" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "dateObtained") } value={ moment(dateObtained, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='certificationNumber' addonBefore="Certificate Number" onChange={ this.onChange } value={ certificationNumber } />
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn }
                            disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } disabled={ !this.state.edit } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } bordered />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Certification));