import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Descriptions, Card, Tag, Select, message, List, Input, Modal, Row, Col, Upload, Table, Statistic, Tooltip, Space, notification } from 'antd';
import axios from 'axios';
import config from '../../config';
import Highlighter from 'react-highlight-words';
import { InboxOutlined, EditOutlined, SearchOutlined, RedoOutlined, DownloadOutlined, EnterOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Template from '../../doc/batch_user_assign.csv'
const { Option } = Select;
const { Dragger } = Upload;
const openNotification = () => {
    notification.success({
        message: 'Any Queries?',
        description:
            'For New Student Registration / Batch or Section Creation / Student Assign to the Batch or Section, Kindly Contact Batch Admin.',
        top: 60,
        duration: 20
    });
};

class ManageBatch extends Component {

    state = {
        key: 0,
        loading: true, loadingInside: false,
        BATCH_NAME: '', EDIT_BATCH_NAME: '', COURSE_CODE: '', COURSE_NAME: '', uploadModalVisible: false, listModalVisible: false, editModalVisible: false,
        selectedBatchID: '', selectedCourseID: '', selectedBatchName: '', selectedCourseCode: '',
        courseList: [], batchList: [], status: [], studentsList: [], filename: '', fileStatus: [], fileLoading: false,
        searchText: '',
        searchedColumn: '',
        header_list: [
            { label: "User ID", key: "USER_ID" },
            { label: "Name", key: "USER_NAME" },
            { label: "Department", key: "DEPARTMENT" },
        ],
        SEMESTER: 0,
        selectedSemester: 0
    };

    componentDidMount() {
        this.onLoad();
        openNotification();
    }

    onLoad = async () => {
        this.setState({ loading: true });
        let data = {
            info: this.props.common.user,
            KEY: config.key
        }
        await axios.post(config.curriculaserver + '/curricula/faculty/batch/courses', data)
            .then(res => {
                if (res.data.Status === 1) this.setState({ courseList: res.data.result1 || [], batchList: res.data.result2 || [], loading: false });
            });
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleChangeSelect = (value) => {
        this.setState({ COURSE_CODE: value.value, COURSE_NAME: value.label[0].props.children, SEMESTER: parseInt(value.label[2].props.children) })
    }

    handleCancel = () => {
        this.setState({ uploadModalVisible: false, listModalVisible: false, editModalVisible: false });
    };

    handleOk = () => {
        let { EDIT_BATCH_NAME, selectedBatchID } = this.state;
        //console.log(EDIT_BATCH_NAME, selectedBatchID)
        if (!EDIT_BATCH_NAME || !selectedBatchID) { message.error("Invalid Entry !"); return false; }

        this.setState({ loadingModal: true });

        let data = {
            info: this.props.common.user,
            BATCH_NAME: EDIT_BATCH_NAME,
            BATCH_ID: selectedBatchID,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/faculty/batch/editname', data)
            .then(res => {
                if (res.data.Status) {
                    let batchList = this.state.batchList || [];
                    batchList = batchList.map(x => (x.BATCH_ID === selectedBatchID ? { ...x, BATCH_NAME: EDIT_BATCH_NAME } : x));
                    this.setState({ batchList: batchList, EDIT_BATCH_NAME: '', editModalVisible: false, loadingModal: false });
                    message.success("Information Updated !");
                }
                else message.error("Operation Failed !");
            });
    };

    onClickCreateBatch = () => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        let { COURSE_CODE, COURSE_NAME, BATCH_NAME, batchList, SEMESTER } = this.state;

        if (!COURSE_CODE || !COURSE_NAME || !BATCH_NAME || !SEMESTER) {
            message.error("Invalid Entry !");
            return false;
        }

        this.setState({ loadingModal: true });

        let data = {
            info: this.props.common.user,
            COURSE_CODE: COURSE_CODE,
            COURSE_NAME: COURSE_NAME,
            BATCH_NAME: BATCH_NAME,
            SEMESTER: parseInt(SEMESTER),
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/faculty/batch/create', data)
            .then(res => {
                if (res.data.Status) {
                    batchList.push(res.data.result);
                    this.setState({ batchList: batchList, loadingModal: false });
                    message.info(res.data.msg);
                }
                else message.error("Operation Failed !");
            });
    }

    onClickShowBatch = (BATCH_ID, BATCH_NAME, COURSE_CODE) => {
        if (!BATCH_ID || !BATCH_NAME) { message.error("Invalid Entry !"); return false; }
        this.setState({ selectedCourseCode: COURSE_CODE, selectedBatchID: BATCH_ID, selectedBatchName: BATCH_NAME, studentsList: [], loadingModal: true });

        let data = {
            info: this.props.common.user,
            BATCH_ID: BATCH_ID,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/faculty/batch/get', data)
            .then(res => {
                if (res.data.Status) {
                    this.setState({ studentsList: res.data.result || [], loadingModal: false, listModalVisible: true });
                }
                else message.error("Operation Failed !");
            });
    }

    onClickRemove = (record) => {
        let { selectedBatchID, studentsList, selectedCourseCode } = this.state;
        if (!selectedBatchID || studentsList.length === 0) { message.error("Invalid Entry !"); return false; }
        if (record) {
            let data = {
                info: this.props.common.user,
                record: record,
                selectedBatchID: selectedBatchID,
                selectedCourseCode: selectedCourseCode,
                KEY: config.key
            }
            axios.post(config.curriculaserver + '/curricula/faculty/batch/removestudent', data)
                .then(res => {
                    if (res.data.Status) {
                        studentsList = studentsList.filter(element => element.key !== record.key);
                        this.setState({ studentsList });
                        message.success("Information Updated !");
                    }
                    else message.error("Operation Failed !");
                });
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { loadingModal, courseList, batchList, studentsList, BATCH_NAME, uploadModalVisible, selectedBatchID, selectedBatchName, selectedCourseID, status, fileLoading, listModalVisible, editModalVisible, EDIT_BATCH_NAME, header_list, COURSE_CODE, selectedSemester } = this.state;

        const params = {
            selectedBatchID: selectedBatchID,
            selectedCourseID: selectedCourseID,
            SEMESTER: parseInt(selectedSemester),
            KEY: config.key,
        }

        let props = {
            className: 'padding-2',
            listType: 'picture',
            accept: ".csv",
            name: 'csv',
            multiple: false,
            action: config.curriculaserver + '/curricula/faculty/batch/assign',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    //console.log(info.file);
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename];
                        if (info.file.response.Status === 0) { message.error(info.file.response.msg) }
                        else if (info.file.response) this.onLoad();
                        return { fileStatus: fileStatus, status: info.file.response ? info.file.response : [], fileLoading: false };
                    });
                    if (info.file.response.Status !== 0)
                        message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 2024 / 2024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (studentsList.findIndex(i => i.key === text) + 1),
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                ...this.getColumnSearchProps('USER_ID'),
            },
            {
                title: 'NAME',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
                ...this.getColumnSearchProps('FULL_NAME'),
                render: (text) => (text.toUpperCase()),
            },
            {
                title: 'DEPARTMENT',
                dataIndex: 'DEPARTMENT',
                key: 'DEPARTMENT',
                ...this.getColumnSearchProps('DEPARTMENT'),
            },
            {
                title: 'ACTION',
                dataIndex: '_id',
                key: '_id',
                render: (text, record) => (
                    <Button icon={<DeleteOutlined />} size="small" danger onClick={this.onClickRemove.bind(this, record)}>Remove</Button>
                ),
            },
        ];

        if (config.ReduceFeaturesFromCoordinatorAndFaculty === 1) columns = columns.filter(item => item.title !== 'ACTION');

        return (<>
            <div className="card-container padding-1">

                <Alert message="Any Queries? For New Student Registration / Section Creation / Student Assign to the Section, Kindly Contact Batch Admin." type="info" showIcon closable />

                <br />

                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>

                    {
                        config.ReduceFeaturesFromCoordinatorAndFaculty === 0 ?
                            <Descriptions.Item label="Batch Creation" >
                                <Row gutter={[8, 8]} align="middle">
                                    <Col xs={24} md={10} align="start">
                                        <Select labelInValue style={{ width: "100%" }} onChange={this.handleChangeSelect}
                                            placeholder="Select Course"
                                        >
                                            {
                                                courseList.map(
                                                    (item, value) => {
                                                        return (
                                                            <Option key={value} value={item.COURSE_CODE}>
                                                                <Tag color="magenta">{item.COURSE_NAME}</Tag>
                                                                <Tag color="cyan">{item.COURSE_CODE}</Tag>
                                                                <Tag color="cyan">{item.SEMESTER}</Tag>
                                                            </Option>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                    </Col>
                                    <Col xs={24} md={10} align="start">
                                        <Input style={{ width: "100%" }} className="ml-1" id="BATCH_NAME" addonBefore="Batch Name" onChange={this.handleChange} value={BATCH_NAME} />
                                    </Col>
                                    <Col xs={24} md={4} align="start">
                                        <Button block className="ml-1" icon={<EnterOutlined />} type="primary" disabled={!COURSE_CODE || !BATCH_NAME ? true : false}
                                            onClick={this.onClickCreateBatch}> CREATE </Button>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                            : null
                    }

                    <Descriptions.Item label="Section List" >
                        <Button icon={<RedoOutlined />} type="primary" className="mb-2" onClick={this.onLoad}>Refersh</Button>

                        <List
                            loading={loadingModal}
                            grid={{
                                gutter: [16, 16],
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 2,
                                xl: 2,
                                xxl: 2,
                            }}
                            dataSource={batchList || []}
                            renderItem={(item, val) => (
                                <List.Item key={val}>
                                    <Card className="course-card" size="small" type="inner" title={"Section Name : " + item.BATCH_NAME} hoverable
                                        extra={
                                            <Tooltip title="Edit Section Name"><Button size="small" type="text" icon={<EditOutlined className="white-text" />} className={config.ReduceFeaturesFromCoordinatorAndFaculty === 1 ? "hide" : ""} onClick={() => { this.setState({ selectedBatchID: item.BATCH_ID, selectedBatchName: item.BATCH_NAME, editModalVisible: true }); }} /></Tooltip>}
                                    >
                                        <Row gutter={[8, 8]} align="middle">
                                            <Col span={12} align="start">
                                                <Tag color="blue">Section ID : {item.BATCH_ID}</Tag>
                                            </Col>
                                            <Col span={24} align="start">
                                                <Statistic title={<>{item.COURSE_NAME + " (" + item.COURSE_CODE + ")"}</>} value={item.COUNT || 0}
                                                    valueStyle={{ color: item.COUNT > 0 ? '#3f8600' : '#cf1322' }}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <Button block size="small" icon={<DownloadOutlined />} type="dashed"
                                                    disabled={!item.COUNT ? true : false}
                                                    onClick={this.onClickShowBatch.bind(this, item.BATCH_ID, item.BATCH_NAME, item.COURSE_CODE)}>VIEW</Button>
                                            </Col>

                                            <Col span={12}>
                                                <Button className={config.ReduceFeaturesFromCoordinatorAndFaculty === 1 ? "hide" : ""} icon={<UploadOutlined />} block size="small" onClick={() => { this.setState({ selectedBatchID: item.BATCH_ID, selectedBatchName: item.BATCH_NAME, selectedCourseID: item.COURSE_CODE, selectedSemester: parseInt(item.SEMESTER), status: [], uploadModalVisible: true }); }} >UPLOAD</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </div >

            <Modal title={selectedBatchName + " Upload List - " + selectedCourseID} visible={uploadModalVisible} onOk={this.handleCancel} onCancel={this.handleCancel} footer={null} className="padding-2">
                <Button className="mb-2" type="primary" icon={<DownloadOutlined />} href={Template} block>Download Excel Template</Button>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single file upload. Strictly prohibit from uploading irrelevant data or other
                        band files
                    </p>
                </Dragger>
                <br />  <br />
                <List
                    className={status.length > 0 ? '' : 'hide'}
                    loading={fileLoading} pagination
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={status}
                    renderItem={item => (
                        <List.Item style={{ padding: "0px 16px" }}>
                            <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                        </List.Item>
                    )}
                />
            </Modal>

            <Modal title={selectedBatchName + " (" + selectedBatchID + ") List"} visible={listModalVisible} onOk={this.handleCancel} onCancel={this.handleCancel} className="padding-2" footer={null} width={800}>
                <CSVLink data={studentsList || []} headers={header_list} filename={selectedBatchName + "-list.csv"}>
                    <Button type="primary" size="small" icon={<DownloadOutlined />} disabled={!studentsList.length ? true : false}>
                        Download EXCEL
                    </Button>
                </CSVLink>
                <Table className="mt-2" dataSource={studentsList} columns={columns} bordered size="small" />
            </Modal>

            <Modal title={<><EditOutlined className="mr-1" /> {selectedBatchName + " - Edit"}</>} visible={editModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} className="padding-2" >
                <Input id="EDIT_BATCH_NAME" addonBefore="New Batch Name" onChange={this.handleChange} value={EDIT_BATCH_NAME} />
            </Modal>

        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ManageBatch));