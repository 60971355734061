import React, { Component } from 'react'
import { Select, Popconfirm, Descriptions, Table, Button, Space, message, Result, Tag, Avatar, Row, Col, Input, Empty } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from '../../../config';
import { DeleteOutlined, PlusOutlined, DownloadOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { CSVLink } from "react-csv";
import Highlighter from 'react-highlight-words';
import BulkCoursesAssign from './BulkCoursesAssign';
const { Option } = Select;
const page_name = "slot";

class CourseAssign extends Component {
    state = {
        COURSE_CODE: '', COURSE_NAME: '', courses: [],
        loading: false,
        faculty: [],
        selectedFaculty: '',
        selectedFacultyID: '',
        selectedFacultyDept: '',
        FacultyList: [], FULL_NAME: '',
        error: 0,
        searchText: '',
        searchedColumn: '',
    };

    async componentDidMount() {
        await this.setState({ loading: true })
        let sendData = {
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/admin/course/getcourses', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    this.setState({ courses: res.data.courses })
            });
        await axios.post(config.curriculaserver + '/curricula/admin/manageuser/listfaculty', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ faculty: res.data.result, loading: false })
                }
            });
    }

    handleChangeSelectFaculty = (e) => {
        let faculty = [...this.state.faculty]
        e = faculty.find(v => v.USER_ID === e);
        this.setState({ selectedFaculty: e.FULL_NAME, selectedFacultyID: e.USER_ID, selectedFacultyDept: e.DEPARTMENT, FULL_NAME: e.FULL_NAME });
    }

    getCourse = async (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "course_learning_session"
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (Object.keys(record).length === 0) {
                            this.setState({ error: 1 })
                        } else this.setState({ error: 0 })
                    }
                });

            sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "facultylist"
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/course/getcoursefaculty', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        this.setState({ FacultyList: res.data.result, loading: false })
                    }
                    else
                        this.setState({ FacultyList: [] })
                });
        }
        else
            message.error("Course Not Selected");
    }

    onClickAdd = () => {
        let { selectedFaculty, selectedFacultyID, selectedFacultyDept, COURSE_CODE, COURSE_NAME } = this.state;

        let FacultyList = [...this.state.FacultyList];

        let data = {
            facultyName: selectedFaculty,
            facultyID: selectedFacultyID,
            department: selectedFacultyDept,
            _id: COURSE_CODE + "_" + selectedFacultyID,
            key: COURSE_CODE + "_" + selectedFacultyID,
        }
        let temp = FacultyList.filter(function (item) {
            return item.facultyID === selectedFacultyID;
        });
        if (temp.length === 0) FacultyList.push(data);
        else message.error("Already Added !");

        if (temp.length === 0) {
            let sendData = {
                data: {
                    facultylist: FacultyList,
                },
                COURSE_CODE: COURSE_CODE,
                COURSE_NAME: COURSE_NAME,
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/addcoursefaculty', sendData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        message.success(res.data.msg);
                    } else {
                        await this.setState({ selectedFaculty: 0, selectedFacultyID: 0, selectedFacultyDept: 0 });
                        message.error(res.data.msg);
                    }
                });
        }

        this.setState({
            FacultyList: FacultyList,
            visible: false,
        });
    };

    onClickFacultyDeleteBtn = (record) => {
        this.setState({ loading: true })
        let { COURSE_CODE, COURSE_NAME } = this.state;

        let FacultyList = [...this.state.FacultyList];
        FacultyList = FacultyList.filter((el) => { return el._id !== record._id; });

        let sendData = {
            data: {
                facultylist: FacultyList,
            },
            COURSE_CODE: COURSE_CODE,
            COURSE_NAME: COURSE_NAME,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/addcoursefaculty', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState({ FacultyList: FacultyList });
                    message.success("Information Updated");
                } else
                    message.error(res.data.msg);
            });

        this.setState({ loading: false })
    }

    handleChangeSelect = async (label, value) => {
        this.setState({ loading: true });
        this.setState({ [label]: value });
        let data = await this.state.courses.find(v => v.COURSE_CODE === value);
        await this.setState({ COURSE_CODE: data.COURSE_CODE });
        await this.setState({ COURSE_NAME: data.COURSE_NAME });
        await this.getCourse(data.COURSE_CODE);
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    reSetButton = (e) => {
        this.setState({
            error: 0,
            COURSE_CODE: '', COURSE_NAME: '',
            faculty: [],
            selectedFaculty: '',
            selectedFacultyID: '',
            selectedFacultyDept: '',
            FacultyList: [], FULL_NAME: '',
            searchText: '',
            searchedColumn: ''
        });
    };

    render() {
        let { COURSE_CODE, COURSE_NAME, FacultyList, courses } = this.state;
        let headers = [
            { label: "Faculty ID", key: "facultyID" },
            { label: "Faculty Name", key: "facultyName" },
            { label: "Department", key: "department" }
        ];
        const columns1 = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (FacultyList.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'ID',
                dataIndex: 'facultyID',
                key: 'facultyID',
                render: (text) => (text),
                ...this.getColumnSearchProps('facultyID'),
            },
            {
                title: 'Name',
                dataIndex: 'facultyName',
                key: 'facultyName',
                render: (text) => (<><Avatar shape="square">{text.charAt(0)}</Avatar> {text.toUpperCase()}</>),
                ...this.getColumnSearchProps('facultyName'),
            },
            {
                title: 'Action',
                render: (text, record) => (
                    <Space>
                        <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={this.onClickFacultyDeleteBtn.bind(this, record)}
                        >
                            <Button type="danger" icon={<DeleteOutlined />}> Delete </Button>
                        </Popconfirm>
                    </Space>
                ),
                fixed: 'right',
            },
        ];

        return (
            <>
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    className='mt-1'
                >
                    <Descriptions.Item label="Course Selection" span={2}>
                        <Row gutter={[16, 16]} justify="start">
                            <Col xs={20}>
                                <Select id="COURSE_NAME" placeholder="Select Course" style={{ width: "100%" }} onChange={this.handleChangeSelect.bind(this, "COURSE_NAME")} value={this.state.COURSE_CODE}
                                    showSearch
                                >
                                    <Option value="" disabled>Choose One</Option>
                                    {
                                        courses.map(
                                            (item, val) => {
                                                return (
                                                    <Option key={val} value={item.COURSE_CODE}>
                                                        <Tag color="blue">{item.COURSE_NAME.toUpperCase()}</Tag>
                                                        <Tag color="magenta">{item.COURSE_CODE}</Tag>
                                                        <Tag color="green">{"SEM " + item.SEMESTER}</Tag>
                                                    </Option>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col xs={4}>
                                <Button type="primary" block icon={<CloseOutlined />} danger disabled={!COURSE_NAME} onClick={this.reSetButton}>RESET</Button>
                            </Col>
                        </Row>
                    </Descriptions.Item>
                </Descriptions>

                {this.state.error === 0 ?
                    <Descriptions
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        className='mt-1'
                    >
                        <Descriptions.Item label="Selected Course Code">
                            {COURSE_CODE || <Empty className="mt-0 mb-0" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </Descriptions.Item>
                        <Descriptions.Item label="Selected Course Name">
                            {COURSE_NAME || <Empty className="mt-0 mb-0" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </Descriptions.Item>
                        <Descriptions.Item label="Faculty Assign - Single Method" span={2}>
                            <Row gutter={[16, 16]} justify="start">
                                <Col xs={8}>
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Search Faculty"
                                        onChange={this.handleChangeSelectFaculty}
                                        value={this.state.USER_ID}
                                        showSearch
                                    >
                                        <Option value="" disabled>Choose One</Option>
                                        {
                                            this.state.faculty.length > 0 ? (
                                                this.state.faculty.map(
                                                    (item, val) => {
                                                        return (
                                                            <Option key={val} value={item.USER_ID}>
                                                                <Tag color="cyan">{item.FULL_NAME.toUpperCase()}</Tag>
                                                                <Tag color="pink">{item.USER_ID}</Tag>
                                                            </Option>
                                                        )
                                                    }
                                                )
                                            )
                                                :
                                                ''
                                        }
                                    </Select>
                                </Col>
                                <Col xs={4}>
                                    <Button disabled={!this.state.selectedFacultyID || !this.state.COURSE_CODE ? true : false} type="primary" icon={<PlusOutlined />} onClick={this.onClickAdd}>ADD FACULTY</Button>
                                </Col>
                            </Row>
                        </Descriptions.Item>

                        <Descriptions.Item label="Assigned Faculty" span={2}>
                            <CSVLink data={this.state.FacultyList} headers={headers} filename={COURSE_CODE + " faculty-list.csv"} >
                                <Button size="middle" disabled={!this.state.FacultyList.length ? true : false} type="dashed" icon={<DownloadOutlined />}>Download EXCEL</Button>
                            </CSVLink>
                            <Table className="mt-2" size="small" bordered dataSource={this.state.FacultyList} columns={columns1} />
                        </Descriptions.Item>

                        <Descriptions.Item label="Faculty Assign - Bulk Method" span={2}>
                            <BulkCoursesAssign />
                        </Descriptions.Item>
                    </Descriptions > :
                    <Result
                        status="404"
                        title={COURSE_NAME + " (" + COURSE_CODE + ") Course Learning Session is Empty"}
                        subTitle="Unit and Session information are empty"
                    />
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseAssign));