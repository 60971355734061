import React, { Component, createRef } from 'react'
import { Card, Row, Col, Result } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import Sunburst from '../../chart/studSunburst';
import CourseSessions from './CourseSessions';

class CourseView extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        flare: {},
        error: 0,
        status: 0,
        key: 0
    }

    scrollDiv = createRef();


    componentDidMount() {
        this.getSession();
    }

    getSession = () => {
        let sendData = {
            data: {
                COURSE_CODE: this.props.COURSE_CODE,
                COURSE_NAME: this.props.COURSE_CODE || 'Course',
                USER_ID: '',
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/student/course/getcircleinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let flare = res.data.flare;
                    if (Object.keys(flare).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else {
                        this.setState({ flare: flare, loading: false, key: 1, error: 0 });
                    }
                }
            })
    }

    onClick = (node) => {
        if (node.data.course) {
            this.setState({ COURSE_CODE: node.data.course.COURSE_CODE, COURSE_NAME: node.data.course.COURSE_NAME, SESSION: node.data.course.SESSION, status: node.data.facultyStatus, topic: node.data.name, key: 2 });
            this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        let { COURSE_NAME, COURSE_CODE, COURSE_INFO, SESSION, status } = this.state;
        return (
            <div>
                {this.state.error === 0 ?
                    (
                        <>
                            <Card bordered={false} loading={this.state.loading} style={{ boxShadow: "none" }}>
                                <Row justify="center" align="top" className="mt-1 mb-2">
                                    <Col xs={24} align="middle">
                                        <Sunburst
                                            data={this.state.flare}
                                            width="820"
                                            height="820"
                                            count_member="size"
                                            labelFunc={(node) => node.data.name}
                                            onClick={this.onClick}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                            <span ref={this.scrollDiv}>
                                {
                                    this.state.key === 2 ? (
                                        <CourseSessions COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} SESSION={SESSION} COURSE_INFO={COURSE_INFO} status={status} />
                                    )
                                        : ''
                                }
                            </span>
                        </>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseView));