import React, { Component } from 'react'
import { Select, Typography, Input, Row, Col, Descriptions, Button, message, Spin, Result, Skeleton, Card, List } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactPlayer from 'react-player'
import axios from 'axios';
import config from '../../../config';
const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;
const page_name = "video";
const { Meta } = Card;
const videoType = [
    {
        title: 'youtube',
    },
    {
        title: 'facebook',
    },
    {
        title: 'soundcloud',
    },
    {
        title: 'mixcloud',
    },
    {
        title: 'twitch',
    },
    {
        title: 'vimeo',
    },
    {
        title: 'dailymotion',
    },
    {
        title: 'vidyard',
    },
];
class Video extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        loading: true,
        UNIT: 1,
        SESSION: 0,
        SESSION_COUNT: 0,
        video: {},
        error: 0
    }

    componentDidMount() {
        this.getVideos(this.state.COURSE_CODE);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getVideos(props.COURSE_CODE);
    }

    getVideos = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else
                        this.setState({ sessions: record, SESSION_COUNT: record[0].SESSIONCOUNT, error: 0 });
                }
            });

        sendData = {
            COURSE_CODE: COURSE_CODE,
            TYPE: page_name,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/question/get', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.question[0];
                    if (record)
                        this.setState({ video: record.video });
                    else
                        this.setState({ video: [] });
                }
            });
        this.setState({ loading: false });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: parseInt(value), loading: true });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT });
        }
        this.setState({ loading: false })
    }

    onLoad = (value) => {
        //console.log(value)
    }

    handleChange = e => {
        let video = this.state.video;
        video[e.target.id] = e.target.value;
        this.setState({ video: video });
    };

    onClickAdd = async () => {
        let { COURSE_CODE, video } = this.state;

        let flag = 1;
        Object.keys(video).forEach(function (key) {
            if (!video[key]) {
                flag = 0;
            }
        });

        if (flag === 2) {
            message.error('Invalid Entry!');
            return false;
        }

        let sendData = {
            data: { video: video },
            COURSE_CODE: COURSE_CODE,
            TYPE: page_name,
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/admin/question/video', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
    }



    render() {
        let { UNIT } = this.state;

        return (
            <div className="padding-2">
                { this.state.error === 0 ?
                    (
                        <Spin spinning={ this.state.loading }>

                            <Row gutter={ [8, 8] } className="mt-1 mb-3" justify="center">
                                <List
                                    grid={ { gutter: 16, sm: 8, lg: 3, } }
                                    dataSource={ videoType }
                                    renderItem={ (item, val) => (
                                        <List.Item key={ val }>
                                            <Card
                                                hoverable
                                                style={ { width: 120 } }
                                                cover=
                                                { <img alt="example" title={ item.title } height="50" className="mt-10" src={ require('../../../images/video/' + item.title + '.svg').default } />
                                                }>
                                                <Meta className="overlineStars" title={ item.title } />
                                            </Card>
                                        </List.Item>
                                    ) }
                                />
                            </Row>

                            <span>
                                <Text className="justify">
                                    To enhance the teacher presentations and the overall comprehension of students, it allows to present their lessons in a more dynamic and effective way. When incorporating videos into a lesson, it’s important to keep in mind the three key components of cognitive load, elements that impact engagement, and elements that promote active learning. Luckily, consideration of these elements converges on a few recommendations:
                                    <ol>
                                        <li>Keep videos brief and targeted on learning goals.</li>
                                        <li> Use audio and visual elements to convey appropriate parts of an explanation; make them complementary rather than redundant.</li>
                                        <li>Use signaling to highlight important ideas or concepts.
                                            Use a conversational, enthusiastic style to enhance engagement.</li>
                                        <li>Embed videos in a context of active learning by using guiding questions, interactive elements, or associated homework assignments.</li>
                                    </ol>
                                </Text>
                            </span>

                            <Descriptions
                                bordered
                                column={ { lg: 1, md: 1, sm: 1, xs: 1 } }
                                className="mb-1 mt-3"
                                size="small"
                            >
                                <Descriptions.Item label="Choose Unit">
                                    <Select id="UNIT" style={ { width: "100%" } } value={ UNIT }
                                        onChange={ this.handleChangeSelect.bind(this, "UNIT") }>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </Descriptions.Item>
                            </Descriptions>

                            <Descriptions
                                bordered
                                className="mb-1 mt-1"
                                size="small"
                            >
                                {
                                    Array.from({ length: this.state.SESSION_COUNT }, (v, SESSION) => {
                                        return (<>
                                            <Descriptions.Item label={ "Session " + (SESSION + 1) } key={ (SESSION + 1) } span={ 2 }>
                                                <Text keyboard>SLO 1</Text>
                                                <Row gutter={ [8, 8] } className="mt-1">
                                                    <Col xs={ 24 } align="middle">
                                                        {
                                                            this.state.video[(UNIT * 100) + (SESSION + 1) + "1"] ?
                                                                <ReactPlayer width="140" height="200" url={ this.state.video[(UNIT * 100) + (SESSION + 1) + "1"] } />
                                                                :
                                                                <Skeleton.Input style={ { width: 475, height: 150 } } active={ true } />
                                                        }
                                                    </Col>
                                                    <Col xs={ 24 } align="middle">
                                                        <Search placeholder="Video URL"
                                                            onSearch={ this.onLoad }
                                                            id={ (UNIT * 100) + (SESSION + 1) + "1" }
                                                            onChange={ this.handleChange }
                                                            value={ this.state.video[(UNIT * 100) + (SESSION + 1) + "1"] }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Descriptions.Item>

                                            <Descriptions.Item label={ "Session " + (SESSION + 1) } key={ (SESSION + 2) } span={ 2 }>
                                                <Text keyboard>SLO 2</Text>
                                                <Row gutter={ [8, 8] } className="mt-1">
                                                    <Col xs={ 24 } align="middle">
                                                        {
                                                            this.state.video[(UNIT * 100) + (SESSION + 1) + "2"] ?
                                                                <ReactPlayer width="140" height="200" url={ this.state.video[(UNIT * 100) + (SESSION + 1) + "2"] } />
                                                                :
                                                                <Skeleton.Input style={ { width: 475, height: 150 } } active={ true } />
                                                        }
                                                    </Col>
                                                    <Col xs={ 24 } align="middle">
                                                        <Search placeholder="Video URL"
                                                            onSearch={ this.handleChange }
                                                            id={ (UNIT * 100) + (SESSION + 1) + "2" }
                                                            onChange={ this.handleChange }
                                                            value={ this.state.video[(UNIT * 100) + (SESSION + 1) + "2"] }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Descriptions.Item>
                                        </>
                                        )
                                    })
                                }
                                <Descriptions.Item label="Submit">
                                    <Button className="mt-1 mb-1" type="primary" onClick={ this.onClickAdd }>Submit</Button>
                                </Descriptions.Item>
                            </Descriptions>
                        </Spin>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Video));