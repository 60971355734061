import React, { Component } from 'react'
import { Row, Col, Input, Button, Tag, Statistic, Typography, Space, message, Card, Select, Descriptions, Popconfirm, Drawer, Table, Badge } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import { CheckOutlined, CloseOutlined, EditOutlined, SearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ReactJson from 'react-json-view'
const { Option } = Select;
const InputGroup = Input.Group;
const { Text, Title } = Typography;
const type1 = ["journal", "conference", "patent", "project", "industrialconsultancy", "researchcentre"];
const type2 = ["researchguideship", "researchguidance", "reviewer", "researchdomain", "researchidentity"];
class ResearchVerification extends Component {
    state = {
        loading: false,
        visible: false,
        visible2: false,
        topic: '',
        data: [],
        data2: [],
        drawerData: [],
        reason: '',
        searchText: '',
        searchedColumn: '',
        verified: 0,
        notVerified: 0,
        rejected: 0,
        user_id: '',
        user_name: '',
        open: false,
        open2: false,
        topic_selected: ''
    };

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onClose = () => {
        this.setState({ visible: false, visible2: false });
    };

    handleChangeSelect = (label, value, x) => {
        this.setState({ [label]: value, data: [], loading: true, topic_selected: x.children });
        let data = {
            info: this.props.common.user,
            key: config.key,
            topic: value
        };

        if (type1.includes(value)) {
            this.setState({ open: true, open2: false });
            axios.post(config.curriculaserver + '/curricula/studentcircle/studentreport/getresearchstatus1', data)
                .then(res => {
                    if (res.data.Status === 1 && res.data.result) {
                        let data = res.data.result, v = 0, rej = 0, nv = 0;
                        for (let i = 0; i < data.length; ++i) {
                            if (data[i].data.docStatus === 1) ++v;
                            else if (data[i].data.docStatus === 2) ++rej;
                            else ++nv;
                        }
                        this.setState({ verified: v, notVerified: nv, rejected: rej, data: res.data.result, loading: false });
                    }
                });
        }
        else if (type2.includes(value)) {
            this.setState({ open2: true, open: false });
            axios.post(config.curriculaserver + '/curricula/studentcircle/studentreport/getresearchstatus2', data)
                .then(res => {
                    if (res.data.Status === 1 && res.data.result) {
                        let data = [], v = 0, nv = 0;
                        res.data.result.forEach(function (entry) {
                            if (entry.data.length > 0) data.push(entry);
                        });

                        for (let i = 0; i < data.length; ++i) {
                            if (data[i].verification === 1) ++v;
                            else ++nv;
                        }
                        this.setState({ verified: v, notVerified: nv, data: data, loading: false });
                    }
                });
        }
    }

    onViewBtn = (record) => {
        let user_id = record ? record.data ? record.data.details[0] ? record.data.details[0].EMPLOYEEID : '' : '' : '';
        let user_name = record ? record.data ? record.data.details[0] ? record.data.details[0].NAME : '' : '' : '';

        if (record) {
            if (!record.key) record.key = 1;
            this.setState({
                drawerData: [record], user_id: user_id, user_name: user_name, visible: true
            });
        }
        else
            message.error("No data found1 !")
    }

    onViewBtn2 = (record) => {
        let data = record.data;
        if (data.length > 0) {
            if (!data[0].key) data[0].key = 1;
            this.setState({
                drawerData: data, user_id: record.USER_ID, user_name: record.USER_NAME, visible2: true
            });
        }
        else
            message.error("No data found !")
    }

    onStatusBtn = (record, status) => {
        record.data.docStatus = status;
        record.data.docMessage = "Verified";

        if (status === 2) {
            if (!this.state.reason) {
                message.error('Invalid Reason !');
                return false;
            }
            record.data.docMessage = this.state.reason;
        }

        let data = {
            key: config.key,
            topic: this.state.topic,
            record: record,
        };

        axios.post(config.curriculaserver + '/curricula/studentcircle/studentreport/updateresearchstatus1', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let drawerData = this.state.drawerData;
                    for (var i = 0; i < drawerData.length; i++) {
                        if (drawerData[i].key === record.key) {
                            drawerData[i].data.docStatus = status;
                            break;
                        }
                    }

                    let data = this.state.data, v = 0, rej = 0, nv = 0;
                    for (let i = 0; i < data.length; ++i) {
                        if (data[i].data.docStatus === 1) ++v;
                        if (data[i].data.docStatus === 2) ++rej;
                        else ++nv;
                    }
                    this.setState({ verified: v, notVerified: nv, rejected: rej, drawerData: drawerData, reason: '' });
                }
            })
    }

    onStatusBtn2 = (record, status) => {
        let overallStatus = 1;
        record.docStatus = status;
        record.docMessage = "Verified";
        if (status === 2) {
            if (!this.state.reason) {
                message.error('Invalid Reason !');
                return false;
            }
            record.docMessage = this.state.reason;
        }

        //set overallStatus
        let drawerData = this.state.drawerData;
        for (var i = 0; i < drawerData.length; i++) {
            if (drawerData[i].key === record.key) drawerData[i].docStatus = status;
            if (drawerData[i].docStatus === 0 || !drawerData[i].docStatus || drawerData[i].docStatus === 2) {
                overallStatus = 0;
                break;
            }
        }

        let data = {
            user_id: this.state.user_id,
            key: config.key,
            topic: this.state.topic,
            record: record,
            overallStatus: parseInt(overallStatus),
        };

        let temp = this.state.data;
        for (i = 0; i < temp.length; i++) {
            if (temp[i].key === this.state.user_id) {
                temp[i].verification = overallStatus;
                break;
            }
        }
        this.setState({ data: temp });

        let v = 0, rej = 0;
        for (let i = 0; i < temp.length; ++i) {
            if (temp[i].verification === 1) ++v;
            else ++rej;
        }
        this.setState({ verified: v, notVerified: rej });

        axios.post(config.curriculaserver + '/curricula/studentcircle/studentreport/updateresearchstatus2', data)
            .then(res => {
                if (res.data.Status === 1) {

                    let drawerData = this.state.drawerData;
                    for (var i = 0; i < drawerData.length; i++) {
                        if (drawerData[i].key === record.key) {
                            drawerData[i].docStatus = status;
                            break;
                        }
                    }
                    this.setState({ drawerData: drawerData, reason: '' });
                }
            })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={ { padding: 8 } }>
                <Input
                    ref={ node => {
                        this.searchInput = node;
                    } }
                    placeholder={ `Search ${dataIndex}` }
                    value={ selectedKeys[0] }
                    onChange={ e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                    onPressEnter={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                    style={ { width: 188, marginBottom: 8, display: 'block' } }
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                        icon={ <SearchOutlined /> }
                        size="small"
                        style={ { width: 90 } }
                    >
                        Search
                    </Button>
                    <Button onClick={ () => this.handleReset(clearFilters) } size="small" style={ { width: 90 } }>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={ { color: filtered ? '#1890ff' : undefined } } />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={ { backgroundColor: '#ffc069', padding: 0 } }
                    searchWords={ [this.state.searchText] }
                    autoEscape
                    textToHighlight={ text.toString() }
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "default" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {

        let { topic, data, drawerData, reason } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
                sorter: (a, b) => a.key - b.key,

            },
            {
                title: 'Employee ID',
                dataIndex: 'data',
                render: (text) => (
                    <Text>{ text ? text.details[0] ? text.details[0].EMPLOYEEID : <Text type="danger">Empty</Text> : <Text type="danger">Empty</Text> }</Text>
                ),
                width: 1,
                fixed: 'left',
            },
            {
                title: 'Name',
                dataIndex: 'data',
                render: (text) => (
                    <Text strong>{ text ? text.details[0] ? text.details[0].NAME.toUpperCase() : <Text type="danger">Empty</Text> : <Text type="danger">Empty</Text> }</Text>
                ),
                width: 3,
            },
            {
                title: 'Status',
                dataIndex: 'data',
                width: 4,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text.docStatus) } key={ text }>
                        { this.getStatusText(text.docStatus) }
                    </Tag>
                ),
            },
            {
                title: 'Action',
                key: '_id',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> }
                            onClick={ this.onViewBtn.bind(this, record) } > View </Button>
                    </>
                ),
                fixed: 'right',
                width: 2,
            },
        ];

        let columns2 = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (drawerData.findIndex(i => i.key === text) + 1),
            },
            {
                title: 'File',
                dataIndex: '_id',
                key: '_id',
                render: (text, record) => (
                    <a target="_blank" rel="noopener noreferrer" href={ config.curriculaserver + '/uploads/student/personal/research/' + this.state.topic + '/' + record.key + ".pdf" }><Button icon={ <FilePdfOutlined /> } type="link">
                    </Button></a>)
            },
            {
                title: 'Status',
                dataIndex: 'data',
                render: (text) => (
                    <Tag color={ this.getStatusColor(text.docStatus) }>
                        { this.getStatusText(text.docStatus) }
                    </Tag>
                ),
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <CheckOutlined /> }
                            disabled={ record.data.docStatus === 1 ? true : false }
                            onClick={ this.onStatusBtn.bind(this, record, 1) } /> &nbsp;

                        <Popconfirm
                            title={ <><Input id="reason" placeholder="Reason for rejection" onChange={ this.onChange } value={ reason } /></> }
                            placement="left"
                            onConfirm={ this.onStatusBtn.bind(this, record, 2) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" danger icon={ <CloseOutlined /> } />
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
            },
        ];

        let columns3 = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
                sorter: (a, b) => a.key - b.key,

            },
            {
                title: 'Employee ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                ...this.getColumnSearchProps('USER_ID'),
                width: 1,
                fixed: 'left',
            },
            {
                title: 'Name',
                dataIndex: 'USER_NAME',
                key: 'USER_NAME',
                ...this.getColumnSearchProps('USER_NAME'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 3,
            },
            {
                title: 'Status',
                dataIndex: 'verification',
                width: 4,
                render: (text, record) => (
                    record.data.length < 1 ? (<Tag>No Data</Tag>) :
                        (<Tag color={ this.getStatusColor(text) } key={ text } >
                            { this.getStatusText(text) }
                        </Tag>)
                ),
                sorter: (a, b) => a.verification - b.verification,
            },
            {
                title: 'Action',
                key: '_id',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> }
                            onClick={ this.onViewBtn2.bind(this, record) } > View </Button>
                    </>
                ),
                fixed: 'right',
                width: 2,
            },
        ];

        let columns4 = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (drawerData.findIndex(i => i.key === text) + 1),
            },
            {
                title: 'File',
                dataIndex: '_id',
                key: '_id',
                render: (text, record) => (
                    <a target="_blank" rel="noopener noreferrer" href={ config.curriculaserver + '/uploads/student/' + this.state.user_id + '/personal/research/' + this.state.topic + '/' + text + ".pdf" }><Button disabled={ this.state.topic === "researchdomain" ? true : false } icon={ <FilePdfOutlined /> } type="link">
                    </Button></a>)
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <CheckOutlined /> }
                            disabled={ record.docStatus === 1 ? true : false }
                            onClick={ this.onStatusBtn2.bind(this, record, 1) } /> &nbsp;

                        <Popconfirm
                            title={ <><Input id="reason" placeholder="Reason for rejection" onChange={ this.onChange } value={ reason } /></> }
                            placement="left"
                            onConfirm={ this.onStatusBtn2.bind(this, record, 2) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" danger icon={ <CloseOutlined /> } />
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
            },
        ];


        return (
            <Row style={ { overflow: "auto" } }>

                <Col xs={ 24 } className="mt-1 mb-1" style={ { padding: "1%" } }>
                    <Descriptions bordered>
                        <Descriptions.Item label={
                            "Select Topic"
                        }>

                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 24 }>
                                        <Select showSearch id="topic" style={ { width: "100%" } } value={ topic }
                                            onChange={ this.handleChangeSelect.bind(this, "topic") }
                                        >
                                            <Option value="" disabled>Choose One</Option>
                                            <Option value="journal">Journal</Option>
                                            <Option value="conference">Conference</Option>
                                            <Option value="patent">Patent</Option>
                                            <Option value="project">Project</Option>
                                            <Option value="industrialconsultancy">Industrial Consultancy</Option>
                                            <Option value="researchdomain">Research Domain</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Descriptions.Item>

                    </Descriptions>
                </Col>

                {
                    this.state.open ?
                        (
                            <>

                                <Col xs={ 24 } style={ { paddingLeft: "1.5%" } }>
                                    <Title level={ 3 }>
                                        { this.state.topic_selected }
                                        <br />
                                        { this.props.common.user.ROLE === "HOD" ?
                                            <Badge status="warning" text={ this.props.common.user.DEPARTMENT } />
                                            : ""
                                        }
                                    </Title>
                                </Col>


                                <Col xs={ 24 } className="mb-1" style={ { padding: "1%" } }>
                                    <Row gutter={ 16 }>
                                        <Col span={ 6 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Verified Records"
                                                    value={ this.state.verified }
                                                    valueStyle={ { color: '#3f8600' } }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={ 6 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Not Verified Records"
                                                    value={ this.state.notVerified }
                                                    valueStyle={ { color: '#cf1322' } }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={ 6 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Rejected Records"
                                                    value={ this.state.rejected }
                                                    valueStyle={ { color: '#575757' } }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={ 6 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Total Records"
                                                    value={ this.state.verified + this.state.notVerified + this.state.rejected }
                                                    valueStyle={ { color: '#1890ff' } }
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={ 24 } style={ { padding: "1%" } }>
                                    <Table loading={ this.state.loading } dataSource={ this.state.data } size="small" columns={ columns } scroll={ { x: 1500 } }
                                    />
                                </Col>
                            </>
                        ) : ''
                }

                {
                    this.state.open2 ?
                        (
                            <>

                                <Col xs={ 24 } style={ { paddingLeft: "1.5%" } }>
                                    <Title level={ 3 }>
                                        { this.state.topic_selected }
                                        <br />
                                        { this.props.common.user.ROLE === "HOD" ?
                                            <Badge status="warning" text={ this.props.common.user.DEPARTMENT } />
                                            : ""
                                        }
                                    </Title>
                                </Col>


                                <Col xs={ 24 } className="mb-1" style={ { padding: "1%" } }>
                                    <Row gutter={ 16 }>
                                        <Col span={ 8 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Verified Records"
                                                    value={ this.state.verified }
                                                    valueStyle={ { color: '#3f8600' } }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={ 8 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Not Verified Records"
                                                    value={ this.state.notVerified }
                                                    valueStyle={ { color: '#cf1322' } }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={ 8 }>
                                            <Card size="small">
                                                <Statistic
                                                    title="Total Records"
                                                    value={ this.state.verified + this.state.notVerified }
                                                    valueStyle={ { color: '#1890ff' } }
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={ 24 } style={ { padding: "1%" } }>
                                    <Table loading={ this.state.loading } dataSource={ this.state.data } size="small" columns={ columns3 } scroll={ { x: 1500 } }
                                    />
                                </Col>
                            </>
                        ) : ''
                }

                <Drawer
                    className="NewDrawer"
                    width={ 620 }
                    title={ <>{ topic.toUpperCase() } </> }
                    placement="right"
                    closable={ false }
                    onClose={ this.onClose }
                    visible={ this.state.visible }
                >
                    <Row gutter={ [16, 16] }>
                        <Col xs={ 24 } md={ 24 }>
                            <Tag color="#b37feb">{ this.state.user_name.toString().toUpperCase() }</Tag>
                            <Tag color="#ff85c0">{ this.state.user_id }</Tag>
                        </Col>
                        <Col xs={ 24 } md={ 24 }>
                            <Table dataSource={ this.state.drawerData }
                                size="small" columns={ columns2 }
                                expandable={ {
                                    expandedRowRender: record => <div key={ record.key || 1 } style={ { margin: 0 } }>
                                        <ReactJson
                                            name="Details"
                                            src={ record.data } displayDataTypes={ false } iconStyle="circle" collapsed={ true } />
                                    </div>
                                } }
                            />
                        </Col>
                    </Row>
                </Drawer>

                <Drawer
                    className="NewDrawer"
                    width={ 620 }
                    title={ <>{ topic.toUpperCase() } </> }
                    placement="right"
                    closable={ false }
                    onClose={ this.onClose }
                    visible={ this.state.visible2 }
                >
                    <Row gutter={ [16, 16] }>
                        <Col xs={ 24 } md={ 24 }>
                            <Tag color="#b37feb">{ this.state.user_name.toString().toUpperCase() }</Tag>
                            <Tag color="#ff85c0">{ this.state.user_id }</Tag>
                        </Col>
                        <Col xs={ 24 } md={ 24 }>
                            <Table dataSource={ this.state.drawerData }
                                size="small" columns={ columns4 }
                                expandable={ {
                                    expandedRowRender: record => <div key={ record.key || 1 } style={ { margin: 0 } }>
                                        <ReactJson
                                            name="Details"
                                            src={ record } displayDataTypes={ false } iconStyle="circle" collapsed={ true } />
                                    </div>
                                } }
                            />
                        </Col>
                    </Row>
                </Drawer>

            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ResearchVerification));