import React, { Component, createRef } from 'react'
import { Row, Col, Card, Badge, Descriptions, Spin, BackTop, Button, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sunburst from '../../chart/sunburst';
import LearningPractice from './LearningPractice'
import LearningOutcomes from './LearningOutcomes'
import UnitMark from './UnitMark'
import LPStatus from './LPStatus'
import IndivdualReport from './IndivdualReport'
import OverallReport from './OverallReport';
import LiveSession from './LiveSession';
import CourseView from './CourseView';
import CLA from './CLA';
import config from '../../config';
import TimeTable from './TimeTable';
import Attendance from './Attendance';
import { EditOutlined, LockOutlined } from '@ant-design/icons';
const CLAShortName = config.CLAshortName + " Marks";

class CoursesHome extends Component {
    scrollDiv = createRef();

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        topic: 'Section View',
        flare: {},
        key: 0
    }

    componentDidMount() {
        this.setState({ loading: true, flare: {} });
        this.getChart(this.props.COURSE_CODE, this.props.COURSE_NAME, this.props.COURSE_INFO);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true, flare: {} });
        this.setState({
            COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME,
            COURSE_INFO: props.COURSE_INFO
        })
        this.getChart(props.COURSE_CODE, props.COURSE_NAME, props.COURSE_INFO);
    }

    getChart = (COURSE_CODE, COURSE_NAME, COURSE_INFO) => {
        let children = [];
        let course = {
            COURSE_CODE: COURSE_CODE,
            COURSE_NAME: COURSE_NAME,
            COURSE_INFO: COURSE_INFO
        }

        this.props.COURSE_INFO.LOCK ?
            children.push(
                {
                    "name": COURSE_CODE + " " + COURSE_NAME,
                    "children": [
                        { "name": "Course View", "size": 600, "course": course },
                        { "name": "Overall Report", "size": 700, "course": course },
                        { "name": "Learner Report", "size": 800, "course": course },
                    ]
                }
            )
            :
            children.push(
                {
                    "name": COURSE_CODE + " " + COURSE_NAME,
                    "children": [
                        { "name": "Time Table", "size": 700, "course": course },
                        { "name": "Course View", "size": 600, "course": course },
                        { "name": "Participation", "size": 700, "course": course },
                        { "name": "Outcomes", "size": 500, "course": course },
                        { "name": "Worksheet Verify", "size": 800, "course": course },
                        { "name": "Worksheet Status", "size": 800, "course": course },
                        { "name": config.UnitTestName, "size": 850, "course": course },
                        { "name": CLAShortName, "size": 850, "course": course },
                        { "name": "Overall Report", "size": 700, "course": course },
                        { "name": "Learner Report", "size": 800, "course": course },
                        { "name": "Live Session", "size": 600, "course": course },
                    ]
                }
            );

        let flareName = COURSE_INFO.BATCH_NAME || "Course";
        let flare = {
            "name": flareName || "Course",
            "children": children
        }
        this.setState({ flare: flare, loading: false });
    }

    onClick = (node) => {
        if (node.data.course) {
            this.setState({ COURSE_CODE: node.data.course.COURSE_CODE, COURSE_NAME: node.data.course.COURSE_NAME, topic: node.data.name });

            if (node.data.name === "Worksheet Verify") this.setState({ key: 1 });
            else if (node.data.name === config.UnitTestName) this.setState({ key: 2 });
            else if (node.data.name === "Outcomes") this.setState({ key: 3, topic: "Learning Outcomes" });
            else if (node.data.name === "Worksheet Status") this.setState({ key: 4 });
            else if (node.data.name === "Learner Report") this.setState({ key: 5, topic: "Indivdual Learner Report" });
            else if (node.data.name === "Overall Report") this.setState({ key: 6, topic: "INTERNAL MARKS - OVERALL REPORT" });
            else if (node.data.name === "Live Session") this.setState({ key: 7, topic: "LIVE STREAMING SESSION" });
            else if (node.data.name === "Course View") this.setState({ key: 8 });
            else if (node.data.name === CLAShortName) this.setState({ key: 9, topic: config.CLAfullName });
            else if (node.data.name === "Time Table") this.setState({ key: 10, topic: "Learning Timetable" });
            else if (node.data.name === "Participation") this.setState({ key: 11, topic: "Learning Participation" });

            this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        let { key, COURSE_CODE, COURSE_NAME, COURSE_INFO } = this.state;
        return (
            <div>
                <BackTop duration={1000} />
                {
                    key === 5 || key === 8 ?
                        (<Row justify="center" align="top" className="mt-5 mb-5">
                            <Col xs={24} align="middle"><Button type="primary" onClick={() => { this.setState({ key: 0 }) }}>VIEW SECTION CIRCLE</Button></Col>
                        </Row>) :
                        this.state.loading === false ?
                            (<Row justify="center" align="top" className="mt-3 mb-2">
                                {
                                    this.props.COURSE_INFO.LOCK ?
                                        <Col xs={8} align="middle">
                                            <Alert message="Batch Locked ! To Unlock Contact Batch Admin / Coordinator !" type="error" showIcon icon={<LockOutlined />} />
                                            <br /><br />
                                        </Col> : ''
                                }
                                <Col xs={24} align="middle">
                                    <Sunburst
                                        data={this.state.flare}
                                        width="640"
                                        height="640"
                                        count_member="size"
                                        onClick={this.onClick}
                                        labelFunc={(node) => node.data.name}
                                    />
                                </Col>
                            </Row>) :
                            (
                                <Row justify="center" align="top" className="mt-5 mb-5">
                                    <Col xs={24} align="middle">
                                        <Spin size="large" />
                                    </Col>
                                </Row>
                            )
                }

                <div className="card-container mt-1 mb-1 padding-1">

                    <Card type="inner" title={<><EditOutlined className="mr-1" />{this.state.topic}</>} className="mt-1" style={{ width: "100%" }} extra={<>
                        <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>
                    } >

                        <div className="card-container mt-1 mb-1">

                            <Descriptions
                                bordered
                                column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                                size="small"
                                className="mb-2"
                            >
                                <Descriptions.Item label="Course Code">
                                    {COURSE_CODE}
                                </Descriptions.Item>
                                <Descriptions.Item label="Course Name">
                                    {COURSE_NAME}
                                </Descriptions.Item>
                            </Descriptions>

                            <span ref={this.scrollDiv}>
                                {key === 0 ? (<> </>)
                                    :
                                    key === 1 ? <LearningPractice COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                        : key === 2 ? <UnitMark COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                            : key === 3 ? <LearningOutcomes COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                : key === 4 ? <LPStatus COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                    : key === 5 ? <IndivdualReport COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                        : key === 6 ? <OverallReport COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                            : key === 7 ? <LiveSession COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                                : key === 8 ? <CourseView COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                                    : key === 9 ? <CLA COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                                        : key === 10 ? <TimeTable COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                                            : key === 11 ? <Attendance COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO} />
                                                                                : ''
                                }
                            </span>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CoursesHome));