import React, { Component } from 'react'
import { Checkbox, Select, Descriptions, Space, Row, message, Tag, Button, Table, Popconfirm, Spin, Result, Alert, Tooltip } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import config from '../../../config';
import ProObj from '../../../ProObj';
import CKEditor from 'ckeditor4-react';
CKEditor.editorUrl = process.env.PUBLIC_URL + '/ckeditor4/ckeditor.js';
const { Option } = Select;
const page_name = "lq";
const level1 = config.level1;
const level2 = config.level2;
const level3 = config.level3;
const level4 = config.level4;
const level5 = config.level5;
const level6 = config.level6;
const mathJaxLib = config.mathJaxLib;

class MCQ extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        loading: true,
        UNIT: 1,
        SESSION: 1,
        QUESTION_DESC: "<p>Type Here</p>",
        ANSWER: "<p>Type Here</p>",
        LEVEL: 1,
        OUTCOMES: [],
        UNIT_OUTCOMES: [],
        question: [],
        _id: 0,
        error: 0,
        PBOJ: ProObj.PROGRAM_OUTCOMES,
    }

    componentDidMount() {
        this.getCourse(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCourse(props.COURSE_CODE);
    }

    getCourse = async (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_objective"
            },
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(async res => {
                let record = res.data.selectedCourseData;
                if (Object.keys(record).length === 0) {
                    this.setState({ error: 2 });
                }
                else {
                    let sendData = {
                        data: {
                            COURSE_CODE: COURSE_CODE,
                            title: "course_learning_session"
                        },
                        key: config.key
                    };

                    await axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                        .then(async res => {
                            if (res.data.Status === 1) {
                                let record = res.data.selectedCourseData;
                                if (Object.keys(record).length === 0) {
                                    this.setState({ error: 1 })
                                }
                                else {
                                    if (res.data.dept) {
                                        let DEPT = res.data.dept;
                                        let data = {
                                            info: this.props.common.user,
                                            key: config.key,
                                            dept: DEPT
                                        };

                                        await axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
                                            .then(async res => {
                                                let tempPBOJ = {}, PLO = [], PSO = [];
                                                if (res.data.Status === 1) {
                                                    PLO = res.data.result.PLO; PSO = res.data.result.PSO;
                                                }
                                                else {
                                                    PLO = res.data.default.PLO; PSO = res.data.default.PSO;
                                                }

                                                tempPBOJ.PLO1 = PLO[0];
                                                tempPBOJ.PLO2 = PLO[1];
                                                tempPBOJ.PLO3 = PLO[2];
                                                tempPBOJ.PLO4 = PLO[3];
                                                tempPBOJ.PLO5 = PLO[4];
                                                tempPBOJ.PLO6 = PLO[5];
                                                tempPBOJ.PLO7 = PLO[6];
                                                tempPBOJ.PLO8 = PLO[7];
                                                tempPBOJ.PLO9 = PLO[8];
                                                tempPBOJ.PLO10 = PLO[9];
                                                tempPBOJ.PLO11 = PLO[10];
                                                tempPBOJ.PLO12 = PLO[11];
                                                tempPBOJ.PLO13 = PSO[0];
                                                tempPBOJ.PLO14 = PSO[1];
                                                tempPBOJ.PLO15 = PSO[2];

                                                await this.setState({ PBOJ: tempPBOJ });
                                            })
                                    }
                                    this.setState({ sessions: record, SESSION_COUNT: record[0].SESSIONCOUNT });
                                }
                            }
                        });
                    await this.getQuestions(COURSE_CODE, 1, 1);
                    await this.getOutcomes(COURSE_CODE, 1);
                    await this.setState({ loading: false });
                }
            });
    }

    onEditorChange(name, evt) {
        this.setState({ [name]: evt.editor.getData() });
    }

    getQuestions = (COURSE_CODE, UNIT, SESSION) => {

        let SESSION_ID = (UNIT * 100) + SESSION;

        let sendData = {
            COURSE_CODE: COURSE_CODE,
            SESSION_ID: SESSION_ID,
            TYPE: page_name,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/question/get', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ question: res.data.question });
                }
            });
    }

    getOutcomes = (COURSE_CODE, UNIT) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_objective"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    let UNIT_OUTCOMES = record[`OC${UNIT}`];
                    this.setState({ UNIT_OUTCOMES: UNIT_OUTCOMES });
                }
            });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ loading: true })
        this.setState({ [label]: parseInt(value) });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT, SESSION: 1, OUTCOMES: [] });
            this.getQuestions(this.state.COURSE_CODE, value, 1);
            this.getOutcomes(this.state.COURSE_CODE, value);
        }
        else
            this.getQuestions(this.state.COURSE_CODE, this.state.UNIT, value);
        this.setState({ loading: false })
    }

    onChangeCheckBox = (name, checkedValues) => {
        this.setState({ [name]: checkedValues });
    }

    FunctionCheckboxGroup = () => {
        let PBOJECTIVES = this.state.PBOJ;
        let PLO, html = [], status, PLONAME;
        let UC = [...this.state.UNIT_OUTCOMES];
        for (let i = 1; i <= 15; i++) {
            PLO = "PLO" + i;
            i < 10 ? PLONAME = "PO 0" + i : PLONAME = "PO " + i;
            status = UC.includes(PLO);
            html.push(
                <Tooltip key={PLO + i} title={PBOJECTIVES[PLO]} color={!status ? "red" : "#1e6055"}>
                    <Checkbox className="ml-2 mt-1" key={PLO} value={PLO} disabled={!status}>{PLONAME}</Checkbox>
                </Tooltip>);
        }
        return html;
    }

    onClickAdd = async () => {
        this.setState({ loading: true })

        let { _id, COURSE_CODE, UNIT, SESSION, QUESTION_DESC, ANSWER, LEVEL, OUTCOMES } = this.state;

        let SESSION_ID = (UNIT * 100) + SESSION;
        let question = [...this.state.question];

        let data = {
            _id: _id,
            TYPE: page_name,
            COURSE_CODE: COURSE_CODE,
            SESSION_ID: SESSION_ID,
            QUESTION_DESC: QUESTION_DESC,
            ANSWER: ANSWER,
            LEVEL: LEVEL,
            OUTCOMES: OUTCOMES,
        }

        let temp = {
            COURSE_CODE: COURSE_CODE,
            SESSION_ID: SESSION_ID,
            QUESTION_DESC: QUESTION_DESC,
            ANSWER: ANSWER,
            LEVEL: LEVEL
        }

        let flag = 1;
        Object.keys(temp).forEach(function (key) {
            if (!temp[key]) {
                flag = 0;
            }
        });

        if (OUTCOMES.length < 1) {
            flag = 0;
        }

        if (flag === 0) {
            this.setState({ loading: false })
            message.error('Invalid Entry!');
            return false;
        }

        let sendData = {
            data: data,
            COURSE_CODE: COURSE_CODE,
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/admin/question/update', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.getQuestions(COURSE_CODE, UNIT, SESSION);
                    if (_id === 0) {
                        //question.push(res.data.question);
                        this.setState({ question: question });
                        message.success("Question Added !");
                    }
                    else {
                        message.success("Question Updated !");
                        this.setState({ _id: 0 });
                    }
                } else
                    message.error(res.data.msg);
            });
        this.setState({ loading: false })
    }

    onClickEditBtn = (record) => {
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
    }

    onClickDeleteBtn = (record) => {
        this.setState({ loading: false })

        let question = [...this.state.question];

        let sendData = {
            _id: record._id,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/question/delete', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    question = question.filter((el) => { return el._id !== record._id; });
                    this.setState({ question });
                    message.success("Successfully Deleted !");
                } else
                    message.error("Error !");
            });
        this.setState({ loading: false })

    }

    render() {
        const columns = [
            {
                title: 'Sr.No',
                dataIndex: '_id',
                render: (text) => (question.findIndex(i => i._id === text) + 1),
                key: '_id',
            },
            {
                title: 'Question ID',
                dataIndex: '_id',
                key: '_id',
            },
            {
                title: 'Level',
                dataIndex: 'LEVEL',
                key: 'LEVEL',
            },
            {
                title: 'Action',
                render: (text, record) => (
                    <Space>
                        <Button type="primary" icon={<EditOutlined />} onClick={this.onClickEditBtn.bind(this, record)}>Edit</Button>
                        <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={this.onClickDeleteBtn.bind(this, record)}
                        >
                            <Button type="danger" icon={<DeleteOutlined />}> Delete </Button>
                        </Popconfirm>
                    </Space>
                ),
                fixed: 'right',
            },
        ];

        //console.log(this.state)
        let config = {
            removePlugins: 'image,ckeditor_wiris',
            mathJaxLib: mathJaxLib,
            allowedContent: true,
        };

        let { UNIT, SESSION, QUESTION_DESC, ANSWER, LEVEL, question } = this.state;
        let SC = []; for (let i = 1; i <= this.state.SESSION_COUNT; i++) SC.push(i);
        let US = "Unit " + UNIT + " Session " + SESSION + " : ";

        return (
            <div>
                {this.state.error === 0 ?
                    (
                        <>
                            <Alert
                                className="mb-1"
                                message="Each session must have minimum 1 question"
                                type="warning" showIcon
                                closable
                            />
                            <Spin spinning={this.state.loading}>
                                <Descriptions
                                    bordered
                                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                    className="mb-1"
                                    size="small"
                                >
                                    <Descriptions.Item label="Status">
                                        {
                                            question.length >= 1 ?
                                                <Result className="padding-1"
                                                    status="success"
                                                    title={US + "Minimum number of Long question is passed !"}
                                                />
                                                :
                                                <Result className="padding-1"
                                                    status="error"
                                                    title={US + "Minimum number of Long question is not passed !"}
                                                />
                                        }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Choose Unit">
                                        <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                                            onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                        </Select>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Choose Session">
                                        <Select id="SESSION" style={{ width: "100%" }} value={SESSION}
                                            onChange={this.handleChangeSelect.bind(this, "SESSION")}>
                                            {
                                                parseInt(this.state.SESSION_COUNT) > 0 ?
                                                    (SC.map(
                                                        (item) => {
                                                            return (
                                                                <Option key={item} value={item}>{item}</Option>
                                                            );
                                                        }
                                                    )
                                                    ) : ''
                                            }
                                        </Select>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Questions">
                                        <Table className="mt-2" dataSource={question} columns={columns} size="small" bordered />
                                    </Descriptions.Item>

                                </Descriptions>

                                <Descriptions
                                    bordered
                                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                    className="mb-1"
                                    size="small"
                                >
                                    <Descriptions.Item label="Question Description" span={2}>
                                        <CKEditor
                                            type="classic"
                                            onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                            data={QUESTION_DESC}
                                            config={config}
                                            onChange={this.onEditorChange.bind(this, "QUESTION_DESC")}
                                        />
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Answer Description" span={2}>
                                        <CKEditor
                                            type="classic"
                                            onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                            data={ANSWER}
                                            config={config}
                                            onChange={this.onEditorChange.bind(this, "ANSWER")}
                                        />
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Level" span={1}>
                                        <Select value={LEVEL} style={{ width: 200 }} onChange={this.handleChangeSelect.bind(this, "LEVEL")}>
                                            <Option value="1"><Tag color="blue">1</Tag><Tag color="magenta">Remember</Tag></Option>
                                            <Option value="2"><Tag color="blue">2</Tag><Tag color="magenta">Understand</Tag></Option>
                                            <Option value="3"><Tag color="blue">3</Tag><Tag color="magenta">Apply</Tag></Option>
                                            <Option value="4"><Tag color="blue">4</Tag><Tag color="magenta">Analyze</Tag></Option>
                                            <Option value="5"><Tag color="blue">5</Tag><Tag color="magenta">Evaluate</Tag></Option>
                                            <Option value="6"><Tag color="blue">6</Tag><Tag color="magenta">Create</Tag></Option>
                                        </Select>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Taxonomy of Learning – Cognitive" span={1}>
                                        <Row>
                                            {
                                                LEVEL === 1 ?
                                                    (level1.map((item, val) => {
                                                        return (<Tag style={{ fontSize: "10px" }} key={val}>{item}</Tag>);
                                                    })) : LEVEL === 2 ?
                                                        (level2.map((item, val) => {
                                                            return (<Tag style={{ fontSize: "10px" }} key={val}>{item}</Tag>);
                                                        })) : LEVEL === 3 ?
                                                            (level3.map((item, val) => {
                                                                return (<Tag style={{ fontSize: "10px" }} key={val}>{item}</Tag>);
                                                            })) : LEVEL === 4 ?
                                                                (level4.map((item, val) => {
                                                                    return (<Tag style={{ fontSize: "10px" }} key={val}>{item}</Tag>);
                                                                })) : LEVEL === 5 ?
                                                                    (level5.map((item, val) => {
                                                                        return (<Tag style={{ fontSize: "10px" }} key={val}>{item}</Tag>);
                                                                    })) : LEVEL === 6 ?
                                                                        (level6.map((item, val) => {
                                                                            return (<Tag style={{ fontSize: "10px" }} key={val}>{item}</Tag>);
                                                                        })) : ''
                                            }
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Outcomes" span={2}>
                                        <Row gutter={[16, 16]} className="mt-1 mb-1">
                                            <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "OUTCOMES")} value={this.state.OUTCOMES}>
                                                {this.FunctionCheckboxGroup()}
                                            </Checkbox.Group>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Submit">
                                        <Button type="primary" onClick={this.onClickAdd}>
                                            {this.state._id ? "Update" : "Add New"}
                                        </Button>
                                    </Descriptions.Item>

                                </Descriptions>
                            </Spin>
                        </>
                    )
                    : this.state.error === 1 ? <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    /> :
                        <Result
                            status="404"
                            title="Course Outcomes & Program Outomes information are Empty"
                            subTitle="CO's and PO's information are empty"
                        />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(MCQ));