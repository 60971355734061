import React, { Component } from 'react'
import { Row, Col, Card, Button, Select, Input, DatePicker, Tooltip, Tag, Statistic, Table, notification, Alert, Popconfirm, Empty } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { MinusOutlined, UploadOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;
const dateFormatFull = ['DD/MM/YYYY'];
const topic = "industrialconsultancy";
const subtopic = "industrialconsultancy";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
const roleList = ["CONSULTANT", "OTHERS"];
function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}

class IndustrialConsultancy extends Component {
    state = {
        title: '',
        consultancyType: '',
        particularsInstitution: '',
        particularsWork: '',
        webLink: '',
        mou: '',
        details: [],
        earning: [
            {
                _id: 1,
                AMOUNT: 0,
                DATE: "01/01/2020",
            }
        ],
        authorCount: 0,
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0,
        key: ''
    };
    initialState = this.state;
    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            subtopic: subtopic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/getresearchtable', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        if (e.target.id === "authorCount") {
            if (e.target.value > 5) e.target.value = 5;
            var details = [
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME: this.props.common.user.FIRST_NAME + " " + this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: config.institutionName
                }
            ];
            for (let i = 1; i < e.target.value; i++) {
                let newDetail = {
                    _id: details.length + 1,
                    EMPLOYEEID: "",
                    ROLE: "",
                    NAME: "",
                    DEPARTMENT: "",
                    INSTITUTION: ""
                };
                details.push(newDetail);
            }
            this.setState({ details: details });
        }

        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickEditBtn = (record) => {
        this.setState({ edit: true, update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/personal/research/industrialconsultancy/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/personal/research/industrialconsultancy/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/personal/research/industrialconsultancy/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let dataSet = this.state.data;
        dataSet = dataSet.filter(element => element.key !== record.key);
        let data = {
            _id: record._id,
            data: record,
            topic: topic,
            subtopic: subtopic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/research/industrialconsultancy/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/deleteresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    openNotificationWithIcon('success');
                    await this.setState(this.initialState);
                    await this.setState({ data: dataSet });
                }
            });
    }

    onClickSubmitBtn = () => {
        let { consultancyType, title, particularsInstitution, particularsWork, webLink, details, earning, errorMessages, mou, _id, key } = this.state;
        this.setState({ errorMessages: [] });

        if (!consultancyType) errorMessages.push("Select consultancyType");
        else if (!title) errorMessages.push("Invalid Title");
        else if (!particularsWork) errorMessages.push("Invalid particularsWork");
        else if (!particularsInstitution) errorMessages.push("Invalid particularsInstitution");
        else if (!webLink) errorMessages.push("Invalid webLink");
        else if (!mou) errorMessages.push("Invalid mou");
        else if (details.length < 1) errorMessages.push("Invalid details");
        else if (details.length > 0) {
            for (let i = 0; i < details.length; i++) {
                if (!details[i].EMPLOYEEID || !details[i].ROLE || !details[i].NAME || !details[i].DEPARTMENT || !details[i].INSTITUTION) {
                    errorMessages.push("Invalid Details Information");
                    break;
                }
            };
        }

        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            subtopic: subtopic,
            data: {
                consultancyType: consultancyType,
                title: title,
                particularsInstitution: particularsInstitution,
                particularsWork: particularsWork,
                webLink: webLink,
                mou: mou,
                details: details,
                earning: earning,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/research/addresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/research/industrialconsultancy/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/researchupload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End


                    //update this state.data
                    let dataSet = this.state.data;

                    if (this.state.key === '')
                        dataSet.push(res.data.value)
                    else {
                        for (let i = 0; i < dataSet.length; i++) {
                            let element = dataSet[i];
                            if (element._id === res.data.key) {
                                dataSet[i] = res.data.value;
                                break;
                            };
                        }
                    }

                    await this.setState(this.initialState);
                    await this.setState({
                        data: dataSet, errorMessages: ["success"], update: false, earning: [
                            {
                                _id: 1,
                                AMOUNT: 0,
                                DATE: "01/01/2020",
                            }
                        ],
                    });
                    //update this.state.data ends 

                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onAdddetails = () => {
        let details = this.state.details;
        let authorCount = this.state.authorCount;
        if (authorCount > 4) return false;
        if (!authorCount) authorCount = 0;
        if (details.length === 0 || authorCount === 0) {
            details = [{
                _id: 1,
                EMPLOYEEID: this.props.common.user.USER_ID,
                ROLE: "",
                NAME: this.props.common.user.FIRST_NAME + " " + this.props.common.user.LAST_NAME,
                DEPARTMENT: this.props.common.user.DEPARTMENT,
                INSTITUTION: config.institutionName
            }]
        }
        else {
            let newDetail = {
                _id: details.length + 1,
                EMPLOYEEID: "",
                ROLE: "",
                NAME: "",
                DEPARTMENT: "",
                INSTITUTION: ""
            };
            details.push(newDetail);
        }
        authorCount++;
        this.setState({ details, authorCount });
    };

    onRemovedetails = () => {
        let details = this.state.details;
        let authorCount = this.state.authorCount;
        if (authorCount === 0 || !authorCount) return false;
        details.pop();
        authorCount--;
        this.setState({ details, authorCount, disable: false });
    };



    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = e.target.value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    onChangedetailsSelect = (id, name, value) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    searchEmployee = (id, employeeid) => {
        let data = {
            employeeId: employeeid,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getusername', data)
            .then(res => {
                if (res.data.result) {
                    let result = res.data.result;
                    var details = this.state.details;
                    let data = details[id];
                    data.NAME = result.employeeName;
                    data.DEPARTMENT = result.department;
                    data.INSTITUTION = result.institution;
                    details[id] = data;
                    this.setState({ errorMessages: [], details: details, disable: false })
                }
            })
    }

    onAddEarning = () => {
        let earning = this.state.earning;
        let newDetail = {
            _id: earning.length + 1,
            AMOUNT: 0,
            DATE: "01/01/2020"
        }
        earning.push(newDetail);
        this.setState({ errorMessages: [], earning: earning })
    }

    onRemoveEarning = () => {
        let earning = this.state.earning;
        earning.pop();
        this.setState({ errorMessages: [], earning: earning, disable: false })
    }

    onChangeEarning = (id, name, e) => {
        var earning = this.state.earning;
        let data = earning[id];
        data[name] = parseInt(e.target.value);
        earning[id] = data;
        this.setState({ errorMessages: [], earning: earning, disable: false })
    }

    onChangeEarningDate = (id, name, label, dateString) => {
        var earning = this.state.earning;
        if (dateString)
            earning[id].DATE = dateString;
        this.setState({ earning: earning, disable: false })
    }


    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let totalEarnings = 0
        let { consultancyType, title, particularsInstitution, particularsWork, webLink, mou, edit, data, authorCount } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 5,
            },
            {
                title: 'Type',
                dataIndex: 'consultancyType',
                key: 'consultancyType',
                width: 2,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 3,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 3,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (


            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Industrial Consultancy
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>


                    <Col xs={ 24 } md={ 16 }>
                        <Input id="title" addonBefore="* Title of Work" onChange={ this.onChange } value={ title } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="consultancyType" style={ { width: "100%" } } placeholder="* Type" onChange={ this.handleChangeSelect.bind(this, "consultancyType") } value={ consultancyType }>
                                        <Option value="INSTITUTION">Institution</Option>
                                        <Option value="FIRM">Firm</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Particulars of Work" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <TextArea id="particularsWork" placeholder="* Particulars of Work" onChange={ this.onChange } value={ particularsWork } rows={ 1 } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Particulars of Institution / Firm" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <TextArea id="particularsInstitution" placeholder="* Particulars of Institution / Firm" onChange={ this.onChange } value={ particularsInstitution } rows={ 1 } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="webLink" addonBefore="* Web link" onChange={ this.onChange } value={ webLink } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 10 }>
                                    <Input defaultValue="MOU Signed with Own Institution" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 14 }>
                                    <Select id="mou" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "mou") } value={ mou }>
                                        <Option value="YES">Yes</Option>
                                        <Option value="NO">No</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col xs={ 24 } md={ 12 }>
                        <Input id="authorCount" disabled addonBefore="* Author Count" onChange={ this.onChange } value={ authorCount } />
                    </Col>

                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="User Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>New</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.details.length > 0 ?
                                    (this.state.details.map(
                                        (item, val) => {
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-1">
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ (val + 1) + ". Employee ID" } id={ val } addonAfter={ <SearchOutlined onClick={ this.searchEmployee.bind(this, val, (item.EMPLOYEEID)) } /> } value={ item.EMPLOYEEID } onChange={ this.onChangedetails.bind(this, val, "EMPLOYEEID") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <InputGroup>
                                                            <Row style={ { width: "100%" } }>
                                                                <Col xs={ 24 } md={ 6 }>
                                                                    <Input defaultValue="Role" disabled className="newLabel" />
                                                                </Col>
                                                                <Col xs={ 24 } md={ 18 }>
                                                                    <Select style={ { width: "100%" } } id={ val } onChange={ this.onChangedetailsSelect.bind(this, val, "ROLE") } value={ item.ROLE }>
                                                                        <Option value="" disabled>Select Role</Option>
                                                                        {
                                                                            roleList.map(
                                                                                (newItem, newVal) => {
                                                                                    return (
                                                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ "Name" } id={ val } value={ item.NAME } onChange={ this.onChangedetails.bind(this, val, "NAME") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Department" } id={ val } value={ item.DEPARTMENT } onChange={ this.onChangedetails.bind(this, val, "DEPARTMENT") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Institution" } id={ val } value={ item.INSTITUTION } onChange={ this.onChangedetails.bind(this, val, "INSTITUTION") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>

                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="Earning Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAddEarning }>New</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemoveEarning }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.earning.length > 0 ?
                                    (this.state.earning.map(
                                        (item, val) => {// eslint-disable-next-line
                                            { totalEarnings = parseInt(totalEarnings) + parseInt(item.AMOUNT) }
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-1">
                                                    <Col xs={ 24 } md={ 6 }>
                                                        <Input addonBefore={ (val + 1) + ". Earnings (Rs.)" } id={ val } value={ item.AMOUNT || 0 } onChange={ this.onChangeEarning.bind(this, val, "AMOUNT") } type="number" />
                                                    </Col>
                                                    <Col xs={ 6 }  >
                                                        <DatePicker id={ val } style={ { width: "100%" } } format={ dateFormatFull } onChange={ this.onChangeEarningDate.bind(this, val, "DATE") } value={ moment(item.DATE, dateFormatFull) } />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }

                            <Col span={ 12 } className="mt-2">
                                <Statistic title="Total Earnings" value={ totalEarnings } prefix="Rs. " />
                            </Col>
                        </Card>
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } disabled={ this.state.disable }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(IndustrialConsultancy));