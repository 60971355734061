import React, { Component } from 'react'
import { Table, Button, Input, Space, Row, Col, Tag, Spin, Progress, Divider } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Highlighter from 'react-highlight-words';
import BarChart from '../../../chart/BarChart';
import { Liquid } from '@ant-design/charts';
import CourseStatus from './CourseStatus';
const headers = [
    { label: "COURSE_CODE", key: "COURSE_CODE" },
    { label: "COURSE_NAME", key: "COURSE_NAME" },
    { label: "UNIT 1", key: "UNIT1" },
    { label: "UNIT 2", key: "UNIT2" },
    { label: "UNIT 3", key: "UNIT3" },
    { label: "UNIT 4", key: "UNIT4" },
    { label: "UNIT 5", key: "UNIT5" },
    { label: "OVERALL", key: "OVERALL" }
];

class CourseStatusAll extends Component {
    state = {
        loading: true,
        courses: [],
        statusArray: [],
        fullStatusArray: [],
        key: 0,
        SELECTED_COURSE_CODE: "",
        SELECTED_COURSE_NAME: "",
        status: {
            UNIT1: 0,
            UNIT2: 0,
            UNIT3: 0,
            UNIT4: 0,
            UNIT5: 0,
            OVERALL: 0,
            COURSECOUNT: 0
        }
    }

    async componentDidMount() {
        let sendData = {
            key: config.key
        };

        var status = {
            UNIT1: 0,
            UNIT2: 0,
            UNIT3: 0,
            UNIT4: 0,
            UNIT5: 0,
            OVERALL: 0,
            COURSECOUNT: 0
        }

        await axios.post(config.curriculaserver + '/curricula/admin/course/getcourses', sendData)
            .then(async res => {
                //console.log(res.data.courses)
                if (res.data.Status === 1) {
                    var i = 0, statusArray = [];
                    let COURSE_CODE, COURSE_NAME;
                    for (i = 0; i < res.data.courses.length; i++) {
                        status['COURSECOUNT'] = status['COURSECOUNT'] + 1;
                        COURSE_CODE = res.data.courses[i].COURSE_CODE;
                        COURSE_NAME = res.data.courses[i].COURSE_NAME;
                        if (COURSE_CODE) {
                            let temp = {
                                key: COURSE_CODE,
                                COURSE_CODE: COURSE_CODE,
                                COURSE_NAME: COURSE_NAME,
                                FULLNAME: COURSE_NAME + " (" + COURSE_CODE + ")",
                                UNIT1: 0,
                                UNIT2: 0,
                                UNIT3: 0,
                                UNIT4: 0,
                                UNIT5: 0,
                                OVERALL: 0
                            }

                            let sendData = {
                                COURSE_CODE: COURSE_CODE,
                                key: config.key
                            };

                            await axios.post(config.curriculaserver + '/curricula/admin/coursereport/getcoursestatus', sendData)
                                .then(async res => {
                                    let result = res.data.result;

                                    //console.log(result);
                                    if (res.data.Status === 1 && result.course_learning_session) {
                                        var overall = 0;
                                        for (let j = 1; j <= 5; j++) {
                                            let unit = j;
                                            let point = 0;
                                            let sessionCount = 0;
                                            let percentage = 0;
                                            let statusDetails = {
                                                CLO: 0,
                                                SESSION: 0,
                                                SLOSRO: 0,
                                                SP: 0,
                                                SLC: 0,
                                                SLCZIP: 0,
                                                SLP: 0,
                                                LPSOLUTION: 0,
                                                LM: 0,
                                                LMDOCX: 0,
                                                MCQ: 0,
                                                SQ: 0,
                                                LQ: 0
                                            };
                                            if (result.sessionCount.length) {
                                                let newArray = result.sessionCount.filter(element => element._id === unit);
                                                sessionCount = newArray[0].SESSIONCOUNT;
                                            }
                                            let SLO = sessionCount * 2;

                                            if (result.course_learning_objective) { point++; statusDetails.CLO = 1; }
                                            if (result.course_learning_session) { point++; statusDetails.SESSION = 1; }
                                            if (result.session_learning_objectives.length) {
                                                let newArray = result.session_learning_objectives.filter(element => element.UnitNo === unit && element.value === 1);
                                                if (newArray.length) { point++; statusDetails.SLOSRO = 1; }
                                            }
                                            if (result.session_plan.length) {
                                                let newArray = result.session_plan.filter(element => element.UnitNo === unit && element.count > 99);
                                                if (newArray.length) { point++; statusDetails.SP = 1; }
                                            }
                                            if (result.slc.length) {
                                                let newArray = result.slc.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
                                                if (newArray.length === SLO) { point++; statusDetails.SLC = 1; }
                                            }
                                            if (result.slczip.length) {
                                                let newArray = result.slczip.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
                                                if (newArray.length === SLO) { point++; statusDetails.SLCZIP = 1; }
                                            }
                                            if (result.slpPractice.length) {
                                                let newArray = result.slpPractice.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
                                                if (newArray.length === SLO) { point++; statusDetails.SLP = 1; }
                                            }
                                            if (result.slpSolutionOverall.length) {
                                                let newArray = result.slpSolutionOverall.filter(element => element > unit * 1000 && element < (unit + 1) * 1000);
                                                if (newArray.length === SLO) { point++; statusDetails.LPSOLUTION = 1; }
                                            }
                                            if (result.unit.length) {
                                                let newArray = result.unit.filter(element => element === unit);
                                                if (newArray.length) { point++; statusDetails.LM = 1; }
                                            }
                                            if (result.unitdocx.length) {
                                                let newArray = result.unitdocx.filter(element => element === unit);
                                                if (newArray.length) { point++; statusDetails.LMDOCX = 1; }
                                            }
                                            if (result.mcq.length) {
                                                let newArray = result.mcq.filter(element => element.UnitNo === unit && element.Count > 4);
                                                if (newArray.length >= sessionCount) { point++; statusDetails.MCQ = 1; }
                                            }
                                            if (result.sq.length) {
                                                let newArray = result.sq.filter(element => element.UnitNo === unit && element.Count > 1);
                                                if (newArray.length >= sessionCount) { point++; statusDetails.SQ = 1; }
                                            }
                                            if (result.lq.length) {
                                                let newArray = result.lq.filter(element => element.UnitNo === unit && element.Count > 0);
                                                if (newArray.length >= sessionCount) { point++; statusDetails.LQ = 1; }
                                            }
                                            percentage = parseFloat((point / 13) * 100).toFixed(1);
                                            overall = overall + parseFloat(percentage);
                                            temp['UNIT' + unit] = percentage;
                                            status['UNIT' + unit] = status['UNIT' + unit] + parseFloat(percentage);
                                        }
                                        temp.OVERALL = parseInt(overall / 5);
                                        status.OVERALL = status.OVERALL + parseInt(overall / 5);
                                    }
                                    await statusArray.push(temp);
                                });
                        }
                    }
                    let tempStatus = {
                        key: "OVERALL",
                        UNIT1: parseFloat(status.UNIT1 / status.COURSECOUNT).toFixed(2),
                        UNIT2: parseFloat(status.UNIT2 / status.COURSECOUNT).toFixed(2),
                        UNIT3: parseFloat(status.UNIT3 / status.COURSECOUNT).toFixed(2),
                        UNIT4: parseFloat(status.UNIT4 / status.COURSECOUNT).toFixed(2),
                        UNIT5: parseFloat(status.UNIT5 / status.COURSECOUNT).toFixed(2),
                        OVERALL: parseFloat(status.OVERALL / status.COURSECOUNT).toFixed(2),
                        COURSECOUNT: status.COURSECOUNT
                    }
                    await this.setState({ statusArray: statusArray, status: tempStatus, loading: false, });
                }
            });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getStatusColorOne = (text) => {
        text = parseFloat(text);
        let color = <Tag style={{ width: "70px" }} color="green">{text}</Tag>;
        if (text < 1) color = <Tag style={{ width: "70px" }} color="red">{text}</Tag>;
        else if (text < 25) color = <Tag style={{ width: "70px" }} color="purple">{text}</Tag>;
        else if (text < 50) color = <Tag style={{ width: "70px" }} color="orange">{text}</Tag>;
        else if (text < 75) color = <Tag style={{ width: "70px" }} color="blue">{text}</Tag>;
        else if (text < 99) color = <Tag style={{ width: "70px" }} color="cyan">{text}</Tag>;
        else if (text > 99) color = <Tag style={{ width: "70px" }} color="green">{text}</Tag>;
        return color;
    }

    StatusBtn = (course) => {
        this.setState({ SELECTED_COURSE_CODE: course.COURSE_CODE, SELECTED_COURSE_NAME: course.COURSE_NAME, key: 1 });
    }

    render() {
        let { SELECTED_COURSE_CODE, SELECTED_COURSE_NAME, status } = this.state;
        const columns = [
            {
                title: 'SNO',
                dataIndex: 'key',
                key: 'key',
                render: (text) => (this.state.statusArray.findIndex(i => i.key === text) + 1),
            },
            {
                title: 'COURSE CODE',
                dataIndex: 'COURSE_CODE',
                key: 'COURSE_CODE',
                ...this.getColumnSearchProps('COURSE_CODE'),
            },
            {
                title: 'COURSE NAME',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
                ...this.getColumnSearchProps('COURSE_NAME')
            },
            {
                title: 'UNIT 1',
                dataIndex: 'UNIT1',
                key: 'UNIT1',
                sorter: (a, b) => a.UNIT1 - b.UNIT1,
                render: (text) => (
                    this.getStatusColorOne(text)
                ),
            },
            {
                title: 'UNIT 2',
                dataIndex: 'UNIT2',
                key: 'UNIT2',
                sorter: (a, b) => a.UNIT2 - b.UNIT2,
                render: (text) => (
                    this.getStatusColorOne(text)
                ),
            },
            {
                title: 'UNIT 3',
                dataIndex: 'UNIT3',
                key: 'UNIT3',
                sorter: (a, b) => a.UNIT3 - b.UNIT3,
                render: (text) => (
                    this.getStatusColorOne(text)
                ),
            },
            {
                title: 'UNIT 4',
                dataIndex: 'UNIT4',
                key: 'UNIT4',
                sorter: (a, b) => a.UNIT4 - b.UNIT4,
                render: (text) => (
                    this.getStatusColorOne(text)
                ),
            },
            {
                title: 'UNIT 5',
                dataIndex: 'UNIT5',
                key: 'UNIT5',
                sorter: (a, b) => a.UNIT5 - b.UNIT5,
                render: (text) => (
                    this.getStatusColorOne(text)
                ),
            },
            {
                title: 'OVERALL',
                dataIndex: 'OVERALL',
                key: 'OVERALL',
                sorter: (a, b) => a.OVERALL - b.OVERALL,
                render: (text) => (
                    this.getStatusColorOne(text)
                ),
            },
            {
                title: 'ACTION',
                fixed: 'right',
                width: 1,
                render: (text, record) => (
                    <>
                        <Button icon={<SearchOutlined />} type="primary" size="small" onClick={this.StatusBtn.bind(this, record)}>STATUS</Button>
                    </>
                )
            },
        ];

        let configChart = {
            data: this.state.statusArray,
            title: "Status",
            xField: "COURSE_CODE",
            yField: "OVERALL",
            scrollbar: {
                type: 'vertical',
            },
        };

        let LiquidChartConfig = {
            height: 200,
            outline: {
                border: 4,
                distance: 4,
            },
            wave: {
                length: 128,
            },
        };

        return (
            <Spin spinning={this.state.loading} tip="Please wait, we're processing your request..">
                <Row gutter={[16, 16]} justify='center'>
                    <Col span={24} align="middle" className='mt-2'>
                        <h2>Overall Status</h2>
                        <Liquid {...LiquidChartConfig} percent={status.OVERALL * 0.01} />
                    </Col>
                    <Divider />
                    <Col span={24} align="middle" className='mt-2'>
                        <h2>Overall Unit Wise Status</h2>
                    </Col>
                    <Col span={4} align="middle"> Unit 1 <Progress type="circle" percent={status.UNIT1} /> </Col>
                    <Col span={4} align="middle"> Unit 2 <Progress type="circle" percent={status.UNIT2} /> </Col>
                    <Col span={4} align="middle"> Unit 3 <Progress type="circle" percent={status.UNIT3} /> </Col>
                    <Col span={4} align="middle"> Unit 4 <Progress type="circle" percent={status.UNIT4} /> </Col>
                    <Col span={4} align="middle"> Unit 5 <Progress type="circle" percent={status.UNIT5} /> </Col>
                    <Divider />
                    <Col span={24} align="middle" className='mt-2'>
                        <h2>Course Wise Status</h2>
                    </Col>
                    <Col span={24} align="start" className='mt-2'>
                        <BarChart details={configChart} />
                    </Col>
                    <Divider />
                    <Col span={24} align="middle" className='mt-2'>
                        <h2>Course Wise & Unit Wise Status</h2>
                    </Col>
                    <Col span={24} align="middle" className='mt-2'>
                        <Tag style={{ width: "170px" }} color="red">{"Not Started | 0%"}</Tag>
                        <Tag style={{ width: "170px" }} color="purple">{"Initiated | < 25%"}</Tag>
                        <Tag style={{ width: "170px" }} color="orange">{"In Progress | < 50%"}</Tag>
                        <Tag style={{ width: "170px" }} color="blue">{"Halfway Completed | < 75%"}</Tag>
                        <Tag style={{ width: "170px" }} color="cyan">{"Detailing Phase | < 99%"}</Tag>
                        <Tag style={{ width: "170px" }} color="green">{"Completion |  100%"}</Tag>
                    </Col>
                    <Col span={24} className='mt-2'>
                        <CSVLink className='mt-2' data={this.state.statusArray} headers={headers} filename={config.toolname + "-Status.csv"} >
                            <Button disabled={this.state.loading ? true : false} size="middle" icon={<DownloadOutlined />}>Download EXCEL</Button>
                        </CSVLink>
                    </Col>
                </Row >
                <Table className='mt-2' loading={this.state.loading} dataSource={this.state.statusArray} columns={columns} bordered={true} size="small" />
                <Divider />

                <Row gutter={[16, 16]} justify='start'>
                    {
                        this.state.key === 1 ?
                            <Col xs={24}>
                                <CourseStatus className="mt-1" COURSE_CODE={SELECTED_COURSE_CODE} COURSE_NAME={SELECTED_COURSE_NAME} />
                            </Col> : null
                    }
                </Row>
                <br />
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseStatusAll));