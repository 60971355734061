import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, DatePicker, Tooltip, Table, notification, Alert, Popconfirm, Tag } from 'antd';
import { message } from 'antd';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { Option } = Select;
const { Title, Text } = Typography;
const dateFormat = ['DD/MM/YYYY'];
const topic = "membership";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

class Membership extends Component {
    state = {
        nameOfProfessionalBody: '',
        bodyRegistrationNumber: '',
        membershipNumber: '',
        membershipTitle: '',
        from: '01/01/2020',
        to: '01/01/2020',
        typeOfMembership: '',
        amount: '',
        _id: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        docStatus: 0
    }

    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }


    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
    }

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });
                }
            });
    }


    onClickSubmitBtn = () => {
        let { nameOfProfessionalBody, bodyRegistrationNumber, key, membershipNumber, membershipTitle, from, to, typeOfMembership, amount, _id } = this.state;
        let errorMessages = [];

        if (!nameOfProfessionalBody) errorMessages.push("Invalid Name of Professional Body");
        //        else if (!bodyRegistrationNumber) errorMessages.push("Invalid Body Registration Number");
        else if (!membershipNumber) errorMessages.push("Invalid Membership Number");
        else if (!membershipTitle) errorMessages.push("Select Membership Title");
        else if (!typeOfMembership) errorMessages.push("Select Type of Membership");
        else if (!from) errorMessages.push("Select From Date");
        if (typeOfMembership !== 'LIFE_MEMBER')
            if (!to) errorMessages.push("Select To Date");
        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                nameOfProfessionalBody: nameOfProfessionalBody,
                bodyRegistrationNumber: bodyRegistrationNumber,
                membershipNumber: membershipNumber,
                membershipTitle: membershipTitle,
                from: from,
                to: to,
                typeOfMembership: typeOfMembership,
                amount: amount,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    await this.setState({ update: false });
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });

    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }


    render() {
        let { nameOfProfessionalBody, bodyRegistrationNumber, membershipNumber, membershipTitle, typeOfMembership, from, to, edit, amount, data } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Professional Body',
                dataIndex: 'nameOfProfessionalBody',
                key: 'nameOfProfessionalBody',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 20,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Type',
                dataIndex: 'typeOfMembership',
                key: 'typeOfMembership',
                width: 20,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 20,
            },
        ];

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Membership on Professional Body
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='nameOfProfessionalBody' addonBefore="* Name of Professional Body" onChange={ this.onChange } value={ nameOfProfessionalBody } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='bodyRegistrationNumber' addonBefore="Body Registration Number" onChange={ this.onChange } value={ bodyRegistrationNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='membershipNumber' addonBefore="* Membership Number" onChange={ this.onChange } value={ membershipNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='membershipTitle' addonBefore="* Membership Title" onChange={ this.onChange } value={ membershipTitle } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Membership Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <Select showSearch id='typeOfMembership' style={ { width: "100%" } } placeholder="* Type of Membership" onChange={ this.handleChangeSelect.bind(this, "typeOfMembership") } value={ typeOfMembership }>
                                        <Option value="ANNUAL_MEMBER">Annual Member</Option>
                                        <Option value="LIFE_MEMBER">Life Member</Option>
                                        <Option value="HONORARY_MEMBER">Honorary Member</Option>
                                        <Option value="FELLOW">Fellow</Option>
                                        <Option value="OTHER">Other</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col xs={ 24 } md={ 8 }>
                        <Input id='amount' addonBefore="* Fees" style={ { width: "100%" } } type="number" onChange={ this.onChange } value={ amount } />
                    </Col>
                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* From" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="from" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "from") } value={ moment(from, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    { typeOfMembership !== 'LIFE_MEMBER' ?
                        <Col xs={ 24 } md={ 8 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 8 }>
                                        <Input defaultValue="* To" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 16 }>
                                        <DatePicker id="to" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "to") } value={ moment(to, dateFormat) } />
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col> : '' }


                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>
                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn }
                            disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } disabled={ !this.state.edit } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } bordered />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Membership));