import React, { Component } from 'react'
import { Select, Row, Col, Result, Calendar, Modal, TimePicker, message, Tag, Spin, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import momenttimezone from 'moment-timezone';
import moment from 'moment';
momenttimezone.tz.setDefault("Asia/Kolkata");
const { Option } = Select;

class TimeTable extends Component {

    state = {
        loading: false,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        flare: {},
        error: 0,
        START_TIME: '',
        END_TIME: '',
        listData: [],
        isModalVisible: false, DATE: '',
        sessions: {}, SESSION_COUNT: 0,
        UNIT: '', SESSION: '',
        thisMonth: new Date().getMonth() + 1,
        thisYear: new Date().getFullYear(),
    }

    async componentDidMount() {
        await this.getStatus(this.props.COURSE_CODE);
        await this.getData(this.state.thisMonth, this.state.thisYear);
    }

    getData = async (MONTH, YEAR) => {
        this.setState({ loading: true });
        const userData = {
            COURSE_INFO: this.props.COURSE_INFO,
            MONTH: MONTH,
            YEAR: YEAR,
            KEY: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/faculty/batch/gettimetable', userData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ listData: res.data.result || [], loading: false });
                }
            });
    }

    onPanelChange = async (value) => {
        let MONTH = parseInt(value.format('MM'));
        let YEAR = parseInt(value.format('YYYY'));
        await this.setState({ thisMonth: MONTH, thisYear: YEAR, MonthName: value.format('MMMM') })
        await this.getData(MONTH, YEAR);
    }

    onSelect = (value) => {
        this.setState({ DATE: moment(value).unix(), isModalVisible: true })
    }

    getStatus = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 });
                    }
                    else {
                        this.setState({ sessions: record, SESSION_COUNT: record[0].SESSIONCOUNT });
                    }
                    this.setState({ loading: false });
                }
            });
    }

    getListData = (value) => {
        let listData = this.state.listData;
        let todayDate = value.date();
        if (listData) {
            var result = listData.filter(obj => {
                return obj.day === todayDate
            })
            if ((this.state.thisMonth - 1) === value.month())
                return Object.keys(result).length ? result[0].data : [];
            else
                return []
        }
        else return [];
    }

    dateCellRender = (value) => {
        const listData = this.getListData(value);
        return (
            <ul className="events" style={{ listStyleType: "none" }}>
                {listData.map(item => (
                    item.content ?
                        <li key={item.content}>
                            <Tag
                                closable={this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? false : true}
                                onClose={this.tagonClose.bind(this, item)}
                                className="overlineCalenderTag" color="#595959">{item.content}</Tag>
                        </li> : ''
                ))}
            </ul>
        );
    }

    tagonClose = (item) => {
        if (this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A') {
            message.info("Operation Not Permitted !");
            return false;
        }
        else {
            let sendData = {
                COURSE_INFO: this.props.COURSE_INFO,
                ITEM: item,
            }
            axios.post(config.curriculaserver + '/curricula/faculty/batch/deletetimetable', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        message.success("Information Updated !");
                    } else
                        message.error("Operation Failed !");
                });
        }
    }


    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT, SESSION: 1 });
        }
    }

    handleOk = () => {
        if (this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A') {
            message.info("Operation Not Permitted !");
            return false;
        }
        else {
            let { START_TIME, END_TIME, UNIT, SESSION, DATE } = this.state;
            if (!UNIT || !SESSION || !START_TIME || !END_TIME || !DATE) {
                message.error("Invalid Entry !");
                return false;
            }

            let sendData = {
                COURSE_INFO: this.props.COURSE_INFO,
                UNIT: UNIT,
                SESSION: SESSION,
                DATE: DATE,
                START_TIME: START_TIME,
                END_TIME: END_TIME
            }
            axios.post(config.curriculaserver + '/curricula/faculty/batch/updatetimetable', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        this.getData(this.state.thisMonth, this.state.thisYear);
                        message.success("Information Updated !");
                        this.setState({ START_TIME: 0, END_TIME: 0 })

                    } else
                        message.error("Error !");
                });

            this.setState({ isModalVisible: false })
        }
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false })
    };

    onChangeTime = (time, timeString) => {
        this.setState({
            START_TIME: moment(time[0]).unix(), END_TIME: moment(time[1]).unix()
        })
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    render() {
        let { SESSION, UNIT, isModalVisible, START_TIME, END_TIME } = this.state;
        let SC = []; for (let i = 1; i <= this.state.SESSION_COUNT; i++) SC.push(i);
        return (
            <div>
                {this.state.error === 0 ?
                    (
                        <Spin spinning={this.state.loading}>
                            <Alert message="The timetable you input for this section becomes immediately visible to students upon their login." type="info" closable />
                            <Calendar fullscreen onSelect={this.onSelect} dateCellRender={this.dateCellRender} onPanelChange={this.onPanelChange} />
                        </Spin>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }

                <Modal title="Add Session Information" visible={isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={10}>
                            <TimePicker.RangePicker onChange={this.onChangeTime} style={{ width: "100%" }} />
                        </Col>
                        <Col xs={24} md={7}>
                            <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                                disabled={!START_TIME || !END_TIME ? true : false}
                                onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                                <Option disabled value="">Unit</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                            </Select>
                        </Col>
                        <Col xs={24} md={7}>
                            <Select id="SESSION" style={{ width: "100%" }} value={SESSION}
                                disabled={!this.state.UNIT ? true : false}
                                onChange={this.handleChangeSelect.bind(this, "SESSION")}>
                                <Option disabled value="">Session</Option>
                                {
                                    parseInt(this.state.SESSION_COUNT) > 0 ?
                                        (SC.map(
                                            (item) => {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                );
                                            }
                                        )
                                        ) : ''
                                }
                            </Select>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(TimeTable));