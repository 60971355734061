import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Input, Button, Tag, Typography, Space, message, Select, Descriptions, Table, Alert } from 'antd';
import axios from 'axios';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { CSVLink } from "react-csv";
import {
    DownloadOutlined, SearchOutlined
} from '@ant-design/icons';
const { Option } = Select;
const InputGroup = Input.Group;
const { Text } = Typography;


class hrReport extends Component {

    state = {
        collapsed: false,
        data: [],
        topic: '',
        topic_selected: '',
        banner: false,
        searchText: '',
        searchedColumn: '',
    }

    handleChangeSelect = (label, value, x) => {
        this.setState({ [label]: value, data: [], loading: true, open: true, topic_selected: x.children, searchText: '', searchedColumn: '' });
        let data = {
            info: this.props.common.user,
            key: config.key,
            topic: value
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/hrreport/getreport', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result) {
                    this.headListGenerate(res.data.result[0]);
                    let result = res.data.result, key = 0;
                    for (let i = 0; i < result.length; ++i) {
                        result[i].key = ++key;
                    }
                    this.setState({ data: res.data.result, loading: false, banner: true });
                    message.success('Information loaded');
                }
            });
    }

    headListGenerate = (data) => {
        let header_list = [];
        if (data)
            Object.keys(data).forEach(function (key) {
                header_list.push({ label: key, key: key });
            })
        this.setState({ header_list: header_list })
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "default" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={ { padding: 8 } }>
                <Input
                    ref={ node => {
                        this.searchInput = node;
                    } }
                    placeholder={ `Search ${dataIndex}` }
                    value={ selectedKeys[0] }
                    onChange={ e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                    onPressEnter={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                    style={ { width: 188, marginBottom: 8, display: 'block' } }
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                        icon={ <SearchOutlined /> }
                        size="small"
                        style={ { width: 90 } }
                    >
                        Search
                    </Button>
                    <Button onClick={ () => this.handleReset(clearFilters) } size="small" style={ { width: 90 } }>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={ { color: filtered ? '#1890ff' : undefined } } />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={ { backgroundColor: '#ffc069', padding: 0 } }
                    searchWords={ [this.state.searchText] }
                    autoEscape
                    textToHighlight={ text.toString() }
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {

        let { data } = this.state;

        let columns1 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "STUDENT_ID",
                key: "STUDENT_ID",
                width: 1,
                ...this.getColumnSearchProps('STUDENT_ID'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "STUDENT_NAME",
                key: "STUDENT_NAME",
                ...this.getColumnSearchProps('STUDENT_NAME'),
                width: 1
            },
            {
                title: "Department",
                dataIndex: "DEPARTMENT",
                key: "DEPARTMENT",
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 3
            },
            {
                title: "Aadhaar",
                dataIndex: "aadhaarNumber",
                key: "aadhaarNumber",
                ...this.getColumnSearchProps('aadhaarNumber'),
                width: 3
            },
            {
                title: "PAN",
                dataIndex: "panNumber",
                key: "panNumber",
                ...this.getColumnSearchProps('panNumber'),
                width: 3
            },
            {
                title: "UAN",
                dataIndex: "UAN",
                key: "UAN",
                ...this.getColumnSearchProps('UAN'),
                width: 3
            },
            {
                title: 'Status',
                dataIndex: 'social',
                width: 2,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) } key={ text }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
                fixed: 'right',
            },
        ];

        let columns2 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "STUDENT_ID",
                key: "STUDENT_ID",
                width: 1,
                ...this.getColumnSearchProps('STUDENT_ID'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "STUDENT_NAME",
                key: "STUDENT_NAME",
                ...this.getColumnSearchProps('STUDENT_NAME'),
                width: 2
            },
            {
                title: "Department",
                dataIndex: "DEPARTMENT",
                key: "DEPARTMENT",
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 3
            },
            {
                title: "Degree",
                dataIndex: "degree",
                key: "degree",
                ...this.getColumnSearchProps('degree'),
                width: 1
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                width: 2,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) } key={ text }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
                fixed: 'right',
            },
        ];

        let columns3 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "STUDENT_ID",
                key: "STUDENT_ID",
                width: 1,
                ...this.getColumnSearchProps('STUDENT_ID'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "FULL_NAME",
                key: "FULL_NAME",
                ...this.getColumnSearchProps('FULL_NAME'),
                width: 2
            },
            {
                title: "Department",
                dataIndex: "DEPARTMENT",
                key: "DEPARTMENT",
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 3
            },
            {
                title: "Experience (Years)",
                dataIndex: "experience",
                key: "experience",
                sorter: (a, b) => a.experience - b.experience,
                width: 1
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                width: 2,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) } key={ text }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
                fixed: 'right',
            },
        ];

        let columns4 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "STUDENT_ID",
                key: "STUDENT_ID",
                width: 1,
                ...this.getColumnSearchProps('STUDENT_ID'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "FULL_NAME",
                key: "FULL_NAME",
                ...this.getColumnSearchProps('FULL_NAME'),
                width: 2
            },
            {
                title: "Department",
                dataIndex: "DEPARTMENT",
                key: "DEPARTMENT",
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 2
            },
            {
                title: "City",
                dataIndex: "city",
                key: "city",
                ...this.getColumnSearchProps('city'),
                width: 1
            },
            {
                title: "Mobile",
                dataIndex: "mobileNumber",
                key: "mobileNumber",
                width: 1
            },
            {
                title: "Emergency Contact",
                dataIndex: "emergencyContactPerson",
                key: "emergencyContactPerson",
                width: 1
            },
            {
                title: "Emergency Contact Mobile",
                dataIndex: "emergencyContactMobile",
                key: "emergencyContactMobile",
                width: 1
            },
            {
                title: "State",
                dataIndex: "statename",
                key: "statename",
                ...this.getColumnSearchProps('statename'),
                width: 1
            },
        ];

        let columns5 = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "STUDENT_ID",
                key: "STUDENT_ID",
                width: 1,
                ...this.getColumnSearchProps('STUDENT_ID'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "FULL_NAME",
                key: "FULL_NAME",
                ...this.getColumnSearchProps('FULL_NAME'),
                width: 2
            },
            {
                title: "Department",
                dataIndex: "DEPARTMENT",
                key: "DEPARTMENT",
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 2
            },
            {
                title: "Gender",
                dataIndex: "gender",
                key: "gender",
                ...this.getColumnSearchProps('gender'),
                width: 1
            },
            {
                title: "DOB",
                dataIndex: "dateOfBirth",
                key: "dateOfBirth",
                width: 1
            },
            {
                title: "Martial Status",
                dataIndex: "martialStatus",
                key: "martialStatus",
                width: 1
            },
            {
                title: "Mother Tongue",
                dataIndex: "motherTongue",
                key: "motherTongue",
                width: 1
            },
            {
                title: "Social Status",
                dataIndex: "socialStatus",
                key: "socialStatus",
                width: 1
            },
        ];

        let column = columns1;
        if (this.state.topic === "social") column = columns1;
        else if (this.state.topic === "qualification") column = columns2;
        else if (this.state.topic === "experience") column = columns3;
        else if (this.state.topic === "communication") column = columns4;
        else if (this.state.topic === "basic") column = columns5;

        return (
            <Row style={ { overflow: "auto" } }>

                <Col xs={ 24 } className="mt-1 mb-1" style={ { padding: "1%" } }>
                    <Descriptions bordered>
                        <Descriptions.Item label={
                            "Select Topic"
                        }>
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 24 }>
                                        <Select showSearch id="topic" style={ { width: "100%" } } value={ this.state.topic }
                                            onChange={ this.handleChangeSelect.bind(this, "topic") }>
                                            <Option value="" disabled>Choose One</Option>
                                            <Option value="basic">Basic</Option>
                                            <Option value="communication">Communication</Option>
                                            <Option value="social">Social</Option>
                                            <Option value="qualification">Qualification</Option>
                                            <Option value="experience">Experience</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>

                <Col xs={ 24 } style={ { padding: "1%" } }>
                    <CSVLink data={ data } headers={ this.state.header_list } filename={ this.state.topic + "-full-report.csv" }>
                        <Row gutter={ [16, 16] } >
                            {
                                this.state.banner ?
                                    (<Col xs={ 24 }>
                                        <Alert message={ this.state.topic_selected + " Information Excel file is ready" } type="success" showIcon closable />
                                    </Col>) : ''
                            }
                            <Col xs={ 24 }>
                                <Button icon={ <DownloadOutlined /> } disabled={ !this.state.header_list ? true : false }>
                                    Download EXCEL
                                </Button>
                            </Col>
                        </Row>
                    </CSVLink>
                    <Table className="mt-1" loading={ this.state.loading } dataSource={ this.state.data } size="small" columns={ column } scroll={ { x: 1500 } }
                        bordered
                    />
                </Col>

            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(hrReport));