import React, { Component } from 'react'
import { Table, Button, Progress, Input, Space } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Highlighter from 'react-highlight-words';

class LearningOutcomes extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        topic: '',
        users: [],
        marks: [],
        key: 0
    }

    componentDidMount() {
        let sendData = {
            key: config.key,
            COURSE_INFO: this.props.COURSE_INFO,
        };
        axios.post(config.curriculaserver + '/curricula/admin/report/getlo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ marks: res.data.result, loading: false });
                }
            });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { marks, loading } = this.state;
        let headers = [
            { label: "User ID", key: "USER_ID" },
            { label: "User Name", key: "FULL_NAME" },
            { label: "UNIT 1", key: "LOSTATUS.UNIT1" },
            { label: "UNIT 2", key: "LOSTATUS.UNIT2" },
            { label: "UNIT 3", key: "LOSTATUS.UNIT3" },
            { label: "UNIT 4", key: "LOSTATUS.UNIT4" },
            { label: "UNIT 5", key: "LOSTATUS.UNIT5" },
            { label: "TOTAL", key: "LOSTATUS.OVERALL" }
        ];

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (marks.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'USER',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                ...this.getColumnSearchProps('FULL_NAME'),
                render: (text, record) => (record.FULL_NAME.toUpperCase() + " (" + text + ")"),
                fixed: 'left',
            },
            {
                title: 'UNIT 1',
                dataIndex: ['LOSTATUS', 'UNIT1'],
                sorter: (a, b) => a.LOSTATUS.UNIT1 - b.LOSTATUS.UNIT1,
                render: (text) => (
                    (text + "%")
                )
            },
            {
                title: 'UNIT 2',
                dataIndex: ['LOSTATUS', 'UNIT2'],
                sorter: (a, b) => a.LOSTATUS.UNIT2 - b.LOSTATUS.UNIT2,
                render: (text) => (
                    (text + "%")
                )
            },
            {
                title: 'UNIT 3',
                dataIndex: ['LOSTATUS', 'UNIT3'],
                sorter: (a, b) => a.LOSTATUS.UNIT3 - b.LOSTATUS.UNIT3,
                render: (text) => (
                    (text + "%")
                )
            },
            {
                title: 'UNIT 4',
                dataIndex: ['LOSTATUS', 'UNIT4'],
                sorter: (a, b) => a.LOSTATUS.UNIT4 - b.LOSTATUS.UNIT4,
                render: (text) => (
                    (text + "%")
                )
            },
            {
                title: 'UNIT 5',
                dataIndex: ['LOSTATUS', 'UNIT5'],
                sorter: (a, b) => a.LOSTATUS.UNIT5 - b.LOSTATUS.UNIT5,
                render: (text) => (
                    (text + "%")
                )
            },
            {
                title: 'TOTAL',
                dataIndex: ['LOSTATUS', 'OVERALL'],
                sorter: (a, b) => a.LOSTATUS.OVERALL - b.LOSTATUS.OVERALL,
                render: (text) => (
                    <Progress percent={text} size="small" />
                )
            }
        ];
        return (
            <div>
                <CSVLink data={marks} headers={headers} filename={"learning-outcomes.csv"} >
                    <Button size="middle" icon={<DownloadOutlined />}>Download EXCEL</Button>
                </CSVLink>

                <Table className="mt-1" size="middle" bordered style={{ width: "100%" }} dataSource={marks} columns={columns} loading={loading} />

            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LearningOutcomes));