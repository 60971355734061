import React, { Component } from 'react'
import { Row, Col, Card, Button, Select, Input, DatePicker, Tooltip, Tag, Table, notification, Alert, Popconfirm, Empty } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { SearchOutlined, UploadOutlined, DeleteOutlined, MinusOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;
const dateFormat = ['DD/MM/YYYY'];
const topic = "project";
const subtopic = "project";
const roleList = ["PRINCIPAL_INVESTIGATOR", "COINVESTIGATOR", "OTHERS"];
const progressList = ["APPLIED", "GRANTED", "COMPLETED", "REJECTED"];
const typeList = ["FUNDED_PROJECTS", "SPONSORED_RESEARCH", "GRANTS"];
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class Project extends Component {
    state = {
        title: '',
        projectID: '',
        from: '01/01/2020',
        to: '01/01/2020',
        particulars: '',
        staffCount: '',
        fundingAgency: '',
        consultancyType: '',
        fundsGenerated: '',
        details: [],
        status: '',
        filingDate: '01/01/2020',
        webLink: '',
        amountRequested: '',
        grantNumber: '',
        amountGranted: '',
        _id: '',
        key: '',
        role: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            subtopic: subtopic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/getresearchtable', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        if (e.target.id === "staffCount") {
            var details = [
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME: this.props.common.user.FIRST_NAME + " " + this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: config.institutionName
                }
            ]
            for (let i = 1; i < e.target.value; i++) {
                let newDetail = {
                    _id: details.length + 1,
                    EMPLOYEEID: "",
                    ROLE: "",
                    NAME: "",
                    DEPARTMENT: "",
                    INSTITUTION: ""
                }
                details.push(newDetail);
            }
            this.setState({ details: details })
        }
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickSubmitBtn = () => {
        let { title, fundingAgency, particulars, details, webLink, amountRequested, status, grantNumber, amountGranted, from, to, resaonForRejection, filingDate, consultancyType, fundsGenerated } = this.state;
        let errorMessages = [];

        if (!consultancyType) errorMessages.push("Select Consultancy Type");
        else if (!title) errorMessages.push("Invalid Title");
        else if (details.length < 1) errorMessages.push("Invalid details");
        else if (details.length > 0) {
            for (let i = 0; i < details.length; i++) {
                if (!details[i].EMPLOYEEID || !details[i].ROLE || !details[i].NAME || !details[i].DEPARTMENT || !details[i].INSTITUTION) {
                    errorMessages.push("Invalid Details Information");
                    break;
                }
            };
        }
        else if (!fundingAgency) errorMessages.push("Invalid Funding Agency");
        else if (!particulars) errorMessages.push("Invalid Particulars");
        else if (!webLink) errorMessages.push("Invalid Web Link");
        else if (isNaN(amountRequested) || !amountRequested) errorMessages.push("Invalid Amount Requested");
        else if (!status) errorMessages.push("Select Status");
        else if (status === "GRANTED" || status === "COMPLETED") {
            if (!grantNumber) errorMessages.push("Invalid Grant Number");
            else if (isNaN(amountGranted) || !amountGranted) errorMessages.push("Invalid Amount Granted");
            else if (!from) errorMessages.push("Invalid From date");
            else if (!to) errorMessages.push("Invalid To Date");
        }
        else if (status === "REJECTED") {
            if (!resaonForRejection) errorMessages.push("Invalid Resaon for Rejection");
        }
        else if (status === "APPLIED") {
            if (!filingDate) errorMessages.push("Invalid Filing Date");
        }
        else if (status === "COMPLETED") {
            if (!fundsGenerated) errorMessages.push("Invalid Funds Generated");
        }


        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            subtopic: subtopic,
            data: {
                consultancyType: consultancyType,
                title: this.state.title,
                projectID: this.state.projectID,
                from: this.state.from,
                to: this.state.to,
                particulars: this.state.particulars,
                staffCount: this.state.staffCount,
                fundingAgency: this.state.fundingAgency,
                details: this.state.details,
                status: this.state.status,
                filingDate: this.state.filingDate,
                webLink: this.state.webLink,
                amountRequested: this.state.amountRequested,
                grantNumber: this.state.grantNumber,
                amountGranted: this.state.amountGranted,
                fundsGenerated: this.state.fundsGenerated,
                _id: this.state._id,
                key: this.state.key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/research/addresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/research/project/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/researchupload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    //update this state.data
                    let dataSet = this.state.data;

                    if (this.state.key === '')
                        dataSet.push(res.data.value)
                    else {
                        for (let i = 0; i < dataSet.length; i++) {
                            let element = dataSet[i];
                            if (element._id === res.data.key) {
                                dataSet[i] = res.data.value;
                                break;
                            };
                        }
                    }
                    await this.setState(this.initialState);

                    await this.setState({ data: dataSet, errorMessages: ["success"], update: false });
                    //update this.state.data ends 
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });

    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/personal/research/project/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/personal/research/project/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/personal/research/project/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let dataSet = this.state.data;
        dataSet = dataSet.filter(element => element.key !== record.key);
        let data = {
            _id: record._id,
            data: record,
            topic: topic,
            subtopic: subtopic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/research/project/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/deleteresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    openNotificationWithIcon('success');
                    await this.setState(this.initialState);
                    await this.setState({ data: dataSet });
                }
            });
    }
    onAdddetails = () => {
        let details = this.state.details;
        let staffCount = parseInt(this.state.staffCount);
        if (staffCount > 6) return false;
        if (!staffCount) staffCount = 0;
        if (staffCount === 0 || details.length === 0)
            details.push(
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME:
                        this.props.common.user.FIRST_NAME +
                        " " +
                        this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: config.institutionName
                });
        else {
            let newDetail = {
                _id: details.length + 1,
                EMPLOYEEID: "",
                ROLE: "",
                NAME: "",
                DEPARTMENT: "",
                INSTITUTION: ""
            };
            details.push(newDetail);
        }
        staffCount++;
        this.setState({ errorMessages: [], details, staffCount });
    };

    onRemovedetails = () => {
        let details = this.state.details;
        let staffCount = this.state.staffCount;
        if (staffCount === 0 || !staffCount) return false;
        details.pop();
        staffCount--;
        this.setState({ details, staffCount, disable: false });
    };

    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = e.target.value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    onChangedetailsSelect = (id, name, value) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    searchEmployee = (id, employeeid) => {
        let data = {
            employeeId: employeeid,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getusername', data)
            .then(res => {
                if (res.data.result) {
                    let result = res.data.result;
                    var details = this.state.details;
                    let data = details[id];
                    data.NAME = result.employeeName;
                    data.DEPARTMENT = result.department;
                    data.INSTITUTION = result.institution;
                    details[id] = data;
                    this.setState({ errorMessages: [], details: details, disable: false })
                }
            })
    }


    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let sno = 1;
        let { title, projectID, fundingAgency, particulars, webLink, amountRequested, status, grantNumber, amountGranted, staffCount, from, to, resaonForRejection, errorMessages, fundsGenerated, edit, filingDate, consultancyType } = this.state;
        let columns = [
            {
                title: 'S.No',
                width: 10,
                render: () => (
                    (sno++)
                ),
                fixed: 'left',
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 100,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 100,
            },
            {
                title: 'Verified',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 3,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 60,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Funded Projects / Sponsored Research / Grants
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Consultancy Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <Select showSearch id="consultancyType" style={ { width: "100%" } } placeholder="* Type" onChange={ this.handleChangeSelect.bind(this, "consultancyType") } value={ consultancyType }>
                                        {
                                            typeList.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={ val } value={ item }>{ item.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 10 }>
                        <Input id="title" addonBefore="Title" onChange={ this.onChange } value={ title } />
                    </Col>

                    <Col xs={ 6 }>
                        <Input id="projectID" placeholder="Reference Number" addonBefore="Project ID" onChange={ this.onChange } value={ projectID } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="fundingAgency" addonBefore="* Funding Agency" onChange={ this.onChange } value={ fundingAgency } />
                    </Col>

                    <Col xs={ 24 } md={ 16 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Project particulars" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <TextArea id="particulars" placeholder="* Particulars / Project Details" onChange={ this.onChange } value={ particulars } rows={ 1 } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="webLink" addonBefore="* Web link" onChange={ this.onChange } value={ webLink } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="amountRequested" addonBefore="* Requested Amount (Rs.)" min="0" onChange={ this.onChange } value={ amountRequested } type="number" />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Status" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="status" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "status") } value={ status }>
                                        {
                                            progressList.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={ val } value={ item }>{ item.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    { status === "GRANTED" || status === "COMPLETED" ?
                        (<>
                            <Col xs={ 24 } md={ 8 }>
                                <Input id="grantNumber" addonBefore="* Granted Number" onChange={ this.onChange } value={ grantNumber } />
                            </Col>

                            <Col xs={ 24 } md={ 8 }>
                                <Input id="amountGranted" addonBefore="* Granted Amount" onChange={ this.onChange } value={ amountGranted } type="number" />
                            </Col>
                            <Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* From" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <DatePicker id="from" placeholder="* From" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "from") } value={ moment(from, dateFormat) } />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>
                            <Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* To" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <DatePicker id="to" placeholder="* to" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "to") } value={ moment(to, dateFormat) } />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>
                        </>) : status === "APPLIED" ?
                            (<Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* Filing Date" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <DatePicker id="filingDate" placeholder="* to" style={ { width: "100%" } } format={ dateFormat } onChange={ this.handleChangeDate.bind(this, "filingDate") } value={ moment(filingDate, dateFormat) } />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>) : ''
                    }


                    { status === "COMPLETED" ?
                        (<Col xs={ 24 } md={ 8 }>
                            <Input id="fundsGenerated" type="number" addonBefore="* Funds Generated" onChange={ this.onChange } value={ fundsGenerated } />
                        </Col>) : '' }


                    { status === "REJECTED" ?
                        (<Col xs={ 24 } md={ 16 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 12 }>
                                        <Input defaultValue="*  State reason for Rejection" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 12 }>
                                        <TextArea id="resaonForRejection" placeholder="* State reason for Rejection" onChange={ this.onChange } value={ resaonForRejection } rows={ 1 } autoSize={ true } />
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col>
                        ) : ''
                    }
                    <Col xs={ 24 } md={ 8 }>
                        <Input id='staffCount' type="number" disabled addonBefore=" * Number of Members" onChange={ this.onChange } value={ staffCount } />
                    </Col>
                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="Members Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>New</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.details.length > 0 ?
                                    (this.state.details.map(
                                        (item, val) => {
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-1">
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ (val + 1) + ". Employee ID" } id={ val } addonAfter={ <SearchOutlined onClick={ this.searchEmployee.bind(this, val, (item.EMPLOYEEID)) } /> } value={ item.EMPLOYEEID } onChange={ this.onChangedetails.bind(this, val, "EMPLOYEEID") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <InputGroup>
                                                            <Row style={ { width: "100%" } }>
                                                                <Col xs={ 24 } md={ 6 }>
                                                                    <Input defaultValue="Role" disabled className="newLabel" />
                                                                </Col>
                                                                <Col xs={ 24 } md={ 18 }>
                                                                    <Select style={ { width: "100%" } } id={ val } onChange={ this.onChangedetailsSelect.bind(this, val, "ROLE") } value={ item.ROLE }>
                                                                        <Option value="" disabled>Select Role</Option>
                                                                        {
                                                                            roleList.map(
                                                                                (newItem, newVal) => {
                                                                                    return (
                                                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ "Name" } id={ val } value={ item.NAME } onChange={ this.onChangedetails.bind(this, val, "NAME") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Dept" } id={ val } value={ item.DEPARTMENT } onChange={ this.onChangedetails.bind(this, val, "DEPARTMENT") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Institution" } id={ val } value={ item.INSTITUTION } onChange={ this.onChangedetails.bind(this, val, "INSTITUTION") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>


                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } >
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Project));