import React, { Component } from 'react';
import { Area } from '@ant-design/charts';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class CurveChart extends Component {

    render() {
        let { data } = this.props.details;
        const config = {
            data,
            forceFit: true,
            padding: 'auto',
            title: {
                visible: true,
                text: this.props.details.title,
                style: {
                    fontSize: 14,
                    fill: '#252525',
                }
            },
            xField: this.props.details.xField,
            yField: this.props.details.yField,
            smooth: true,
            label: {
                visible: true,
                position: 'middle',
                adjustColor: true,
            },
            yAxis: {
                min: 1,
                max: 6,
            }
        };

        return (
            <div>
                <Area {...config} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CurveChart));
