import React, { Component } from 'react'
import { Select, Popconfirm, Descriptions, Table, Button, Space, message, Empty, Tag, Row, Col, Input } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from '../../config';
import axios from 'axios';
import CourseHome from '../faculty/CourseHome'
import { EnterOutlined, LockOutlined, UnlockOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Highlighter from 'react-highlight-words';
const { Option } = Select;

class Report extends Component {
    state = {
        COURSE_CODE: '', COURSE_NAME: '', COURSE_INFO: '',
        loading: false, FacultyList: [],
        courses: [],
        key: 0,
    };

    componentDidMount() {
        this.setState({ loading: true });
        let sendData = {
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/course/getcourses', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    this.setState({ courses: res.data.courses, loading: false })
            });
    }

    getCourse = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "facultylist"
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcoursebatches', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        this.setState({ FacultyList: res.data.result, loading: false })
                    }
                    else
                        this.setState({ FacultyList: [], loading: false })
                });
        }
        else
            message.error("Course Not Selected");
    }

    handleChangeSelect = (label, value) => {
        this.setState({ loading: true });
        this.setState({ [label]: value });
        let data = this.state.courses.find(v => v.COURSE_CODE === value);
        this.setState({ COURSE_CODE: data.COURSE_CODE });
        this.setState({ COURSE_NAME: data.COURSE_NAME });
        this.getCourse(data.COURSE_CODE);
    }

    onClickFacultyBtn = (record) => {
        this.setState({ COURSE_INFO: record, key: 0 });
        this.forceUpdate();
    }

    showBtn = () => {
        this.setState({ key: 1 });
    }

    lockinfobtn = (record) => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        let LOCK = record.LOCK ? 0 : 1;
        this.setState({ loading: true });

        let data = {
            BATCH_ID: record.BATCH_ID,
            LOCK: LOCK,
            key: config.key
        }
        axios.post(config.curriculaserver + '/curricula/admin/course/setlockbybatch', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let FacultyList = this.state.FacultyList;
                    let index = FacultyList.findIndex(x => x.BATCH_ID === record.BATCH_ID);
                    FacultyList[index].LOCK = LOCK;
                    this.setState({ FacultyList: FacultyList, loading: false });
                    message.success(LOCK ? "Batch Locked" : "Batch Unlocked");
                }
                else {
                    message.error("Operation Failed !"); this.setState({ loading: false });
                }
            });
    }

    LockTypeCourses = (type) => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        this.setState({ loading: true });

        let data = {
            LOCK: parseInt(type),
            key: config.key
        }

        axios.post(config.curriculaserver + '/curricula/admin/course/setlockvalueforallcourses', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let FacultyList = [...this.state.FacultyList]
                    FacultyList.forEach((obj) => {
                        obj.LOCK = data.LOCK;
                    });
                    this.setState({ FacultyList: FacultyList, loading: false });
                    type === 0 ? message.success("Successfully All Courses are Unlocked") : message.success("Successfully All Courses are Locked");
                }
                else {
                    message.error("Operation Failed !");
                }
            });
    }

    LockTypeforOneCourse = (type) => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        this.setState({ loading: true });

        let data = {
            COURSE_CODE: this.state.COURSE_CODE,
            LOCK: parseInt(type),
            key: config.key
        }

        axios.post(config.curriculaserver + '/curricula/admin/course/setlockvalueforonecourse', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let FacultyList = [...this.state.FacultyList]
                    FacultyList.forEach((obj) => {
                        obj.LOCK = data.LOCK;
                    });
                    this.setState({ FacultyList: FacultyList, loading: false });
                    type === 0 ? message.success("Unlocked Successfully ") : message.success("Locked Successfully");
                }
                else {
                    message.error("Operation Failed !");
                }
            });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { key, COURSE_INFO, COURSE_CODE, COURSE_NAME, courses } = this.state;
        let headers = [
            { label: "Course Code", key: "COURSE_CODE" },
            { label: "Course Name", key: "COURSE_NAME" },
            { label: "Section ID", key: "BATCH_ID" },
            { label: "Section Name", key: "BATCH_NAME" },
            { label: "Faculty ID", key: "USER_ID" },
            { label: "Faculty Name", key: "FULL_NAME" },
        ];

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (this.state.FacultyList.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'SECTION ID',
                dataIndex: 'BATCH_ID',
                key: 'BATCH_ID',
                ...this.getColumnSearchProps('BATCH_ID'),
            },
            {
                title: 'SECTION NAME',
                dataIndex: 'BATCH_NAME',
                key: 'BATCH_NAME',
                ...this.getColumnSearchProps('BATCH_NAME'),
            },
            {
                title: 'COURSE',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
                ...this.getColumnSearchProps('COURSE_NAME'),
            },
            {
                title: 'COURSE FACILITATOR',
                key: '_id',
                ...this.getColumnSearchProps('USER_ID'),
                render: (text, record) => (
                    record.FULL_NAME + " (" + record.USER_ID + ")"
                )
            },
            {
                title: 'ACTION',
                render: (text, record) => (
                    <Space>
                        <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={this.showBtn}
                        >
                            <Space>
                                <Button icon={<EnterOutlined />} style={{ backgroundColor: "#15898a" }} type="primary" onClick={this.onClickFacultyBtn.bind(this, record)}>View Report </Button>
                            </Space>
                        </Popconfirm>

                        {
                            record.LOCK ? <Button style={{ width: "120%" }} danger type="primary" icon={<LockOutlined />}
                                className={this.props.common.user.ROLE === 'H' ? "hide" : ''}
                                onClick={
                                    this.lockinfobtn.bind(this, record)}> Locked </Button> :
                                <Button type="primary" icon={<UnlockOutlined />}
                                    className={this.props.common.user.ROLE === 'H' ? "hide" : ''}
                                    onClick={this.lockinfobtn.bind(this, record)}> Unlocked  </Button>

                        }

                    </Space>
                ),
                fixed: 'right',
            },
        ];

        return (
            <>
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label="Lock / Unclock Courses" span={2} className={this.props.common.user.ROLE === 'H' ? "hide" : ''}>
                        <Space>
                            <Button style={{ backgroundColor: "#15898a" }} type="primary" icon={<UnlockOutlined />} onClick={this.LockTypeCourses.bind(this, 0)} block> Unlock All Courses  </Button>
                            <Button danger type="primary" icon={<LockOutlined />} onClick={this.LockTypeCourses.bind(this, 1)} block> Lock All Courses  </Button>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Course Selection" span={2}>
                        <Row gutter={[8, 8]} align="start">
                            <Col xs={24} md={16} align="start">
                                <Select id="COURSE_NAME" placeholder="Select Course" style={{ width: "100%" }} onChange={this.handleChangeSelect.bind(this, "COURSE_NAME")} value={this.state.COURSE_CODE} showSearch>
                                    <Option value="" disabled>Choose One</Option>
                                    {
                                        courses.map(
                                            (item, val) => {
                                                return (
                                                    <Option key={val} value={item.COURSE_CODE}>
                                                        <Tag color="blue">{item.COURSE_NAME}</Tag>
                                                        <Tag color="magenta">{item.COURSE_CODE}</Tag>
                                                        <Tag color="green">SEM {item.SEMESTER}</Tag>
                                                    </Option>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} md={4} align="start" className={this.props.common.user.ROLE === 'H' ? "hide" : ''}>
                                <Button disabled={!COURSE_CODE ? true : false} block type="primary" icon={<UnlockOutlined />} onClick={this.LockTypeforOneCourse.bind(this, 0)}> Unlock Course  </Button>
                            </Col>
                            <Col xs={24} md={4} align="start" className={this.props.common.user.ROLE === 'H' ? "hide" : ''}>
                                <Button danger disabled={!COURSE_CODE ? true : false} block type="primary" icon={<LockOutlined />} onClick={this.LockTypeforOneCourse.bind(this, 1)}> Lock Course  </Button>
                            </Col>
                        </Row>
                    </Descriptions.Item>
                    <Descriptions.Item label="Selected Course Code">
                        {COURSE_CODE || <Empty className="mt-0 mb-0" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Descriptions.Item>
                    <Descriptions.Item label="Selected Course Name">
                        {COURSE_NAME || <Empty className="mt-0 mb-0" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Descriptions.Item>
                    {
                        this.state.FacultyList.length ?
                            <Descriptions.Item label="Section Lists" span={2}>
                                <br />
                                <CSVLink data={this.state.FacultyList || []} headers={headers} filename={COURSE_CODE + "-batch-list.csv"}>
                                    <Button size="middle" type="dashed" icon={<DownloadOutlined />}>Download EXCEL</Button>
                                </CSVLink>

                                <Table size="small" bordered className="mt-1" dataSource={this.state.FacultyList} columns={columns} loading={this.state.loading} />
                            </Descriptions.Item> : null
                    }
                </Descriptions>
                {
                    key === 1 ? (
                        <CourseHome COURSE_INFO={COURSE_INFO} COURSE_NAME={COURSE_NAME} COURSE_CODE={COURSE_CODE} />
                    ) : ''
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Report));