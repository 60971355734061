import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Descriptions, Upload, List, message, Row, Col, Input, Tag, Select, Result, Typography } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { InboxOutlined, DownloadOutlined, EnterOutlined } from '@ant-design/icons';
import Template from '../../../doc/batch_user_register.csv'
const { Dragger } = Upload;
const { Option } = Select;
const { Paragraph } = Typography;

class UserCreate extends Component {

    state = {
        key: 0,
        loading: false,
        filename: '', fileStatus: [], fileLoading: false, status: [], SECTION: 0,
        USER_ID: '', FIRST_NAME: '', LAST_NAME: '', DEPARTMENT: '',
        FACULTY_REGISTER_FLAG: JSON.parse(localStorage.getItem('flags')).FACULTY_REGISTER || 1,
        STUDENT_REGISTER_FLAG: JSON.parse(localStorage.getItem('flags')).STUDENT_REGISTER || 1,
    };

    componentDidMount() {
        if (this.props.ROLE === 'TF') this.setState({ SECTION: 1 });
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleChangeSelect = (value) => {
        this.setState({ DEPARTMENT: value.value })
    }

    onClickSingleUser = () => {
        let { USER_ID, FIRST_NAME, LAST_NAME, DEPARTMENT } = this.state;
        let studentIDdigits = config.studentIDdigits;
        if (this.props.ROLE === 'S') {
            if (USER_ID.length !== studentIDdigits) {
                message.error("Username should be " + studentIDdigits + "-digits !");
                return false;
            }
        }
        else if (!USER_ID || !FIRST_NAME || !LAST_NAME || !DEPARTMENT || !this.props.ROLE) {
            message.error("Invalid Entry !");
            return false;
        }

        this.setState({ loading: true });

        let data = {
            info: this.props.common.user,
            user: {
                USER_ID: USER_ID.toLocaleUpperCase(),
                FIRST_NAME: FIRST_NAME.toLocaleUpperCase(),
                LAST_NAME: LAST_NAME.toLocaleUpperCase(),
                DEPARTMENT: DEPARTMENT,
                ROLE: this.props.ROLE,
                SLOT: []
            },
            key: config.key
        }

        axios.post(config.curriculaserver + '/curricula/admin/manageuser/create', data)
            .then(res => {
                if (res.data.Status) {
                    message.info(res.data.msg); this.setState({ loading: false, USER_ID: '', FIRST_NAME: '', LAST_NAME: '', DEPARTMENT: '' });
                }
                else message.error("Operation Failed !");
            });
    }

    render() {
        let { USER_ID, FIRST_NAME, LAST_NAME, DEPARTMENT, status, fileLoading, FACULTY_REGISTER_FLAG, STUDENT_REGISTER_FLAG } = this.state;


        const params = {
            key: config.key,
            ROLE: this.props.ROLE,
            dept: config.departments
        }

        let props = {
            className: 'padding-2 mt-1',
            listType: 'picture',
            accept: ".csv",
            name: 'csv',
            multiple: false,
            action: config.curriculaserver + '/curricula/admin/manageuser/bulk',
            data: params,
            onChange: (info) => {
                //console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        if (info.file.response.Status === 0) { message.error(info.file.response.msg) }
                        else if (info.file.response.Status === 1)
                            return { fileStatus: fileStatus, status: info.file.response.result || [], fileLoading: false };
                    });
                    if (info.file.response.Status !== 0)
                        message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                //console.log(file);
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 2024 / 2024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        let USER = (this.props.ROLE === 'S') ? "Student" : "Faculty";
        let closed = 0;

        if (this.props.ROLE === 'TF') FACULTY_REGISTER_FLAG !== 1 ? closed = 1 : closed = 0;
        else if (this.props.ROLE === 'S') STUDENT_REGISTER_FLAG !== 1 ? closed = 1 : closed = 0;
        return (<>

            {!closed ?
                <div style={{ padding: '0px', minHeight: "100%", marginTop: "20px" }}>
                    <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                        <Descriptions.Item label={"Single " + USER + " Registration"}>
                            <Row gutter={[16, 16]} className="mt-1" >
                                <Col xs={24} md={8}>
                                    <Input id="USER_ID" addonBefore="Username" autoComplete="off" onChange={this.handleChange} value={USER_ID} />
                                </Col>
                                <Col xs={24} md={8}>
                                    <Input disabled addonBefore="Password" autoComplete="off" onChange={this.handleChange} value={USER_ID} />
                                </Col>
                                <Col xs={24} md={8}>
                                    <Input id="FIRST_NAME" addonBefore="First Name" autoComplete="off" onChange={this.handleChange} value={FIRST_NAME} />
                                </Col>
                                <Col xs={24} md={8}>
                                    <Input id="LAST_NAME" addonBefore="Last Name" autoComplete="off" onChange={this.handleChange} value={LAST_NAME} />
                                </Col>
                                <Col xs={24} md={8}>
                                    <Select labelInValue style={{ width: "100%" }} onChange={this.handleChangeSelect} placeholder="Select Department">
                                        {
                                            config.departments.map(
                                                (item, value) => {
                                                    return (item !== "ALL" ? <Option key={value} value={item}>{item}</Option> : '')
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Button icon={<EnterOutlined />}
                                        disabled={!USER_ID || !FIRST_NAME || !LAST_NAME || !DEPARTMENT ? true : false}
                                        block type="primary" onClick={this.onClickSingleUser}> Submit</Button>
                                </Col>
                            </Row>
                        </Descriptions.Item>

                        <Descriptions.Item label={"Registration Template"}>
                            <Button type="primary" icon={<DownloadOutlined />} href={Template}>Download Template</Button>
                        </Descriptions.Item>

                        <Descriptions.Item label={"Departments List"}>
                            <List
                                pagination
                                size="small"
                                dataSource={config.departments}
                                renderItem={item => (
                                    item !== "ALL" ?
                                        <List.Item>
                                            <Paragraph copyable> {item}</Paragraph>
                                        </List.Item> : ''
                                )}
                            />
                        </Descriptions.Item>

                        <Descriptions.Item label={"Bulk " + USER + " Registration"}>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload (MAX Size lessthan 1 MB)</p>
                                <p className="ant-upload-hint">
                                    Support for a single file upload. Strictly prohibit from uploading irrelevant data or other band files
                                </p>
                            </Dragger>
                            <br />
                            <List
                                className={status.length > 0 ? '' : 'hide'}
                                loading={fileLoading} pagination
                                grid={{
                                    gutter: 16,
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 1,
                                    xl: 1,
                                    xxl: 1,
                                }}
                                dataSource={status}
                                renderItem={item => (
                                    <List.Item style={{ padding: "0px 16px" }}>
                                        <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                                    </List.Item>
                                )}
                            />
                        </Descriptions.Item>

                    </Descriptions>
                </div>
                :
                <Result
                    status="error"
                    title={USER + " Registration Closed !"}
                    subTitle={"Please contact " + config.centreName}
                />
            }

        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(UserCreate));