import React, { Component } from 'react'
import { Row, Col, Button, Input, Tooltip, Alert, Descriptions, Spin, Tag } from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
const { Title } = Typography;
const topic = "researchidentity";

class ResearchIdentity extends Component {
    state = {
        orcid: '',
        scopusID: '',
        googlescholarID: '',
        researcherID: '',
        citationsGoogleScholar: 0,
        citationsScopus: 0,
        hIndex: 0,
        gIndex: 0,
        i10Index: 0,
        github: '',
        mendeley: '',
        gitlab: '',
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: false,
        update: false,
        data: [],
        loading: false
    };

    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        this.setState({ loading: true });
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result) Object.entries(res.data.result).map(([key, value]) => this.setState({ [key]: value, update: true }))
            })
        this.setState({ loading: false });
    }


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }


    onClickSubmitBtn = () => {
        let { orcid, scopusID, googlescholarID, key, researcherID, citationsGoogleScholar, citationsScopus, hIndex, gIndex, i10Index, github, gitlab, mendeley, _id } = this.state;
        let errorMessages = [];

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                orcid: orcid,
                mendeley: mendeley,
                scopusID: scopusID,
                googlescholarID: googlescholarID,
                researcherID: researcherID,
                citationsGoogleScholar: citationsGoogleScholar,
                citationsScopus: citationsScopus,
                hIndex: hIndex,
                gIndex: gIndex,
                i10Index: i10Index,
                github: github,
                gitlab: gitlab,
                _id: _id,
                key: key
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/update', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState({ edit: false });
                    await this.setState({ errorMessages: ["success"] });
                } else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    reset = () => {
        this.setState(this.initialState)
    }

    render() {
        let { orcid, scopusID, googlescholarID, researcherID, citationsGoogleScholar, citationsScopus, hIndex, gIndex, i10Index, edit, github, gitlab, mendeley } = this.state;

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Research Identity
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                            <Tag className="ml-1" color="success">Self-Verified</Tag>
                        </Title>

                    </Col>
                </Row>

                <Spin spinning={ this.state.loading }>
                    <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                        <Col xs={ 24 }>
                            <Descriptions
                                bordered
                                column={ { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 } }
                            >

                                <Descriptions.Item label="Scopus Identity">
                                    <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='scopusID' addonBefore="Scopus ID" onChange={ this.onChange } value={ scopusID } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://www.scopus.com/authid/detail.url?authorId=" + scopusID }><Button type="link" block>
                                                { "http://www.scopus.com/authid/detail.url?authorId=" + scopusID }
                                            </Button></a>
                                        </Col>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='hIndex' addonBefore="H - Index" onChange={ this.onChange } value={ hIndex } type="number" />
                                        </Col>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='citationsScopus' addonBefore="Citations" onChange={ this.onChange } value={ citationsScopus } type="number" />
                                        </Col>
                                    </Row>
                                </Descriptions.Item>


                                <Descriptions.Item label="ORCID Identity">
                                    <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='orcid' addonBefore="ORCID" onChange={ this.onChange } value={ orcid } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://www.orcid.org/" + orcid }><Button type="link" block>
                                                { "http://www.orcid.org/" + orcid }
                                            </Button></a>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="Mendeley Identity">
                                    <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='mendeley' addonBefore="Mendeley ID" onChange={ this.onChange } value={ mendeley } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://www.mendeley.com/profiles/" + mendeley }><Button type="link" block>
                                                { "http://www.mendeley.com/profiles/" + mendeley }
                                            </Button></a>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="Google Scholar Identity">
                                    <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id="googlescholarID" addonBefore="Google Scholar ID" onChange={ this.onChange } value={ googlescholarID } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://scholar.google.co.in/citations?user=" + googlescholarID }><Button type="link" block>
                                                { "http://scholar.google.co.in/citations?user=" + googlescholarID }
                                            </Button></a>
                                        </Col>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='gIndex' addonBefore="G - Index" onChange={ this.onChange } value={ gIndex } type="number" />
                                        </Col>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='i10Index' addonBefore="I-10 Index" onChange={ this.onChange } value={ i10Index } type="number" />
                                        </Col>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='citationsGoogleScholar' addonBefore="Citations" onChange={ this.onChange } value={ citationsGoogleScholar } type="number" />
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="Web of Science Identity">
                                    <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id="researcherID" addonBefore="Researcher ID (publons)" onChange={ this.onChange } value={ researcherID } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://www.researcherid.com/rid/" + researcherID }><Button type="link" block>
                                                { "http://www.researcherid.com/rid/" + researcherID }
                                            </Button></a>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="Development Identity">
                                    <Row gutter={ [16, 16] } style={ { margin: 0 } }>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='github' addonBefore="Github ID" onChange={ this.onChange } value={ github } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://github.com/" + github }><Button type="link" block>
                                                { "http://github.com/" + github }
                                            </Button></a>
                                        </Col>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input id='gitlab' addonBefore="Gitlab ID" onChange={ this.onChange } value={ gitlab } />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <a target="_blank" rel="noopener noreferrer" href={ "http://gitlab.com/" + gitlab }><Button type="link" block>
                                                { "http://gitlab.com/" + gitlab }
                                            </Button></a>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                            </Descriptions>
                        </Col>

                    </Row>

                    <Row gutter={ [16, 16] } className="mt-1 mb-1">
                        {
                            this.state.errorMessages.map(
                                (item, val) => {
                                    return (
                                        <Col xs={ 24 } key={ val }>
                                            <Alert
                                                message={ item === "success" ? "Information Updated" : item }
                                                closable showIcon
                                                type={ item === "success" ? "success" : "error" }
                                            />
                                        </Col>
                                    )
                                }
                            )
                        }
                    </Row>

                    <Row gutter={ [16, 16] } className="mt-1 mb-1">
                        <Col xs={ 24 } md={ 4 }>
                            <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } >
                                { this.state.update === false ? "Add" : "Update" }
                            </Button>
                        </Col>

                    </Row>

                </Spin>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ResearchIdentity));