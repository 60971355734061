import React, { Component } from 'react'
import { Popconfirm, Descriptions, Table, Button, Space, message, Input, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from '../../config';
import axios from 'axios';
import CourseHome from '../faculty/CourseHome'
import { DownloadOutlined, EnterOutlined, LockOutlined, UnlockOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Highlighter from 'react-highlight-words';

class Report extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: '',
        loading: false,
        FacultyList: [],
        key: 0,
        temp: 0,
        mutatekey: false
    };

    componentDidMount() {
        this.setState({ loading: true })
        this.getCourse(this.state.COURSE_CODE)
    }

    getCourse = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "facultylist"
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcoursebatches', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        this.setState({ FacultyList: res.data.result, loading: false })
                    }
                    else
                        this.setState({ FacultyList: [], loading: false })
                });
        }
        else
            message.error("Course Not Selected");
    }

    onClickFacultyBtn = (record) => {
        record.COURSE_NAME = this.state.COURSE_NAME;
        record.COURSE_CODE = this.state.COURSE_CODE;
        this.setState({ COURSE_INFO: record, key: 0 });
        this.forceUpdate()
    }

    showBtn = () => {
        this.setState({ key: 1 });
    }

    lockinfobtn = (record) => {
        let c = window.confirm("Are you sure?"); if (c === false) return;
        let LOCK = record.LOCK ? 0 : 1;

        let data = {
            BATCH_ID: record.BATCH_ID,
            LOCK: LOCK,
            key: config.key
        }
        axios.post(config.curriculaserver + '/curricula/admin/course/setlockbybatch', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let FacultyList = this.state.FacultyList;
                    let index = FacultyList.findIndex(x => x.BATCH_ID === record.BATCH_ID);
                    FacultyList[index].LOCK = LOCK;
                    this.setState({ FacultyList: FacultyList, loading: false });
                    message.success(LOCK ? "Batch Locked" : "Batch Unlocked");
                }
                else {
                    message.error("Operation Failed !"); this.setState({ loading: false });
                }
            });

    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { key, COURSE_INFO, COURSE_CODE, COURSE_NAME, FacultyList } = this.state;
        let headers = [
            { label: "Faculty ID", key: "facultyID" },
            { label: "Faculty Name", key: "facultyName" },
            { label: "Department", key: "department" },
            { label: "Section", key: "section" },
        ];

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (FacultyList.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'SECTION ID',
                dataIndex: 'BATCH_ID',
                key: 'BATCH_ID',
                ...this.getColumnSearchProps('BATCH_ID'),
            },
            {
                title: 'SECTION NAME',
                dataIndex: 'BATCH_NAME',
                key: 'BATCH_NAME',
                ...this.getColumnSearchProps('BATCH_NAME'),
            },
            {
                title: 'COURSE',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
            },
            {
                title: 'COURSE FACILITATOR',
                key: '_id',
                ...this.getColumnSearchProps('USER_ID'),
                render: (text, record) => (
                    record.FULL_NAME + " (" + record.USER_ID + ")"
                )
            },
            {
                title: 'ACTION',
                render: (text, record) => (
                    <Space>
                        <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={this.showBtn}
                        >
                            <Space>
                                <Button icon={<EnterOutlined />} type="primary" onClick={this.onClickFacultyBtn.bind(this, record)}>View Report </Button>
                            </Space>
                        </Popconfirm>

                        {this.props.common.user.ROLE === "A" || this.props.common.user.ROLE === "B" ? <>
                            record.LOCK ? <Button style={{ width: "120%" }} danger type="primary" icon={<LockOutlined />} onClick={
                                this.lockinfobtn.bind(this, record)}> Locked </Button> :
                            <Button style={{ backgroundColor: "#15898a" }} type="primary" icon={<UnlockOutlined />} onClick={this.lockinfobtn.bind(this, record)}> Unlocked  </Button>
                        </> : null
                        }

                    </Space>
                ),
                fixed: 'right',
            },
        ];
        return (
            <>
                <Alert message="For Section Lock / Unlock, Kindly Contact Batch Admin." type="info" showIcon />
                <br />
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    className="mb-3"
                >
                    <Descriptions.Item label="Course Code">
                        {COURSE_CODE}
                    </Descriptions.Item>
                    <Descriptions.Item label="Course Name">
                        {COURSE_NAME}
                    </Descriptions.Item>

                    <Descriptions.Item label="Faculty" span={2}>
                        <br />
                        <CSVLink data={this.state.FacultyList} headers={headers} filename={"faculty-list.csv"}>
                            <Button size="middle" type="dashed" icon={<DownloadOutlined />}>Download EXCEL</Button>
                        </CSVLink>

                        <Table className="mt-2" bordered size="small" dataSource={this.state.FacultyList} columns={columns} loading={this.state.loading} />
                    </Descriptions.Item>


                </Descriptions>
                {
                    key === 1 ? (
                        <CourseHome COURSE_INFO={COURSE_INFO} COURSE_NAME={COURSE_NAME} COURSE_CODE={COURSE_CODE} />
                    ) : ''
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Report));