import React, { Component, createRef } from 'react'
import { Row, Col, Card, Result, Typography, Tag } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sunburst from '../../chart/studSunburst';
import Sessions from './Sessions';
import store from "../../redux/store";
import { logoutUser } from '../../redux/actions/commonActions';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';
const { Title, Text } = Typography;

class Home extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        USER_ID: this.props.USER_ID,
        FULL_NAME: this.props.FULL_NAME,
        DEPARTMENT: this.props.DEPARTMENT,
        SLOT: this.props.SLOT,
        key: 0,
        flare: {},
        error: 0
    }

    scrollDiv = createRef();

    componentDidMount() {
        this.getSession();
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true, key: 1 });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getSession();
    }

    getToken = () => {
        if (this.props.common.user.ROLE === 'S') {
            let data = {
                INFO: this.props.common.user,
                KEY: config.key
            }
            axios.post(config.curriculaserver + '/curricula/gettoken', data)
                .then(res => {
                    if (res.data.result) {
                        if (res.data.result.localeCompare(localStorage.jwtToken) !== 0) {
                            return 1;
                        }
                        else return 0;
                    }
                });
        }
    }

    getSession = () => {
        //let acronym = this.props.COURSE_NAME.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')

        let sendData = {
            data: {
                COURSE_CODE: this.props.COURSE_CODE,
                COURSE_NAME: this.props.COURSE_CODE || 'Course',
                USER_ID: this.props.USER_ID,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/student/course/getcircleinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let flare = res.data.flare;
                    if (Object.keys(flare).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else {
                        this.setState({ flare: flare, loading: false, key: 1, error: 0 });
                    }
                }
            });

    }

    onClick = (node) => {
        if (this.getToken()) {
            alert("You have logged in other system !")
            store.dispatch(logoutUser());
        }
        else if (node.data.course) {
            this.setState({ COURSE_CODE: node.data.course.COURSE_CODE, COURSE_NAME: node.data.course.COURSE_NAME, SESSION: node.data.course.SESSION, topic: node.data.name, key: 2 });
            this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        let { COURSE_NAME, COURSE_CODE, SESSION, COURSE_INFO } = this.state;

        return (
            <div>
                {this.state.error === 0 ?
                    (
                        <>

                            <Row justify="center" align="top" className="mt-1 mb-1">
                                <Col xs={24} align="middle">
                                    <Text type="secondary" strong>Learning Course</Text>
                                    <Title level={3}>{this.props.COURSE_NAME + " (" + this.props.COURSE_CODE + ")"}</Title>
                                </Col>
                            </Row>

                            <Card bordered={false} loading={this.state.loading} style={{ boxShadow: "none" }}>
                                <Row justify="center" align="top" className="mt-1 mb-2">
                                    <Col xs={24} align="middle">
                                        <Sunburst
                                            data={this.state.flare}
                                            width="720"
                                            height="720"
                                            count_member="size"
                                            labelFunc={(node) => node.data.name}
                                            onClick={this.onClick}
                                        />
                                    </Col>
                                    <Col xs={24} align="middle" className="mt-5">
                                        <Tag color="#FD5C65" icon={<CloseCircleOutlined />}>Learning Not Started</Tag>
                                        <Tag color="#F7B834" icon={<MinusCircleOutlined />}>Learning In Progress</Tag>
                                        <Tag color="#21BC9B" icon={<CheckCircleOutlined />}>Learning Completed</Tag>
                                    </Col>
                                </Row>
                            </Card>
                            <span ref={this.scrollDiv}>
                                {
                                    this.state.key === 2 ? (
                                        <Sessions COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} SESSION={SESSION} COURSE_INFO={COURSE_INFO}
                                            USER_ID={this.props.USER_ID}
                                            FULL_NAME={this.props.FULL_NAME}
                                            DEPARTMENT={this.props.DEPARTMENT}
                                            SLOT={this.props.SLOT}
                                        />
                                    )
                                        : ''
                                }
                            </span>
                        </>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Home));