import React, { Component } from 'react'
import { List, Button, message, Tag, Upload } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from '../../../config';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import Template from '../../../doc/student_assign_to_section.csv';
const { Dragger } = Upload;

class BulkStudentAssign extends Component {
    state = {
        loading: true,
        filename: '', fileStatus: [], fileLoading: false, status: [],
    }

    componentDidMount() {
        this.setState({ loading: false })
    }

    render() {
        let { status, fileLoading } = this.state;

        const params = {
            key: config.key,
        }

        let props = {
            className: 'padding-2',
            listType: 'picture',
            accept: ".csv",
            name: 'csv',
            multiple: false,
            action: config.curriculaserver + '/curricula/batchcoordinator/bulk/studentassign',
            data: params,
            onChange: (info) => {
                //console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.dept];
                        console.log(info.file.response)
                        if (info.file.response.Status === 0) {
                            message.error(info.file.response.msg);
                            return { status: info.file.response.status || [] };
                        }
                        else if (info.file.response.Status === 1)
                            return { fileStatus: fileStatus, status: info.file.response.result || [], fileLoading: false };
                    });
                    if (info.file.response.Status !== 0)
                        message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 2024 / 2024 < 2;
                if (!isLt1M) {
                    message.error('File must smaller than 2 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (<>
            {
                this.props.common.user.ROLE === 'A' || this.props.common.user.ROLE === 'B' ?
                    <>
                        <Button type="primary" icon={<DownloadOutlined />} href={Template}>Download Template</Button>
                        <br />  <br />
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload (MAX Size lessthan 2 MB)</p>
                            <p className="ant-upload-hint">
                                Support for a single file upload. Strictly prohibit from uploading irrelevant data or other band files
                            </p>
                        </Dragger>
                        <br /> <br />
                        <List
                            className={status.length > 0 ? '' : 'hide'}
                            loading={fileLoading} pagination={true}
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 1,
                                xl: 1,
                                xxl: 1,
                            }}
                            dataSource={status}
                            renderItem={item => (
                                <List.Item style={{ padding: "0px 16px" }}>
                                    <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                                </List.Item>
                            )}
                        />
                    </>
                    : ''
            }
            <br />
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(BulkStudentAssign));