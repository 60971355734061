import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, DatePicker, Popconfirm, Table, Tooltip, Alert, notification, Tag } from 'antd';
import { Upload, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Title, Text } = Typography;
const InputGroup = Input.Group;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const dateFormat = ['DD/MM/YYYY'];
const topic = "cep";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class CEP extends Component {
    state = {
        natureOfEvent: '',
        level: '',
        title: '',
        organizerName: '',
        place: '',
        country: '',
        from: '01/01/2020',
        to: '01/01/2020',
        journalIndexingDetails: '',
        role: '',
        fundsReceived: '',
        sponsoringAgency: '',
        renumerationByAgency: '',
        renumerationByInstitute: '',
        registrationFeeByInstitute: '',
        expensesByIndividual: '',
        TAByInstitute: '',
        DAByInstitute: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        _id: '',
        key: '',
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let data = this.state.data;
        this.setState(this.initialState)
        this.setState({ data: data })
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        if (label === 'fundsReceived' && value === 'NO')
            this.setState({ sponsoringAgency: '', renumerationByAgency: '', feePaidByIndividual: '' })
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickSubmitBtn = () => {
        let { natureOfEvent, level, title, organizerName, place, country, from, to, journalIndexingDetails, role, fundsReceived, sponsoringAgency, renumerationByAgency, renumerationByInstitute, totalParticipants, registrationFeeByInstitute, TAByInstitute, DAByInstitute, expensesByIndividual } = this.state;
        let errorMessages = [];

        if (!natureOfEvent) errorMessages.push("Select Nature of Event");
        else if (natureOfEvent === "CONFERENCE") {
            if (!level) errorMessages.push("Select Level");
            else if (!journalIndexingDetails) errorMessages.push("Select Journal Indexing Details");
        }
        else if (!role) errorMessages.push("Select Role");
        else if (!title) errorMessages.push("Select Title");
        else if (!organizerName) errorMessages.push("Select Organizer Name");
        else if (!place) errorMessages.push("Select Place");
        else if (!country) errorMessages.push("Select Country");
        else if (!from) errorMessages.push("Select From");
        else if (!to) errorMessages.push("Select To");
        //else if (!fundsReceived) errorMessages.push("Invalid Funds Received");
        if (fundsReceived === 'YES') {

            if (role === "ORGANIZED") {
                if (!sponsoringAgency) errorMessages.push("Invalid Sponsoring Agency");
                else if (!renumerationByAgency) errorMessages.push("Invalid Renumeration By Agency");
                else if (isNaN(renumerationByInstitute) || !renumerationByInstitute) errorMessages.push("Invalid Renumeration By Institute");
            }
            else if (role === "ATTENDED") {
                if (!expensesByIndividual) errorMessages.push("Invalid Expenses By Individual");
                else if (!registrationFeeByInstitute) errorMessages.push("Invalid Registration Fee By Institute");
                else if (!TAByInstitute) errorMessages.push("Invalid TA By Institute");
                else if (!DAByInstitute) errorMessages.push("Invalid DA By Institute");
            }
        }
        if (role === "ORGANIZED") if (!totalParticipants) errorMessages.push("Invalid Total Participants");

        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                natureOfEvent: this.state.natureOfEvent,
                level: this.state.level,
                title: this.state.title,
                organizerName: this.state.organizerName,
                place: this.state.place,
                country: this.state.country,
                from: this.state.from,
                to: this.state.to,
                journalIndexingDetails: this.state.journalIndexingDetails,
                role: this.state.role,
                registrationFeeByInstitute: this.state.registrationFeeByInstitute,
                TAByInstitute: this.state.TAByInstitute,
                DAByInstitute: this.state.DAByInstitute,
                fundsReceived: this.state.fundsReceived,
                sponsoringAgency: this.state.sponsoringAgency,
                renumerationByAgency: this.state.renumerationByAgency,
                renumerationByInstitute: this.state.renumerationByInstitute,
                _id: this.state._id,
                key: this.state.key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/professional/cep/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        await axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    this.setState({ edit: false, update: false });
                    await this.setState(this.initialState)
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onClickEditBtn = async (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/cep/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/cep/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/cep/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/professional/cep/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState)
                    openNotificationWithIcon('success');
                    this.setState({ data: res.data.result });
                }
            });
    }

    onAddNatureOfWork = () => {
        let natureOfWork = this.state.natureOfWork;
        natureOfWork.push('0');
        this.setState({ natureOfWork: natureOfWork })
    }

    onRemoveNatureOfWork = () => {
        let natureOfWork = this.state.natureOfWork;
        natureOfWork.pop();
        this.setState({ natureOfWork: natureOfWork })
    }

    onChangeNatureOfWork = (id, e) => {
        var natureOfWork = this.state.natureOfWork;
        natureOfWork[id - 1] = e.target.value;
        this.setState({ natureOfWork: natureOfWork })
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { natureOfEvent, level, title, organizerName, place, country, from, to, journalIndexingDetails, role, fundsReceived, renumerationByAgency, renumerationByInstitute, registrationFeeByInstitute, TAByInstitute, DAByInstitute, data, edit, totalParticipants, expensesByIndividual, sponsoringAgency } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Nature of Event',
                dataIndex: 'natureOfEvent',
                key: 'natureOfEvent',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 40,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                width: 20,
            },
            {
                title: 'Action',
                key: 'certificateNumber',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }> Continuing Education Programs
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Nature of Event" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="natureOfEvent" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "natureOfEvent") } value={ natureOfEvent }>
                                        <Option value="CONFERENCE">Conference</Option>
                                        <Option value="FDP">FDP</Option>
                                        <Option value="WORKSHOP">Workshop</Option>
                                        <Option value="SYMPOSIUM">Symposium</Option>
                                        <Option value="SEMINAR">Seminar</Option>
                                        <Option value="STTP">STTP</Option>
                                        <Option value="REFRESHER">Refresher</Option>
                                        <Option value="GUEST_LECTURE">Guest Lecture</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    {
                        natureOfEvent === "CONFERENCE" ?
                            (
                                <>
                                    <Col xs={ 24 } md={ 12 }>
                                        <Select id='level' style={ { width: "100%" } } placeholder="Level" onChange={ this.handleChangeSelect.bind(this, "level") } value={ level }>
                                            <Option value="NATIONAL">National</Option>
                                            <Option value="INTERNATIONAL">International</Option>
                                        </Select>
                                    </Col>

                                    <Col xs={ 24 } md={ 12 }>
                                        <Input id="journalIndexingDetails" addonBefore="* Journal Indexing Details" onChange={ this.onChange } value={ journalIndexingDetails } />
                                    </Col>
                                </>
                            ) : ''
                    }

                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Role" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="role" style={ { width: "100%" } } placeholder="* Role" onChange={ this.handleChangeSelect.bind(this, "role") } value={ role }>
                                        <Option value="ORGANIZED">Organized</Option>
                                        <Option value="ATTENDED">Attended</Option>
                                        <Option value="KEYNOTE_SPEAKER">Keynote Speaker</Option>
                                        <Option value="RESOURCE_PERSON">Resource Person</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 24 }>
                        <Input id='title' addonBefore=" *  Title" onChange={ this.onChange } value={ title } />
                    </Col>

                    <Col xs={ 24 } md={ 18 }>
                        <Input id='organizerName' addonBefore=" * Organizer Name" onChange={ this.onChange } value={ organizerName } />
                    </Col>

                    <Col xs={ 24 } md={ 6 }>
                        <Input id="place" addonBefore="* Place" onChange={ this.onChange } value={ place } />
                    </Col>

                    <Col xs={ 24 } md={ 6 }>
                        <Input id="country" addonBefore="* Country" onChange={ this.onChange } value={ country } />
                    </Col>

                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* From" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="from" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "from") } value={ moment(from, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* To" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="to" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "to") } value={ moment(to, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 14 }>
                                    <Input defaultValue="Funds Received" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 10 }>
                                    <Select id="fundsReceived" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "fundsReceived") } value={ fundsReceived }>
                                        <Option value="YES">Yes</Option>
                                        <Option value="NO">No</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    { fundsReceived === 'YES' ? <>
                        <Col xs={ 24 } md={ 8 }>
                            <Input id="sponsoringAgency" addonBefore="* Sponsoring Agency" onChange={ this.onChange } value={ sponsoringAgency } />
                        </Col>

                        <Col xs={ 24 } md={ 8 }>
                            <Input id="renumerationByAgency" addonBefore="* Renumeration by Agency" onChange={ this.onChange } value={ renumerationByAgency } type="number" />
                        </Col>


                        { role === "ATTENDED" ?
                            (<><Col xs={ 24 } md={ 8 }>
                                <Input id="registrationFeeByInstitute" addonBefore="* Registration Fee By Institute" onChange={ this.onChange } value={ registrationFeeByInstitute } type="number" />
                            </Col>

                                <Col xs={ 24 } md={ 8 }>
                                    <Input id="TAByInstitute" addonBefore="* TA By Institute" onChange={ this.onChange } value={ TAByInstitute } type="number" />
                                </Col>

                                <Col xs={ 24 } md={ 8 }>
                                    <Input id="DAByInstitute" addonBefore="* DA By Institute" onChange={ this.onChange } value={ DAByInstitute } type="number" />
                                </Col></>)
                            : ''
                        }

                    </> : '' }

                    { role !== "ATTENDED" ?
                        (<Col xs={ 24 } md={ 8 }>
                            <Input id="renumerationByInstitute" addonBefore="* Renumeration by Institute" onChange={ this.onChange } value={ renumerationByInstitute } type="number" />
                        </Col>)
                        : (<Col xs={ 24 } md={ 8 }>
                            <Input id="expensesByIndividual" addonBefore="* Expenses By Individual" onChange={ this.onChange } value={ expensesByIndividual } type="number" />
                        </Col>)
                    }


                    { role === "ORGANIZED" ?
                        (<Col xs={ 24 } md={ 6 }>
                            <Input id="totalParticipants" type="number" addonBefore="* Total Participants" onChange={ this.onChange } value={ totalParticipants } />
                        </Col>) : '' }

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } disabled={ !this.state.edit } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } bordered />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CEP));