import React, { Component } from 'react'
import { Row, Col, Button, Input, Tooltip, Alert, Upload, Tag } from 'antd';
import { message, Card } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { PlusOutlined, EditOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
const { Title } = Typography;
const topic = "social";
const { Meta } = Card;

//const fileList = ["aadhaar", "pan", "passport"];

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('Image must smaller than 500KB! ');
    }
    return isJpgOrPng && isLt2M;
}

class Social extends Component {
    state = {
        aadhaarNumber: '',
        nationality: '',
        countryOfBirth: '',
        gstNumber: '',
        panNumber: '',
        passportNumber: '',
        UAN: this.props.common.user.UAN || 0,
        pfNumber: this.props.common.user.PF_NUMBER || 0,
        rationCardNumber: '',
        npsNumber: '',
        voterID: '',
        drivingLicenseNumber: '',
        errorMessages: [],
        disable: true,
        disable2: true,
        edit: false,
        file: '',
        fileList: [{ name: 'aadhaar', file: '' }, { name: 'pancard', file: '', }, { name: 'signature', file: '' }, { name: 'passport', file: '', }, { name: 'voterid', file: '' }, { name: 'drivinglicence', file: '' }, { name: 'rationcard', file: '' }, { name: 'uancard', file: '' },]
    };
    initialState = this.state;


    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }


    imageExists = (item) => {
        let image_url = config.curriculaserver + "/uploads/student/" + this.props.common.user.USER_ID + "/personal/basic/social/" + item + ".jpg?" + Date.now();
        let http = new XMLHttpRequest();
        http.open('HEAD', image_url, false);
        http.send();
        if (http.status !== 404) return true; else return false;
    }

    componentDidMount = async () => {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    Object.entries(res.data.result).map(([key, value]) => this.setState({ [key]: value, update: true }))
            });


        var fileList = this.state.fileList;
        for (let i = 0; i < fileList.length; i++) {
            data = {
                info: this.props.common.user,
                path: "/personal/basic/social/",
                filename: fileList[i].name + ".jpg",
                key: config.key
            };
            await axios.post(config.curriculaserver + '/curricula/studentcircle/file/getfile', data)
                .then(async res => {
                    if (res.data.Status === 1 && res.data.path) {
                        fileList[i].file = config.curriculaserver + "/" + res.data.path;
                    }
                });

        }
        this.setState({ fileList: fileList, });

        this.imageVerify();
    }

    imageVerify = () => {
        if (this.imageExists("aadhaar") && this.imageExists("pancard") && this.imageExists("signature")) {
            this.setState({ disable2: false });
        } else
            this.setState({ disable2: true });
    }

    onClickSubmitBtn = () => {
        let { aadhaarNumber, nationality, countryOfBirth, panNumber } = this.state;

        let errorMessages = [];

        if (isNaN(aadhaarNumber) || aadhaarNumber.length !== 12) errorMessages.push("Invalid Aadhaar Number");
        else if (!nationality || !/^[a-zA-Z ]+$/i.test(nationality)) errorMessages.push("Invalid Nationality");
        else if (!countryOfBirth || !/^[a-zA-Z ]+$/i.test(countryOfBirth)) errorMessages.push("Invalid Country of Birth");
        else if (panNumber.length !== 10) errorMessages.push("Invalid PAN Number");

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                aadhaarNumber: this.state.aadhaarNumber,
                nationality: this.state.nationality,
                countryOfBirth: this.state.countryOfBirth,
                gstNumber: this.state.gstNumber,
                panNumber: this.state.panNumber,
                passportNumber: this.state.passportNumber,
                UAN: this.state.UAN,
                pfNumber: this.state.pfNumber,
                rationCardNumber: this.state.rationCardNumber,
                npsNumber: this.state.npsNumber,
                voterID: this.state.voterID,
                drivingLicenseNumber: this.state.drivingLicenseNumber,
                uploadedStatus: this.state.uploadedStatus,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/update', data)
            .then(async res => {

                if (res.data.Status === 1) {
                    this.setState({ edit: false });
                    // document.getElementById("edit").classList.add("disableAllitems");
                    await this.setState({ errorMessages: ["success"] });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    handleChangeFile = async (name, index, info) => {
        let fileList = this.state.fileList;
        fileList[index].file = '';
        await this.setState({ fileList: fileList, loading: true });

        await this.setState({ file: info.file.originFileObj, });
    }

    handleChangeUploadBtn = async (name, index) => {
        //let uploadedStatus = this.state.uploadedStatus
        let fileList = this.state.fileList;
        if (this.state.file) {
            let formData = new FormData();
            formData.append('USER_ID', this.props.common.user.USER_ID);
            formData.append('path', "/personal/basic/social/");
            formData.append('filename', (name + ".jpg"));
            formData.append('key', config.key);
            formData.append('file', this.state.file);
            await axios.post(config.curriculaserver + `/curricula/studentcircle/file/photoupload`, formData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        let path = config.curriculaserver + "/" + res.data.path
                        fileList[index].file = path;
                        await this.setState({
                            fileList: fileList,
                            loading: false
                        });
                    }
                    else {
                        message.error('Error uploading file!');
                    }
                })
                .then(async () => {
                    await this.imageVerify();
                    await message.success(`${name} is added`)
                })
        }
        this.imageVerify();

    };

    handleChangeRemoveBtn = (name, index) => {
        let fileList = this.state.fileList;
        let data = {
            info: this.props.common.user,
            path: "/personal/basic/social/",
            filename: name + ".jpg",
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/file/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    if (fileList[index].name === name)
                        fileList[index].file = '';
                    await this.setState({ fileList: fileList });
                }
                else {
                    await message.error(`${name} not available`)
                }
            })
            .then(async () => {
                await this.imageVerify();
                await message.success(`${name} is deleted`)
            })
    }


    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        const uploadButton = (
            <div>
                { this.state.loading ? <LoadingOutlined /> : <PlusOutlined /> }
                <div className="ant-upload-text"> Select File</div>
            </div>
        );

        let { aadhaarNumber, nationality, countryOfBirth, gstNumber, panNumber, passportNumber, UAN, rationCardNumber, npsNumber, voterID, drivingLicenseNumber, pfNumber, edit } = this.state;

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1" >
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Social Information
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }); this.imageVerify(); } } />
                                    </Tooltip>
                                ) : ''
                            }
                            <Tooltip className="ml-1" title={ this.state.docMessage }>
                                <Tag color={ this.getStatusColor(this.state.docStatus) }>
                                    { this.getStatusText(this.state.docStatus) }
                                </Tag>
                            </Tooltip>
                        </Title>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="aadhaarNumber" addonBefore="* Aadhaar Number" onChange={ this.onChange } value={ aadhaarNumber } type="number" />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="panNumber" style={ { width: "100%" } } addonBefore="* PAN Number" value={ panNumber } onChange={ this.onChange } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="nationality" addonBefore="* Nationality" onChange={ this.onChange } value={ nationality } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="countryOfBirth" addonBefore="* Country of Birth" onChange={ this.onChange } value={ countryOfBirth } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="passportNumber" style={ { width: "100%" } } onChange={ this.onChange } addonBefore="Passport Number" value={ passportNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="UAN" disabled style={ { width: "100%" } } addonBefore="UAN Number" value={ UAN } onChange={ this.onChange } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="rationCardNumber" style={ { width: "100%" } } addonBefore="Ration Card Number" value={ rationCardNumber } onChange={ this.onChange } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="npsNumber" style={ { width: "100%" } } addonBefore="National Pension Scheme Number" type="number" value={ npsNumber } onChange={ this.onChange } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="voterID" style={ { width: "100%" } } onChange={ this.onChange } addonBefore="Voter ID" value={ voterID } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="drivingLicenseNumber" style={ { width: "100%" } } onChange={ this.onChange } addonBefore="Driving License Number" value={ drivingLicenseNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="gstNumber" style={ { width: "100%" } } onChange={ this.onChange } addonBefore="GST Number" value={ gstNumber } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="pfNumber" style={ { width: "100%" } } addonBefore="PF Number" value={ pfNumber } disabled />
                    </Col>

                </Row>


                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } htmlType="submit" onClick={ this.onClickSubmitBtn }
                            disabled={ !this.state.edit || this.state.disable2 }>
                            Submit
                        </Button>
                    </Col>
                    <Col xs={ 24 }>
                        <Tag className="overlineTagSmall" color="warning">Note : Aadhaar Card, Pan Card and Signature files are Mandatory to Submit Social Information</Tag>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ "mt-2 social " }>


                    {
                        this.state.fileList.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } md={ 6 } key={ val }>
                                        <Card
                                            hoverable
                                            style={ { width: "90%" } }
                                            bordered={ false }
                                            cover={
                                                <Upload
                                                    style={ { width: "100%", height: "100%" } }
                                                    name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={ false }
                                                    beforeUpload={ beforeUpload }
                                                    onChange={ this.handleChangeFile.bind(this, item.name, val) }
                                                >
                                                    { item.file ? <img src={ `${item.file}?${Date.now()}` } alt="avatar" style={ { width: '100%' } } /> : uploadButton }
                                                </Upload>
                                            }
                                        >
                                            <Meta title={ item.name === "aadhaar" || item.name === "pancard" || item.name === "signature" ?
                                                ("* " + item.name) : item.name
                                            }
                                                description={
                                                    <>
                                                        <Button className="ant-btn-blue" onClick={ this.handleChangeUploadBtn.bind(this, item.name, val) }>
                                                            <UploadOutlined />
                                                        </Button> &nbsp; &nbsp;
                                                        <Button danger onClick={ this.handleChangeRemoveBtn.bind(this, item.name, val) }>
                                                            <DeleteOutlined />
                                                        </Button>
                                                    </>
                                                } />
                                        </Card>
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>


            </div >
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Social));