import React, { Component } from 'react'
import { Table, Spin, Space, Button, Modal, message, DatePicker, Tag, Skeleton, Typography } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import { DownloadOutlined, EnterOutlined, EyeOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { CSVLink } from "react-csv";
momenttimezone.tz.setDefault("Asia/Kolkata");
const dateFormatList = 'DD/MM/YYYY HH:mm:ss';
const { Title } = Typography;

class Attendance extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        allSessions: [], studentList: [],
        isModalVisible: false, selectedRecord: {},
        isDateModalVisable: false,
        selectedRowKeys: [],
        DATETIME: moment(moment().unix()),
        attList: [],
        datesColumn: [],
        csvHeaders: {},
        csv: [],
    }

    async componentDidMount() {
        await this.getSessionInformation();
    }

    getSessionInformation = async () => {
        let sendData = {
            data: {
                COURSE_CODE: this.props.COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        let data = {
            BATCH_ID: this.props.COURSE_INFO.BATCH_ID,
            key: config.key
        }

        let datesArray = [];

        await axios.post(config.curriculaserver + '/curricula/faculty/batch/getdates', data)
            .then(res => {
                if (res.data.Status === 1) {
                    datesArray = res.data.result;
                }
            });

        await axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    let SessionCount, i, unitNo, temp, N = 1, dNo;
                    let allSessions = [];
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else {
                        record.forEach(function (entry) {
                            SessionCount = entry.SESSIONCOUNT;
                            unitNo = entry._id;
                            for (i = 1; i <= SessionCount; i++) {
                                dNo = unitNo * 100 + i;
                                temp = {
                                    BATCH_ID: data.BATCH_ID, UNIT: unitNo, SESSION: i,
                                    DATE_INFO: datesArray[dNo] || 0,
                                    key: N++
                                };
                                allSessions.push(temp);
                            }
                        });
                        this.setState({ allSessions, error: 0 });
                    }
                }
            });


        sendData = {
            COURSE_CODE: this.props.COURSE_INFO.COURSE_CODE,
            BATCH_ID: this.props.COURSE_INFO.BATCH_ID,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/faculty/batch/get', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ studentList: res.data.result || [] });
                }
            });

        await this.getbatchattendance();
    }

    getbatchattendance = async () => {
        this.setState({ enableTable: false });
        let sendData = {
            COURSE_CODE: this.props.COURSE_INFO.COURSE_CODE,
            BATCH_ID: this.props.COURSE_INFO.BATCH_ID,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/admin/report/getbatchattendance', sendData)
            .then(res => {
                let datesColumn = [{
                    title: 'User ID',
                    dataIndex: 'USER_ID',
                    key: 'USER_ID',
                    width: 12, fixed: 'left'
                },
                {
                    title: 'Name', dataIndex: 'NAME',
                    key: 'NAME', width: 20, fixed: 'left'
                }], temp, unit = 0, session = 0, index = -1;
                if (res.data.Status === 1) {
                    Object.keys(res.data.header).forEach(function (key) {
                        index++;
                        unit = parseInt(parseInt(key) / 100);
                        session = parseInt(parseInt(key) - (unit * 100));
                        temp = {
                            title: res.data.header[key] || "-",
                            width: 4,
                            children: [
                                {
                                    title: 'U' + unit + " S" + session,
                                    dataIndex: ['ATTENDANCE', index, 'Present'],
                                    key: ['ATTENDANCE', index, 'Present'],
                                    render: (text) => (
                                        text ? <Tag color="cyan">P</Tag> : <Tag color="error">A</Tag>
                                    ),
                                    width: 4,
                                }],
                        }
                        datesColumn.push(temp);
                    });

                    datesColumn.push({
                        title: 'Percentage',
                        dataIndex: ['PERCENTAGE'],
                        key: ['PERCENTAGE'],
                        sorter: (a, b) => a.PERCENTAGE - b.PERCENTAGE,
                        width: 12, fixed: 'right'
                    });

                    let csvHeaders = [];
                    if (res.data.csv.length && res.data.csv !== null) {
                        Object.keys(res.data.csv[0]).forEach(function (key) {
                            if (key !== '_id' && key !== 'key') csvHeaders.push({ label: key, key: key })
                        });
                    }

                    this.setState({ attList: res.data.result || [], csv: res.data.csv, datesColumn: datesColumn, enableTable: true, csvHeaders: csvHeaders, loading: false });
                }
            });
    }

    getAttendanceJitsi = (record) => {
        let sendData = {
            record: record,
            key: config.key,
        };
        axios.post(config.curriculaserver + '/curricula/faculty/batch/updatejitsiattendance', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                }
            });
    }

    getList = (record) => {
        let sendData = {
            record: record,
            key: config.key,
        };
        axios.post(config.curriculaserver + '/curricula/faculty/batch/getattendance', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ selectedRowKeys: res.data.result, isModalVisible: true, selectedRecord: record });
                    message.success("Operation Done !");
                }
            });
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false, isDateModalVisable: false, })
    }

    handleOk = () => {
        if (this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A') {
            message.info("Operation Not Permitted !");
            return false;
        }
        else {
            let sendData = {
                record: this.state.selectedRecord,
                attendance: this.state.selectedRowKeys,
                key: config.key,
            };
            axios.post(config.curriculaserver + '/curricula/faculty/batch/updateattendance', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        message.success("Information Updated !");
                        this.setState({ isModalVisible: false });
                    }
                });
        }
    }

    onOkdate = (date) => {
        let timeStamp = (moment(date).unix());
        this.setState({ DATETIME: timeStamp });
    }

    updateDate = (record) => {
        this.setState({ selectedRecord: record, isDateModalVisable: true });
    }

    dateModalhandleOk = () => {
        if (this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A') {
            message.info("Operation Not Permitted !");
            return false;
        }
        else {
            if (this.state.DATETIME) {
                let sendData = {
                    record: this.state.selectedRecord,
                    DATETIME: this.state.DATETIME,
                    key: config.key,
                };
                axios.post(config.curriculaserver + '/curricula/faculty/batch/updatedate', sendData)
                    .then(res => {
                        if (res.data.Status === 1) {
                            let allSessions = this.state.allSessions;
                            let index = allSessions.findIndex(x => x.key === sendData.record.key);
                            allSessions[index].DATE_INFO = res.data.result;
                            this.setState({ allSessions: allSessions, isDateModalVisable: false });
                            message.success("Information Updated !");
                        }
                    });
            }
        }
    }

    render() {
        let { allSessions, isModalVisible, studentList, selectedRowKeys, isDateModalVisable, attList, datesColumn, enableTable, csv, csvHeaders, selectedRecord } = this.state;

        let headers = [
            { label: "Unit", key: "UNIT" },
            { label: "Session", key: "SESSION" },
            { label: "Date Conducted", key: "DATE_INFO" },
        ];

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 2,
                render: (text) => (allSessions.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Unit',
                dataIndex: 'UNIT',
                key: 'UNIT',
                fixed: 'left'
            },
            {
                title: 'Session',
                dataIndex: 'SESSION',
                key: 'SESSION',
                fixed: 'left'
            },
            {
                title: 'Action',
                dataIndex: 'key',
                key: 'key',
                render: (text, record) => (
                    <Space>
                        <Button icon={ <DownloadOutlined /> } danger size="small" onClick={ this.getAttendanceJitsi.bind(this, record) }>GET FROM INSTANT LIVE SESSION</Button>
                        <Button icon={ <EyeOutlined /> } type="primary" size="small"
                            onClick={ this.getList.bind(this, record) }
                        >VIEW</Button>
                    </Space>
                ),
                fixed: 'right',
            },
            {
                title: 'Date Conducted',
                dataIndex: 'DATE_INFO',
                key: 'DATE_INFO',
                render: (text, record) => (
                    <Space>
                        { text ? <Tag color="cyan">{ text }</Tag> : <Tag color="red" style={ { width: "118px", textAlign: "center" } }
                        >Empty</Tag> }
                        <Button icon={ <EnterOutlined /> } type="primary" size="small"
                            onClick={ this.updateDate.bind(this, record) }
                            disabled={ this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? true : false }

                        >SET</Button>
                    </Space>
                ),
                fixed: 'right',
            },
        ];

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                //console.log(`selectedRowKeys: ${selectedRowKeys}`);
                this.setState({ selectedRowKeys: selectedRowKeys || [] })
            },
        };

        const StudColumns = [
            {
                title: 'NAME',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
            },
            {
                title: 'DEPARTMENT',
                dataIndex: 'DEPARTMENT',
                key: 'DEPARTMENT',
            },
        ];

        return (
            <Spin spinning={ this.state.loading }>
                <Title className="mt-1 mb-1" level={ 4 }>Session Wise Learning Participation Information :</Title>

                <CSVLink data={ allSessions } headers={ headers } filename={ "date-conducted.csv" } >
                    <Button size="middle"
                        disabled={ allSessions.length === 0 ? true : false }
                        icon={ <DownloadOutlined /> }>Download EXCEL</Button>
                </CSVLink>

                {
                    allSessions.length > 0 ?
                        <Table className="mt-1 mb-4" size="middle" bordered style={ { width: "100%" } } dataSource={ allSessions } columns={ columns } />
                        : ''
                }

                {
                    enableTable ?
                        <>
                            <Title className="mb-1" level={ 4 }>Overall Learning Participation Information :</Title>

                            <CSVLink data={ csv } headers={ csvHeaders } filename={ "full-report.csv" } >
                                <Button size="middle"
                                    disabled={ attList.length === 0 ? true : false }
                                    icon={ <DownloadOutlined /> }>Download EXCEL</Button>
                            </CSVLink>

                            <Button className="ml-1" type="primary" size="middle" icon={ <RedoOutlined /> } onClick={ this.getbatchattendance }>REFRESH</Button>

                            <Table className="mt-1 mb-4 attendance" size="middle" bordered style={ { width: "100%" } }
                                dataSource={ attList } columns={ datesColumn } scroll={ { x: 2000, y: 500 } }
                            />
                        </>
                        : <Skeleton className="mt-2 mb-4" active />
                }

                <Modal title="Edit Learning Participation Information" width={ 620 } visible={ isModalVisible } onOk={ this.handleOk } onCancel={ this.handleCancel }
                >
                    <Tag color="magenta">{ "Unit " + selectedRecord.UNIT }</Tag>
                    <Tag color="green">{ "Session " + selectedRecord.SESSION }</Tag>
                    <Table
                        className="mt-2"
                        bordered
                        size="middle"
                        rowSelection={ {
                            type: "checkbox",
                            ...rowSelection,
                        } }
                        columns={ StudColumns }
                        dataSource={ studentList }
                    />
                </Modal>

                <Modal title="Select Date" visible={ isDateModalVisable } onOk={ this.dateModalhandleOk } onCancel={ this.handleCancel }
                >
                    <DatePicker id="DATETIME" showTime
                        style={ { width: "100%" } }
                        format={ dateFormatList } onChange={ this.onChange } onOk={ this.onOkdate }
                        value={ moment.unix(this.state.DATETIME, dateFormatList) }
                    />
                </Modal>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Attendance));