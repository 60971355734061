import React, { Component } from 'react'
import { Button, message, Select, Result, Row, Col, List, Card, Tag, Badge, Descriptions, Upload, Spin } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import templateSLOSRO from '../../../doc/slo-sro-import-template.csv'
import templateSessionPlan from '../../../doc/spimport-template.csv'
import templateMCQ from '../../../doc/mcqimport-template.csv'
import templateSQ from '../../../doc/sqimport-template.csv'
import templateLQ from '../../../doc/lqimport-template.csv'
import {
    DownloadOutlined, InboxOutlined
} from '@ant-design/icons';
const { Option } = Select;
const { Meta } = Card;
const { Dragger } = Upload;


class CourseUploads extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        error: 0, TYPE: "", template: "", action: "",
        filename: "", fileStatus: [], status: [], fileLoading: false,
        loading: true, UNIT: "", exportloading: false, downloadcsv: false
    }

    componentDidMount() {
        this.getStatus(this.props.COURSE_CODE);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.getStatus(props.COURSE_CODE);
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME });
    }

    getStatus = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    this.setState({ loading: false });
                }
            });
    }

    exportbtn = () => {
        let COURSE_CODE = this.state.COURSE_CODE;
        if (COURSE_CODE) {
            this.setState({ exportloading: true });
            let sendData = {
                unit: this.state.UNIT,
                COURSE_CODE: COURSE_CODE,
                title: this.state.action,
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/export', sendData)
                .then(res => {
                    this.setState({ exportloading: false });
                    if (res.data.Status === 1) {
                        window.open(config.curriculaserver + "/" + res.data.filePath);
                    }
                    else
                        message.error("Operation Failed !");
                }).catch(() => {
                    message.error("Operation Failed ! Contact Admin !");
                    this.setState({ exportloading: false });
                });
        }
    }


    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        let template, action;
        if (value === "SLO_SRO") { template = templateSLOSRO; action = "slosro"; }
        else if (value === "SESSION_PLAN") { template = templateSessionPlan; action = "sp"; }
        else if (value === "MCQ_QUESTIONS") { template = templateMCQ; action = "mcq"; }
        else if (value === "SHORT_QUESTIONS") { template = templateSQ; action = "sq"; }
        else if (value === "LONG_QUESTIONS") { template = templateLQ; action = "lq"; }
        this.setState({ template, action, downloadcsv: false, UNIT: '' })
    }

    handleChangeSelectUnit = (label, value) => {
        this.setState({ [label]: parseInt(value) });
    }

    render() {
        let { COURSE_CODE, COURSE_NAME, template, TYPE, status, action, UNIT } = this.state;
        const params = {
            key: config.key,
            COURSE_CODE: COURSE_CODE
        }
        let props = {
            accept: ".csv",
            name: 'csv',
            disabled: TYPE ? false : true,
            multiple: false,
            action: config.curriculaserver + '/curricula/admin/bulk/' + action,
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        return { fileStatus: fileStatus, status: info.file.response.msg, fileLoading: false };
                    });
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 1024 / 1024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (
            <Card type="inner"
                title={"Import / Export"} className="mt-1 mb-1"
                style={{ width: "100%" }}
                loading={this.state.loading}
                extra={<><Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>}> <br />
                {
                    !this.state.loading && this.state.error === 0 ?
                        (<>
                            <Descriptions
                                bordered
                                column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                                size="small"
                                className="mb-2"
                            >
                                <Descriptions.Item label="Course Code">
                                    {COURSE_CODE}
                                </Descriptions.Item>
                                <Descriptions.Item label="Course Name">
                                    {COURSE_NAME}
                                </Descriptions.Item>
                            </Descriptions>

                            <Descriptions
                                bordered
                                column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                                size="middle"
                                className="mb-2"
                            >
                                <Descriptions.Item label="Type">
                                    <Select id="TYPE" style={{ width: "100%" }} onChange={this.handleChangeSelect.bind(this, "TYPE")} value={this.state.TYPE}>
                                        <Option value="" disabled>Choose Type</Option>
                                        <Option value="SLO_SRO">{"SLO & SRO"}</Option>
                                        <Option value="SESSION_PLAN">Session Plan</Option>
                                        <Option value="MCQ_QUESTIONS">MCQ Questions</Option>
                                        <Option value="SHORT_QUESTIONS">Short Questions</Option>
                                        <Option value="LONG_QUESTIONS">Long Questions</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label="Download Template">
                                    <a href={template} className={!TYPE ? "img-grayscale cnot" : ''}>
                                        <Card
                                            hoverable
                                            bordered={false}
                                            style={{
                                                width: "100%", boxShadow: "none",
                                            }}
                                            cover={<img className={!TYPE ? "img-grayscale" : ''} alt="TEL" height="60" src={require('../../../images/excel.svg').default} />}
                                        >
                                            <Meta style={{ textAlign: "center" }} description={(TYPE ? TYPE + "-" : '') + "template.csv"} />
                                        </Card>
                                    </a>
                                </Descriptions.Item>
                                <Descriptions.Item label="Import">
                                    <Row justify="center" className="mb-1">
                                        <Col span={24}>
                                            <Dragger {...props} className="padding-1">
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                <p className="ant-upload-hint">
                                                    Support for a single file upload. Strictly prohibit from uploading irrelevant data or other band files
                                            </p>
                                            </Dragger>
                                        </Col>
                                    </Row>
                                    <List
                                        style={{ lineHeight: "0.4" }}
                                        split={false}
                                        className="mt-3"
                                        loading={this.state.fileLoading}
                                        size="small"
                                        pagination
                                        dataSource={status}
                                        renderItem={item => <List.Item style={{ padding: "2px 16px" }}>
                                            <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                                        </List.Item>}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label="Export">
                                    <Spin spinning={this.state.exportloading}>
                                        <Row justify="center">
                                            <Col span={18}>
                                                <Select id="UNIT" style={{ width: "98%" }} value={UNIT}
                                                    onChange={this.handleChangeSelectUnit.bind(this, "UNIT")}>
                                                    <Option value="" disabled>Choose Unit</Option>
                                                    <Option value="1">Unit 1</Option>
                                                    <Option value="2">Unit 2</Option>
                                                    <Option value="3">Unit 3</Option>
                                                    <Option value="4">Unit 4</Option>
                                                    <Option value="5">Unit 5</Option>
                                                </Select>
                                            </Col>
                                            <Col span={6} align="middle">
                                                <Button type="primary" block disabled={!UNIT || !action || !COURSE_CODE ? true : false} onClick={this.exportbtn}>
                                                    <DownloadOutlined /> Export Excel
                                                </Button>
                                            </Col>
                                            {
                                                this.state.downloadcsv ?
                                                    <Col span={24} align="middle">
                                                        <br />
                                                        <Button type="primary" icon={<DownloadOutlined />} size={"large"} target="_blank" href={this.state.downloadcsv}>
                                                            Download
                                                        </Button>
                                                    </Col> : ''
                                            }
                                        </Row>
                                    </Spin>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                        ) : ''
                }
                {
                    this.state.error === 1 ? (
                        <Result
                            status="404"
                            title="Course Learning Session is Empty"
                            subTitle="Unit and Session information are empty"
                        />
                    ) : ''
                }
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseUploads));