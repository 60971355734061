import React, { Component } from 'react'
import { Card, Row, Col, Descriptions, Select } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import Sunburst from '../../chart/studSunburst';
const { Option } = Select;

class IndivdualReport extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        users: [],
        flare: {},
        open: false,
        key: 0
    }

    componentDidMount() {
        let sendData = {
            key: config.key,
            COURSE_INFO: this.props.COURSE_INFO,
        };
        axios.post(config.curriculaserver + '/curricula/admin/report/getlo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ users: res.data.result, loading: false });
                }
            });
    }

    handleChangeSelectUsers = (e) => {
        this.setState({ loading: true, open: false, flare: {} });
        let users = [...this.state.users]
        this.setState({ selectedStudent: users[e].FULL_NAME, selectedStudentID: users[e].USER_ID });
        this.getSession(users[e].USER_ID);
    }

    getSession = (selectedStudentID) => {
        //let acronym = this.props.COURSE_NAME.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')

        let sendData = {
            data: {
                COURSE_CODE: this.props.COURSE_CODE,
                COURSE_NAME: this.props.COURSE_CODE || 'Course',
                USER_ID: selectedStudentID,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/student/course/getcircleinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let flare = res.data.flare;
                    if (Object.keys(flare).length === 0) {
                        this.setState({ loading: true })
                    }
                    else {
                        this.setState({ flare: flare, loading: false, open: true });
                    }
                }
            });
    }

    onClick = (node) => {
        //console.log(node.data)
    }

    render() {
        return (
            <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                className="mb-1"
                size="small"
            >
                <Descriptions.Item label="Choose Student" span={2}>
                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Search Student"
                        onChange={this.handleChangeSelectUsers}
                        optionFilterProp="children"
                        loading={this.state.loading}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            this.state.users.length > 0 ? (
                                this.state.users.map(
                                    (item, val) => {
                                        return (
                                            <Option key={val} value={val}>{item.FULL_NAME.toUpperCase() + " (" + item.USER_ID + ")"}</Option>
                                        )
                                    }
                                )
                            )
                                :
                                ''
                        }
                    </Select>
                </Descriptions.Item>

                <Descriptions.Item label="Chart">
                    {this.state.open ?
                        <div>
                            <Card bordered={false} style={{ boxShadow: "none" }}>
                                <Row justify="center" align="top" className="mt-1 mb-2">
                                    <Col xs={24} align="middle">
                                        <Sunburst
                                            data={this.state.flare}
                                            width="720"
                                            height="720"
                                            count_member="size"
                                            labelFunc={(node) => node.data.name}
                                            onClick={this.onClick}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </div> : ''
                    }

                </Descriptions.Item>
            </Descriptions>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(IndivdualReport));