import React, { Component } from 'react'
import { Layout } from 'antd';
import { Row, Col, Card, Statistic, Select, Alert, Input, Empty, Tooltip, Space, Badge, message, PageHeader, Button, Modal, Breadcrumb, Tag, notification, Typography } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CourseHome from './CourseHome'
import StudentRegister from '../admin/ManageUsers/UserCreate'
import ManageUsers from '../admin/ManageUsers/ManageUsers'
import ManageBatch from './ManageBatch'
import {
    PoweroffOutlined, UserOutlined, HomeOutlined, MenuOutlined, AppstoreOutlined, UserSwitchOutlined, VideoCameraOutlined, VideoCameraAddOutlined, TeamOutlined, CheckOutlined, WechatOutlined, QuestionOutlined, DownloadOutlined
} from '@ant-design/icons';
import store from "../../redux/store";
import { logoutUser } from '../../redux/actions/commonActions';
import Jitsi from 'react-jitsi'
import Faq from '../common/Faq';
const { Option } = Select;
const { Content } = Layout;
const { Meta } = Card;
const { Link } = Typography;
const openNotification = () => {
    notification.open({
        message: 'Features in Instant Live Session',
        duration: 20,
        description:
            'HD audio video communications, Meeting access for up to 100 participants at a time,  End-to-End encryption, Start Live Stream, Share a YouTube video, Record videos into Dropbox, Multiple participants can share their screen simultaneously and Remotely control other participants desk',
    });
};

class Home extends Component {

    state = {
        loading: true,
        open: false,
        visible: false,
        visibleprofile: false,
        visiblesettings: false,
        PASSWORD: "",
        CONFIRMPASSWORD: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        MAIL_ID: "",
        MOBILE: "",
        DEPARTMENT: "",
        SLOT: "",
        courses: [],
        key: 0,
        meetingCourse: {},
        meetingCourseIndex: '',
        GENDER_IMAGE: this.props.common.user.GENDER === 'FEMALE' ? 'student3' : 'student5',
        GENDER: this.props.common.user.GENDER,
        api: '',
        enableJitsi: false,
        error: 0, sessions: {}, SESSION_COUNT: 0,
        UNIT: '', SESSION: '',
        currentMeetingID: 0,
        SESSION_NAME: '',
        jitsipass: '',
        batchList: [], batchListLength: 0,
        COURSE_INFO: {},
        jitsiCourses: [],
        groupedBySemester: {},
        //selectedSemester: 1,
        selectedSemester: JSON.parse(localStorage.getItem('flags')).CURRENT_SEMESTER || 0,

    }

    async componentDidMount() {
        let data = {
            USER_ID: this.props.common.user.USER_ID,
            department: this.props.common.user.DEPARTMENT,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/getprofile', data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({
                        USER_ID: res.data.user.USER_ID,
                        FIRST_NAME: res.data.user.FIRST_NAME,
                        LAST_NAME: res.data.user.LAST_NAME,
                        MAIL_ID: res.data.user.MAIL_ID,
                        MOBILE: res.data.user.MOBILE,
                        DEPARTMENT: res.data.user.DEPARTMENT,
                        SLOT: res.data.user.section,
                        GENDER: res.data.user.GENDER || "Male",
                    });
                }
            });

        data = {
            info: this.props.common.user,
            KEY: config.key
        }

        await axios.post(config.curriculaserver + '/curricula/faculty/batch/courses', data)
            .then(async res => {
                let jitsiCourses = [];
                if (res.data.Status === 1) {

                    if (res.data.result2.length > 0) {
                        await res.data.result2.forEach(function (entry) {
                            let temp = {};
                            temp.COURSE_CODE = entry.COURSE_CODE;
                            temp.COURSE_NAME = entry.COURSE_NAME;
                            temp.BATCH_ID = entry.BATCH_ID;
                            temp.BATCH_NAME = entry.BATCH_NAME;
                            temp.key = entry.key;
                            temp._id = entry._id;
                            jitsiCourses.push(temp);
                        });
                    }

                    const groupedBySemester = await res.data.result2.reduce((groups, item) => {
                        const semester = item.SEMESTER;
                        if (!groups[semester]) {
                            groups[semester] = [];
                        }
                        groups[semester].push(item);
                        return groups;
                    }, {});

                    this.setState({ courses: res.data.result1 || [], batchList: res.data.result2 || [], jitsiCourses: jitsiCourses || [], groupedBySemester: groupedBySemester, batchListLength: res.data.result2.length || 0, loading: false });
                }
            });


    }

    getStatus = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else {
                        this.setState({ sessions: record, SESSION_COUNT: record[0].SESSIONCOUNT });
                    }
                    this.setState({ loading: false });
                }
            });
    }

    getToken = () => {
        let data = {
            INFO: this.props.common.user,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/gettoken', data)
            .then(res => {
                if (res.data.result) {
                    if (res.data.result.localeCompare(localStorage.jwtToken) !== 0) {
                        return 1;
                    }
                    else return 0;
                }
            });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT, SESSION: 1 });
        }
    }

    StatisticTemplate(title, value, precision, suffix) {
        return (
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Card size="small" loading={this.state.loading}>
                    <img src={require('../../images/' + suffix + '.svg').default} alt="" className="verifiable-skill-card-logo" />
                    <Statistic
                        title={title}
                        value={value}
                        precision={precision}
                        valueStyle={{ color: '#3f8600' }}
                        groupSeparator=""
                    />
                </Card>
            </Col>);
    }

    handleCancel = async () => {
        await this.setState({ visible: false, visibleprofile: false, visiblesettings: false, visiblemeeting: false });
    };

    showModal = () => { this.setState({ visibleprofile: true }); };
    showModalsettings = () => { this.setState({ visiblesettings: true }); };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleOk = () => {
        if (!this.state.FIRST_NAME || !this.state.LAST_NAME || !this.state.MAIL_ID || !this.state.MOBILE || !this.state.PASSWORD || !this.state.GENDER) {
            message.error('Invalid Entry !');
            return false;
        }

        if (this.state.PASSWORD !== this.state.CONFIRMPASSWORD) {
            message.error('Password Not Matched!');
            return false;
        }

        if (isNaN(this.state.MOBILE)) {
            message.error('Invalid Mobile Number!');
            return false;
        }

        let data = {
            USER_ID: this.props.common.user.USER_ID,
            FIRST_NAME: this.state.FIRST_NAME.toUpperCase(),
            LAST_NAME: this.state.LAST_NAME.toUpperCase(),
            MAIL_ID: this.state.MAIL_ID,
            MOBILE: this.state.MOBILE,
            PASSWORD: this.state.PASSWORD,
            GENDER: this.state.GENDER,
            key: config.key,
        }

        axios.post(config.curriculaserver + '/curricula/profile', data)
            .then(async res => {
                if (res.data.Status === 1) { message.success('Profile Updated ! Pl Logout and Login !', 10); }
                else message.error('Failed Entry !');
                this.setState({ visibleprofile: false });
            });
    };

    courseCard = (course) => {
        return <Badge.Ribbon color={parseInt(course.LP_COUNT) ? "volcano" : "green"} text={parseInt(course.LP_COUNT) ? "Worksheet to verify " + parseInt(course.LP_COUNT || 0) : <Tooltip title="No pending worksheets to verify" color="green"><CheckOutlined /></Tooltip>}><Card
            size="small"
            onClick={this.selectCourse.bind(this, course)}
            hoverable
            style={{ width: "100%" }}
            cover={<img alt="course" height="100%" src={require('../../images/' + this.state.GENDER_IMAGE + '.svg').default} />}
            actions={[
                course.COUNT + " Students",
                course.BATCH_NAME,
                "SEM " + course.SEMESTER
            ]}
        >
            <Meta title={course.COURSE_NAME}
                description={
                    <Space align="start" wrap className='mb-1'>
                        <Tag color="magenta">{course.COURSE_CODE}</Tag>
                        <Badge color="blue" text={"Section ID " + course.BATCH_ID} />
                    </Space>
                }
            />
        </Card></Badge.Ribbon>;
    }

    selectCourse = (course) => {
        if (this.getToken()) {
            alert("You have logged in other system !")
            store.dispatch(logoutUser());
        }
        else
            this.setState({ COURSE_INFO: course, COURSE_CODE: course.COURSE_CODE, COURSE_NAME: course.COURSE_NAME, key: 1 })
    }

    handleChangeSelectMeeting = async (label, value) => {
        let jitsiCourses = this.state.jitsiCourses;
        let temp = jitsiCourses[value];
        await this.setState({ meetingCourse: temp, meetingCourseIndex: value });
        await this.getStatus(jitsiCourses[value].COURSE_CODE);
    }

    InstantMeeting = async () => {
        let meetingCourse = this.state.meetingCourse;
        let roomName = meetingCourse.key;
        meetingCourse.roomName = roomName;
        meetingCourse.REQ = "Laptop / Mobile, Internet";
        meetingCourse.TYPE = "Jitsi";
        meetingCourse.SESSION_TITLE = this.state.SESSION_NAME;
        meetingCourse.ACTIVE = 1;
        meetingCourse.UNIT = (this.state.UNIT);
        meetingCourse.SESSION = parseInt(this.state.SESSION);
        meetingCourse.DURATION = 0;
        meetingCourse.facultyID = this.props.common.user.USER_ID;
        let password = meetingCourse.COURSE_CODE + meetingCourse.facultyID + meetingCourse.UNIT + meetingCourse.SESSION + '';
        meetingCourse.password = password;
        this.setState({ jitsipass: password });

        let sendData = {
            info: this.props.common.user,
            meetingCourse: meetingCourse,
            key: config.key,
        }
        await axios.post(config.curriculaserver + '/curricula/faculty/livesession/createjitsi', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ currentMeetingID: res.data.result._id, enableJitsi: true, });
                    message.success("Session Initated !");
                }
                else
                    message.error("Operation Failed");
            });

    }

    handleAPI = (api) => {
        let password = this.state.jitsipass;
        //api.executeCommand('password', password);


        api.on('passwordRequired', function () {
            api.executeCommand('password', password);
        });


        api.addListener('readyToClose', () => {
            //console.log('call hung up fron add Event Listener Event');
            //alert('call hung up fron add Event Listener Event');
        });

        api.addListener('participantJoined', (e) => {
            message.info("Participant Joined");            //alert(api._myUserID)
        });

        api.addEventListener('participantLeft', function (event) {
            message.info("Participant Left");
        });

        this.setState({ api: api });
    }

    hangup = () => {
        let api = this.state.api;
        let displayName = this.props.common.user.USER_ID + "_" + this.props.common.user.FIRST_NAME;
        var members = api.getParticipantsInfo();
        members.forEach(async function (entry) {
            if (displayName !== entry.displayName) {
                await api.executeCommand('kickParticipant', entry.participantId);
            }
        })
        api.executeCommand('hangup');
        this.setState({ enableJitsi: false });
        let sendData = {
            info: this.props.common.user,
            MEETING_ID: this.state.currentMeetingID,
            key: config.key,
        }
        axios.post(config.curriculaserver + '/curricula/faculty/livesession/hangupjitsi', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ meetingCourse: {}, currentMeetingID: 0, meetingCourseIndex: '', })
                    message.success("Session Closed !");
                }
                else
                    message.error("Operation Failed");
            });
    }

    getAttendance = () => {
        let api = this.state.api;
        let sendData = {
            info: this.props.common.user,
            MEETING_ID: this.state.currentMeetingID,
            data: api.getParticipantsInfo(),
            key: config.key,
        }
        axios.post(config.curriculaserver + '/curricula/faculty/livesession/pickattendance', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    message.success("Information Updated !");
                }
                else
                    message.error("Operation Failed");
            });
    }

    render() {
        let { key, COURSE_INFO, COURSE_CODE, COURSE_NAME, SESSION_NAME, enableJitsi, meetingCourse, UNIT, SESSION, currentMeetingID, meetingCourseIndex, jitsiCourses, selectedSemester, groupedBySemester } = this.state;
        let SC = []; for (let i = 1; i <= this.state.SESSION_COUNT; i++) SC.push(i);
        let toolname = config.toolname + " " + config.totalYear;
        let newBatchList = (selectedSemester !== 0 ? (groupedBySemester[selectedSemester]) : this.state.batchList) || [];
        return (
            <div className="userPageHeader">
                <PageHeader
                    title={<span> ITMS - {toolname}</span>}
                    extra={[
                        // <Button ghost icon={<DownloadOutlined />} href={"https://drive.google.com/file/d/1XFSwpiBYsTHdvB62YEfUXbfzeiheQxW2/view"} target="_blank">Manual</Button>,
                        <Tooltip key="8" placement="left" title={"FAQ"}><Button ghost icon={<QuestionOutlined />} onClick={() => { this.setState({ key: 8 }) }}></Button></Tooltip>,
                        <Tooltip key="7" placement="left" title={"Chat"}><Button ghost icon={<WechatOutlined />} href={"https://chat.google.com"} target="_blank"></Button></Tooltip>,
                        <Tooltip key="6" placement="bottom" title={"Instant Live Session"}><Button ghost icon={<VideoCameraOutlined />} className={enableJitsi ? "pulse" : ' '} onClick={() => { this.setState({ visiblemeeting: true }); openNotification(); }}></Button></Tooltip>,
                        <Tooltip key="3" placement="bottom" title={"Users List"}><Button ghost icon={<AppstoreOutlined />} onClick={() => { this.setState({ key: 3 }) }}></Button></Tooltip>,
                        //  <Tooltip key="2" placement="bottom" title={ "Student Registration" }><Button ghost icon={ <UsergroupAddOutlined /> } onClick={ () => { this.setState({ key: 2 }) } }></Button></Tooltip>,
                        <Tooltip key="5" placement="bottom" title={"Section Assign"}><Button ghost icon={<UserSwitchOutlined />} onClick={() => { this.setState({ key: 5 }) }}></Button></Tooltip>,
                        <Tooltip key="4" placement="bottom" title={"My Profile"}><Button ghost icon={<UserOutlined />} onClick={this.showModal}></Button></Tooltip>,
                        <Button key="1" type="danger" icon={<PoweroffOutlined />} onClick={() => { this.props.history.push('/') }}>Logout</Button>,
                    ]}
                />

                <Layout>
                    {/* {
                        !this.props.common.user.GENDER ?
                            <Alert
                                message={"Mange your information and privacy to make " + config.toolname + " work better for you"}
                                banner
                                closable
                                action={
                                    <Button size="small" type="ghost" onClick={this.showModal}>Update</Button>
                                }
                            /> : ''
                    } */}
                    <Content style={{ padding: '0 30px' }}>

                        <div style={{ padding: '0 24px', minHeight: "100%", marginTop: "35px" }} className="course-card">

                            <Row gutter={[16, 16]}>
                                {this.StatisticTemplate("Username", this.props.common.user.USER_ID, '', "account")}
                                {this.StatisticTemplate("Name", this.props.common.user.FULL_NAME, '', "user")}
                                {this.StatisticTemplate("Sections", this.state.batchListLength, '', "slot")}
                                {this.StatisticTemplate("Role", "COURSE FACILITATOR", '', "faculty2")}
                            </Row>

                            <Card type="inner" className="mt-2 mb-5 student-breadcrump"
                                title={<>
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item style={{ cursor: "pointer", color: "white" }} onClick={() => { this.setState({ COURSE_INFO: {}, key: 0 }) }}><Button> <HomeOutlined className="mr-1" />Home </Button></Breadcrumb.Item>
                                        {
                                            this.state.key === 1 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Section View</Button></Breadcrumb.Item>
                                                //  : this.state.key === 2 ? <Breadcrumb.Item style={ { color: "white" } }> Student Register</Button></Breadcrumb.Item>
                                                : this.state.key === 3 ? <Breadcrumb.Item style={{ color: "white" }}><Button> Users List</Button></Breadcrumb.Item>
                                                    : this.state.key === 5 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Section Assign</Button></Breadcrumb.Item> :
                                                        this.state.key === 8 ? <Breadcrumb.Item style={{ color: "white" }}><Button>FAQ</Button></Breadcrumb.Item> :
                                                            ''
                                        }
                                    </Breadcrumb>
                                </>
                                }
                                style={{ width: "100%" }}
                                loading={this.state.loading}
                                extra={<>
                                    <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>
                                }
                                bodyStyle={{ padding: "1%" }}
                            >

                                <Row type="flex" justify="center" align="middle" className="mb-2 mt-1">
                                    <Col span={24} align="middle">
                                        <Space>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 0, key: 0 }) }}>ALL</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 1, key: 0 }) }}>SEM 1</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 2, key: 0 }) }}>SEM 2</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 3, key: 0 }) }}>SEM 3</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 4, key: 0 }) }}>SEM 4</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 5, key: 0 }) }}>SEM 5</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 6, key: 0 }) }}>SEM 6</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 7, key: 0 }) }}>SEM 7</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 8, key: 0 }) }}>SEM 8</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 9, key: 0 }) }}>SEM 9</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 10, key: 0 }) }}>SEM 10</Button>
                                        </Space>
                                    </Col>
                                </Row>

                                {
                                    key === 0 ?
                                        (
                                            newBatchList.length ?
                                                (
                                                    <Row type="flex" justify="start" align="middle" gutter={[16, 32]} className="padding-1">
                                                        {
                                                            newBatchList.map(
                                                                (item, value) => {
                                                                    return (
                                                                        <Col xs={24} sm={12} md={6} lg={6} key={value}>
                                                                            {this.courseCard(item)}
                                                                        </Col>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Row>
                                                ) :
                                                (
                                                    <Row type="flex" justify="center" align="middle" className="mb-2">
                                                        <Col span={24}>
                                                            <Empty />
                                                        </Col>
                                                    </Row>
                                                )
                                        ) :
                                        key === 1 ? <CourseHome COURSE_INFO={COURSE_INFO} COURSE_NAME={COURSE_NAME} COURSE_CODE={COURSE_CODE} />
                                            : key === 2 ? <StudentRegister ROLE='S' />
                                                : key === 3 ? <ManageUsers />
                                                    : key === 5 ? <ManageBatch />
                                                        : key === 8 ? <Faq />
                                                            : ''
                                }
                            </Card>
                            <Row justify="center" style={{ marginTop: "-60px" }}>
                                <Col span={24} align="middle">
                                    {/* <Tag style={{ fontSize: "1.5em", lineHeight: "23px" }} className='mb-3  padding-2' color="geekblue">
                                        To submit inquiries, kindly utilize the query form found on the DLD website at <Link href="https://dld.srmist.edu.in" target="_blank">
                                            https://dld.srmist.edu.in.
                                        </Link><br /> and you can also access tutorial videos on the website under the Initiatives -&gt; eCurricula section.
                                    </Tag> */}
                                </Col>
                            </Row>
                        </div>

                    </Content>

                </Layout>

                <Modal
                    title="My Profile"
                    visible={this.state.visibleprofile}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} >
                            <Input disabled value={this.state.USER_ID} addonBefore="Username" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.PASSWORD} type="password" addonBefore="New Password" id="PASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.CONFIRMPASSWORD} type="password" addonBefore="Confirm Password" id="CONFIRMPASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.FIRST_NAME} addonBefore="First Name" id="FIRST_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.LAST_NAME} addonBefore="Last Name" id="LAST_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.DEPARTMENT} addonBefore="Department" />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.SLOT} addonBefore="Slot" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MAIL_ID} addonBefore="Official Mail ID" id="MAIL_ID" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MOBILE} addonBefore="Mobile" id="MOBILE" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24}>
                            <Input.Group compact>
                                <Input className="FontBook" style={{ width: '20%', color: "#262626", background: "#FAFAFA" }} defaultValue="Gender" readOnly disabled />

                                <Select id="GENDER" placeholder="Select Gender" style={{ width: "80%" }} onChange={this.handleChangeSelect.bind(this, "GENDER")} value={this.state.GENDER}>
                                    <Option value="" >{'Choose One'}</Option>
                                    <Option value={'MALE'} >{'MALE'}</Option>
                                    <Option value={'FEMALE'}>{'FEMALE'}</Option>
                                </Select>
                            </Input.Group>
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    centered
                    title="Instant Live Session"
                    visible={this.state.visiblemeeting}
                    onCancel={this.handleCancel}
                    width={835}
                    footer={null}
                >
                    <Row gutter={[16, 16]}>

                        <Col xs={24}>
                            <Alert
                                message="Instant Live Session is ready now !"
                                type="info"
                                action={
                                    <Button size="small" type="primary" onClick={openNotification}>
                                        Info
                                    </Button>
                                }
                                closable
                            /></Col>
                        <Col xs={24}>
                            <Select
                                id="meetingCourseIndex" placeholder="Select Course" style={{ width: "100%" }} onChange={this.handleChangeSelectMeeting.bind(this, "meetingCourseIndex")} value={this.state.meetingCourseIndex}
                            >
                                <Option value="" disabled>Choose Course</Option>
                                {
                                    jitsiCourses.map(
                                        (item, val) => {
                                            return (
                                                <Option key={val} value={val}>
                                                    <Tag color="blue">{item.COURSE_NAME}</Tag>
                                                    <Tag color="magenta">{item.COURSE_CODE}</Tag>
                                                    <Tag color="green">{item.BATCH_NAME}</Tag>
                                                </Option>
                                            )
                                        }
                                    )
                                }
                            </Select>
                        </Col>
                        <Col xs={12}>
                            <Input value={this.state.SESSION_NAME} addonBefore="Session Name" id="SESSION_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col span={4}>
                            <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                                disabled={Object.keys(meetingCourse).length === 0 ? true : false}
                                onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                                <Option disabled value="">Unit</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                            </Select>
                        </Col>
                        <Col span={4}>
                            <Select id="SESSION" style={{ width: "100%" }} value={SESSION}
                                disabled={!this.state.UNIT ? true : false}
                                onChange={this.handleChangeSelect.bind(this, "SESSION")}>
                                <Option disabled value="">Session</Option>
                                {
                                    parseInt(this.state.SESSION_COUNT) > 0 ?
                                        (SC.map(
                                            (item) => {
                                                return (
                                                    <Option key={item} value={item}>{item}</Option>
                                                );
                                            }
                                        )
                                        ) : ''
                                }
                            </Select>
                        </Col>
                        <Col xs={4}>
                            <Button icon={<VideoCameraAddOutlined />} disabled={
                                meetingCourseIndex === '' || !SESSION_NAME || !UNIT || !SESSION || currentMeetingID ? true : false} block danger type="primary" onClick={this.InstantMeeting}>CREATE</Button>
                        </Col>
                        {
                            enableJitsi ?
                                <>
                                    <Col xs={24}>
                                        <Jitsi
                                            config={{ prejoinPageEnabled: false, startWithVideoMuted: true, startWithAudioMuted: true, enableWelcomePage: false, enableClosePage: false }}
                                            roomName={meetingCourse.key}
                                            displayName={this.props.common.user.USER_ID + "_" + this.props.common.user.FIRST_NAME}
                                            password={this.state.jitsipass}
                                            onAPILoad={this.handleAPI}
                                            interfaceConfig={{
                                                noSsl: true,
                                                TOOLBAR_BUTTONS: [
                                                    'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
                                                    'fodeviceselection', 'profile', 'chat', 'recording',
                                                    'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                                                    'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
                                                    'tileview', 'select-background', 'download', 'help', 'mute-everyone', 'mute-video-everyone', 'security'
                                                ],

                                            }}
                                        />
                                    </Col>

                                    <Col span={6} offset={14}>
                                        <Button icon={<TeamOutlined />} block type="primary" onClick={this.getAttendance}>PICK ATTENDANCE</Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button icon={<PoweroffOutlined />} block danger type="primary" onClick={this.hangup}>Hangup</Button>
                                    </Col>
                                </>
                                : ''
                        }
                    </Row>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Home));