import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Table, Input, Popconfirm, Space, Tag, Button, Typography, message, Avatar, Alert, Upload, List } from 'antd';
import axios from 'axios';
import { SearchOutlined, EnterOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import config from '../../config';
import AdmissionDetails from '../student/PersonalDetails/AdmissionDetails';
import Highlighter from 'react-highlight-words';
import { CSVLink } from "react-csv";
const { Text } = Typography;
const { Dragger } = Upload;

class AdmissionsEntry extends Component {

    state = {
        loading: false,
        filename: '', fileStatus: [], fileLoading: false, status: [],
        data: [],
        errorMessages: [],
        disable: false,
        searchText: '',
        searchedColumn: '',
        header_list: [
            { label: "User ID", key: "USER_ID" },
            { label: "Name", key: "FULL_NAME" },
            { label: "Register Number", key: "REGISTER_NUMBER" },
            { label: "Department", key: "DEPARTMENT" },
            { label: "Course", key: "MAIL_ID" },
            { label: "Mobile", key: "MOBILE" },
            { label: "Role", key: "ROLE" },
        ],
        key: 0,
        record: 0
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        let data = {
            info: this.props.common.user,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/manageuser/listallfaculty', data)
            .then(res => {
                if (res.data.Status === 1) {
                    if (this.props.common.user.ROLE) {
                        let result = res.data.result.filter(function (el) { return el.ROLE === 'S' });
                        this.setState({ data: result, loading: false });
                    }
                    else this.setState({ data: [], loading: false });
                    message.success('Inofrmation Loaded');
                }
            })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getRole = (text) => {
        let value;
        if (text === "S") value = <Tag style={{ fontSize: "10px" }} color="blue" key={text}>STUDENT</Tag>;
        else if (text === "TF") value = <Tag style={{ fontSize: "10px" }} color="green" key={text}>COURSE FACILITATOR</Tag>;
        else if (text === "C") value = <Tag style={{ fontSize: "10px" }} color="cyan" key={text}>COORDINATOR</Tag>;
        else if (text === "B") value = <Tag style={{ fontSize: "10px" }} color="volcano" key={text}>ORDINATOR</Tag>;
        else if (text === "H") value = <Tag style={{ fontSize: "10px" }} color="purple" key={text}>HOD</Tag>;
        else value = <Tag style={{ fontSize: "10px" }} key={text}>EMPTY</Tag>;
        return value;
    }

    onClickViewBtn = (record) => {
        if (record.USER_ID) {
            this.setState({ record: record })
        }
    }

    onConfirm = (record) => {
        if (record.USER_ID) {
            this.setState({ key: 1 })
        }
    }


    render() {
        let { data, key, status, fileLoading } = this.state;

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'USER_ID',
                width: 1.4,
                render: (text) => (data.findIndex(i => i.USER_ID === text) + 1),
                fixed: 'left',
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                width: 5,
                ...this.getColumnSearchProps('USER_ID'),
                render: (text) => (
                    <Text>{text}</Text>
                ),
                fixed: 'left',
            },
            {
                title: 'NAME',
                dataIndex: 'FIRST_NAME',
                key: 'FIRST_NAME',
                ...this.getColumnSearchProps('FIRST_NAME'),
                render: (text) => (<><Avatar shape="square">{text.charAt(0)}</Avatar> {text.toUpperCase()}</>),
                width: 8,
            },
            {
                title: 'REGISTER NUMBER',
                dataIndex: 'REGISTER_NUMBER',
                key: 'REGISTER_NUMBER',
                width: 3,
                ...this.getColumnSearchProps('REGISTER_NUMBER'),
            },
            {
                title: 'DEPARTMENT',
                dataIndex: 'DEPARTMENT',
                key: 'DEPARTMENT',
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 6,
            },
            {
                title: 'ROLE',
                dataIndex: 'ROLE',
                ...this.getColumnSearchProps('ROLE'),
                render: (text) => (
                    this.getRole(text)
                ),
                width: 4,
            },
            {
                title: 'MAIL',
                dataIndex: 'MAIL_ID',
                ...this.getColumnSearchProps('MAIL_ID'),
                width: 6,
            },
            {
                title: 'MOBILE',
                dataIndex: 'MOBILE',
                ...this.getColumnSearchProps('MOBILE'),
                width: 4,
            },
            {
                title: 'ACTION',
                dataIndex: 'LOCK',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={this.onConfirm.bind(this, record)}
                    >
                        <Button disabled={this.props.common.user.ROLE !== 'A' && this.props.common.user.ROLE !== 'B' && this.props.common.user.ROLE !== 'SA' ? true : false} icon={<EnterOutlined />} type="primary" onClick={this.onClickViewBtn.bind(this, record)}>
                            VIEW
                        </Button>
                    </Popconfirm>
                ),
                fixed: 'right',
                width: 3,
            },
        ];


        const params = {
            key: config.key,
        }

        let props = {
            listType: 'picture',
            accept: ".csv",
            name: 'csv',
            multiple: false,
            action: config.curriculaserver + '/curricula/studentcircle/home/admissiondetails',
            data: params,
            onChange: (info) => {
                //console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        if (info.file.response.Status === 0) { message.error(info.file.response.msg) }
                        else if (info.file.response.Status === 1)
                            return { fileStatus: fileStatus, status: info.file.response.result || [], fileLoading: false };
                    });
                    if (info.file.response.Status !== 0)
                        message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                //console.log(file);
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 2024 / 2024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (
            <div className="card-container padding-1" >
                <Row gutter={[16, 16]} justify="end">
                    <Col xs={24}>
                        <Alert message="For Faculty / Student Registration, Kindly Contact Batch Admin / Coordinator !" type="info" showIcon closable />
                    </Col>
                    <Col xs={24}>
                        <CSVLink data={data} headers={this.state.header_list} filename={"users-report.csv"}>
                            <Button type="dashed" icon={<DownloadOutlined />} size="small"
                                className={this.props.common.user.ROLE !== 'A' && this.props.common.user.ROLE !== 'B' && this.props.common.user.ROLE !== 'SA' ? 'hide' : ''}>
                                Download EXCEL
                            </Button>
                        </CSVLink>
                    </Col>
                    <Col xs={24}>
                        <Table dataSource={data} columns={columns} scroll={{ x: 1400 }} loading={this.state.loading} size="small" />
                    </Col>

                    <Col xs={24}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <h2>Admission Entry Bulk Upload</h2>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single file upload. Strictly prohibit from uploading irrelevant data or other band files
                            </p>
                        </Dragger>
                        <br />
                    </Col>

                    <Col xs={24}>
                        <List
                            className={status.length > 0 ? 'mt-5' : 'mt-5 hide'}
                            loading={fileLoading} pagination
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 1,
                                xl: 1,
                                xxl: 1,
                            }}
                            dataSource={status}
                            renderItem={item => (
                                <List.Item style={{ padding: "0px 16px" }}>
                                    <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                                </List.Item>
                            )}
                        />
                        <br />
                    </Col>

                    {
                        key === 1 ?
                            <Col xs={24}>
                                <AdmissionDetails record={this.state.record} />
                            </Col>
                            : ''
                    }
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(AdmissionsEntry));