import React, { Component } from 'react'
import { Row, Tag, Col, message, Upload, Descriptions, Select, Button } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    DownloadOutlined, InboxOutlined
} from '@ant-design/icons';
const { Option } = Select;
const { Dragger } = Upload;

class ImportExport extends Component {

    state = {
        loading: true,
        courses: [],
        total: 0,
        key: 0,
        filename: "", fileStatus: [], status: [], fileLoading: false,
        COURSE_CODE: '', COURSE_NAME: '',
    }

    componentDidMount() {
        this.setState({ loading: true });
        let sendData = {
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/course/getcourses', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    this.setState({ courses: res.data.courses, loading: false })
            });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ loading: true });
        this.setState({ [label]: value });
        let data = this.state.courses.find(v => v.COURSE_NAME === value);
        this.setState({ COURSE_CODE: data.COURSE_CODE });
    }

    onClickExport = () => {
        let { COURSE_NAME, COURSE_CODE } = this.state;
        let data = {
            COURSE_NAME: COURSE_NAME,
            COURSE_CODE: COURSE_CODE,
            key: config.key
        }
        axios.post(config.curriculaserver + '/curricula/admin/export/course', data)
            .then(res => {
                if (res.data.Status === 1)
                    if (res.data.file) window.open(config.curriculaserver + "/" + res.data.file);
                    else message.error("Operation Failed !");
            });
    }

    render() {
        let { courses } = this.state;
        const params = {
            key: config.key,
        }
        let props = {
            accept: ".json",
            name: 'file',
            multiple: false,
            action: config.curriculaserver + '/curricula/admin/import/course',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        return { fileStatus: fileStatus, status: info.file.response.msg, fileLoading: false };
                    });
                    message.success(`${info.file.name} file uploaded successfully`, 2);
                    message.info(info.file.response.msg, 5);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 1024 / 1024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (
            <div>
                <Descriptions
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    span={5}
                >

                    <Descriptions.Item label="Import Full Course" layout="vertical">
                        <Row justify="center" className="mb-1">
                            <Col span={24}>
                                <Dragger {...props} className="padding-1">
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single file upload. Strictly prohibit from uploading irrelevant data or other band files
                                            </p>
                                </Dragger>
                            </Col>
                        </Row>
                    </Descriptions.Item>

                    <Descriptions.Item label="Export Full Course"  >
                        <Row gutter={[16, 16]}>
                            <Col span={20}>
                                <Select id="COURSE_NAME" placeholder="Select Course" style={{ width: "100%" }} onChange={this.handleChangeSelect.bind(this, "COURSE_NAME")} value={this.state.COURSE_NAME}>
                                    <Option value="" disabled>Choose One</Option>
                                    {
                                        courses.map(
                                            (item, val) => {
                                                return (
                                                    <Option key={val} value={item.COURSE_NAME}>
                                                        <Tag color="blue">{item.COURSE_NAME.toUpperCase()}</Tag>
                                                        <Tag color="magenta">{item.COURSE_CODE}</Tag>
                                                    </Option>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </Col>
                            <Col span={4}>
                                <Button icon={<DownloadOutlined />} block type="primary" onClick={this.onClickExport}>EXPORT</Button>
                            </Col>
                        </Row>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ImportExport));