import React, { Component } from 'react'
import { Row, Col, Result, Table, Button, Spin, Space, message } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Iframe from 'react-iframe'
import {
    SearchOutlined,
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons'

class LearningPractice extends Component {

    state = {
        loading: true,
        bulkLoading: false,
        COURSE_INFO: this.props.COURSE_INFO,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        key: 0,
        numPages: 0,
        pageNumber: 1,
        flare: [],
        SLPFile: '',
        student: [],
        error: 0,
        uniqueID: 0,
        selectedRowKeys: [], selectedRows: []
    }

    componentDidMount() {
        this.getSession();
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true, key: 1 });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getSession();
    }

    getSession = () => {
        let data = {
            USER_ID: this.state.COURSE_INFO.facultyID,
            COURSE_INFO: this.props.COURSE_INFO,
            STATUS: 1,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/learningpractice/get', data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ student: res.data.result, loading: false })
                }
            });
    }

    viewLP = (record) => {
        let link = record.VIEW.replaceAll("view", "preview") || '#';
        this.setState({ SLPFile: link, uniqueID: record._id })
    }

    acceptLP = (record) => {
        let sendData = {
            record: record,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/learningpractice/accept', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let student = [...this.state.student];
                    student = student.filter((el) => { return el._id !== record._id; });
                    this.setState({ student });
                }
            });
    }

    rejectLP = (record) => {
        let sendData = {
            record: record,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/learningpractice/reject', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let student = this.state.student;
                    student = student.filter((el) => { return el._id !== record._id; });
                    this.setState({ student });
                }
            });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    };

    bulkAccept = () => {
        this.setState({ bulkLoading: true });
        let sendData = {
            data: this.state.selectedRows,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/learningpractice/bulkaccept', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.getSession();
                    await message.success("Information Updated !");
                    await this.setState({ bulkLoading: false });
                }
            });
    }


    bulkReject = () => {
        this.setState({ bulkLoading: true });
        let sendData = {
            data: this.state.selectedRows,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/learningpractice/bulkreject', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.getSession();
                    await message.success("Information Updated !");
                    await this.setState({ bulkLoading: false });
                }
            });
    }

    render() {
        let { SLPFile, uniqueID, student, selectedRowKeys, selectedRows } = this.state;

        const rowSelection = {
            selectedRowKeys, selectedRows,
            onChange: this.onSelectChange,
        };

        const hasSelected = selectedRowKeys.length > 0;

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (student.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'NAME',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
                width: 3,
                fixed: 'left',
            },
            {
                title: 'USER_ID',
                dataIndex: 'USER_ID',
                width: 1,
                key: 'USER_ID',
            },
            {
                title: 'UNIT',
                dataIndex: 'UNIT',
                width: 1,
                key: 'UNIT',
            },
            {
                title: 'SESSION',
                dataIndex: 'SESSION',
                key: 'SESSION',
                width: 1,
            },
            {
                title: 'SLO',
                dataIndex: 'SLO',
                key: 'SLO',
                width: 1,
            },
            {
                title: 'ACTION',
                render: (text, record) => (
                    <Space>
                        <Button type="primary" icon={ <SearchOutlined /> } onClick={ this.viewLP.bind(this, record) } />
                        <Button type="primary" icon={ <CheckOutlined /> }
                            onClick={ this.acceptLP.bind(this, record) } disabled={ this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? true : false }
                        />
                        <Button type="danger" icon={ <CloseOutlined /> } onClick={ this.rejectLP.bind(this, record) } disabled={ this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? true : false }
                        />
                    </Space>
                ),
                fixed: 'right',
                width: 2,
            },
        ];
        return (
            <div>
                { this.state.error === 0 ?
                    (
                        <Spin spinning={ this.state.loading }>
                            <Row gutter={ [16, 16] } className="mt-2 mb-2" justify="center" align="start">
                                {
                                    this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? '' :
                                        <Col span={ 24 }>
                                            <Space>
                                                <Button icon={ <CheckOutlined /> } type="primary" disabled={ !hasSelected ? true : false } onClick={ this.bulkAccept }>Accept</Button>
                                                <Button icon={ <CloseOutlined /> } danger type="primary" disabled={ !hasSelected ? true : false } onClick={ this.bulkReject }>RE-Submission</Button>
                                            </Space>
                                        </Col>
                                }
                                <Col span={ 10 }>
                                    <Table
                                        rowSelection={ rowSelection }
                                        bordered style={ { width: "100%" } } dataSource={ this.state.student } columns={ columns } size="middle"
                                        loading={ this.state.bulkLoading || this.state.loading }
                                    />
                                </Col>

                                <Col span={ 14 } align="middle" className="faculty">
                                    <Iframe title={ uniqueID } is="x-frame-bypass" url={ SLPFile }
                                        width="600px"
                                        height="600px"
                                        display="initial"
                                        position="relative"
                                        frameBorder="0" scrolling="no"
                                        styles={ { backgroundColor: "red" } }
                                        allowFullScreen />
                                </Col>
                            </Row>
                        </Spin>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LearningPractice));