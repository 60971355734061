import React, { Component } from 'react'
import { Row, Col, Card, Button, Select, Input, Tooltip, Tag, DatePicker, Empty, Alert, Spin } from 'antd';
import { Upload, message } from 'antd';
import axios from 'axios';
import config from '../../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography, notification, Table, Popconfirm, } from 'antd';
import { UploadOutlined, SearchOutlined, MinusOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { Option } = Select;
const { Title } = Typography;
const dateFormat = 'DD/MM/YYYY';
const topic = "conference";
const subtopic = "conference";
const roleList = ["First Author", "Second Author", "Third Author", "Fourth Author", "Corresponding Author"];

function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
        message.error('Image must smaller than 1MB!');
    }
    return temp && isLt2M;
}


const openNotificationWithIcon = type => {
    notification[type]({
        message: 'success',
        description:
            'Information updated',
    });
};
class Conference extends Component {
    state = {
        type: 'CONFERENCE',
        conferenceTitle: '',
        organizer: '',
        location: '',
        stateName: '',
        country: 'India',
        dateFrom: '15/03/2020',
        dateTo: '16/03/2020',
        indexing: '',
        remarks: '',
        level: "NATIONAL",
        eISBN: '',
        pISBN: '',
        publisherName: '',
        sponsorName: '',
        paperTitle: '',
        paperStatus: 'PUBLISHED',
        year: '02/2020',
        link: '',
        doi: '',
        _id: '',
        key: '',
        authorCount: 0,
        expensesByInstituteRegistration: "0",
        expensesByIndividualRegistration: "0",
        expensesByInstituteTravel: '0',
        expensesByIndividualTravel: '0',
        details: [],
        errorMessages: [],
        disable: false,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: false,
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;
    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            subtopic: subtopic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/getresearchtable', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }
    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    onChange = e => {
        if (e.target.id === "authorCount") {
            var details = [
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME: this.props.common.user.FIRST_NAME + " " + this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: config.institutionName
                }
            ]
            for (let i = 1; i < e.target.value; i++) {
                let newDetail = {
                    _id: details.length + 1,
                    EMPLOYEEID: "",
                    ROLE: "",
                    NAME: "",
                    DEPARTMENT: "",
                    INSTITUTION: ""
                }
                details.push(newDetail);
            }
            this.setState({ details: details })
        }
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    onChange2 = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages2: [], disable2: false });
    };
    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/personal/research/conference/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/personal/research/conference/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/personal/research/conference/' + record.key + ".pdf"

        });
        //File get End
    }


    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickSubmitBtn = () => {
        let { conferenceTitle, organizer, level, paperTitle, authorCount, details } = this.state;
        let errorMessages = [];
        if (!conferenceTitle) errorMessages.push("Invalid conference Title");
        else if (!organizer) errorMessages.push("Invalid organizer / College Name");
        else if (!level) errorMessages.push("Invalid Level");
        else if (!paperTitle) errorMessages.push("Invalid paper Title");
        else if (!authorCount) errorMessages.push("Invalid Author Count");
        else if (details.length < 1) errorMessages.push("Invalid Author Details");
        else if (details.length > 0) {
            for (let i = 0; i < details.length; i++) {
                if (!details[i].EMPLOYEEID || !details[i].ROLE || !details[i].NAME || !details[i].DEPARTMENT || !details[i].INSTITUTION) {
                    errorMessages.push("Invalid Details Information");
                    break;
                }
            };
        }
        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        this.setState({ loading: true });
        let data = {
            key: config.key,
            topic: topic,
            subtopic: subtopic,
            data: {
                type: this.state.type,
                conferenceTitle: this.state.conferenceTitle,
                organizer: this.state.organizer,
                location: this.state.location,
                stateName: this.state.stateName,
                country: this.state.country,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
                indexing: this.state.indexing,
                remarks: this.state.remarks,
                level: this.state.level,
                eISBN: this.state.eISBN,
                pISBN: this.state.pISBN,
                publisherName: this.state.publisherName,
                sponsorName: this.state.sponsorName,
                paperTitle: this.state.paperTitle,
                paperStatus: this.state.paperStatus,
                year: this.state.year,
                link: this.state.link,
                doi: this.state.doi,
                _id: this.state._id,
                key: this.state.key,
                authorCount: this.state.authorCount,
                expensesByInstituteRegistration: this.state.expensesByInstituteRegistration,
                expensesByIndividualRegistration: this.state.expensesByIndividualRegistration,
                expensesByInstituteTravel: this.state.expensesByInstituteTravel,
                expensesByIndividualTravel: this.state.expensesByIndividualTravel,
                details: this.state.details,
                file: this.state.file,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/research/addresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/research/conference/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        await axios.post(config.curriculaserver + `/curricula/studentcircle/file/researchupload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End


                    //update this state.data
                    let dataSet = this.state.data;

                    if (this.state.key === '')
                        dataSet.push(res.data.value)
                    else {
                        for (let i = 0; i < dataSet.length; i++) {
                            let element = dataSet[i];
                            if (element._id === res.data.key) {
                                dataSet[i] = res.data.value;
                                break;
                            };
                        }
                    }
                    await this.setState(this.initialState);

                    await this.setState({ data: dataSet, errorMessages: ["success"], update: false });
                    //update this.state.data ends 

                }
                else if (res.data.Status === 0) { await message.error(res.data.msg); this.setState({ loading: false }); }
            });

        this.setState({ loading: false });

    }

    onClickDeleteBtn = async (record) => {
        let dataSet = this.state.data;
        dataSet = dataSet.filter(element => element.key !== record.key);
        let data = {
            _id: record._id,
            data: record,
            topic: topic,
            subtopic: subtopic,
            key: config.key,
            info: this.props.common.user,
            path: "/personal/research/conference/",
            filename: record.key + ".pdf"
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/deleteresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: dataSet });
                }
            });
    }

    onAdddetails = () => {
        let details = this.state.details;
        let authorCount = this.state.authorCount;
        if (authorCount > 4) return false;
        if (!authorCount) authorCount = 0;
        if (details.length === 0 || authorCount === 0) {
            details = [{
                _id: 1,
                EMPLOYEEID: this.props.common.user.USER_ID,
                ROLE: "",
                NAME: this.props.common.user.FIRST_NAME + " " + this.props.common.user.LAST_NAME,
                DEPARTMENT: this.props.common.user.DEPARTMENT,
                INSTITUTION: config.institutionName
            }]
        }
        else {
            let newDetail = {
                _id: details.length + 1,
                EMPLOYEEID: "",
                ROLE: "",
                NAME: "",
                DEPARTMENT: "",
                INSTITUTION: ""
            };
            details.push(newDetail);
        }
        authorCount++;
        this.setState({ details, authorCount });
    }

    onRemovedetails = () => {

        let details = this.state.details;
        let authorCount = this.state.authorCount;
        if (authorCount === 0 || !authorCount) return false;
        details.pop();
        authorCount--;
        this.setState({ details, authorCount, disable: false })
    }
    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = e.target.value;
        details[id] = data;
        this.setState({ details: details, disable: false })
    }

    onChangedetailsSelect = (id, name, value) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = value;
        details[id] = data;
        this.setState({ details: details, disable: false })
    }
    searchEmployee = (id, employeeid) => {
        let data = {
            employeeId: employeeid,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getusername', data)
            .then(res => {
                if (res.data.result) {
                    let result = res.data.result;
                    var details = this.state.details;
                    let data = details[id];
                    data.NAME = result.employeeName;
                    data.DEPARTMENT = result.department;
                    data.INSTITUTION = result.institution;
                    details[id] = data;
                    this.setState({ details: details, disable: false })
                }
            })
    }


    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { conferenceTitle, data, organizer, sponsorName, publisherName, stateName, location, dateFrom, dateTo, level, country, indexing, remarks, paperTitle, paperStatus, doi, link, authorCount, pISBN, eISBN, expensesByInstituteRegistration, expensesByIndividualRegistration, expensesByInstituteTravel, expensesByIndividualTravel } = this.state;

        let columns = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "Paper Title",
                dataIndex: "paperTitle",
                key: "paperTitle",
                width: 5
            },
            {
                title: "Conference Name",
                dataIndex: "conferenceTitle",
                key: "conferenceTitle",
                width: 3
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 3,
            },
            {
                title: "Action",
                key: "key",
                render: (text, record) => (
                    <>
                        <Button
                            type="primary"
                            icon={ <EditOutlined /> }
                            onClick={ this.onClickEditBtn.bind(this, record, "conference") }
                        >
                            { " " }
                            Edit{ " " }
                        </Button>{ " " }
                        &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record, "conference") }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }>
                                { " " }
                                Delete{ " " }
                            </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: "right",
                width: 3
            }
        ];


        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>
                <Row gutter={ [16, 16] } className="mt-2" >
                    <Col xs={ 24 }>
                        <Title level={ 4 }>Conference Details</Title>
                    </Col>
                </Row>
                <Row id="edit" gutter={ [16, 16] }>
                    <Col xs={ 24 } md={ 24 }>
                        <Input id='conferenceTitle' addonBefore=" * Conference Title" onChange={ this.onChange } value={ conferenceTitle } />
                    </Col>
                    <Col xs={ 24 } md={ 12 }>
                        <Input id='organizer' addonBefore=" * Organizer / College Name" onChange={ this.onChange } value={ organizer } />
                    </Col>
                    <Col xs={ 24 } md={ 12 }>
                        <Input id='publisherName' addonBefore="Publisher Name" onChange={ this.onChange } value={ publisherName } />
                    </Col>
                    <Col xs={ 24 } md={ 12 }>
                        <Input id='sponsorName' addonBefore="Sponsor Name" onChange={ this.onChange } value={ sponsorName } />
                    </Col>
                    <Col xs={ 24 } md={ 12 }>
                        <Input id='indexing' placeholder="Scopus/WOS/SCIE/IEEE/or any other" addonBefore="* Indexing Details" onChange={ this.onChange } value={ indexing } />
                    </Col>
                    <Col xs={ 24 } md={ 8 }>
                        <Input id='location' addonBefore="* Location / City" onChange={ this.onChange } value={ location } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='stateName' addonBefore="* State" onChange={ this.onChange } value={ stateName } />
                    </Col>
                    <Col xs={ 24 } md={ 8 }>
                        <Input id='country' addonBefore="* Country" onChange={ this.onChange } value={ country } />
                    </Col>
                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 10 }>
                                    <Input defaultValue="* Level" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 14 }>
                                    <Select id='level' style={ { width: "100%" } } placeholder="Level" onChange={ this.handleChangeSelect.bind(this, "level") } value={ level } >
                                        <Option value="NATIONAL">National</Option>
                                        <Option value="INTERNATIONAL">International</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* From" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="dateFrom" style={ { width: "100%" } } onChange={ this.handleChangeDate.bind(this, "dateFrom") } format={ dateFormat } value={ moment(dateFrom, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* To" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="dateTo" style={ { width: "100%" } } onChange={ this.handleChangeDate.bind(this, "dateTo") } format={ dateFormat } value={ moment(dateTo.toString(), dateFormat) || '' } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2" >
                    <Col xs={ 24 }>
                        <Title level={ 4 }>Paper Details</Title>
                    </Col>
                </Row>


                <Row id="edit" gutter={ [16, 16] }>
                    <Col xs={ 24 } md={ 18 }>
                        <Input id='paperTitle' addonBefore=" * Title of the Paper" onChange={ this.onChange } value={ paperTitle } />
                    </Col>

                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Paper Status" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <Select id='paperStatus' style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "paperStatus") } value={ paperStatus }>
                                        <Option value="ACCEPTED">Accepted</Option>
                                        <Option value="PUBLISHED">Published</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    { paperStatus === 'PUBLISHED' ? (<>
                        <Col xs={ 24 } md={ 8 }>
                            <Input id='eISBN' addonBefore="eISBN" onChange={ this.onChange } value={ eISBN } />
                        </Col>

                        <Col xs={ 24 } md={ 8 }>
                            <Input id='pISBN' addonBefore="pISBN" onChange={ this.onChange } value={ pISBN } />
                        </Col>

                        <Col xs={ 24 } md={ 8 }>
                            <Input id='doi' addonBefore="DOI Number" onChange={ this.onChange } value={ doi } />
                        </Col>

                        <Col xs={ 24 } md={ 8 }>
                            <Input id='link' addonBefore="Link" onChange={ this.onChange } value={ link } />
                        </Col>
                        <Col xs={ 24 } md={ 8 }>
                            <Input id='remarks' addonBefore="Remarks" onChange={ this.onChange } value={ remarks } />
                        </Col>
                    </>) : ''
                    }

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='authorCount' type="number" disabled addonBefore=" * Number of Authors" onChange={ this.onChange } value={ authorCount } />
                    </Col>
                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="Author Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>New</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.details.length > 0 ?
                                    (this.state.details.map(
                                        (item, val) => {
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-1">
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ (val + 1) + ". Employee ID" } id={ val } addonAfter={ <SearchOutlined onClick={ this.searchEmployee.bind(this, val, (item.EMPLOYEEID)) } /> } value={ item.EMPLOYEEID } onChange={ this.onChangedetails.bind(this, val, "EMPLOYEEID") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <InputGroup>
                                                            <Row style={ { width: "100%" } }>
                                                                <Col xs={ 24 } md={ 6 }>
                                                                    <Input defaultValue="Role" disabled className="newLabel" />
                                                                </Col>
                                                                <Col xs={ 24 } md={ 18 }>
                                                                    <Select style={ { width: "100%" } } id={ val } onChange={ this.onChangedetailsSelect.bind(this, val, "ROLE") } value={ item.ROLE }>
                                                                        <Option value="" disabled>Select Role</Option>
                                                                        {
                                                                            roleList.map(
                                                                                (newItem, newVal) => {
                                                                                    return (
                                                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ "Name" } id={ val } value={ item.NAME } onChange={ this.onChangedetails.bind(this, val, "NAME") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Department" } id={ val } value={ item.DEPARTMENT } onChange={ this.onChangedetails.bind(this, val, "DEPARTMENT") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Institution" } id={ val } value={ item.INSTITUTION } onChange={ this.onChangedetails.bind(this, val, "INSTITUTION") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>

                </Row>


                <Row gutter={ [16, 16] } className="mt-2" >
                    <Col xs={ 24 }>
                        <Title level={ 4 }>Remuneration Details</Title>
                    </Col>
                </Row>

                <Row id="edit" gutter={ [16, 16] }>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id='expensesByInstituteRegistration' addonBefore="  Remuneration By Institute for Registration" onChange={ this.onChange } value={ expensesByInstituteRegistration } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id='expensesByInstituteTravel' addonBefore="  Remuneration By Institute for Travel" onChange={ this.onChange } value={ expensesByInstituteTravel } />
                    </Col>
                    <Col xs={ 24 } md={ 12 }>
                        <Input id='expensesByIndividualRegistration' addonBefore=" Remuneration By Individual for Registration" onChange={ this.onChange } value={ expensesByIndividualRegistration } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id='expensesByIndividualTravel' addonBefore=" Remuneration By Individual for Travel" onChange={ this.onChange } value={ expensesByIndividualTravel } />
                    </Col>
                </Row>

                <Spin spinning={ this.state.loading } delay={ 500 }>
                    <Row gutter={ [16, 16] } className="mt-1 mb-1">
                        <Col xs={ 24 }>
                            <Upload { ...props }>
                                <Button>
                                    <UploadOutlined /> Select File
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                </Spin>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            showIcon closable
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } >
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>
                <Row gutter={ [16, 16] } className="mt-3">
                    <Col xs={ 24 }>
                        <Title level={ 4 }>Conference Publications</Title>
                    </Col>

                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } bordered
                            size="middle" columns={ columns } scroll={ { x: 1000 } } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common,
    publicationType: state.string,
});
export default withRouter(connect(mapStateToProps, {})(Conference));