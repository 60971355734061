import React, { Component } from 'react'
import { Card, Collapse, Typography, Pagination, List, Avatar, Button, Popover, Progress, Space, Badge, message, Descriptions, Row, Col, Checkbox, Tag, Empty, Radio, Tooltip, Switch } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactPlayer from 'react-player'
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import CKEditor from 'ckeditor4-react';
import Iframe from 'react-iframe';
import { RedoOutlined, DownloadOutlined, CaretDownOutlined } from '@ant-design/icons';
CKEditor.editorUrl = process.env.PUBLIC_URL + '/ckeditor4/ckeditor.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const { Panel } = Collapse;
const { Text } = Typography;
const CKEditorconfig = {
    mathJaxLib: config.mathJaxLib,
    allowedContent: true,
    removePlugins: 'ckeditor_wiris',
    toolbarGroups: [],
};

class Sessions extends Component {

    state = {
        loading: true,
        ListLoading: false,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        SESSION: this.props.SESSION,
        completionStatus: this.props.status,
        numPages1: 0,
        pageNumber1: 1,
        numPages2: 0,
        pageNumber2: 1,
        SLCFile1: "",
        SLCFile2: "",
        video: [],
        duration: {},
        plan: {},
        SO: '',
        MCQvalue: [],
        MCQ: [], SQ: [], LQ: [],
        attemptedMCQ: [],
        resultMCQ: 0,
        resultStatus: 0,
        folderid: "",
        filename: "",
        fileStatus: [],
        previousMCQ: 0,
        practiceStatus: {},
        assessment: [],
        apps: [],
        error: 0
    }

    componentDidMount() {
        let { SESSION } = this.state;
        let UNIT = Math.round(SESSION / 100);
        let SESSIONNO = parseInt(SESSION) - (UNIT * 100);
        this.setState({ UNIT: UNIT, SESSIONNO: SESSIONNO });
        this.appsGet(this.state.COURSE_CODE)
        this.getSLCFile(SESSION, 1);
        this.getSLCFile(SESSION, 2);
        this.getQuestion(SESSION, 1);
    }

    UNSAFE_componentWillReceiveProps(props) {
        //console.log(this.props)
        this.setState({ loading: true, key: 1 });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME, SESSION: props.SESSION, completionStatus: props.status })
        let SESSION = parseInt(props.SESSION);
        let UNIT = Math.round(SESSION / 100);
        let SESSIONNO = parseInt(SESSION) - (UNIT * 100);
        this.setState({ UNIT: UNIT, SESSIONNO: SESSIONNO });
        this.appsGet(props.COURSE_CODE)
        this.getSLCFile(SESSION, 1);
        this.getSLCFile(SESSION, 2);
        this.getQuestion(SESSION, 1);
    }

    appsGet = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "apps"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let apps = res.data.selectedCourseData || [];
                    if (Object.keys(apps).length === 0) {
                        this.setState({ apps: [] });
                    }
                    else {
                        const results = config.apps.filter((el) => apps.some((el1) => el1 === el.label));
                        this.setState({ apps: results });
                    }
                }
            });
    }

    getQuestion = (SESSION, type) => {
        if (type === 1) this.setState({ loading: true })
        else if (type === 2) this.setState({ ListLoading: true })

        let sendData = {
            COURSE_INFO: this.props.COURSE_INFO,
            USER_ID: this.props.common.user.USER_ID,
            FULL_NAME: this.props.common.user.FULL_NAME,
            DEPARTMENT: this.props.common.user.DEPARTMENT,
            SLOT: this.props.common.user.SLOT,
            SESSION: SESSION,
            key: config.key,
            MCQ: 5,
            SQ: 2,
            LQ: 1
        };

        axios.post(config.curriculaserver + '/curricula/student/session/getquestions', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ MCQ: res.data.mcq || [], SQ: res.data.sq || [], LQ: res.data.lq || [], video: res.data.video || [], SO: res.data.slo || {}, duration: res.data.sp.duration || {}, plan: res.data.sp.plan || {}, loading: false, assessment: res.data.assessment || [], MCQvalue: [], resultMCQ: 0, resultStatus: 0, attemptedMCQ: [], ListLoading: false, });
                }
            });
    }

    onDocumentLoadSuccess1 = (document) => {
        const { numPages } = document;
        this.setState({
            numPages1: numPages,
        });
    };

    onDocumentLoadSuccess2 = (document) => {
        const { numPages } = document;
        this.setState({
            numPages2: numPages,
        });
    };

    onPageChange1 = (pageNumber1) => {
        this.setState({ pageNumber1 });
    }

    onPageChange2 = (pageNumber2) => {
        this.setState({ pageNumber2 });
    }

    getSLCFile = (SESSION, SLO) => {
        let filename = parseInt(SESSION) + "" + SLO + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/slc";

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error("Learning Content Empty !");
                else if (res.data.Status === 1) {
                    if (res.data.result.path) {
                        this.setState({ [`SLCFile${SLO}`]: res.data.result.path })
                    }
                }
            });
    }

    FunctionCheckboxGroup = (id) => {
        let P, html = [];
        let CDIO = ["", "Conceive", "Design", "Implement", "Operate"]
        for (let i = 1; i <= 4; i++) {
            P = "P" + i;
            html.push(<Checkbox key={P + id} disabled value={CDIO[i]}>{CDIO[i]}</Checkbox>);
        }
        return html;
    }

    getSP = (NAME, N, T, P, DEFAULT_TIME) => {
        let html = <Descriptions.Item label={NAME} span={2}>
            <Space>
                <Tag color="blue" style={{ padding: T < 10 ? "0px 18px" : "0px 14px" }}> {T || DEFAULT_TIME} Min.</Tag>
                <Checkbox.Group className="ml-5"
                    style={{ width: '100%' }} value={P}>
                    {this.FunctionCheckboxGroup(N)}
                </Checkbox.Group>
            </Space>
        </Descriptions.Item>
        return html;
    }

    reloadQuestion = (event) => {
        event.stopPropagation();
        this.getQuestion(this.state.SESSION, 2);
        this.setState({
            MCQvalue: [], resultMCQ: 0, resultStatus: 0
        });
    }

    displayQuestion = (Q, qNo) => {
        qNo = 0;
        let html =
            Q.length > 0 ?
                (<List
                    itemLayout="horizontal"
                    loading={this.state.ListLoading}
                    dataSource={Q}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={index + 1 + "."}
                                title={
                                    <CKEditor
                                        type="inline"
                                        data={item.QUESTION_DESC}
                                        config={CKEditorconfig}
                                        readOnly={true}
                                    />
                                }
                                description={<CKEditor
                                    type="inline"
                                    data={item.ANSWER}
                                    config={CKEditorconfig}
                                    readOnly={true}
                                />}
                            />
                            <Tag>Level {item.LEVEL}</Tag>
                        </List.Item>
                    )}
                />) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No questions found !" />
        return html;
    }

    displayMCQ = (Q) => {
        let html =
            Q.length > 0 ?
                (<><List
                    itemLayout="horizontal"
                    loading={this.state.ListLoading}
                    dataSource={Q}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={index + 1 + "."}
                                title={
                                    <CKEditor
                                        type="inline"
                                        data={item.QUESTION_DESC}
                                        config={CKEditorconfig}
                                        readOnly={true}
                                    />
                                }
                                description={
                                    <Radio.Group onChange={this.onChangeMCQ.bind(this, index, item.ANSWER)} value={this.state.MCQvalue[index]} disabled={this.state.resultStatus === 1 ? true : false}>
                                        <Space className="mcq">
                                            <Space><Radio value={1} style={{ top: "-8px" }}>
                                            </Radio><CKEditor type="inline" data={item.OPT_1} config={CKEditorconfig} readOnly={true} /></Space>
                                            <Space><Radio value={2} style={{ top: "-8px" }}></Radio><CKEditor type="inline" data={item.OPT_2} config={CKEditorconfig} readOnly={true} /></Space>
                                            <Space><Radio value={3} style={{ top: "-8px" }}></Radio><CKEditor type="inline" data={item.OPT_3} config={CKEditorconfig} readOnly={true} /></Space>
                                            <Space><Radio value={4} style={{ top: "-8px" }}></Radio><CKEditor type="inline" data={item.OPT_4} config={CKEditorconfig} readOnly={true} /></Space>
                                        </Space>
                                    </Radio.Group>
                                }
                            />
                            <Tag>Level {item.LEVEL}</Tag>
                        </List.Item>
                    )}
                />
                    <Row justify="end" className="mb-2">
                        <Col span={4}>
                            <Button className="mb-2" style={{ width: "100%" }} type="primary" disabled={this.state.attemptedMCQ.length >= this.state.MCQ.length ? false : true} onClick={this.mcqButtonClick}>Submission</Button>
                        </Col>
                        <Col span={24} className="mt-2" align="center">

                            <Space>
                                <Descriptions
                                    bordered
                                    size="small"
                                    column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                                >
                                    {this.state.resultStatus === 1 ?
                                        <Descriptions.Item label="Current Score">
                                            <Progress type="circle" percent={(this.state.resultMCQ / this.state.MCQ.length) * 100} width={80} />
                                        </Descriptions.Item>
                                        : ''
                                    }
                                </Descriptions>
                            </Space>

                        </Col>
                    </Row>
                </>) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No questions found !" />
        return html;
    }

    mcqButtonClick = () => {
        this.setState({
            previousMCQ: (this.state.resultMCQ / this.state.MCQ.length) * 100, resultStatus: 1, attemptedMCQ: []
        });
    }

    DownloadSLCFile = (SESSION) => {
        window.open(config.curriculaserver + "/uploads/data/coordinator/" + this.props.COURSE_CODE + "/slc/" + SESSION + ".pdf");
    }

    onChangeMCQ = (index, ANSWER, e) => {
        let MCQvalue = [...this.state.MCQvalue];
        MCQvalue[index] = e.target.value;
        let attemptedMCQ = [...this.state.attemptedMCQ];
        attemptedMCQ.push(e.target.value);
        this.setState({ MCQvalue, attemptedMCQ });
        let resultMCQ = this.state.resultMCQ + 1;
        if (ANSWER === e.target.value)
            this.setState({ resultMCQ: resultMCQ });
    };

    setButtonLinkDOCX = (SESSION, SLO) => {
        let filename = parseInt(SESSION) + "" + SLO + ".docx";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/slp";
        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    setButtonLinkPDF = (SESSION, SLO) => {
        let filename = parseInt(SESSION) + "" + SLO + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/slppdf";
        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }


    getSessionLearningPractice = (SESSION, SLO) => {
        let html =
            <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            >
                <Descriptions.Item label="Worksheet">
                    <Space>
                        <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLinkDOCX.bind(this, SESSION, SLO)}> Download DOCX</Button>
                        <Button danger type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLinkPDF.bind(this, SESSION, SLO)}> Download PDF</Button>
                    </Space>
                </Descriptions.Item>
            </Descriptions>;
        return html;
    }

    getLearningMaterial = (UNIT) => {
        let filename = (UNIT * 100) + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/unit";

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    onChangeSwitch = (checked) => {
        let sendData = {
            BATCH_ID: this.props.COURSE_INFO.BATCH_ID,
            STATUS: checked ? 1 : 0,
            SESSION_ID: this.state.SESSION,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/batch/setsessioncompletionstatus', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState({ completionStatus: 1 })
                    message.success("Information Updated !");
                } else
                    message.error("Error ! Contact Admin !");
            });
    }


    render() {
        let { UNIT, SESSIONNO, SESSION, pageNumber1, numPages1, pageNumber2, numPages2, video, duration, plan, SO, assessment, completionStatus } = this.state;

        return (
            <Card type="inner" bordered={false} bodyStyle={{ padding: 0 }}
                title={<> Learning Unit : {UNIT} & Session : {SESSIONNO} </>} className="mt-1 mb-2" style={{ width: "100%" }}
                loading={this.state.loading}
                extra={<>
                    <span className="apps">
                        <Popover class="apps" placement="bottom"
                            content={
                                <Row justify="start" className="capps" >
                                    {
                                        this.state.apps.map(
                                            (item, val) => {
                                                return (
                                                    <Col key={val} span={8} align="center" className="capp">
                                                        <Space direction="vertical" size="middle" align="center">
                                                            <a href={item.link} target="_blank" rel="noopener noreferrer"><Avatar shape="square" size="large" src={require('../../images/folder.svg').default} />
                                                                <p>{item.label}</p>
                                                            </a>
                                                        </Space>
                                                    </Col>
                                                )
                                            }
                                        )
                                    }
                                </Row>
                            }
                        >
                            <Tag>Learning APP <CaretDownOutlined /></Tag>
                        </Popover>
                    </span>
                    <Badge color="red" style={{ marginLeft: "20px" }} /><Badge color="yellow" /><Badge color="green" />
                </>
                }>
                <br />

                <Descriptions
                    bordered
                    size="small"
                    column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                >

                    <Descriptions.Item label="Status" className='hide'>
                        <Switch checkedChildren="Completed" unCheckedChildren="Not Completed" onChange={this.onChangeSwitch} checked={completionStatus ? true : false} disabled={this.props.common.user.ROLE !== 'TF' ? true : false} />
                    </Descriptions.Item>

                    <Descriptions.Item label="SLR / SLO">
                        <Collapse defaultActiveKey={['0']} ghost>
                            <Panel header="Session Learning Rationale / Outcomes" key="1">
                                {Object.keys(SO).length !== 0 ?
                                    (<>
                                        <span className="ml-3">
                                            <Text>SLR : The purpose of learning this session is to:</Text>
                                            <ol className="ml-3">
                                                <li>{SO.SRO1}</li>
                                                <li>{SO.SRO2}</li>
                                            </ol>
                                        </span>
                                        <span className="ml-3">
                                            <Text>SLO : At the end of this session, you will able to:</Text>
                                            <ol className="ml-3">
                                                <li>{SO.SLO1}</li>
                                                <li>{SO.SLO2}</li>
                                            </ol>
                                        </span>
                                    </>) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                            <Panel header="Learning Plan" key="2">
                                {
                                    Object.keys(duration).length !== 0 && Object.keys(plan).length !== 0 ?
                                        (<Descriptions
                                            bordered
                                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                            className="mb-1 mt-2"
                                            size="middle"
                                        >
                                            {this.getSP("Recap / Introduction", 1, duration.T1, plan.P1, 5)}
                                            {this.getSP("SLO 1 Content", 2, duration.T2, plan.P2, 10)}
                                            {this.getSP("SLO 1 Practice", 3, duration.T3, plan.P3, 10)}
                                            {this.getSP("SLO 2 Content", 4, duration.T4, plan.P4, 10)}
                                            {this.getSP("SLO 2 Practice", 5, duration.T5, plan.P5, 10)}
                                            {this.getSP("Summary", 6, duration.T6, plan.P6, 5)}
                                        </Descriptions>) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="SLO 1">
                        <Collapse ghost accordion>

                            <Panel header="Learning Content" key="1" extra={<Tag onClick={this.DownloadSLCFile.bind(this, (SESSION + "1"))} icon={<DownloadOutlined />} color="blue">Download</Tag>}>
                                {
                                    this.state.SLCFile1 ?
                                        (<Row gutter={[16, 16]} justify="center">
                                            <Col span={20} align="center" className="mb-2">
                                                <Document
                                                    style={{ zoom: "90%" }}
                                                    file={this.state.SLCFile1}
                                                    onLoadSuccess={this.onDocumentLoadSuccess1}
                                                >
                                                    <Page pageNumber={pageNumber1} width={750} />
                                                </Document>
                                            </Col>
                                            {
                                                <Col span={24} align="center">
                                                    <Pagination className="ml-3" simple current={pageNumber1} total={numPages1 * 10} onChange={this.onPageChange1} />
                                                </Col>
                                            }
                                        </Row>) : ''
                                }
                            </Panel>

                            {video[SESSION + "1"] ?
                                (<Panel header="Learning Content (Video)" key="2">
                                    <Row gutter={[16, 16]} justify="center">
                                        <Col span={24} align="center" className="mb-1">
                                            <ReactPlayer url={video[SESSION + "1"]} />
                                        </Col>
                                    </Row>
                                </Panel>) : ''
                            }

                            {
                                SESSION ?
                                    (<Panel header="Learning Practice" key="4">
                                        {
                                            assessment.includes(SESSION + "1") ? (
                                                <Row justify="center" align="middle">
                                                    <Col span={24} align="center">
                                                        <Iframe is="x-frame-bypass" url={config.curriculaserver + "/uploads/data/coordinator/" + this.state.COURSE_CODE + "/assessment/" + SESSION + "1/index.html?"}
                                                            sandbox="allow-forms allow-pointer-lock allow-popups allow-scripts allow-top-navigation"
                                                            width="600px"
                                                            height="600px"
                                                            display="initial"
                                                            position="relative"
                                                            frameBorder="0" scrolling="no"
                                                            styles={{ backgroundColor: "red" }}
                                                            allowFullScreen />
                                                    </Col>
                                                </Row>)
                                                : this.getSessionLearningPractice(SESSION, 1)
                                        }
                                    </Panel>) : ''
                            }
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="SLO 2">
                        <Collapse ghost accordion>
                            <Panel header="Learning Content" key="1" extra={<Tag onClick={this.DownloadSLCFile.bind(this, (SESSION + "2"))} icon={<DownloadOutlined />} color="blue">Download</Tag>}>                                {
                                this.state.SLCFile2 ?
                                    (<Row gutter={[16, 16]} justify="center">
                                        <Col span={20} align="center" className="mb-2">
                                            <Document
                                                style={{ zoom: "90%" }}
                                                file={this.state.SLCFile2}
                                                onLoadSuccess={this.onDocumentLoadSuccess2}
                                            >
                                                <Page pageNumber={pageNumber2} width={750} />
                                            </Document>
                                        </Col>
                                        <Col span={24} align="center">
                                            <Pagination className="ml-3" simple current={pageNumber2} total={numPages2 * 10} onChange={this.onPageChange2} />
                                        </Col>
                                    </Row>) : ''
                            }
                            </Panel>

                            {video[SESSION + "2"] ?
                                (<Panel header="Learning Content (Video)" key="2">
                                    <Row gutter={[16, 16]} justify="center">
                                        <Col span={24} align="center" className="mb-1">
                                            <ReactPlayer url={video[SESSION + "2"]} />
                                        </Col>
                                    </Row>
                                </Panel>) : ''
                            }

                            {
                                SESSION ?
                                    (<Panel header="Learning Practice" key="3">
                                        {
                                            assessment.includes(SESSION + "2") ? (
                                                <Row justify="center" align="middle">
                                                    <Col span={24} align="center">
                                                        <Iframe is="x-frame-bypass" url={config.curriculaserver + "/uploads/data/coordinator/" + this.state.COURSE_CODE + "/assessment/" + SESSION + "2/index.html?"}
                                                            sandbox="allow-forms allow-pointer-lock allow-popups allow-scripts allow-top-navigation"
                                                            width="600px"
                                                            height="600px"
                                                            display="initial"
                                                            position="relative"
                                                            frameBorder="0" scrolling="no"
                                                            styles={{ backgroundColor: "red" }}
                                                            allowFullScreen />
                                                    </Col>
                                                </Row>)
                                                : this.getSessionLearningPractice(SESSION, 2)
                                        }
                                    </Panel>) : ''
                            }
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="Assessment">
                        <Collapse accordion ghost>
                            <Panel header="Multiple Choice Questions" key="1" extra={
                                <Space>
                                    <Tooltip title="Reload Questions"><RedoOutlined onClick={this.reloadQuestion} /></Tooltip>
                                </Space>
                            }>
                                {
                                    this.state.MCQ.length > 0 ?
                                        (this.displayMCQ(this.state.MCQ)) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                            <Panel header="Short Questions" key="2" extra={<Tooltip title="Reload Questions"><RedoOutlined onClick={this.reloadQuestion} /></Tooltip>}>
                                {
                                    this.state.SQ.length > 0 ?
                                        (this.displayQuestion(this.state.SQ, 0)) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                            <Panel header="Long Questions" key="3" extra={<Tooltip title="Reload Questions"><RedoOutlined onClick={this.reloadQuestion} /></Tooltip>}>
                                {
                                    this.state.LQ.length > 0 ?
                                        (this.displayQuestion(this.state.LQ, 0)) :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Panel>
                        </Collapse>
                    </Descriptions.Item>

                    <Descriptions.Item label="Learning Material">
                        <Button icon={<DownloadOutlined />} className="mt-1 mb-1 ml-1" onClick={this.getLearningMaterial.bind(this, (UNIT))}>
                            Download  Unit {UNIT} Learning Material
                        </Button>
                    </Descriptions.Item>
                </Descriptions>
                <br />
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Sessions));