import React, { Component } from 'react'
import { Layout } from 'antd';
import { Row, Col, Card, Statistic, Input, Breadcrumb, Badge, message, PageHeader, Button, Menu, Dropdown, Modal, Tooltip } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    CaretDownOutlined, PoweroffOutlined, MenuOutlined, HomeOutlined, WechatOutlined
} from '@ant-design/icons';
import ManageUsers from '../admin/ManageUsers/ManageUsers'
import CreateCourses from '../admin/CourseInformation/CreateCourses';
import Report from '../admin/Report';
import store from "../../redux/store";
import { logoutUser } from '../../redux/actions/commonActions';
import CourseStatusAll from '../admin/CourseInformation/CourseStatusAll'
import FullReport from '../admin/NewFeatures/FullReport';
import LearnerProgress from '../admin/NewFeatures/LearnerProgress';
import Faq from '../common/Faq';
const { Content } = Layout;

class Home extends Component {

    state = {
        open: false,
        visible: false,
        visibleprofile: false,
        PASSWORD: "",
        CONFIRMPASSWORD: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        MAIL_ID: "",
        MOBILE: "",
        DEPARTMENT: "",
        SLOT: "",
        key: 0,
        Courses: 0, Student: 0, Faculty: 0
    }

    async componentDidMount() {

        let data = {
            USER_ID: this.props.common.user.USER_ID,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/getprofile', data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({
                        USER_ID: res.data.user.USER_ID,
                        FIRST_NAME: res.data.user.FIRST_NAME,
                        LAST_NAME: res.data.user.LAST_NAME,
                        MAIL_ID: res.data.user.MAIL_ID,
                        MOBILE: res.data.user.MOBILE,
                        DEPARTMENT: res.data.user.DEPARTMENT,
                        SLOT: res.data.user.SLOT,
                    });
                }
            });

        await axios.post(config.curriculaserver + '/curricula/admin/home/getdata', data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({
                        Courses: res.data.course,
                        Student: res.data.student,
                        Faculty: res.data.faculty,
                    });
                }
            });

    }

    getToken = () => {
        let data = {
            INFO: this.props.common.user,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/gettoken', data)
            .then(res => {
                if (res.data.result) {
                    if (res.data.result.localeCompare(localStorage.jwtToken) !== 0) {
                        return 1;
                    }
                    else return 0;
                }
            });
    }

    handleMenuClick = (e) => {
        if (this.getToken()) {
            alert("You have logged in other system !")
            store.dispatch(logoutUser());
        }
        else this.setState({ key: parseInt(e.key) })
    }

    menu = (
        <Menu onClick={this.handleMenuClick}>
            { /*   <Menu.Item key="1">Users List</Menu.Item> */}
            <Menu.Item key="2">Detailed Reports</Menu.Item>
            <Menu.Item key="3">Course Content Status</Menu.Item>
            <Menu.Item key="4">Overall Report</Menu.Item>
            <Menu.Item key="5">Student Progress</Menu.Item>
            <Menu.Item key="6">FAQ</Menu.Item>
        </Menu>
    );

    StatisticTemplate(title, value, precision, suffix) {
        return (
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Card size="small">
                    <img src={require('../../images/' + suffix + '.svg').default} alt="" className="verifiable-skill-card-logo" />
                    <Statistic
                        title={title}
                        value={value}
                        precision={precision}
                        valueStyle={{ color: '#3f8600' }}
                    />
                </Card>
            </Col>);
    }

    handleCancel = () => {
        this.setState({ visible: false, visibleprofile: false });
    };

    showModal = () => { this.setState({ visibleprofile: true }); };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleOk = () => {
        if (!this.state.FIRST_NAME || !this.state.LAST_NAME || !this.state.MAIL_ID || !this.state.MOBILE || !this.state.PASSWORD) {
            message.error('Invalid Entry !');
            return false;
        }

        if (this.state.PASSWORD !== this.state.CONFIRMPASSWORD) {
            message.error('Password Not Matched!');
            return false;
        }

        if (isNaN(this.state.MOBILE)) {
            message.error('Invalid Mobile Number!');
            return false;
        }

        let data = {
            USER_ID: this.props.common.user.USER_ID,
            FIRST_NAME: this.state.FIRST_NAME.toUpperCase(),
            LAST_NAME: this.state.LAST_NAME.toUpperCase(),
            MAIL_ID: this.state.MAIL_ID,
            MOBILE: this.state.MOBILE,
            PASSWORD: this.state.PASSWORD,
            key: config.key,
        }

        axios.post(config.curriculaserver + '/curricula/profile', data)
            .then(async res => {
                if (res.data.Status === 1)
                    message.success('Profile Updated !');
                else
                    message.error('Failed Entry !');
                this.setState({ visibleprofile: false });
            });
    };


    render() {
        let { Student, Faculty } = this.state;
        let toolname = config.toolname + " " + config.totalYear;
        let role = this.props.common.user.USER_ID === "dean" ? "DEAN" : "REPORT USER ADMIN";

        return (
            <div className="userPageHeader">
                <PageHeader
                    title={<span>
                       ITMS - {toolname}
                    </span>}
                    extra={[
                        <Tooltip key="5" placement="left" title={"Chat"}><Button ghost icon={<WechatOutlined />} href={"https://chat.google.com"} target="_blank"></Button></Tooltip>,
                        <Dropdown key="2" overlay={this.menu}>
                            <Button type="primary">
                                More Actions <CaretDownOutlined />
                            </Button>
                        </Dropdown>,
                        <Button key="1" type="danger" icon={<PoweroffOutlined />} onClick={() => { this.props.history.push('/') }}>Logout</Button>,
                    ]}
                />

                <Layout>
                    <Content style={{ padding: '0 30px' }}>
                        <div style={{ padding: '0 24px', minHeight: "100%", marginTop: "35px" }} className="course-card">

                            <Row gutter={[16, 16]}>
                                {this.StatisticTemplate("Role", role, '', "account")}
                                {this.StatisticTemplate("Year", config.totalYear, '', "course")}
                                {this.StatisticTemplate("Students", Student, '', "user")}
                                {this.StatisticTemplate("Faculty", Faculty, '', "faculty2")}
                            </Row>

                            <Card type="inner" className="student-breadcrump mt-2 mb-2" style={{ width: "100%" }}
                                bodyStyle={{ padding: "1.5%" }}
                                title={<>
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item style={{ cursor: "pointer", color: "white" }} onClick={() => { this.setState({ key: 0 }) }}><Button><HomeOutlined className="mr-1" />Home</Button></Breadcrumb.Item>
                                        {
                                            this.state.key === 1 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Users List</Button></Breadcrumb.Item>
                                                : this.state.key === 2 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Reports</Button></Breadcrumb.Item>
                                                    : this.state.key === 3 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Course Content Status</Button></Breadcrumb.Item>
                                                        : this.state.key === 4 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Overall Report</Button></Breadcrumb.Item>
                                                            : this.state.key === 5 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Student Progress</Button></Breadcrumb.Item>
                                                                : this.state.key === 6 ? <Breadcrumb.Item style={{ color: "white" }}><Button>FAQ</Button></Breadcrumb.Item>
                                                                    : ''}
                                    </Breadcrumb>
                                </>}
                                loading={this.state.loading}
                                extra={<>
                                    <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>
                                }>
                                {
                                    this.state.key === 1 ? <ManageUsers /> :
                                        this.state.key === 2 ? <Report /> :
                                            this.state.key === 3 ? <CourseStatusAll /> :
                                                this.state.key === 4 ? <FullReport /> :
                                                    this.state.key === 5 ? <LearnerProgress /> :
                                                        this.state.key === 6 ? <Faq /> :
                                                            <CreateCourses />
                                }
                                <br />
                            </Card>
                            <br />
                        </div>
                    </Content>
                </Layout>

                <Modal
                    title="My Profile"
                    visible={this.state.visibleprofile}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} >
                            <Input disabled value={this.state.USER_ID} addonBefore="Username" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.PASSWORD} type="password" addonBefore="New Password" id="PASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.CONFIRMPASSWORD} type="password" addonBefore="Confirm Password" id="CONFIRMPASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.FIRST_NAME} addonBefore="First Name" id="FIRST_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.LAST_NAME} addonBefore="Last Name" id="LAST_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.DEPARTMENT} addonBefore="Department" />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.SLOT} addonBefore="Slot" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MAIL_ID} addonBefore="Mail ID" id="MAIL_ID" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MOBILE} addonBefore="Mobile" id="MOBILE" onChange={this.handleChange} />
                        </Col>
                    </Row>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Home));