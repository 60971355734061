const data = {
    course_Structure: [
        "Basic Sciences",
        "Professional Core",
        "Professional Elective",
        "Open Elective"],
    PROGRAM_OUTCOMES: {
        PLO1: "Engineering knowledge",
        PLO2: "Problem analysis",
        PLO3: "Design/development of solutions",
        PLO4: "Conduct investigations of complex problems",
        PLO5: "Modern tool usage",
        PLO6: "The engineer and society",
        PLO7: "Environment and sustainability",
        PLO8: "Ethics",
        PLO9: "Individual and team work",
        PLO10: "Communication",
        PLO11: "Project management and finance",
        PLO12: "Life-long learning"
    },
    OUTCOMES: [
        {
            
            DEPT: "DEFAULT",
            PEO: {
                PEO1: '',
                PEO2: "",
                PEO3: "",
            },
            PLO: {
                PLO1: "",
                PLO2: "",
                PLO3: "",
                PLO4: "",
                PLO5: "",
                PLO6: "",
                PLO7: "",
                PLO8: "",
                PLO9: "",
                PLO10: "",
                PLO11: "",
                PLO12: "",
            },
            PSO: {
                PSO1: "",
                PSO2: "",
                PSO3: "",
            }
        }
    ]

};
export default data;