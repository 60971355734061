import React, { Component } from 'react'
import { Button, Input, Tag, Space, Table, message } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";

class AllSectionsList extends Component {
    state = {
        loading: true,
        batches: []
    }

    async componentDidMount() {
        this.onLoad();
    }

    onLoad = async () => {
        let data = {
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/admin/course/getallbatches', data)
            .then(res => {
                if (res.data.Status === 1) this.setState({ batches: res.data.result || [], loading: false });
                else {
                    message.error("Something went wrong ! Contact Admin !");
                    this.setState({ loading: false });
                }
            });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { batches, loading } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 0.5,
                render: (text) => (batches.findIndex(i => i._id === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Section ID',
                dataIndex: 'BATCH_ID',
                key: 'BATCH_ID',
                ...this.getColumnSearchProps('BATCH_ID'),
            },
            {
                title: 'Section Name',
                dataIndex: 'BATCH_NAME',
                key: 'BATCH_NAME',
                ...this.getColumnSearchProps('BATCH_NAME'),
            },
            {
                title: 'Semester',
                dataIndex: 'SEMESTER',
                key: 'SEMESTER',
                ...this.getColumnSearchProps('SEMESTER'),
            },
            {
                title: 'Course Code',
                dataIndex: 'COURSE_CODE',
                key: 'COURSE_CODE',
                ...this.getColumnSearchProps('COURSE_CODE'),
            },
            {
                title: 'Course Name',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
                ...this.getColumnSearchProps('COURSE_NAME'),
            },
            {
                title: 'Faculty ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                ...this.getColumnSearchProps('USER_ID'),
            },
            {
                title: 'Faculty Name',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
                ...this.getColumnSearchProps('FULL_NAME'),
            },
            {
                title: 'Mapping ID',
                dataIndex: 'MAPPING_ID',
                key: 'MAPPING_ID',
                ...this.getColumnSearchProps('MAPPING_ID'),
            },
            {
                title: 'Lock Status',
                dataIndex: 'LOCK',
                key: 'LOCK',
                render: (text) => (<>{text === 1 ? <Tag color='red'>Locked</Tag> : <Tag color='success'>Unlocked</Tag>}</>),

            },
        ];

        let header_list = [
            { label: "Section ID", key: "BATCH_ID" },
            { label: "Section Name", key: "BATCH_NAME" },
            { label: "Semester", key: "SEMESTER" },
            { label: "Course Code", key: "COURSE_CODE" },
            { label: "Course Name", key: "COURSE_NAME" },
            { label: "Faculty ID", key: "USER_ID" },
            { label: "Faculty Name", key: "FULL_NAME" },
            { label: "Mapping ID", key: "MAPPING_ID" },
            { label: "Lock Status", key: "LOCK" },
        ];

        return (<>
            <Space className='mt-1 mb-1'>
                <CSVLink data={batches} headers={header_list} filename={config.toolname + "-all-coursewise-sections-list.csv"}>
                    <Button type="primary" icon={<DownloadOutlined />} size="small">
                        Download EXCEL
                    </Button>
                </CSVLink>
                <Button danger type="primary" icon={<ReloadOutlined />} onClick={this.onLoad} size="small">
                    Refersh
                </Button>
                <Tag color="green">{"Total Course-Wise Sections Created : " + batches.length}  </Tag>
            </Space>

            <Table loading={loading} size="small" bordered className='mt-1' dataSource={batches} columns={columns} />
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(AllSectionsList));