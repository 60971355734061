import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import HODVerification from './verification/HODVerification'
import ReportVerification from './verification/ReportVerification'
import ResearchVerification from './verification/ResearchVerification'
const { Title } = Typography;
const { TabPane } = Tabs;

class Verification extends Component {
    state = {
    }

    componentDidMount() {
    }

    render() {
        let tabName = ["Basic", "Professional", "Research", "Admission Entry"];
        return (
            <div>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Verification Report</Title>
                    </Col>
                    <Col xs={ 24 }>
                        <Tabs defaultActiveKey="1" tabPosition="top" style={ { height: "100%", textAlign: "left" } } animated={ false } >
                            <TabPane tab={ tabName[0] } key="1"><ReportVerification /> </TabPane>
                            <TabPane tab={ tabName[1] } key="2"><HODVerification /> </TabPane>
                            <TabPane tab={ tabName[2] } key="3"><ResearchVerification /> </TabPane>
                         </Tabs>
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Verification));