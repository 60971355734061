import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import HrReport from './ReportView/HrReport'
import HodReport from './ReportView/HodReport'
import ResearchReport from './ReportView/ResearchReport'
const { Title } = Typography;
const { TabPane } = Tabs;

class ProfileReport extends Component {
    state = {
    }

    componentDidMount() {
    }

    render() {
        let tabName = ["Basic", "Professional", "Research"];
        return (
            <div>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }>View Profile Report</Title>
                    </Col>
                    <Col xs={ 24 }>
                        <Tabs defaultActiveKey="1" tabPosition="top" style={ { height: "100%", textAlign: "left" } } animated={ false } >
                            <TabPane tab={ tabName[0] } key="1"><HrReport /> </TabPane>
                            <TabPane tab={ tabName[1] } key="2"><HodReport /> </TabPane>
                            <TabPane tab={ tabName[2] } key="3"><ResearchReport /> </TabPane>

                        </Tabs>
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ProfileReport));