import React, { Component, createRef } from 'react'
import { Row, Col, Card, Badge, Descriptions, Spin, BackTop, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CourseStructure from '../admin/CourseInformation/CourseStructure'
import CourseLearningObjective from '../admin/CourseInformation/CourseLearningObjective'
import LearningSession from '../admin/CourseInformation/CourseLearningSession'
import SLOSRO from '../admin/CourseInformation/SLOSRO'
import SessionPlan from '../admin/CourseContent/SessionPlan'
import LearningPPT from '../admin/CourseContent/LearningPPT'
import LearningPPTZIP from '../admin/CourseContent/LearningPPTZIP'
import IspringZip from '../admin/CourseContent/IspringZIP';
import AddResources from '../admin/CourseContent/AddResources';
import LearningPractice from '../admin/CourseContent/LearningPractice'
import LearningPracticePDF from '../admin/CourseContent/LearningPracticePDF'
import LPispring from '../admin/CourseContent/LPispring'
import LearningPracticeSolutions from '../admin/CourseContent/LearningPracticeSolutions'
import LearningMaterial from '../admin/CourseContent/LearningMaterial'
import LearningMaterialDocx from '../admin/CourseContent/LearningMaterialDocx'
import Video from '../admin/CourseContent/Video'
import MCQ from '../admin/Questions/MCQ'
import SQ from '../admin/Questions/SQ'
import LQ from '../admin/Questions/LQ'
import Sunburst from '../../chart/sunburst';
import CourseStatus from '../admin/CourseInformation/CourseStatus';
import CourseUploads from '../admin/CourseInformation/CourseUploads';
import QuestionPaper from '../admin/CourseInformation/QuestionPaper';
import Apps from './Apps';
import { EditOutlined } from '@ant-design/icons';
import store from "../../redux/store";
import { logoutUser } from '../../redux/actions/commonActions';
import axios from 'axios';
import config from '../../config';

class CoursesHome extends Component {
    scrollDiv = createRef();

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        topic: 'COURSE HOME',
        key: 0
    }

    componentDidMount() {
        let children = []
        let COURSE_CODE = this.props.COURSE_CODE;
        let COURSE_NAME = this.props.COURSE_NAME;

        let course = {
            COURSE_CODE: COURSE_CODE,
            COURSE_NAME: COURSE_NAME,
        }

        children.push(
            {
                "name": COURSE_CODE + " " + COURSE_NAME,
                "children": [
                    {
                        "name": "Course Information",
                        "children": [
                            { "name": "Structure", "key": 1, "size": 800, "course": course },
                              { "name": "Outcomes", "key": 2, "size": 800, "course": course },
                            { "name": "Session", "key": 3, "size": 700, "course": course },
                            { "name": "SLO/SLR", "key": 4, "size": 700, "course": course },
                            { "name": "Plan", "key": 5, "size": 500, "course": course },
                        ]
                    },
                    {
                        "name": "Course Content",
                        "children": [
                            { "name": "PDF", "key": 6, "size": 500, "course": course },
                            { "name": "PPTx", "key": 21, "size": 500, "course": course },
                            { "name": "L Material", "key": 7, "size": 800, "course": course },
                            { "name": "L Material Source", "key": 8, "size": 1300, "course": course },
                            { "name": "Video", "key": 9, "size": 500, "course": course },
                        ]
                    },
                    {
                        "name": "Worksheet",
                        "children": [
                            { "name": "DOCX", "key": 10, "size": 400, "course": course },
                            { "name": "PDF", "key": 11, "size": 400, "course": course },
                            { "name": "Solution", "key": 12, "size": 600, "course": course },
                            { "name": "iSpring", "key": 13, "size": 600, "course": course },
                            { "name": "iSpring Source", "key": 22, "size": 1100, "course": course },
                        ]
                    },
                    {
                        "name": "Assessment",
                        "children": [
                            { "name": "MCQ", "key": 14, "size": 400, "course": course },
                            { "name": "Short", "key": 15, "size": 500, "course": course },
                            { "name": "Long", "key": 16, "size": 400, "course": course },
                        ]
                    },
                    {
                        "name": "Activities",
                        "children": [
                            { "name": "Status", "key": 17, "size": 500, "course": course },
                            { "name": "Import/Export", "key": 18, "size": 1100, "course": course },
                            { "name": "QP", "key": 19, "size": 300, "course": course },
                            { "name": "Resources", "key": 23, "size": 650, "course": course },
                            // { "name": "Apps", "key": 20, "size": 400, "course": course },
                        ]
                    },
                ]
            }
        );

        let flare = {
            "name": COURSE_CODE || "Course",
            "children": children
        }
        this.setState({ flare: flare, loading: false });
    }

    getToken = async () => {
        let data = {
            INFO: this.props.common.user,
            KEY: config.key
        }
        let result = await axios.post(config.curriculaserver + '/curricula/gettoken', data)
            .then(res => {
                return res.data.result;
            });
        if (result) {
            return result.localeCompare(localStorage.jwtToken);
        }
    }

    onClick = async (node) => {
        let token = await this.getToken();
        if (token) {
            store.dispatch(logoutUser());
        }
        else if (node.data.course) {
            this.setState({ COURSE_CODE: node.data.course.COURSE_CODE, COURSE_NAME: node.data.course.COURSE_NAME });
            if (node.data.key === 1) this.setState({ key: 1, topic: "Course Structure" });
            else if (node.data.key === 2) this.setState({ key: 2, topic: "Course Outcomes" });
            else if (node.data.key === 3) this.setState({ key: 3, topic: "Course Learning Session" });
            else if (node.data.key === 4) this.setState({ key: 4, topic: "Sessin Learning Rationale & Outcomes" });
            else if (node.data.key === 5) this.setState({ key: 5, topic: "Session Learning Plan" });
            else if (node.data.key === 6) this.setState({ key: 6, topic: "Learning Content" });
            else if (node.data.key === 10) this.setState({ key: 7, topic: "Learning Practice DOCX" });
            else if (node.data.key === 13) this.setState({ key: 15, topic: "Live Worksheet" });
            else if (node.data.key === 12) this.setState({ key: 8, topic: "Learning Practice Answers DOCX" });
            else if (node.data.key === 7) this.setState({ key: 9, topic: "Learning Material" });
            else if (node.data.key === 8) this.setState({ key: 10, topic: "Learning Material - Source File DOCX" });
            else if (node.data.key === 14) this.setState({ key: 11, topic: "Multiple Choice Questions" });
            else if (node.data.key === 15) this.setState({ key: 12, topic: "Short Questions" });
            else if (node.data.key === 16) this.setState({ key: 13, topic: "Long Questions" });
            else if (node.data.key === 9) this.setState({ key: 14, topic: "Video" });
            else if (node.data.key === 17) this.setState({ key: 16, topic: "Course Status" });
            else if (node.data.key === 18) this.setState({ key: 17, topic: "Import / Export" });
            else if (node.data.key === 19) this.setState({ key: 18, topic: "Question Paper Generation" });
            else if (node.data.key === 20) this.setState({ key: 19, topic: "Teaching Learning Tools" });
            else if (node.data.key === 11) this.setState({ key: 20, topic: "Learning Practice PDF" });
            else if (node.data.key === 21) this.setState({ key: 21, topic: "PPTx Source (ZIP)" });
            else if (node.data.key === 22) this.setState({ key: 22, topic: "ISpring Source (ZIP)" });
            else if (node.data.key === 23) this.setState({ key: 23, topic: "Additional Resources (ZIP)" });
            this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        let { key, COURSE_CODE, COURSE_NAME } = this.state;
        return (
            <div>
                <BackTop duration={1000} />
                {
                    this.state.loading === false ?
                        (<Row justify="center" align="top" className="mt-1 mb-2">

                            {/* <Col xs={24} align="start">
                                <Alert message="Content Management enables development, management, distribution, collaboration and delivery of content required or used for teaching purpose by an faculty for a program. It helps to effectively store, retrieve and access the training content which can be imported or exported." type="info" closable />
                                <br/>
                            </Col> */}

                            <Col xs={24} align="middle">
                                <Sunburst
                                    data={this.state.flare}
                                    width="720"
                                    height="720"
                                    count_member="size"
                                    onClick={this.onClick}
                                    labelFunc={(node) => node.data.name}
                                />
                            </Col>
                        </Row>) :
                        (
                            <Row justify="center" align="top" className="mt-5 mb-5">
                                <Col xs={24} align="middle">
                                    <Spin size="large" />
                                </Col>
                            </Row>
                        )
                }


                <div className="card-container mt-1 mb-1" ref={this.scrollDiv}>
                    {
                        key !== 16 && key !== 17 && key !== 18 && key !== 19 ?
                            <Card type="inner" title={<><EditOutlined className="mr-1" />  {this.state.topic}</>} className="mt-1" style={{ width: "100%" }} extra={<>
                                <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>
                            } >
                                {
                                    (key >= 1 && key <= 7) || (key >= 11 && key <= 15) || key === 9 || key === 20 ? <Alert message={this.state.topic + " is displayed to students login !"} type="warning" showIcon closable />
                                        : key === 8 || key === 10 || (key >= 21 && key <= 23) ? <Alert message={this.state.topic + " is not displayed to students !  It is only maintained by course coordinators for future updates !"} type="info" showIcon closable /> : ""
                                }

                                <Alert className='mt-1' message="Please use MS Office 2016 or later versions when creating Word, PPT, and Excel files." type="success" showIcon closable />

                                <div className="card-container mt-1 mb-1">
                                    {key !== -1 ?
                                        <Descriptions
                                            bordered
                                            column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                                            size="small"
                                            className="mb-2"
                                        >
                                            <Descriptions.Item label="Course Code">
                                                {COURSE_CODE}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Course Name">
                                                {COURSE_NAME}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        : ''
                                    }

                                    <span >
                                        {key === 0 ? (<> </>)
                                            :
                                            key === 1 ?
                                                <CourseStructure COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                :
                                                key === 2 ?
                                                    <CourseLearningObjective COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                    :
                                                    key === 3 ?
                                                        <LearningSession COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                        :
                                                        key === 4 ?
                                                            <SLOSRO COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                            :
                                                            key === 5 ?
                                                                <SessionPlan COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                :
                                                                key === 6 ?
                                                                    <LearningPPT COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                    :
                                                                    key === 7 ?
                                                                        <LearningPractice COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                        :
                                                                        key === 8 ?
                                                                            <LearningPracticeSolutions COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                            :
                                                                            key === 9 ?
                                                                                <LearningMaterial COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                :
                                                                                key === 10 ?
                                                                                    <LearningMaterialDocx COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                    :
                                                                                    key === 11 ?
                                                                                        <MCQ COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                        :
                                                                                        key === 12 ?
                                                                                            <SQ COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                            :
                                                                                            key === 13 ?
                                                                                                <LQ COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                :
                                                                                                key === 14 ?
                                                                                                    <Video COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                    :
                                                                                                    key === 15 ?
                                                                                                        <LPispring COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                        :
                                                                                                        key === 20 ?
                                                                                                            <LearningPracticePDF COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                            :
                                                                                                            key === 21 ?
                                                                                                                <LearningPPTZIP COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                                :
                                                                                                                key === 22 ?
                                                                                                                    <IspringZip COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                                    :
                                                                                                                    key === 23 ?
                                                                                                                        <AddResources COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                                                                                        :
                                                                                                                        ''
                                        }

                                    </span>
                                </div>
                            </Card>

                            : key === 16 ? <CourseStatus className="mt-3" COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                : key === 17 ? <CourseUploads className="mt-3" COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                    : key === 18 ? <QuestionPaper className="mt-3" COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                        : key === 19 ? <Apps className="mt-3" COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                            : ''
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CoursesHome));