import React, { Component } from 'react'
import { Input, Table, Space, Button, message } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import { DownloadOutlined, EnterOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";

class UnitMark extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        topic: '',
        users: [],
        marks: [],
        key: 0
    }

    componentDidMount() {
        let sendData = {
            key: config.key,
            COURSE_INFO: this.props.COURSE_INFO,
        };
        axios.post(config.curriculaserver + '/curricula/faculty/unitmarks/get', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ marks: res.data.result, loading: false });
                }
            });
    }

    handleChange = (UNIT, record, e) => {
        if (isNaN(e.target.value) || parseInt(e.target.value) > 100 || parseInt(e.target.value) < 0) {
            message.error("Invalid Entry !");
            e.target.value = 0;
        }
        let marks = [...this.state.marks];
        for (var i in marks) {
            let UNIT_MARKS = marks[i].UNIT_MARKS ? marks[i].UNIT_MARKS : {}
            if (marks[i].USER_ID === e.target.id) {
                UNIT_MARKS[UNIT] = e.target.value
                marks[i].UNIT_MARKS = UNIT_MARKS;
                break;
            }
        }
        this.setState({ marks });
    };

    SETMARKS = (record) => {
        let marks = [...this.state.marks];
        let STUDENT = {};
        for (var i in marks) {
            if (marks[i].USER_ID === record.USER_ID) {
                STUDENT = marks[i].UNIT_MARKS;
                break;
            }
        }

        let UNIT_MARK = {
            UNIT1: parseInt(STUDENT.UNIT1) || 0,
            UNIT2: parseInt(STUDENT.UNIT2) || 0,
            UNIT3: parseInt(STUDENT.UNIT3) || 0,
            UNIT4: parseInt(STUDENT.UNIT4) || 0,
            UNIT5: parseInt(STUDENT.UNIT5) || 0,
        }

        let sendData = {
            record: {
                USER_ID: record.USER_ID,
                key: record.USER_ID,
                DEPARTMENT: record.DEPARTMENT,
                SLOT: record.SLOT,
                FULL_NAME: record.FULL_NAME,
                SECTION: record.SLOT
            },
            COURSE_CODE: this.props.COURSE_CODE,
            COURSE_NAME: this.props.COURSE_NAME,
            UNIT_MARKS: UNIT_MARK,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/faculty/unitmarks/update', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    message.success("Information Updated !");
                }
                else
                    message.success("Update Error !");
            });
    }

    render() {
        let { marks, loading } = this.state;
        let headers = [
            { label: "User ID", key: "USER_ID" },
            { label: "User Name", key: "FULL_NAME" },
            { label: "UNIT 1", key: "UNIT_MARKS.UNIT1" },
            { label: "UNIT 2", key: "UNIT_MARKS.UNIT2" },
            { label: "UNIT 3", key: "UNIT_MARKS.UNIT3" },
            { label: "UNIT 4", key: "UNIT_MARKS.UNIT4" },
            { label: "UNIT 5", key: "UNIT_MARKS.UNIT5" },
            { label: "TOTAL", key: "UNIT_MARKS.OVERALL" }
        ];

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (marks.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                render: (text, record) => (record.FULL_NAME.toUpperCase() + " (" + text + ")"),
                width: 5,
                fixed: 'left'
            },
            {
                title: 'UNIT 1 (100)',
                dataIndex: ['UNIT_MARKS', 'UNIT1'],
                render: (text, record) => (
                    <Input id={ record.USER_ID } min={ 0 } max={ 100 } value={ text } type="number"
                        onChange={ this.handleChange.bind(this, "UNIT1", record) } />
                ),
                width: 1
            },
            {
                title: 'UNIT 2 (100)',
                dataIndex: ['UNIT_MARKS', 'UNIT2'],
                render: (text, record) => (
                    <Input id={ record.USER_ID } min={ 0 } max={ 100 } value={ text } type="number"
                        onChange={ this.handleChange.bind(this, "UNIT2", record) } />
                ),
                width: 1
            },
            {
                title: 'UNIT 3 (100)',
                dataIndex: ['UNIT_MARKS', 'UNIT3'],
                render: (text, record) => (
                    <Input id={ record.USER_ID } min={ 0 } max={ 100 } value={ text } type="number"
                        onChange={ this.handleChange.bind(this, "UNIT3", record) } />
                ),
                width: 1
            },
            {
                title: 'UNIT 4 (100)',
                dataIndex: ['UNIT_MARKS', 'UNIT4'],
                render: (text, record) => (
                    <Input id={ record.USER_ID } min={ 0 } max={ 100 } value={ text } type="number"
                        onChange={ this.handleChange.bind(this, "UNIT4", record) } />
                ),
                width: 1
            },
            {
                title: 'UNIT 5 (100)',
                dataIndex: ['UNIT_MARKS', 'UNIT5'],
                render: (text, record) => (
                    <Input id={ record.USER_ID } min={ 0 } max={ 100 } value={ text } type="number"
                        onChange={ this.handleChange.bind(this, "UNIT5", record) } />
                ),
                width: 1
            },
            {
                title: 'ACTION',
                render: (text, record) => (
                    <Space>
                        <Button type="primary" icon={ <EnterOutlined /> } size="middle" onClick={ this.SETMARKS.bind(this, record) }
                            disabled={ this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? true : false }
                        >UPDATE</Button>
                    </Space>
                ),
                fixed: 'right',
                width: 1,
            },
        ];

        return (
            <div>
                <CSVLink data={ marks } headers={ headers } filename={ "unit-marks.csv" } >
                    <Button size="middle" icon={ <DownloadOutlined /> }>Download EXCEL</Button>
                </CSVLink>
                <Table className="mt-1" size="middle" bordered style={ { width: "100%" } } dataSource={ marks } columns={ columns } loading={ loading } />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(UnitMark));