import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import Project from './Project'
import IndustrialConsultancy from './IndustrialConsultancy'
const { Title } = Typography;
const { TabPane } = Tabs;

class ResearchConsultancy extends Component {
    state = {
        loading: false,
    };

    render() {

        let tabName = ["Funded Projects / Sponsored Research / Grants", "Industrial Consultancy", "Research Centre Establishment"];

        return (
            <div style={{ padding: "24px" }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Title level={3}>Consultancy Information</Title>
                    </Col>
                    <Col xs={24}>
                        <Tabs defaultActiveKey="0" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                            <TabPane tab={tabName[0]} key="1"><Project /> </TabPane>
                            <TabPane tab={tabName[1]} key="2"><IndustrialConsultancy /> </TabPane>
                            { //<TabPane tab={tabName[2]} key="3"><ResearchCentre /> </TabPane> 
                            }
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ResearchConsultancy));