import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Descriptions, Upload, List, message, Tag, Select } from 'antd';
import config from '../../../config';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import Template from '../../../doc/po_update_template.csv';
const { Dragger } = Upload;
const { Option } = Select;

class UserCreate extends Component {

    state = {
        key: 0,
        loading: false,
        filename: '', fileStatus: [], fileLoading: false, status: [],
        DEPARTMENT: ''
    };

    handleChangeSelect = (value) => {
        this.setState({ DEPARTMENT: value.value })
    }

    render() {
        let { status, fileLoading } = this.state;

        const params = {
            key: config.key,
            dept: this.state.DEPARTMENT,
            title: "PLO"
        }

        let props = {
            className: 'padding-2 mt-1',
            listType: 'picture',
            accept: ".csv",
            name: 'csv',
            multiple: false,
            action: config.curriculaserver + '/curricula/batchcoordinator/po/update',
            data: params,
            onChange: (info) => {
                //console.log(info)
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.dept];
                        if (info.file.response.Status === 0) {
                            message.error(info.file.response.msg);
                            return { status: info.file.response.status || [] };
                        }
                        else if (info.file.response.Status === 1)
                            return { fileStatus: fileStatus, status: info.file.response.status || [], fileLoading: false };
                    });
                    if (info.file.response.Status !== 0)
                        message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                //console.log(file);
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 2024 / 2024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (<>
            <div style={{ padding: '0px', minHeight: "100%", marginTop: "20px" }}>
                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label={"Template File"}>
                        <Button type="primary" icon={<DownloadOutlined />} href={Template}>Download PO Template</Button>
                    </Descriptions.Item>

                    <Descriptions.Item label={"Choose Department"}>
                        <Select labelInValue style={{ width: "100%" }} onChange={this.handleChangeSelect} placeholder="Select Department">
                            {
                                config.departments.map(
                                    (item, value) => {
                                        return (item !== "ALL" ? <Option key={value} value={item}>{item}</Option> : '')
                                    }
                                )
                            }
                        </Select>
                    </Descriptions.Item>

                    <Descriptions.Item label={"PO Update"}>
                        <Dragger {...props}
                            disabled={!this.state.DEPARTMENT ? true : false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single file upload. Strictly prohibit from uploading irrelevant data or other band files
                            </p>
                        </Dragger>
                        <br /> <br />
                        <List
                            className={status.length > 0 ? '' : 'hide'}
                            loading={fileLoading} pagination={false}
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 1,
                                xl: 1,
                                xxl: 1,
                            }}
                            dataSource={status}
                            renderItem={item => (
                                <List.Item style={{ padding: "0px 16px" }}>
                                    <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                                </List.Item>
                            )}
                        />
                    </Descriptions.Item>

                </Descriptions>
            </div>
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(UserCreate));