import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserCreate from './UserCreate'
import ManageUsers from './ManageUsers'
import {
    UsergroupAddOutlined, UserAddOutlined, AppstoreOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;

class ManageFaculty extends Component {
    state = {
        loading: false,
    };

    render() {
        let tabName = ["Create User", "Create Faculty", "Create Students", "Users List", "Slot Change"];

        return (
            <Row style={{ overflow: "auto" }}>
                <Col xs={24} className="mt-1 mb-1">
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                                <TabPane tab={
                                    <span>
                                        <UserAddOutlined />
                                        {tabName[1]}
                                    </span>
                                } key="1"><UserCreate ROLE='TF' /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <UsergroupAddOutlined />
                                        {tabName[2]}
                                    </span>
                                } key="2"><UserCreate ROLE='S' /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <AppstoreOutlined />
                                        {tabName[3]}
                                    </span>
                                } key="3"><ManageUsers /></TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ManageFaculty));