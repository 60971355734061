import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, DatePicker, Tooltip, Table, notification, Alert, Popconfirm, Tag } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { Option } = Select;
const { Text, Title } = Typography;
const dateFormatFull = ['DD/MM/YYYY'];
const dateFormat = ["MM/YYYY"];

const topic = "outreachexaminer";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
const examinerTypeList = ["QUESTION_PAPER_SETTER", "QUESTION_PAPER_AUDITOR", "ANSWER_SHEET_EVALUATOR", "ANSWER_SHEET_MODERATOR", "END_SEMESTER_THEORY_EXAMINER", "END_SEMESTER_LABORATORY_EXAMINER", "END_SEMESTER_SQUAD_MEMBER"];
function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}

class OutreachExaminer extends Component {

    state = {
        subjectName: '',
        examinerType: '',
        role: '',
        totalPapers: '',
        institutionName: '',
        university: '',
        place: '',
        stateName: '',
        country: '',
        date: '01/01/2020',
        examYear: "02/2020",
        _id: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });

        if (label === "role" && value === "INTERNAL") {
            this.setState({ institutionName: this.props.common.user.INSTITUTION });
            this.setState({ deptName: this.props.common.user.DEPARTMENT });
            this.setState({ university: "Anna University", place: "Karur", stateName: 'Tamil Nadu', country: 'India' });
        }
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/outreach/outreachexaminer/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/outreach/outreachexaminer/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/outreach/outreachexaminer/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/outreach/outreachexaminer/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });
                }
            });
    }

    onClickSubmitBtn = () => {
        let { subjectName, examinerType, role, key, totalPapers, institutionName, university, place, stateName, country, date, _id, } = this.state;
        let errorMessages = [];

        if (!subjectName) errorMessages.push("Invalid memberType");
        else if (!examinerType) errorMessages.push("Invalid examinerType");
        else if (!role) errorMessages.push("Invalid role");
        else if (examinerType === "ANSWER_SHEET_EVALUATOR" || examinerType === "ANSWER_SHEET AUDITOR") {
            if (!totalPapers) errorMessages.push("Invalid totalPapers");
        }
        else if (!institutionName) errorMessages.push("Invalid institutionName");
        else if (!university) errorMessages.push("Invalid university");
        else if (!place) errorMessages.push("Invalid place");
        else if (!stateName) errorMessages.push("Invalid stateName");
        else if (!country) errorMessages.push("Invalid country");
        else if (!date) errorMessages.push("Invalid date");


        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                subjectName: subjectName,
                examinerType: examinerType,
                role: role,
                totalPapers: totalPapers,
                institutionName: institutionName,
                university: university,
                place: place,
                stateName: stateName,
                country: country,
                date: date,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/outreach/outreachexaminer/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    this.setState({ update: false });
                    await this.setState(this.initialState);
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { subjectName, examinerType, role, totalPapers, institutionName, university, place, stateName, country, date, examYear, edit, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Subject Name',
                dataIndex: 'subjectName',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                key: 'subjectName',
                width: 20,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                width: 20,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Type',
                dataIndex: 'examinerType',
                key: 'examinerType',
                render: (text) => (
                    <Text>{ text.replace(/_|-|\./g, ' ') }</Text>
                ),
                width: 20,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End
        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }> Examiner
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="subjectName" addonBefore="* Subject Name" onChange={ this.onChange } value={ subjectName } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 10 }>
                                    <Input defaultValue="Examiner Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 14 }>
                                    <Select showSearch id="examinerType" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "examinerType") } value={ examinerType }>
                                        {
                                            examinerTypeList.map(
                                                (newItem, newVal) => {
                                                    return (
                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 6 }>
                                    <Input defaultValue="Role" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 18 }>
                                    <Select id="role" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "role") } value={ role }>
                                        <Option value="INTERNAL">Internal</Option>
                                        <Option value="EXTERNAL">External</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    {
                        examinerType === "ANSWER_SHEET_EVALUATOR" || examinerType === "ANSWER_SHEET AUDITOR" ?
                            (<Col xs={ 24 } md={ 8 }>
                                <Input id="totalPapers" addonBefore="* No.of Papers Evaluated / Audited" onChange={ this.onChange }
                                    value={ totalPapers } type="number" />
                            </Col>)
                            : ''
                    }

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="institutionName" addonBefore="* Institution Name" onChange={ this.onChange }
                            value={ institutionName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="university" addonBefore="* University" onChange={ this.onChange }
                            value={ university } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='place' addonBefore=" * Place" onChange={ this.onChange } value={ place } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='stateName' addonBefore=" * State" onChange={ this.onChange } value={ stateName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='country' addonBefore=" * Country" onChange={ this.onChange } value={ country } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Order Date" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <DatePicker id="date" placeholder="* Date" style={ { width: "100%" } } format={ dateFormatFull } onChange={ this.handleChangeDate.bind(this, "date") } value={ moment(date, dateFormatFull) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col xs={ 24 } md={ 8 }>
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 14 }>
                                    <Input defaultValue="* Month & Year of Exam" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 10 }>
                                    <DatePicker id="examYear" style={ { width: "100%" } } onChange={ this.handleChangeDate.bind(this, "examYear") } format={ dateFormat }
                                        value={ moment(examYear, dateFormat) }
                                    />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } disabled={ !this.state.edit } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(OutreachExaminer));