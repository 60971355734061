import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, Tooltip, Table, notification, Alert, Popconfirm, Tag } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { Option } = Select;
const { Text, Title } = Typography;
const topic = "outreachmembership";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
const typeList = ["BOARD _OF_STUDIES_MEMBER", "ACADEMIC_COUNCIL_MEMBER", "GOVERNING_COUNCIL_MEMBER", "INDUSTRIAL_BOARD_MEMBER"]
function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}

class OutreachMembership extends Component {

    state = {
        memberType: '',
        role: '',
        institutionName: '',
        university: '',
        deptName: '',
        place: '',
        stateName: '',
        country: '',
        date: '01/01/2020',
        _id: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });

        if (label === "role") {
            if (value === "INTERNAL") {
                this.setState({ institutionName: this.props.common.user.INSTITUTION });
                this.setState({ deptName: this.props.common.user.DEPARTMENT });
                this.setState({ university: "Anna University", place: "Karur", stateName: 'Tamil Nadu', country: 'India' });
            }

        }
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/outreach/outreachmembership/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/outreach/outreachmembership/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/outreach/outreachmembership/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/outreach/outreachmembership/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });
                }
            });
    }

    onClickSubmitBtn = () => {
        let { memberType, role, institutionName, university, deptName, place, stateName, country, _id, key } = this.state;
        let errorMessages = [];

        if (!memberType) errorMessages.push("Invalid memberType");
        else if (!role) errorMessages.push("Invalid role");
        else if (!institutionName) errorMessages.push("Invalid institutionName");
        else if (!university) errorMessages.push("Invalid university");
        else if (role !== "EXTERNAL") if (!deptName) errorMessages.push("Invalid deptName");
        else if (!place) errorMessages.push("Invalid place");
        else if (!stateName) errorMessages.push("Invalid stateName");
        else if (!country) errorMessages.push("Invalid country");

        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                memberType: memberType,
                role: role,
                institutionName: institutionName,
                university: university,
                deptName: deptName,
                place: place,
                stateName: stateName,
                country: country,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/outreach/outreachmembership/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    this.setState({ update: false });
                    await this.setState(this.initialState);
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });

    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { memberType, role, institutionName, university, deptName, place, stateName, country, edit, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Member Type',
                dataIndex: 'memberType',
                key: 'memberType',
                render: (text) => (
                    <Text strong>{ text.replace(/_|-|\./g, ' ') }</Text>
                ),
                width: 50,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                width: 20,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Institution',
                dataIndex: 'institutionName',
                key: 'institutionName',
                width: 20,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }> Membership Institution / Industry
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 6 }>
                                    <Input defaultValue="Member Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 18 }>
                                    <Select id="memberType" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "memberType") } value={ memberType }>
                                        {
                                            typeList.map(
                                                (newItem, newVal) => {
                                                    return (
                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                    )
                                                }
                                            )
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 12 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 6 }>
                                    <Input defaultValue="Role" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 18 }>
                                    <Select id="role" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "role") } value={ role }>
                                        <Option value="INTERNAL">Internal</Option>
                                        <Option value="EXTERNAL">External</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    <Col xs={ 24 } md={ 12 }>
                        <Input id="institutionName" addonBefore="* Institution Name" onChange={ this.onChange }
                            value={ institutionName } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="university" addonBefore="* University" onChange={ this.onChange }
                            value={ university } />
                    </Col>

                    {
                        memberType === "BOARD _OF_STUDIES_MEMBER" || memberType === "INDUSTRIAL_BOARD_MEMBER" ?
                            (<Col xs={ 24 } md={ 12 }>
                                <Input id="deptName" addonBefore="* Department" onChange={ this.onChange }
                                    value={ deptName } />
                            </Col>) : ''
                    }

                    <Col xs={ 24 } md={ 12 }>
                        <Input id='place' addonBefore=" * Place" onChange={ this.onChange } value={ place } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id='stateName' addonBefore=" * State" onChange={ this.onChange } value={ stateName } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id='country' addonBefore=" * Country" onChange={ this.onChange } value={ country } />
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } >
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(OutreachMembership));