import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Descriptions, Card, Tag, Select, message, List, Input, Modal, Row, Col, Upload, Table, Statistic, Tooltip, Space } from 'antd';
import axios from 'axios';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { InboxOutlined, EditOutlined, SearchOutlined, DownloadOutlined, DeleteOutlined, UploadOutlined, } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import Template from '../../../doc/batch_user_assign.csv'
const { Option } = Select;
const { Dragger } = Upload;

class FacultySectionList extends Component {

    state = {
        key: 0,
        loading: true, loadingInside: false,
        BATCH_NAME: '', EDIT_BATCH_NAME: '', COURSE_CODE: '', COURSE_NAME: '', uploadModalVisible: false, listModalVisible: false, editModalVisible: false,
        selectedBatchID: '', selectedCourseID: '', selectedBatchName: '', selectedCourseCode: '',
        courses: [], batchList: [], status: [], studentsList: [], filename: '', fileStatus: [], fileLoading: false, FacultyList: [],
        searchText: '',
        searchedColumn: '',
        header_list: [
            { label: "User ID", key: "USER_ID" },
            { label: "Name", key: "USER_NAME" },
            { label: "Department", key: "DEPARTMENT" },
        ],
        SEMESTER: 0,
        selectedSemester: 0,
    };

    async componentDidMount() {
        await this.setState({ loading: true })
        let sendData = {
            data: {
                title: "facultylist"
            },
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/admin/manageuser/listfaculty', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ FacultyList: res.data.result, loading: false })
                }
            });
    }

    onLoad = async (facultyID) => {
        this.setState({ loading: true });
        let data = {
            info: { USER_ID: facultyID },
            KEY: config.key
        }
        await axios.post(config.curriculaserver + '/curricula/faculty/batch/courses', data)
            .then(res => {
                //console.log(res.data.result2)
                let temp = res.data.result2;
                if (res.data.Status === 1) this.setState({ batchList: temp || [], loading: false });
            });
    }

    handleChangeSelectFaculty = (e) => {
        let faculty = [...this.state.FacultyList]
        let temp = faculty.find(v => v.USER_ID === e);
        this.setState({ selectedFaculty: temp.FULL_NAME, selectedFacultyID: temp.USER_ID, selectedFacultyDept: temp.DEPARTMENT, facultyName: temp.FULL_NAME });
        this.onLoad(temp.USER_ID);
    }

    handleCancel = () => {
        this.setState({ uploadModalVisible: false, listModalVisible: false, editModalVisible: false });
    };

    handleOk = () => {
        let { EDIT_BATCH_NAME, selectedBatchID, selectedFacultyID, selectedFaculty } = this.state;
        if (!EDIT_BATCH_NAME || !selectedBatchID) { message.error("Invalid Entry !"); return false; }

        this.setState({ loadingModal: true });

        let data = {
            info: {
                USER_ID: selectedFacultyID,
                FIRST_NAME: selectedFaculty,
                LAST_NAME: "",
            },
            BATCH_NAME: EDIT_BATCH_NAME,
            BATCH_ID: selectedBatchID,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/faculty/batch/editname', data)
            .then(res => {
                if (res.data.Status) {
                    let batchList = this.state.batchList || [];
                    batchList = batchList.map(x => (x.BATCH_ID === selectedBatchID ? { ...x, BATCH_NAME: EDIT_BATCH_NAME } : x));
                    this.setState({ batchList: batchList, EDIT_BATCH_NAME: '', editModalVisible: false, loadingModal: false });
                    message.success("Information Updated !");
                }
                else message.error("Operation Failed !");
            });
    };

    onClickShowBatch = (BATCH_ID, BATCH_NAME, COURSE_CODE) => {
        if (!BATCH_ID || !BATCH_NAME) { message.error("Invalid Entry !"); return false; }
        this.setState({ selectedCourseCode: COURSE_CODE, selectedBatchID: BATCH_ID, selectedBatchName: BATCH_NAME, studentsList: [], loadingModal: true });

        let data = {
            info: {
                USER_ID: this.state.selectedFacultyID,
                FIRST_NAME: this.state.selectedFaculty,
                LAST_NAME: "",
            },
            BATCH_ID: BATCH_ID,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/faculty/batch/get', data)
            .then(res => {
                if (res.data.Status) {
                    this.setState({ studentsList: res.data.result || [], loadingModal: false, listModalVisible: true });
                }
                else message.error("Operation Failed !");
            });
    }

    onClickRemove = (record) => {
        let { selectedBatchID, studentsList, selectedCourseCode } = this.state;
        if (!selectedBatchID || studentsList.length === 0) { message.error("Invalid Entry !"); return false; }
        if (record) {
            let data = {
                info: {
                    USER_ID: this.state.selectedFacultyID,
                    FIRST_NAME: this.state.selectedFaculty,
                    LAST_NAME: "",
                },
                record: record,
                selectedBatchID: selectedBatchID,
                selectedCourseCode: selectedCourseCode,
                KEY: config.key
            }
            axios.post(config.curriculaserver + '/curricula/faculty/batch/removestudent', data)
                .then(res => {
                    if (res.data.Status) {
                        studentsList = studentsList.filter(element => element.key !== record.key);
                        this.setState({ studentsList });
                        message.success("Information Updated !");
                    }
                    else message.error("Operation Failed !");
                });
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { loadingModal, batchList, studentsList, uploadModalVisible, selectedBatchID, selectedBatchName, selectedCourseID, status, fileLoading, listModalVisible, editModalVisible, EDIT_BATCH_NAME, header_list, selectedSemester } = this.state;

        const params = {
            selectedBatchID: selectedBatchID,
            selectedCourseID: selectedCourseID,
            SEMESTER: parseInt(selectedSemester),
            KEY: config.key,
        }

        let props = {
            className: 'padding-2',
            listType: 'picture',
            accept: ".csv",
            name: 'csv',
            multiple: false,
            action: config.curriculaserver + '/curricula/faculty/batch/assign',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    //console.log(info.file);
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename];
                        if (info.file.response.Status === 0) { message.error(info.file.response.msg) }
                        else if (info.file.response) this.onLoad(this.state.selectedFacultyID);
                        return { fileStatus: fileStatus, status: info.file.response ? info.file.response : [], fileLoading: false };
                    });
                    if (info.file.response.Status !== 0)
                        message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                this.setState({ fileLoading: true })
                const isLt1M = file.size / 2024 / 2024 < 1;
                if (!isLt1M) {
                    message.error('File must smaller than 1 MB!');
                }
                return isLt1M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (studentsList.findIndex(i => i.key === text) + 1),
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                ...this.getColumnSearchProps('USER_ID'),
            },
            {
                title: 'NAME',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
                ...this.getColumnSearchProps('FULL_NAME'),
                render: (text) => (text.toUpperCase()),
            },
            {
                title: 'DEPARTMENT',
                dataIndex: 'DEPARTMENT',
                key: 'DEPARTMENT',
                ...this.getColumnSearchProps('DEPARTMENT'),
            },
            {
                title: 'ACTION',
                dataIndex: '_id',
                key: '_id',
                render: (text, record) => (
                    <Button icon={<DeleteOutlined />} size="small" danger onClick={this.onClickRemove.bind(this, record)}>Remove</Button>
                ),
            },
        ];

        return (<>
            <div className="card-container padding-1">

                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>

                    <Descriptions.Item label="Select Faculty" >
                        <Row gutter={[8, 8]} align="middle">
                            <Col xs={24}>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Search Faculty"
                                    onChange={this.handleChangeSelectFaculty}
                                    value={this.state.FULL_NAME}
                                    showSearch
                                >
                                    <Option value="" disabled>Choose One</Option>
                                    {
                                        this.state.FacultyList.length > 0 ? (
                                            this.state.FacultyList.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={val} value={item.USER_ID}>
                                                            <Tag color="cyan">{item.FULL_NAME.toUpperCase()}</Tag>
                                                            <Tag color="pink">{item.USER_ID}</Tag>
                                                            <Tag color="green">{item.DEPARTMENT}</Tag>
                                                        </Option>
                                                    )
                                                }
                                            )
                                        )
                                            :
                                            ''
                                    }
                                </Select>
                            </Col>

                        </Row>
                    </Descriptions.Item>
                </Descriptions>

                <br />

                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Section List & Student Assign" >
                        <Row gutter={[8, 8]} align="middle">
                            <Col span={24} align="start" className='mb-1'>
                                <Tag color="#f50">{"Total Section Created : " + batchList.length}  </Tag>
                            </Col>
                            <Col xs={24} md={24} align="start">
                                <List
                                    loading={loadingModal}
                                    grid={{
                                        gutter: [16, 16],
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 2,
                                        xl: 4,
                                        xxl: 4,
                                    }}
                                    dataSource={batchList || []}
                                    renderItem={(item, val) => (
                                        <List.Item key={val}>
                                            <Card className="course-card" size="small" type="inner" title={"Section Name : " + item.BATCH_NAME} hoverable
                                                extra={
                                                    <Tooltip title="Edit Section Name"><Button size="small" type="text" icon={<EditOutlined className="white-text" />} onClick={() => { this.setState({ selectedBatchID: item.BATCH_ID, selectedBatchName: item.BATCH_NAME, editModalVisible: true }); }} /></Tooltip>}
                                            >
                                                <Row gutter={[8, 8]} align="middle">
                                                    <Col span={12} align="start">
                                                        <Tag color="blue">Section ID : {item.BATCH_ID}</Tag>
                                                    </Col>
                                                    <Col span={24} align="start">
                                                        <Statistic title={<>{item.COURSE_NAME + " (" + item.COURSE_CODE + ")"}</>} value={item.COUNT || 0}
                                                            valueStyle={{ color: item.COUNT > 0 ? '#3f8600' : '#cf1322' }}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button block size="small" icon={<DownloadOutlined />} type="dashed"
                                                            disabled={!item.COUNT ? true : false}
                                                            onClick={this.onClickShowBatch.bind(this, item.BATCH_ID, item.BATCH_NAME, item.COURSE_CODE)}>VIEW</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button icon={<UploadOutlined />} block size="small" onClick={() => { this.setState({ selectedBatchID: item.BATCH_ID, selectedBatchName: item.BATCH_NAME, selectedCourseID: item.COURSE_CODE, selectedSemester: parseInt(item.SEMESTER), status: [], uploadModalVisible: true }); }} >UPLOAD</Button>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Descriptions.Item>
                </Descriptions>
            </div>

            <Modal title={selectedBatchName + " Upload List - " + selectedCourseID} visible={uploadModalVisible} onOk={this.handleCancel} onCancel={this.handleCancel} footer={null} className="padding-2">
                <Button className="mb-2" type="primary" icon={<DownloadOutlined />} href={Template} block>Download Excel Template</Button>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single file upload. Strictly prohibit from uploading irrelevant data or other
                        band files
                    </p>
                </Dragger>
                <br />  <br />
                <List
                    className={status.length > 0 ? '' : 'hide'}
                    loading={fileLoading} pagination
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={status}
                    renderItem={item => (
                        <List.Item style={{ padding: "0px 16px" }}>
                            <Tag style={{ width: "100%" }} color={item.status}>{item.msg}</Tag>
                        </List.Item>
                    )}
                />
            </Modal>

            <Modal title={selectedBatchName + " List"} visible={listModalVisible} onOk={this.handleCancel} onCancel={this.handleCancel} className="padding-2" footer={null} width={800}>
                <CSVLink data={studentsList || []} headers={header_list} filename={selectedBatchName + "-list.csv"}>
                    <Button type="primary" size="small" icon={<DownloadOutlined />} disabled={!studentsList.length ? true : false}>
                        Download EXCEL
                    </Button>
                </CSVLink>
                <Table className="mt-2" dataSource={studentsList} columns={columns} bordered size="small" />
            </Modal>

            <Modal title={<><EditOutlined className="mr-1" /> {selectedBatchName + " - Edit"}</>} visible={editModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} className="padding-2" >
                <Input id="EDIT_BATCH_NAME" addonBefore="New Section Name" onChange={this.handleChange} value={EDIT_BATCH_NAME} />
            </Modal>
        </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(FacultySectionList));