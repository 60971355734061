import React, { Component } from 'react'
import { Calendar, Spin, Tag, message, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
const color = ['#595959', '#015BA2', '#DA3026', '#1E8F3D', '#108ee', '#108ee', 'cyan', 'geekblue', '#108ee', '#108ee', '#108ee', '#108ee', '#108ee', 'default'];

class TimeTable extends Component {

    state = {
        loading: true,
        batches: this.props.batches,
        listData: [],
        thisMonth: new Date().getMonth() + 1,
        thisYear: new Date().getFullYear(),
        courses: []
    }

    async componentDidMount() {
        await this.getData(this.state.thisMonth, this.state.thisYear);
    }

    getData = async (MONTH, YEAR) => {
        this.setState({ loading: true });
        const userData = {
            batches: this.props.batches,
            MONTH: MONTH,
            YEAR: YEAR,
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/student/timetable/get', userData)
            .then(res => {
                if (res.data.Status === 1) {
                    message.success("Information Loaded !");
                    this.setState({ listData: res.data.result || [], courses: res.data.courseCodes, loading: false });
                }
            });
    }

    onPanelChange = async (value) => {
        let MONTH = parseInt(value.format('MM'));
        let YEAR = parseInt(value.format('YYYY'));
        await this.setState({ thisMonth: MONTH, thisYear: YEAR, MonthName: value.format('MMMM') })
        await this.getData(MONTH, YEAR);
    };

    getListData = (value) => {
        let listData = this.state.listData;
        let todayDate = value.date();
        if (listData) {
            var result = listData.filter(obj => {
                return obj.day === todayDate
            })
            if ((this.state.thisMonth - 1) === value.month())
                return Object.keys(result).length ? result[0].data : [];
            else
                return []
        }
        else return [];
    }

    tagColor = (content) => {
        let courses = this.state.courses;
        for (let i = 0; i <= courses.length; i++) if (content.includes(courses[i])) { return color[i]; }
    }

    dateCellRender = (value) => {
        const listData = this.getListData(value);
        return (
            <ul className="events">
                {listData.map((item, val) => (
                    item.time ?
                        <li key={item.time} style={{ marginLeft: "-25%" }}>
                            <Tag className="overlineCalenderTag" color="#2B7DE9">{item.time}</Tag>
                            <Tag style={{ marginLeft: "-10px" }} className="overlineCalenderTag" color={this.tagColor(item.course)}> {item.course}</Tag>
                        </li> : ''
                ))}
            </ul>
        );
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Alert message="You can view your courses timetable, which has been updated by your faculty !" type="info" showIcon />
                <Calendar fullscreen onSelect={this.onSelect} dateCellRender={this.dateCellRender} onPanelChange={this.onPanelChange} mode="month" />
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(TimeTable));