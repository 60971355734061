import React, { Component } from 'react'
import { Select, Typography, Row, Col, Space, Descriptions, Button, Tag, Upload, message, Spin, Result, Card } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
const { Option } = Select;
const { Text } = Typography;
const { Meta } = Card;
const page_name = "slc";

class LearningPPT extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        loading: true,
        UNIT: 1,
        SESSION: 0,
        SESSION_COUNT: 0,
        folderid: "",
        filename: "",
        fileStatus: [],
        error: 0
    }

    componentDidMount() {
        this.getCourse(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCourse(props.COURSE_CODE);
    }

    getCourse = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else
                        this.setState({ sessions: record, UNIT: 1, SESSION_COUNT: record[0].SESSIONCOUNT, error: 0 });
                }
            });

        this.handleFileStatus(COURSE_CODE);
    }

    handleFileStatus = (COURSE_CODE) => {
        let folderlink = "data/coordinator/" + COURSE_CODE + "/" + page_name;
        let sendData = {
            server: config.curriculaserver,
            path: folderlink,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/file/getallfilenames', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ fileStatus: res.data.result });
                }
                else
                    this.setState({ fileStatus: [] });

            });
        this.setState({ loading: false });
    }

    checkFileStatus = (sessionID) => {
        if (this.state.fileStatus.includes(parseInt(sessionID)))
            return <Tag color="green">Available</Tag>
        else
            return <Tag style={{ padding: "0px 20px" }} color="red">Empty</Tag>
    }


    handleChangeSelect = (label, value) => {
        this.setState({ [label]: parseInt(value), loading: true });
        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT });
        }
        this.setState({ loading: false });
    }

    setSession = (SESSION, SLO) => {
        let filename = (parseInt(this.state.UNIT) * 100 + parseInt(SESSION)) + "" + SLO + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/" + page_name;
        this.setState({ SESSION: SESSION, folderid: folderlink, filename: filename });
    }

    setButtonLink = (SESSION, SLO) => {
        let filename = (parseInt(this.state.UNIT) * 100 + parseInt(SESSION)) + "" + SLO + ".pdf";
        let folderlink = "data/coordinator/" + this.state.COURSE_CODE + "/" + page_name;

        let sendData = {
            path: folderlink,
            filename: filename,
            server: config.curriculaserver,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/file/getfile', sendData)
            .then(res => {
                if (res.data.Status === 0)
                    message.error(res.data.msg)
                else if (res.data.Status === 1)
                    window.open(res.data.result.path, '_blank');
            });
    }

    render() {
        let { UNIT } = this.state;
        const params = {
            key: config.key,
            path: this.state.folderid,
            filename: this.state.filename,
        }

        let props = {
            accept: ".pdf",
            name: 'file',
            multiple: false,
            action: config.curriculaserver + '/curricula/admin/file/upload',
            data: params,
            onChange: (info) => {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    this.setState(state => {
                        const fileStatus = [...state.fileStatus, params.filename]
                        return { fileStatus: fileStatus };
                    });
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            beforeUpload: (file) => {
                const PDF = file.type === 'application/pdf';
                if (!PDF) {
                    message.error('You can only upload PDF file!');
                }
                const isLt3M = file.size / 1024 / 1024 < 1;
                if (!isLt3M) {
                    message.error('File must smaller than 1MB!');
                }
                return PDF && isLt3M;
            },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
            },
        };

        return (
            <div className="padding-2">
                {this.state.error === 0 ?
                    (
                        <Spin spinning={this.state.loading}>

                            <Row gutter={[8, 8]}>
                                <Col xs={24} lg={20}>

                                    <Text className="justify">
                                        To enhance the teacher presentations and the overall comprehension of students, it allows to present their lessons in a more dynamic and effective way.
                                        <ol>
                                            <li>Set a Learning Objective</li>
                                            <li>Write an Outline for Key Ideas</li>
                                            <li>Give the Presentation About Education a "Why"</li>
                                            <li>Share Techniques and Tips for the Topic</li>
                                            <li>Teach With Visualizations</li>
                                            <li>Use Animations Carefully</li>
                                        </ol>
                                    </Text>
                                </Col>
                                <Col xs={24} lg={4}>
                                    <Card
                                        hoverable
                                        bordered={false}
                                        style={{
                                            width: "90%", boxShadow: "none"
                                        }}
                                        cover={<img alt="TEL" height="60" src={require('../../../images/pdf.svg').default} />}
                                    >
                                        <Meta style={{ textAlign: "center" }} description={"file.pdf < 1 MB"} />
                                    </Card>
                                </Col>
                            </Row>

                            <Descriptions
                                bordered
                                column={{ lg: 1, md: 1, sm: 1, xs: 1 }}
                                className="mb-1 mt-2"
                                size="small"
                            >
                                <Descriptions.Item label="Choose Unit">
                                    <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                                        onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </Descriptions.Item>
                            </Descriptions>

                            <Descriptions
                                bordered
                                column={{ lg: 1, md: 1, sm: 1, xs: 1 }}
                                className="mb-1 mt-1"
                                size="small"
                            >
                                {
                                    Array.from({ length: this.state.SESSION_COUNT }, (v, SESSION) => {
                                        return (
                                            <Descriptions.Item label={"Session " + (SESSION + 1)} key={(SESSION + 1)}>
                                                <Row gutter={[8, 8]}>
                                                    <Col xs={24} lg={12}>
                                                        <Space>
                                                            <Text keyboard>SLO 1</Text>
                                                            <Upload {...props} >
                                                                <Button size="small" onClick={this.setSession.bind(this, (SESSION + 1), 1)}>
                                                                    <UploadOutlined /> Click to Upload
                                                                </Button>
                                                            </Upload>
                                                            {this.checkFileStatus((UNIT * 100) + (SESSION + 1) + "" + 1 + "")}
                                                            <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, (SESSION + 1), 1)}> View</Button>
                                                        </Space>
                                                    </Col>
                                                    <Col xs={24} lg={12}>
                                                        <Space>
                                                            <Text keyboard>SLO 2</Text>
                                                            <Upload {...props}>
                                                                <Button size="small" onClick={this.setSession.bind(this, (SESSION + 1), 2)}>
                                                                    <UploadOutlined /> Click to Upload
                                                                </Button>
                                                            </Upload>
                                                            {this.checkFileStatus((UNIT * 100) + (SESSION + 1) + "" + 2 + "")}
                                                            <Button type="primary" icon={<DownloadOutlined />} size={"small"} onClick={this.setButtonLink.bind(this, (SESSION + 1), 2)}>View</Button>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Descriptions.Item>
                                        )
                                    })
                                }
                            </Descriptions>
                        </Spin>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LearningPPT));