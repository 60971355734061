import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CourseAssign from './CourseAssign'
import BatchCreation from './BatchCreation';
import FacultySectionList from './FacultySectionList';
import AllSectionsList from './AllSectionsList';
import {
    UsergroupAddOutlined, UserAddOutlined,UnorderedListOutlined,UserOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;

class ManageFaculty extends Component {
    state = {
        loading: false,
    };

    render() {
        let tabName = ["", "Add Faculty to Course", "Section Creation & Student Assign", "Facultywise Section List", 'All Sections List'];

        return (
            <Row style={{ overflow: "auto" }}>
                <Col xs={24} className="mt-1 mb-1">
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                                <TabPane tab={
                                    <span>
                                        <UserAddOutlined />
                                        {tabName[1]}
                                    </span>
                                } key="1"><CourseAssign /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <UsergroupAddOutlined />
                                        {tabName[2]}
                                    </span>
                                } key="2"><BatchCreation /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <UserOutlined />
                                        {tabName[3]}
                                    </span>
                                } key="3"><FacultySectionList /></TabPane>
                                <TabPane tab={
                                    <span>
                                        <UnorderedListOutlined />
                                        {tabName[4]}
                                    </span>
                                } key="4"><AllSectionsList /></TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ManageFaculty));