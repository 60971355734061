import React, { Component } from 'react'
import { Row, Input, Col, message, Button, Descriptions, Statistic, Select } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Donut from '../../chart/Donut';
import { EnterOutlined } from '@ant-design/icons';
const { Option } = Select;

class Weightage extends Component {

    state = {
        loading: true,
        LO: JSON.parse(localStorage.getItem('flags')).LO || 0,
        UNIT_MARKS: JSON.parse(localStorage.getItem('flags')).UNIT_MARKS || 0,
        MCQ: JSON.parse(localStorage.getItem('flags')).MCQ || 0,
        STATUS: JSON.parse(localStorage.getItem('flags')).STATUS || 0,
        PRACTICE: JSON.parse(localStorage.getItem('flags')).PRACTICE || 0,
        SLOSTATUS: JSON.parse(localStorage.getItem('flags')).SLOSTATUS || 0,
        CLA: JSON.parse(localStorage.getItem('flags')).CLA || 0,
        ATTENDANCE: JSON.parse(localStorage.getItem('flags')).ATTENDANCE || 0,
        COLLEGE_NAME: config.institutionName,
        total: 0,
        key: 0,
        CURRENT_SEMESTER: parseInt(JSON.parse(localStorage.getItem('flags')).CURRENT_SEMESTER || 0),
    }

    componentDidMount() {
        let { LO, UNIT_MARKS, MCQ, STATUS, PRACTICE, SLOSTATUS, CLA, ATTENDANCE } = (this.state);
        let details = {
            data: [
                { Type: 'Learning Outcomes', Value: LO || 0 },
                { Type: 'Unit Marks', Value: UNIT_MARKS || 0 },
                { Type: 'MCQ', Value: MCQ || 0 },
                { Type: 'Session Status', Value: STATUS || 0 },
                { Type: 'Learning Practice', Value: PRACTICE || 0 },
                { Type: 'SLO Status', Value: SLOSTATUS || 0 },
                { Type: 'CLA', Value: CLA || 0 },
                { Type: 'Attendance', Value: ATTENDANCE || 0 },
            ],
            title: "Weightage",
            xField: "Type",
            yField: "Value"
        };

        let total = LO + UNIT_MARKS + MCQ + STATUS + PRACTICE + SLOSTATUS + CLA + ATTENDANCE;

        this.setState({
            total,
            details,
            key: 2
        })
    }

    handleChange = e => {
        e.target.value = !e.target.value ? 0 : e.target.value;
        this.setState({ [e.target.id]: e.target.value });
        this.status(e.target.id, parseInt(e.target.value));
    };

    status = (Type, value) => {
        let { LO, UNIT_MARKS, MCQ, STATUS, PRACTICE, SLOSTATUS, CLA, ATTENDANCE } = (this.state);

        let details = {
            data: [
                { Type: 'Learning Outcomes', Value: parseInt(LO) || 0 },
                { Type: 'Unit Marks', Value: parseInt(UNIT_MARKS) || 0 },
                { Type: 'MCQ', Value: parseInt(MCQ) || 0 },
                { Type: 'Session Status', Value: parseInt(STATUS) || 0 },
                { Type: 'Learning Practice', Value: parseInt(PRACTICE) || 0 },
                { Type: 'SLO Status', Value: parseInt(SLOSTATUS) || 0 },
                { Type: 'CLA', Value: parseInt(CLA) || 0 },
                { Type: 'Attendance', Value: parseInt(ATTENDANCE) || 0 },
            ],
            title: "WEIGHTAGE",
            xField: "Type",
            yField: "Value"
        };

        if (Type === "LO") Type = 'Learning Outcomes';
        else if (Type === "UNIT_MARKS") Type = 'Unit Marks';
        else if (Type === "MCQ") Type = 'MCQ';
        else if (Type === "STATUS") Type = 'Session Status';
        else if (Type === "PRACTICE") Type = 'Learning Practice';
        else if (Type === "SLOSTATUS") Type = 'SLO Status';
        else if (Type === "CLA") Type = 'CLA';
        else if (Type === "ATTENDANCE") Type = 'Attendance';

        var objIndex = parseInt(details.data.findIndex((obj => obj.Type === Type)));
        details.data[objIndex].Value = value;

        let total = 0;
        details.data.forEach(function (entry) {
            total += parseInt(entry.Value)
        });

        this.setState({
            total,
            details,
            key: 2
        })
    }

    handleChangeSelect = (value) => {
        this.setState({ CURRENT_SEMESTER: parseInt(value) });
    }

    onClickAdd = () => {
        let data = {
            COLLEGE_NAME: config.institutionName,
            LO: parseInt(this.state.LO),
            UNIT_MARKS: parseInt(this.state.UNIT_MARKS),
            MCQ: parseInt(this.state.MCQ),
            STATUS: parseInt(this.state.STATUS),
            PRACTICE: parseInt(this.state.PRACTICE),
            SLOSTATUS: parseInt(this.state.SLOSTATUS),
            CLA: parseInt(this.state.CLA),
            ATTENDANCE: parseInt(this.state.ATTENDANCE),
            CURRENT_SEMESTER: parseInt(this.state.CURRENT_SEMESTER),
        }

        /*  if (!data.LO || !data.UNIT_MARKS || !data.MCQ || !data.STATUS || !data.PRACTICE || !data.SLOSTATUS) {
              message.error('Invalid Entry !');
              return false;
          }
          */

        let sendData = {
            data: data,
            key: config.key,
        }

        let total = data.LO + data.UNIT_MARKS + data.MCQ + data.STATUS + data.PRACTICE + data.SLOSTATUS + data.CLA + data.ATTENDANCE;

        if (total !== 100) {
            message.error('Invalid Total Percentage !');
            return false;
        }

        axios.post(config.curriculaserver + '/curricula/admin/home/updateconfig', sendData)
            .then(res => {
                if (res.data.Status === 1)
                    message.success('Information Updated. Please Logout & Login !');
                else
                    message.error('Failed Entry !');
            });
    }

    render() {
        let { LO, UNIT_MARKS, MCQ, STATUS, PRACTICE, SLOSTATUS, CLA, ATTENDANCE } = this.state;
        return (
            <div>
                <Descriptions
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    span={5}
                >

                    <Descriptions.Item label="Internal Marks Weightage Distribution (%)" layout="vertical">
                        <Row gutter={[16, 16]} className="mt-1" justify="center" align="middle">
                            {
                                this.state.key === 2 ? (
                                    <>
                                        <Col xs={4} align="middle">
                                            {
                                                <Statistic suffix="%"
                                                    title="Total" value={this.state.total}
                                                    valueStyle={{ color: this.state.total === 100 ? '#3f8600' : '#cf1322' }} />

                                            }
                                        </Col>
                                        <Col xs={20} align="middle" className="mt-1 mb-2">
                                            <Donut details={this.state.details} />
                                        </Col>
                                    </>
                                ) : ''
                            }
                        </Row>

                    </Descriptions.Item>

                    <Descriptions.Item label="Weightage Parameters">

                        <Row gutter={[16, 16]} justify="center" align="middle">

                            <Col xs={24} md={12}>
                                <Input value={LO} type="number" addonBefore="Learning Outcomes" id="LO" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={UNIT_MARKS} type="number" addonBefore={config.UnitTestName} id="UNIT_MARKS" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={MCQ} type="number" addonBefore="MCQ Completion Status" id="MCQ" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={STATUS} type="number" addonBefore="Session Completion Status" id="STATUS" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={PRACTICE} type="number" addonBefore="Learning Practice Completion" id="PRACTICE" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={SLOSTATUS} type="number" addonBefore="SLO Completion" id="SLOSTATUS" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={CLA} type="number" addonBefore={config.CLAfullName} id="CLA" onChange={this.handleChange} />
                            </Col>
                            <Col xs={24} md={12}>
                                <Input value={ATTENDANCE} type="number" addonBefore="Learning Participation" id="ATTENDANCE" onChange={this.handleChange} />
                            </Col>
                        </Row>
                    </Descriptions.Item>


                    <Descriptions.Item label="Current Semester" span={2}>

                        <Select style={{ width: "100%" }} onChange={this.handleChangeSelect} value={this.state.CURRENT_SEMESTER}>
                            <Option value="" disabled>Choose Semester</Option>
                            <Option value="0">ALL</Option>
                            <Option value="1">SEMESTER 1</Option>
                            <Option value="2">SEMESTER 2</Option>
                            <Option value="3">SEMESTER 3</Option>
                            <Option value="4">SEMESTER 4</Option>
                            <Option value="5">SEMESTER 5</Option>
                            <Option value="6">SEMESTER 6</Option>
                            <Option value="7">SEMESTER 7</Option>
                            <Option value="8">SEMESTER 8</Option>
                            <Option value="9">SEMESTER 9</Option>
                            <Option value="10">SEMESTER 10</Option>
                        </Select>

                    </Descriptions.Item>

                    <Descriptions.Item label="Action">
                        <Button icon={<EnterOutlined />} type="primary" onClick={this.onClickAdd}>Submit</Button>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Weightage));