import React, { Component } from 'react'
import { Tabs, message, Skeleton, Result } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import SLOSROInfo from './SLOSROInfo'
const { TabPane } = Tabs;

class SLOSRO extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        CLS: [],
        error: 0
    }

    componentDidMount() {
        this.getSLOSRO(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getSLOSRO(props.COURSE_CODE);
    }

    getSLOSRO = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let page_name = "course_learning_session";
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let CLS = res.data.selectedCourseData;
                        if (Object.keys(CLS).length === 0) {
                            this.setState({ error: 1 })
                        }
                        else
                            this.setState({ CLS: CLS, loading: false });
                    }
                });
        }
        else
            message.error("Course Not Selected");
    }

    render() {
        let { CLS, loading, COURSE_CODE, COURSE_NAME } = this.state;
        return (
            <div>
                {
                    this.state.error === 0 ?
                        (
                            loading === false ?
                                (<Tabs type="card" className="mt-2 mb-2" defaultActiveKey="1">
                                    <TabPane tab="Unit 1" key="1" className="border-1">
                                        <SLOSROInfo UNIT={ 1 } SESSIONCOUNT={ CLS[0].SESSIONCOUNT } COURSE_CODE={ COURSE_CODE } COURSE_NAME={ COURSE_NAME } />
                                    </TabPane>
                                    <TabPane tab="Unit 2" key="2" className="border-1">
                                        <SLOSROInfo UNIT={ 2 } SESSIONCOUNT={ CLS[1].SESSIONCOUNT } COURSE_CODE={ COURSE_CODE } COURSE_NAME={ COURSE_NAME } />
                                    </TabPane>
                                    <TabPane tab="Unit 3" key="3" className="border-1">
                                        <SLOSROInfo UNIT={ 3 } SESSIONCOUNT={ CLS[2].SESSIONCOUNT } COURSE_CODE={ COURSE_CODE } COURSE_NAME={ COURSE_NAME } />
                                    </TabPane>
                                    <TabPane tab="Unit 4" key="4" className="border-1">
                                        <SLOSROInfo UNIT={ 4 } SESSIONCOUNT={ CLS[3].SESSIONCOUNT } COURSE_CODE={ COURSE_CODE } COURSE_NAME={ COURSE_NAME } />
                                    </TabPane>
                                    <TabPane tab="Unit 5" key="5" className="border-1">
                                        <SLOSROInfo UNIT={ 5 } SESSIONCOUNT={ CLS[4].SESSIONCOUNT } COURSE_CODE={ COURSE_CODE } COURSE_NAME={ COURSE_NAME } />
                                    </TabPane>
                                </Tabs>) :
                                <Skeleton active={ true } />)

                        :
                        <Result
                            status="404"
                            title="Course Learning Session is Empty"
                            subTitle="Unit and Session information are empty"
                        />
                }
            </div>

        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(SLOSRO));