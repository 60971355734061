import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Table, Input, Popconfirm, Space, Tooltip, Button, Typography, message, Alert } from 'antd';
import axios from 'axios';
import { SearchOutlined, UnlockOutlined, DeleteOutlined, RedoOutlined, LockOutlined, DownloadOutlined } from '@ant-design/icons';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { CSVLink } from "react-csv";
const { Text } = Typography;

class manageuser extends Component {

    state = {
        loading: false,
        data: [],
        errorMessages: [],
        disable: false,
        searchText: '',
        searchedColumn: '',
        header_list: [
            { label: "User ID", key: "USER_ID" },
            { label: "Name", key: "FULL_NAME" },
            { label: "Register Number", key: "REGISTER_NUMBER" },
            { label: "Department", key: "DEPARTMENT" },
            { label: "Course", key: "MAIL_ID" },
            { label: "Mobile", key: "MOBILE" },
            { label: "Role", key: "ROLE" },
        ],
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        let data = {
            info: this.props.common.user,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/manageuser/listallfaculty', data)
            .then(res => {
                if (res.data.Status === 1) {
                    if (this.props.common.user.ROLE === 'C') {
                        let result = res.data.result.filter(function (el) { return el.ROLE === 'S' || el.ROLE === 'TF'; });
                        this.setState({ data: result, loading: false });
                    }
                    else if (this.props.common.user.ROLE === 'TF') {
                        let result = res.data.result.filter(function (el) { return el.ROLE === 'S'; });
                        this.setState({ data: result, loading: false });
                    }
                    else this.setState({ data: res.data.result, loading: false });
                    message.success('Inofrmation Loaded');
                }
            })
    }

    onClickPasswordResetBtn = (record) => {
        if (record.USER_ID) {
            let data = {
                info: record,
                key: config.key
            };
            axios.post(config.curriculaserver + '/curricula/admin/manageuser/passwordreset', data)
                .then(res => {
                    if (res.data.Status === 1) {
                        message.success('Password reset as ' + record.USER_ID);
                    }
                })
        }
    }

    onClickLockResetBtn = (record) => {
        if (typeof record.LOCK == "undefined") { record.LOCK = 0; }
        if (record.LOCK === 0 || record.LOCK === 1) {

            if (record.LOCK === 0) record.LOCK = 1; else record.LOCK = 0;

            let data = {
                info: record,
                key: config.key
            };
            axios.post(config.curriculaserver + '/curricula/admin/manageuser/lockreset', data)
                .then(res => {
                    if (res.data.Status === 1) {
                        let data = this.state.data;
                        for (var i = 0; i < data.length; i++) {
                            if (data[i].USER_ID === record.USER_ID) {
                                data[i].LOCK = record.LOCK;
                                break;
                            }
                        }
                        this.setState({ data: data });
                        message.success('Lock Reset successfully!');
                    }
                })
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getRole = (text) => {
        let value;
        if (text === "S") value = <Text>STUDENT</Text>;
        else if (text === "TF") value = <Text>COURSE FACILITATOR</Text>;
        else if (text === "C") value = <Text>COURSE COORDINATOR</Text>;
        else if (text === "B") value = <Text>BATCH ADMIN</Text>;
        else if (text === "H") value = <Text>HOD</Text>;
        else value = <Text>EMPTY</Text>;
        return value;
    }

    render() {
        let { data } = this.state;

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'USER_ID',
                width: 0.5,
                render: (text) => (data.findIndex(i => i.USER_ID === text) + 1),
                fixed: 'left',
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                width: 1,
                ...this.getColumnSearchProps('USER_ID'),
                render: (text) => (
                    <Text>{text}</Text>
                ),
                fixed: 'left',
            },
            {
                title: 'NAME',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
                ...this.getColumnSearchProps('FULL_NAME'),
                render: (text) => (text.toUpperCase()),
                width: 1,
            },
            {
                title: 'REGISTER NUMBER',
                dataIndex: 'REGISTER_NUMBER',
                key: 'REGISTER_NUMBER',
                width: 1,
                ...this.getColumnSearchProps('REGISTER_NUMBER'),
            },
            {
                title: 'DEPARTMENT',
                dataIndex: 'DEPARTMENT',
                key: 'DEPARTMENT',
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 2,
            },
            {
                title: 'ROLE',
                dataIndex: 'ROLE',
                ...this.getColumnSearchProps('ROLE'),
                render: (text) => (
                    this.getRole(text)
                ),
                width: 1,
            },
            {
                title: 'MAIL',
                dataIndex: 'MAIL_ID',
                ...this.getColumnSearchProps('MAIL_ID'),
                width: 1,
            },
            {
                title: 'MOBILE',
                dataIndex: 'MOBILE',
                ...this.getColumnSearchProps('MOBILE'),
                width: 1,
            },
            {
                title: 'ACTION',
                dataIndex: 'LOCK',
                render: (text, record) => (
                    <>
                        <Tooltip title="Password Reset">
                            <Button disabled={this.props.common.user.ROLE !== 'A' && this.props.common.user.ROLE !== 'C' && this.props.common.user.ROLE !== 'B' && this.props.common.user.ROLE !== 'TF' ? true : false} icon={<RedoOutlined />} type="primary" onClick={this.onClickPasswordResetBtn.bind(this, record)} /></Tooltip>&nbsp;
                        <Tooltip title="Lock Reset">
                            <Button type="primary"
                                className={this.props.common.user.ROLE !== 'A' && this.props.common.user.ROLE !== 'B' ? "hide" : ''}
                                icon={text === 1 ? <LockOutlined /> : <UnlockOutlined />}
                                onClick={this.onClickLockResetBtn.bind(this, record)} danger={text === 1 ? true : false} />
                        </Tooltip>&nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size='small' className={this.props.common.user.ROLE !== 'A' ? "hide" : ''} type="primary" danger disabled icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 1,
            },
        ];

        return (
            <div className="card-container padding-1">
                <Row gutter={[16, 16]} justify="end">
                    {
                        this.props.common.user.ROLE !== 'B' ?
                            <Col xs={24}>
                                <Alert message="For Faculty / Student Registration, Kindly Contact Batch Admin / Coordinator !" type="info" showIcon closable />
                            </Col> : null
                    }

                    {
                        this.props.common.user.ROLE === 'TF' ?
                            <Col xs={24}>
                                <Alert message="For Faculty password reset, Kindly Contact Course Coordinator !" type="success" showIcon closable />
                            </Col> : null
                    }

                    <Col xs={24}>
                        <CSVLink data={data} headers={this.state.header_list} filename={"users-report.csv"}>
                            <Button type="dashed" icon={<DownloadOutlined />} size="small"
                                className={this.props.common.user.ROLE !== 'A' && this.props.common.user.ROLE !== 'B' ? 'hide' : ''}>
                                Download EXCEL
                            </Button>
                        </CSVLink>
                    </Col>
                    <Col xs={24}>
                        <Table dataSource={data} columns={columns} scroll={{ x: 1400 }} bordered loading={this.state.loading} size="small" />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(manageuser));