import React, { Component } from 'react'
import { Descriptions, Table, Space, Button, Row, Col, Card, Badge, Statistic, Tag, Tooltip, Input } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import BarChart from '../../chart/BarChart';
import Donut from '../../chart/Donut';
import { DownloadOutlined, EnterOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { CSVLink } from "react-csv";

class OverallReport extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE,
        COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        topic: '',
        users: [],
        marks: [],
        selectedStudent: '',
        key: 0,
        attendanceDetailed: {},
        ATTENDANCE_DATA: []
    }

    componentDidMount() {
        let sendData = {
            key: config.key,
            COURSE_INFO: this.props.COURSE_INFO,
        };
        axios.post(config.curriculaserver + '/curricula/admin/report/getoverallreport', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({ marks: res.data.result, ATTENDANCE_DATA: res.data.ATTENDANCE_DATA, loading: false });
                }
            });
    }

    display = (record) => {
        let LO = {
            data: [
                { Unit: 'UNIT 1', Value: record.LO.UNIT1 },
                { Unit: 'UNIT 2', Value: record.LO.UNIT2 },
                { Unit: 'UNIT 3', Value: record.LO.UNIT3 },
                { Unit: 'UNIT 4', Value: record.LO.UNIT4 },
                { Unit: 'UNIT 5', Value: record.LO.UNIT5 },
            ],
            title: "Learning Outcomes",
            xField: "Unit",
            yField: "Value"
        };
        let MCQ = {
            data: [
                { Unit: 'UNIT 1', Value: record.MCQ.UNIT1 },
                { Unit: 'UNIT 2', Value: record.MCQ.UNIT2 },
                { Unit: 'UNIT 3', Value: record.MCQ.UNIT3 },
                { Unit: 'UNIT 4', Value: record.MCQ.UNIT4 },
                { Unit: 'UNIT 5', Value: record.MCQ.UNIT5 },
            ],
            title: "MCQ",
            xField: "Unit",
            yField: "Value"
        }
        let PRACTICE = {
            data: [
                { Unit: 'UNIT 1', Value: record.PRACTICE.UNIT1 },
                { Unit: 'UNIT 2', Value: record.PRACTICE.UNIT2 },
                { Unit: 'UNIT 3', Value: record.PRACTICE.UNIT3 },
                { Unit: 'UNIT 4', Value: record.PRACTICE.UNIT4 },
                { Unit: 'UNIT 5', Value: record.PRACTICE.UNIT5 },
            ],
            title: "Learning Practice",
            xField: "Unit",
            yField: "Value"
        }
        let STATUS = {
            data: [
                { Unit: 'UNIT 1', Value: record.STATUS.UNIT1 },
                { Unit: 'UNIT 2', Value: record.STATUS.UNIT2 },
                { Unit: 'UNIT 3', Value: record.STATUS.UNIT3 },
                { Unit: 'UNIT 4', Value: record.STATUS.UNIT4 },
                { Unit: 'UNIT 5', Value: record.STATUS.UNIT5 },
            ],
            title: "Session Status",
            xField: "Unit",
            yField: "Value"
        }
        let SLOSTATUS = {
            data: [
                { Unit: 'UNIT 1', Value: parseFloat(record.SLOSTATUS.UNIT1.toFixed(2)) },
                { Unit: 'UNIT 2', Value: parseFloat(record.SLOSTATUS.UNIT2.toFixed(2)) },
                { Unit: 'UNIT 3', Value: parseFloat(record.SLOSTATUS.UNIT3.toFixed(2)) },
                { Unit: 'UNIT 4', Value: parseFloat(record.SLOSTATUS.UNIT4.toFixed(2)) },
                { Unit: 'UNIT 5', Value: parseFloat(record.SLOSTATUS.UNIT5.toFixed(2)) },
            ],
            title: "SLO Status",
            xField: "Unit",
            yField: "Value"
        }
        let UNIT_MARKS = {
            data: [
                { Unit: 'UNIT 1', Value: parseFloat(record.UNIT_MARKS.UNIT1.toFixed(2)) },
                { Unit: 'UNIT 2', Value: parseFloat(record.UNIT_MARKS.UNIT2.toFixed(2)) },
                { Unit: 'UNIT 3', Value: parseFloat(record.UNIT_MARKS.UNIT3.toFixed(2)) },
                { Unit: 'UNIT 4', Value: parseFloat(record.UNIT_MARKS.UNIT4.toFixed(2)) },
                { Unit: 'UNIT 5', Value: parseFloat(record.UNIT_MARKS.UNIT5.toFixed(2)) },
            ],
            title: "Unit Marks",
            xField: "Unit",
            yField: "Value"
        }
        let CLA = {
            data: [
                { Mark: 'MARKS', Value: record.CLA.MARKS },
            ],
            title: config.CLAfullName,
            xField: "Mark",
            yField: "Value"
        }
        let ATTENDANCE = {
            data: [
                { Unit: 'UNIT 1', Value: parseFloat(record.ATTENDANCE.UNIT1.toFixed(2)) },
                { Unit: 'UNIT 2', Value: parseFloat(record.ATTENDANCE.UNIT2.toFixed(2)) },
                { Unit: 'UNIT 3', Value: parseFloat(record.ATTENDANCE.UNIT3.toFixed(2)) },
                { Unit: 'UNIT 4', Value: parseFloat(record.ATTENDANCE.UNIT4.toFixed(2)) },
                { Unit: 'UNIT 5', Value: parseFloat(record.ATTENDANCE.UNIT5.toFixed(2)) },
            ],
            title: "Attendance  ",
            xField: "Unit",
            yField: "Value"
        }
        let details = {
            data: [
                { Type: 'UNIT 1', Value: parseFloat(record.INTERNAL_MARKS.UNIT1.toFixed(2)) },
                { Type: 'UNIT 2', Value: parseFloat(record.INTERNAL_MARKS.UNIT2.toFixed(2)) },
                { Type: 'UNIT 3', Value: parseFloat(record.INTERNAL_MARKS.UNIT3.toFixed(2)) },
                { Type: 'UNIT 4', Value: parseFloat(record.INTERNAL_MARKS.UNIT4.toFixed(2)) },
                { Type: 'UNIT 5', Value: parseFloat(record.INTERNAL_MARKS.UNIT5.toFixed(2)) },
            ],
            title: "Internal Marks",
            xField: "Type",
            yField: "Value"
        };
        let total = record.INTERNAL_MARKS.OVERALL_PERCENTAGE;

        let ATTENDANCE_DATA = this.state.ATTENDANCE_DATA;
        let index = ATTENDANCE_DATA.findIndex(x => x.USER_ID === record.USER_ID);
        let individualATTENDANCE = ATTENDANCE_DATA[index].ATTENDANCE;

        this.setState({
            LO, MCQ, PRACTICE, STATUS, SLOSTATUS, UNIT_MARKS, CLA, ATTENDANCE, details, total,
            attendanceDetailed: individualATTENDANCE,
            selectedStudent: record.FULL_NAME + " (" + record.USER_ID + ")",
            key: 2
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { marks, loading, attendanceDetailed } = this.state;
        let headers = [
            { label: "User ID", key: "USER_ID" },
            { label: "User Name", key: "FULL_NAME" },
            { label: "UNIT 1", key: "INTERNAL_MARKS.UNIT1" },
            { label: "UNIT 2", key: "INTERNAL_MARKS.UNIT2" },
            { label: "UNIT 3", key: "INTERNAL_MARKS.UNIT3" },
            { label: "UNIT 4", key: "INTERNAL_MARKS.UNIT4" },
            { label: "UNIT 5", key: "INTERNAL_MARKS.UNIT5" },
            { label: "TOTAL", key: "INTERNAL_MARKS.OVERALL" }
        ];
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (marks.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'USER ID',
                dataIndex: 'USER_ID',
                key: 'USER_ID',
                ...this.getColumnSearchProps('USER_ID'),
                fixed: 'left',
            },
            {
                title: 'USER NAME',
                dataIndex: 'FULL_NAME',
                key: 'FULL_NAME',
                ...this.getColumnSearchProps('FULL_NAME'),
            },
            {
                title: 'UNIT 1 (100)',
                dataIndex: ['INTERNAL_MARKS', 'UNIT1'],
                sorter: (a, b) => a.INTERNAL_MARKS.UNIT1 - b.INTERNAL_MARKS.UNIT1,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                )
            },
            {
                title: 'UNIT 2 (100)',
                dataIndex: ['INTERNAL_MARKS', 'UNIT2'],
                sorter: (a, b) => a.INTERNAL_MARKS.UNIT2 - b.INTERNAL_MARKS.UNIT2,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                )
            },
            {
                title: 'UNIT 3 (100)',
                dataIndex: ['INTERNAL_MARKS', 'UNIT3'],
                sorter: (a, b) => a.INTERNAL_MARKS.UNIT3 - b.INTERNAL_MARKS.UNIT3,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                )
            },
            {
                title: 'UNIT 4 (100)',
                dataIndex: ['INTERNAL_MARKS', 'UNIT4'],
                sorter: (a, b) => a.INTERNAL_MARKS.UNIT4 - b.INTERNAL_MARKS.UNIT4,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                )
            },
            {
                title: 'UNIT 5 (100)',
                dataIndex: ['INTERNAL_MARKS', 'UNIT5'],
                sorter: (a, b) => a.INTERNAL_MARKS.UNIT5 - b.INTERNAL_MARKS.UNIT5,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                )
            },
            {
                title: 'TOTAL (500)',
                dataIndex: ['INTERNAL_MARKS', 'OVERALL'],
                sorter: (a, b) => a.INTERNAL_MARKS.OVERALL - b.INTERNAL_MARKS.OVERALL,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                ),
                fixed: 'right',
            },
            {
                title: 'TOTAL (100)',
                dataIndex: ['INTERNAL_MARKS', 'OVERALL_PERCENTAGE'],
                sorter: (a, b) => a.INTERNAL_MARKS.OVERALL_PERCENTAGE - b.INTERNAL_MARKS.OVERALL_PERCENTAGE,
                render: (text, record) => (
                    (text ? parseFloat(text).toFixed(2) : 0)
                ),
                fixed: 'right',
            },
            {
                title: 'DETAILED',
                render: (text, record) => (
                    <Space>
                        <Button icon={<EnterOutlined />} size="small" type="primary" onClick={this.display.bind(this, record)}>FULL VIEW</Button>
                    </Space>
                ),
                fixed: 'right',
                width: 2,
            },
        ];

        let extraCard = <><Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>;
        return (
            <div>
                <CSVLink data={marks} headers={headers} filename={"internal-marks.csv"} >
                    <Button size="middle" icon={<DownloadOutlined />}>Download EXCEL</Button>
                </CSVLink>

                <Table className="mt-1" size="middle" bordered style={{ width: "100%" }} dataSource={marks} columns={columns}
                    loading={loading} />
                {
                    this.state.key === 2 ?
                        (
                            <Descriptions
                                bordered
                                column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                                size="small"
                                className="mb-2"
                            >
                                <Descriptions.Item label="Student">
                                    {this.state.selectedStudent}
                                </Descriptions.Item>
                                <Descriptions.Item label="Course">
                                    <Tag color="blue">{this.props.COURSE_CODE}</Tag>
                                    <Tag color="magenta">{this.props.COURSE_NAME}</Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Internal Scored">
                                    {<>
                                        <Row justify="space-around" align="middle">
                                            <Col span={4} align="middle">
                                                <Statistic suffix=""
                                                    title="Total" value={this.state.total + " / 100"}
                                                    valueStyle={{ color: this.state.total > 40 ? '#3f8600' : this.state.total > 25 ? "#faad14" : '#cf1322' }} />
                                            </Col>
                                            <Col span={20} align="middle" className="padding-5">
                                                <Donut details={this.state.details} />
                                            </Col>
                                        </Row>
                                    </>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Attendance (U-Unit, S-Session)" span={2}>
                                    <Row gutter={[2, 8]} justify="start" className="padding-1">
                                        {
                                            Object.keys(attendanceDetailed).map((item, val) => (
                                                <Col span={2} key={val}>
                                                    <Tooltip title={attendanceDetailed[item] === 1 ? "Present" : "Absent / Not Updated"}>
                                                        <Tag color={attendanceDetailed[item] === 1 ? "cyan" : "error"}>{"U" + Math.floor(item / 100) + " S" + Math.floor(item % 100)}</Tag>
                                                    </Tooltip>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    <Row gutter={[16, 16]} className="justify padding-1">
                                        <Col span={8}>
                                            <Card title="Learning Outcomes Achived" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.LO} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title="Multiple Choice Questions Completion" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.MCQ} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title="Learning Practice Completion" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.PRACTICE} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title="Session Completion Status" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.STATUS} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title="SLO Completion Status" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.SLOSTATUS} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title={config.UnitTestName} size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.UNIT_MARKS} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title={config.CLAfullName + " Marks Earned"} size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.CLA} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title="Attendance Marks Earned" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.ATTENDANCE} />
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card title="Internal Marks Earned" size="small" type="inner" style={{ width: "100%" }} extra={extraCard}>
                                                <BarChart details={this.state.details} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : ''
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(OverallReport));