import React, { Component } from 'react'
import { Row, Col, Card, Button, Select, Input, DatePicker, Tag, Tooltip, Table, notification, Alert, Popconfirm, Empty, Badge } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { MinusOutlined, UploadOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
const InputGroup = Input.Group;
const { Option } = Select;
const { Text, Title } = Typography;
const dateFormat = ['YYYY'];
const topic = "book", subtopic = "book";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};
var publishers = ["Thomas Reuters", "Barnes and Noble", "Nova Science", "SAGE", "Macmillan", "CRC Press", "Purdue", "BrownWalker", "Fordham", "Cambridge Press", "Wilfrid", "Wiley", "Allen and Unwin", "McGraw Hill", "Springer", "Pearson", "PHI / Prentice Hall", "Nelson", "IEEE", "Elsevier", "Oxford Press", "Kindle", "S.Chand", "Himalaya Publishing", "TMH", "AneBooks", "Khanna", "Galgotia", "Vikas", "Charotar", "BBP", "TEL Curricula", "Others"];
const roleList = ["AUTHOR"];

function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class Book extends Component {
    state = {
        publicationType: '',
        title: '',
        publisherName: '',
        isbn: '',
        publishedYear: '2020',
        editionNumber: '',
        details: [],
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        authorCount: 0,
        institutionName: config.institutionName,
        docStatus: 0
    };
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }


    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            subtopic: subtopic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/getresearchtable', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }


    onChange = e => {
        if (e.target.id === "authorCount") {
            if (e.target.value > 5) e.target.value = 5;
            var details = [
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "AUTHOR",
                    NAME:
                        this.props.common.user.FIRST_NAME +
                        " " +
                        this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: this.state.institutionName
                }
            ];
            for (let i = 1; i < e.target.value; i++) {
                let newDetail = {
                    _id: details.length + 1,
                    EMPLOYEEID: "",
                    ROLE: "AUTHOR",
                    NAME: "",
                    DEPARTMENT: "",
                    INSTITUTION: ""
                };
                details.push(newDetail);
            }
            this.setState({ details: details });
        }
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/personal/professional/book/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/personal/professional/book/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/personal/professional/book/' + record.key + ".pdf"
        });
        //File get End
    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let dataSet = this.state.data;
        dataSet = dataSet.filter(element => element.key !== record.key);
        let data = {
            _id: record._id,
            data: record,
            topic: topic,
            subtopic: subtopic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/professional/book/',
            filename: record.key + ".pdf",
            docStatus: this.state.docStatus === 0 ? 0 : 3,
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/research/deleteresearchtable', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    openNotificationWithIcon('success');
                    await this.setState(this.initialState);
                    await this.setState({ data: dataSet });
                }
            });
    }


    onClickSubmitBtn = () => {
        let { publicationType, title, publisherName, isbn, publishedYear, details } = this.state;
        let errorMessages = [];

        if (!publicationType) errorMessages.push("Select publication type");
        else if (!title) errorMessages.push("Invalid Title");
        else if (publicationType === "BOOK") {
            if (!publisherName) errorMessages.push("Select Publisher Name");
            else if (!isbn) errorMessages.push("Invalid ISBN");
            else if (!publishedYear) errorMessages.push("Select Published Year");
        }
        else if (details.length < 1) errorMessages.push("Invalid details");
        else if (details.length > 0) {
            for (let i = 0; i < details.length; i++) {
                if (!details[i].EMPLOYEEID || !details[i].ROLE || !details[i].NAME || !details[i].DEPARTMENT || !details[i].INSTITUTION) {
                    errorMessages.push("Invalid Details Information");
                    break;
                }
            };
        }

        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            subtopic: subtopic,
            data: {
                publicationType: this.state.publicationType,
                title: this.state.title,
                publisherName: this.state.publisherName,
                isbn: this.state.isbn,
                publishedYear: this.state.publishedYear,
                editionNumber: this.state.editionNumber,
                authorCount: this.state.authorCount,
                details: this.state.details,
                _id: this.state._id,
                key: this.state.key,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + "/curricula/studentcircle/research/addresearchtable", data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/professional/book/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/researchupload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    //update this state.data
                    let dataSet = this.state.data;

                    if (this.state.key === '') {
                        dataSet.push(res.data.value);
                    }
                    else {
                        for (let i = 0; i < dataSet.length; i++) {
                            let element = dataSet[i];
                            if (element._id === res.data.key) {
                                dataSet[i] = res.data.value;
                                break;
                            };
                        }
                    }
                    await this.setState(this.initialState);
                    await this.setState({ data: dataSet, errorMessages: ["success"], update: false });
                    //update this.state.data ends 

                } else if (res.data.Status === 0) await message.error(res.data.msg);
            });
    }

    onAdddetails = () => {
        let details = this.state.details;
        let authorCount = parseInt(this.state.authorCount);
        if (authorCount > 6) return false;
        if (!authorCount) authorCount = 0;
        if (authorCount === 0 || details.length === 0)
            details.push(
                {
                    _id: 1,
                    EMPLOYEEID: this.props.common.user.USER_ID,
                    ROLE: "",
                    NAME:
                        this.props.common.user.FIRST_NAME +
                        " " +
                        this.props.common.user.LAST_NAME,
                    DEPARTMENT: this.props.common.user.DEPARTMENT,
                    INSTITUTION: this.state.institutionName
                });
        else {
            let newDetail = {
                _id: details.length + 1,
                EMPLOYEEID: "",
                ROLE: "",
                NAME: "",
                DEPARTMENT: "",
                INSTITUTION: ""
            };
            details.push(newDetail);
        }
        authorCount++;
        this.setState({ errorMessages: [], details, authorCount });
    };

    onRemovedetails = () => {
        let details = this.state.details;
        let authorCount = this.state.authorCount;
        // if (authorCount === 0 || !authorCount) return false;
        details.pop();
        authorCount--;
        this.setState({ details, authorCount, disable: false });
    };
    onChangedetails = (id, name, e) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = e.target.value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    onChangedetailsSelect = (id, name, value) => {
        var details = this.state.details;
        let data = details[id];
        data[name] = value;
        details[id] = data;
        this.setState({ errorMessages: [], details: details, disable: false })
    }

    searchEmployee = (id, employeeid) => {
        let data = {
            employeeId: employeeid,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getusername', data)
            .then(res => {
                if (res.data.result) {
                    let result = res.data.result;
                    var details = this.state.details;
                    let data = details[id];
                    data.NAME = result.employeeName;
                    data.DEPARTMENT = result.department;
                    data.INSTITUTION = result.institution;
                    details[id] = data;
                    this.setState({ errorMessages: [], details: details, disable: false })
                }
            })
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { publicationType, title, publisherName, isbn, publishedYear, editionNumber, edit, authorCount, otherName, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Publication Type',
                dataIndex: 'publicationType',
                key: 'publicationType',
                render: (text) => (
                    <Text strong>{ text.replace(/_|-|\./g, ' ') }</Text>
                ),
                width: 20,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                width: 20,
            },
            {
                title: 'Publisher Name',
                dataIndex: 'publisherName',
                key: 'publisherName',
                width: 50,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Professional Publications <br /> <Badge status="warning" text="Other than Research" />
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "mt-1 " : "mt-1 disableAllitems" }>
                    <Col xs={ 24 } md={ 16 }>
                        <Input id="title" addonBefore="* Title" onChange={ this.onChange } value={ title } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 10 }>
                                    <Input defaultValue="* Publication Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 14 }>
                                    <Select showSearch id="publicationType" style={ { width: "100%" } } placeholder="* Type" onChange={ this.handleChangeSelect.bind(this, "publicationType") } value={ publicationType }>
                                        <Option value="ANIMATION">Animations</Option>
                                        <Option value="BOOK">Book</Option>
                                        <Option value="CASE_STUDY">Case Study</Option>
                                        <Option value="CHAPTER">Chapter</Option>
                                        <Option value="ECONTENT-LMS">E-Content LMS</Option>
                                        <Option value="HANDBOOK">Hand Book</Option>
                                        <Option value="LAB-MANUAL">Lab Manual</Option>
                                        <Option value="LECTURE-VIDEOS">Lecture Videos</Option>
                                        <Option value="MONOGRAPH">Mono Graph</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    { publisherName !== "Others" ?
                        <Col xs={ 24 } md={ 16 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 6 }>
                                        <Input defaultValue="Publisher Name" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 18 }>
                                        <Select showSearch id="publisherName" style={ { width: "100%" } } placeholder="* Publisher Name" onChange={ this.handleChangeSelect.bind(this, "publisherName") } value={ publisherName }>
                                            {
                                                publishers.map(
                                                    (item, val) => {
                                                        return (
                                                            <Option key={ val } value={ item }>{ item }</Option>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col> : '' }
                    { publisherName === "Others" ? <>
                        <Col xs={ 24 } md={ 8 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 6 }>
                                        <Input defaultValue="Publisher Name" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 18 }>
                                        <Select showSearch id="publisherName" style={ { width: "100%" } } placeholder="* Publisher Name" onChange={ this.handleChangeSelect.bind(this, "publisherName") } value={ publisherName }>
                                            {
                                                publishers.map(
                                                    (item, val) => {
                                                        return (
                                                            <Option key={ val } value={ item }>{ item }</Option>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col>
                        <Col xs={ 24 } md={ 8 }>
                            <Input id="otherName" addonBefore="* Name" onChange={ this.onChange } value={ otherName } />
                        </Col>
                    </> : '' }

                    { publicationType === "BOOK" || publicationType === "CHAPTER" ?
                        (<><Col xs={ 24 } md={ 8 }>
                            <Input id="isbn" addonBefore="ISBN" onChange={ this.onChange } value={ isbn } />
                        </Col>

                            <Col xs={ 24 } md={ 8 }>
                                <Input id="editionNumber" addonBefore="* Edition Number" style={ { width: "100%" } } onChange={ this.onChange } value={ editionNumber } />
                            </Col>
                            <Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 12 }>
                                            <Input defaultValue="* Published Year" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 12 }>
                                            <DatePicker id="publishedYear" style={ { width: "100%" } } picker="year" placeholder="* Published Year" onChange={ this.handleChangeDate.bind(this, "publishedYear") } value={ moment(publishedYear, dateFormat) } />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col></>) : ''
                    }
                    <Col xs={ 24 } md={ 8 }>
                        <Input id="authorCount" disabled addonBefore="Author Count" onChange={ this.onChange } value={ authorCount } />
                    </Col>


                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="Author Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>New</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.details.length > 0 ?
                                    (this.state.details.map(
                                        (item, val) => {
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-2">
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ (val + 1) + ". User ID" } id={ val } addonAfter={ <SearchOutlined onClick={ this.searchEmployee.bind(this, val, (item.EMPLOYEEID)) } /> } value={ item.EMPLOYEEID } onChange={ this.onChangedetails.bind(this, val, "EMPLOYEEID") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <InputGroup>
                                                            <Row style={ { width: "100%" } }>
                                                                <Col xs={ 24 } md={ 6 }>
                                                                    <Input defaultValue="Role" disabled className="newLabel" />
                                                                </Col>
                                                                <Col xs={ 24 } md={ 18 }>
                                                                    <Select style={ { width: "100%" } } id={ val } onChange={ this.onChangedetailsSelect.bind(this, val, "ROLE") } value={ item.ROLE }>
                                                                        <Option value="" disabled>Select Role</Option>
                                                                        {
                                                                            roleList.map(
                                                                                (newItem, newVal) => {
                                                                                    return (
                                                                                        <Option key={ newVal } value={ newItem }>{ newItem.replace(/_|-|\./g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }</Option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }>
                                                        <Input addonBefore={ "Name" } id={ val } value={ item.NAME } onChange={ this.onChangedetails.bind(this, val, "NAME") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Department" } id={ val } value={ item.DEPARTMENT } onChange={ this.onChangedetails.bind(this, val, "DEPARTMENT") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                    <Col xs={ 24 } md={ 12 }  >
                                                        <Input addonBefore={ "Institution" } id={ val } value={ item.INSTITUTION } onChange={ this.onChangedetails.bind(this, val, "INSTITUTION") } disabled={ val === 0 ? true : false } />
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            showIcon
                                            closable
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500 } } bordered />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Book));