import React, { Component } from 'react'
import { Typography, Select, Descriptions, message, Button, Space, Checkbox, Spin, Result, Row, Col, Card, Statistic } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
const { Text } = Typography;
const { Option } = Select;
const page_name = "session_plan";

class SessionPlan extends Component {

    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        P1: [], P2: [], P3: [], P4: [], P5: [], P6: [],
        T1: 5, T2: 10, T3: 10, T4: 10, T5: 10, T6: 5,
        loading: true,
        UNIT: 1,
        SESSION: 1,
        SESSION_COUNT: 0,
        error: 0
    }

    componentDidMount() {
        this.getSC(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getSC(props.COURSE_CODE);
    }

    getSC = (COURSE_CODE) => {
        let sendData = {
            data: {
                COURSE_CODE: COURSE_CODE,
                title: "course_learning_session"
            },
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.selectedCourseData;
                    if (Object.keys(record).length === 0) {
                        this.setState({ error: 1 })
                    }
                    else {
                        this.setState({ sessions: record, SESSION_COUNT: record[0].SESSIONCOUNT });
                        this.getSessionPlan(COURSE_CODE, 1, 1);
                    }
                }
            });

        this.setState({ loading: false });
    }

    getSessionPlan = (COURSE_CODE, UNIT, SESSION) => {
        this.setState({ loading: true });
        if (UNIT && SESSION) {
            this.setState({ loading: true });
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    let id = this.state.COURSE_CODE + "_" + UNIT + "_" + SESSION;
                    if (res.data.Status === 1) {
                        if (Object.keys(res.data.selectedCourseData).length !== 0) {
                            let data = res.data.selectedCourseData.filter(function (entry) {
                                return entry._id === id;
                            });

                            let record = data[0];
                            if (typeof record !== 'undefined') {
                                if (record.duration) {
                                    let result = record.duration;
                                    Object.entries(result).map(([key, value]) => this.setState({ [key]: value }))
                                }
                                if (record.plan) {
                                    let result = record.plan;
                                    Object.entries(result).map(([key, value]) => this.setState({ [key]: value }))
                                }
                            }
                            else {
                                this.setState({ P1: [], P2: [], P3: [], P4: [], P5: [], P6: [] });
                            }
                        }
                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("SESSION NOT FOUND !")
    }

    FunctionCheckboxGroup = (id) => {
        let P, html = [];
        let CDIO = ["", "Discover", "Discern", "Develop", "Deploy"]
        for (let i = 1; i <= 4; i++) {
            P = "P" + i;
            html.push(<Checkbox key={P + id} value={CDIO[i]}>{CDIO[i]}</Checkbox>);
        }
        return html;
    }

    onChangeCheckBox = (name, checkedValues) => {
        this.setState({ [name]: checkedValues });
    }

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: parseInt(value) });

        if (label === "UNIT") {
            let temp = value - 1;
            let SC = this.state.sessions;
            this.setState({ SESSION_COUNT: SC[temp].SESSIONCOUNT, SESSION: 1 });
            this.getSessionPlan(this.state.COURSE_CODE, value, 1);
        }
        else
            this.getSessionPlan(this.state.COURSE_CODE, this.state.UNIT, value);
    }

    onChangeTimePicker = (label, value) => {
        this.setState({ [label]: parseInt(value) });
    }

    onClickAdd = async () => {
        this.setState({ loading: true });

        let { COURSE_CODE, P1, P2, P3, P4, P5, P6, T1, T2, T3, T4, T5, T6 } = this.state;

        let totalTime = parseInt(T1) + parseInt(T2) + parseInt(T3) + parseInt(T4) + parseInt(T5) + parseInt(T6);
        if (totalTime !== 50) {
            message.error("Invalid Time Duration !");
            return false;
        }

        let plan = {
            P1: P1,
            P2: P2,
            P3: P3,
            P4: P4,
            P5: P5,
            P6: P6
        }

        let flag = 1;
        Object.keys(plan).forEach(function (key) {
            if (plan[key].length < 1) {
                flag = 0;
            }
        });

        if (flag === 0) {
            this.setState({ loading: false });
            message.error('Invalid Entry!');
            return false;
        }

        let data = {
            _id: COURSE_CODE + "_" + this.state.UNIT + "_" + this.state.SESSION,
            COURSE_CODE: COURSE_CODE,
            unit: this.state.UNIT,
            session: this.state.SESSION,
            duration: {
                T1: T1,
                T2: T2,
                T3: T3,
                T4: T4,
                T5: T5,
                T6: T6
            },
            plan: {
                P1: P1,
                P2: P2,
                P3: P3,
                P4: P4,
                P5: P5,
                P6: P6
            }
        }

        let sendData = {
            title: page_name,
            data: data,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/admin/course/addsessionplan', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
        this.setState({ loading: false });
    }

    render() {
        let { UNIT, SESSION, T1, T2, T3, T4, T5, T6 } = this.state;
        let SC = []; for (let i = 1; i <= this.state.SESSION_COUNT; i++) SC.push(i);

        return (
            <div className="padding-1">

                {this.state.error === 0 ?
                    (
                        <>
                            <Text className="justify">
                                Session plan – also called a learning plan – is an organized description of the activities and resources you'll use to guide a group toward a specific learning objective. It details the subject matter that you'll teach, how long each section should take, the methods of instruction for each topic covered.
                            </Text>

                            <div className="site-card-wrapper mt-2 mobile-hide">
                                <Row gutter={16} className="justify">
                                    <Col span={6}>
                                        <Card title="Discover" bordered size="small" hoverable headStyle={{ backgroundColor: "#6185a0" }}>
                                        <h4>Objective:</h4> In this phase, students engage in discovering and understanding the core tenets of the Bible, church history, theology, and ministry contexts. They explore foundational questions about faith, the Christian life, and the needs of the communities they will serve.
                                        <br/>
                                       
                                        <h4> Examples of Activities:</h4>
                                            <ul>
                                                <li>Study and analysis of biblical texts and theological concepts.</li>
                                                <li>Exploration of church history, denominational distinctives, and contemporary ministry challenges.</li>
                                                <li>Identification of community needs, spiritual challenges, and opportunities for ministry.</li>
                                                <li>Engaging in prayer and spiritual reflection to discern God’s calling. </li>
                                            </ul>
                                            <br/>
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card title="Discern" bordered size="small" hoverable headStyle={{ backgroundColor: "#6185a0" }}>
                                        <h4>Objective:</h4>  In this phase, students learn to discern how to apply biblical principles and theological knowledge to practical ministry contexts. They design ministry programs, sermons, outreach activities, and discipleship plans.
                                        <br/>
                                        <br/>
                                        <h4> Examples of Activities:</h4>
                                            <ul>
                                                <li>Developing sermons, Bible studies, and teaching plans.</li>
                                                <li>Designing outreach programs, church planting strategies, and community service initiatives.</li>
                                                <li> Role-playing, case studies, and simulations to practice pastoral care, counseling, and conflict resolution.</li>
                                               <li> Engaging with mentors and peers to refine ministry strategies and approaches.</li>
                                            </ul>
                                            <br/>
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card title="Develop" bordered size="small" hoverable headStyle={{ backgroundColor: "#6185a0" }}>
                                        <h4>Objective:</h4> This phase focuses on the practical implementation of what has been designed. Students gain hands-on experience in ministry settings, applying their learning in real-world contexts.
                                        <br/>
                                        <br/>
                                        <h4> Examples of Activities:</h4>
                                            <ul>
                                                <li>Participating in internships, field education, or supervised ministry placements.</li>
                                               <li> Leading worship services, Bible studies, youth groups, or community outreach events.</li>
                                                <li>Practicing pastoral care, preaching, and teaching in real or simulated environments.</li>
                                               <li> Receiving feedback from supervisors, peers, and congregations to improve ministry skills.</li>
                                            </ul>
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card title="Deploy" bordered size="small" hoverable headStyle={{ backgroundColor: "#6185a0" }}>
                                        <h4>Objective:</h4> In this final phase, students are prepared to deploy their skills and knowledge into full-time ministry or leadership roles. They learn to sustain their ministry efforts, engage in lifelong learning, and adapt to new challenges.
                                        <h4> Examples of Activities:</h4>
                                            <ul>
                                            <li>Reflecting on ministry experiences to identify strengths and areas for growth.</li>
                                            <li>Developing a personal and professional plan for continued learning and spiritual growth.</li>
                                            <li>Engaging in community-building activities to foster relationships and collaboration among ministry leaders.</li>
                                            <li>Learning about sustainability in ministry, including self-care, ethical decision-making, and adaptive leadership.</li>
                                            </ul>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                            <Descriptions
                                bordered
                                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                className="mb-1 mt-2"
                                size="small"
                            >
                                <Descriptions.Item label="Choose Unit">
                                    <Select id="UNIT" style={{ width: "100%" }} value={UNIT}
                                        onChange={this.handleChangeSelect.bind(this, "UNIT")}>
                                        <Option value="1">1</Option>
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label="Choose Session">
                                    <Select id="SESSION" style={{ width: "100%" }} value={SESSION}
                                        onChange={this.handleChangeSelect.bind(this, "SESSION")}>
                                        {
                                            parseInt(this.state.SESSION_COUNT) > 0 ?
                                                (SC.map(
                                                    (item) => {
                                                        return (
                                                            <Option key={item} value={item}>{item}</Option>
                                                        );
                                                    }
                                                )
                                                ) : ''
                                        }
                                    </Select>
                                </Descriptions.Item>

                            </Descriptions>

                            <Spin spinning={this.state.loading}>


                                <Descriptions
                                    bordered
                                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                                    className="mb-1 mt-1"
                                    size="middle"
                                >
                                    <Descriptions.Item label="Selected" span={2}>
                                        <Space size="large">
                                            <Statistic title="Unit" valueStyle={{ color: '#3f8600' }} value={UNIT} />
                                            <Statistic title="Session" valueStyle={{ color: '#3f8600' }} value={SESSION} />
                                        </Space>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Recap / Introduction" span={2}>
                                        <Space>
                                            <Select value={T1} style={{ width: 120 }} onChange={this.onChangeTimePicker.bind(this, "T1")}>
                                                <Option value="0">0</Option>
                                                <Option value="5">5</Option>
                                                <Option value="10">10</Option>
                                                <Option value="15">15</Option>
                                                <Option value="20">20</Option>
                                            </Select>
                                            <Checkbox.Group className="ml-5"
                                                style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "P1")} value={this.state.P1}>
                                                {this.FunctionCheckboxGroup(1)}
                                            </Checkbox.Group>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="SLO 1 Content" span={2}>
                                        <Space>
                                            <Select value={T2} style={{ width: 120 }} onChange={this.onChangeTimePicker.bind(this, "T2")}>
                                                <Option value="0">0</Option>
                                                <Option value="5">5</Option>
                                                <Option value="10">10</Option>
                                                <Option value="15">15</Option>
                                                <Option value="20">20</Option>
                                            </Select>
                                            <Checkbox.Group className="ml-5"
                                                style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "P2")} value={this.state.P2}>
                                                {this.FunctionCheckboxGroup(2)}
                                            </Checkbox.Group>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="SLO 1 Practice" span={2}>
                                        <Space>
                                            <Select value={T3} style={{ width: 120 }} onChange={this.onChangeTimePicker.bind(this, "T3")}>
                                                <Option value="0">0</Option>
                                                <Option value="5">5</Option>
                                                <Option value="10">10</Option>
                                                <Option value="15">15</Option>
                                                <Option value="20">20</Option>
                                            </Select>
                                            <Checkbox.Group className="ml-5"
                                                style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "P3")} value={this.state.P3}>
                                                {this.FunctionCheckboxGroup(3)}
                                            </Checkbox.Group>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="SLO 2 Content	" span={2}>
                                        <Space>
                                            <Select value={T4} style={{ width: 120 }} onChange={this.onChangeTimePicker.bind(this, "T4")}>
                                                <Option value="0">0</Option>
                                                <Option value="5">5</Option>
                                                <Option value="10">10</Option>
                                                <Option value="15">15</Option>
                                                <Option value="20">20</Option>
                                            </Select>
                                            <Checkbox.Group className="ml-5"
                                                style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "P4")} value={this.state.P4}>
                                                {this.FunctionCheckboxGroup(4)}
                                            </Checkbox.Group>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="SLO 2 Practice" span={2}>
                                        <Space>
                                            <Select value={T5} style={{ width: 120 }} onChange={this.onChangeTimePicker.bind(this, "T5")}>
                                                <Option value="0">0</Option>
                                                <Option value="5">5</Option>
                                                <Option value="10">10</Option>
                                                <Option value="15">15</Option>
                                                <Option value="20">20</Option>
                                            </Select>
                                            <Checkbox.Group className="ml-5"
                                                style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "P5")} value={this.state.P5}>
                                                {this.FunctionCheckboxGroup(5)}
                                            </Checkbox.Group>
                                        </Space>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Summary" span={2}>
                                        <Space>
                                            <Select value={T6} style={{ width: 120 }} onChange={this.onChangeTimePicker.bind(this, "T6")}>
                                                <Option value="0">0</Option>
                                                <Option value="5">5</Option>
                                                <Option value="10">10</Option>
                                                <Option value="15">15</Option>
                                                <Option value="20">20</Option>
                                            </Select>
                                            <Checkbox.Group className="ml-5"
                                                style={{ width: '100%' }} onChange={this.onChangeCheckBox.bind(this, "P6")} value={this.state.P6}>
                                                {this.FunctionCheckboxGroup(6)}
                                            </Checkbox.Group>
                                        </Space>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Submit">
                                        <Button type="primary" onClick={this.onClickAdd}>Submit</Button>
                                    </Descriptions.Item>
                                </Descriptions>

                            </Spin>
                        </>
                    )
                    : <Result
                        status="404"
                        title="Course Learning Session is Empty"
                        subTitle="Unit and Session information are empty"
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(SessionPlan));