import React, { Component } from 'react'
import { Row, Col, Select, Progress, Input, message, Button, Space, Tag, Spin, Table, Popconfirm, Descriptions } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
import {
    SearchOutlined, EnterOutlined, CloseOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import CourseHome from '../../student/CourseHome';
const { Option } = Select;

class LearnerProgress extends Component {
    state = {
        loading: false,
        data: [],
        STUDENT_ID: '',
        selectedStudent: {},
        selectedCourse: {},
        courses: [], batches: [],
        key: 0,
        flag: 0
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        let data = {
            info: this.props.common.user,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/admin/manageuser/listallfaculty', data)
            .then(res => {
                if (res.data.Status === 1) {
                    let result = res.data.result.filter(function (el) { return el.ROLE === 'S'; });
                    this.setState({ data: result, loading: false });
                    message.success('Inofrmation Loaded');
                }
            })
    }

    handleChangeSelect = (value) => {
        let { data } = this.state;
        let selectedStudent = data.filter(function (el) { return el._id === value; });
        this.setState({ selectedStudent: selectedStudent[0], STUDENT_ID: value, courses: [], batches: [] });

    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    submit = async () => {
        var data = {
            USER_ID: this.state.STUDENT_ID,
            key: config.key
        };

        let batches = [];

        await axios.post(config.curriculaserver + '/curricula/student/home/getcourses', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    res.data.courses.forEach(function (entry) {
                        let temp = entry.BATCH_ID;
                        batches.push(temp);
                    });

                    await this.setState({ courses: res.data.courses, batches: batches || [], flag: 1 })
                }
            });
    }

    view = (record) => {
        record.LOCK = 0;
        this.setState({ selectedCourse: record, key: 1 });
    }

    showBtn = () => {
        this.setState({ key: 0 });
    }

    render() {
        let { data, STUDENT_ID, courses, key, selectedCourse, selectedStudent, flag } = this.state;
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 0.5,
                render: (text) => (courses.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Semester',
                key: 'SEMESTER',
                dataIndex: 'SEMESTER',
                width: 0.5,
                ...this.getColumnSearchProps('SEMESTER'),
            },
            {
                title: 'Course Code',
                dataIndex: 'COURSE_CODE',
                fixed: 'left',
                key: 'COURSE_CODE',
                width: 0.5,
                ...this.getColumnSearchProps('COURSE_CODE'),
            },
            {
                title: 'Course Name',
                dataIndex: 'COURSE_NAME',
                key: 'COURSE_NAME',
                width: 2,
                ...this.getColumnSearchProps('COURSE_NAME'),
                render: (text) => (text.toUpperCase()),
            },
            {
                title: 'Section ID',
                dataIndex: 'BATCH_ID',
                key: 'BATCH_ID',
                width: 1,
                ...this.getColumnSearchProps('BATCH_ID'),
                render: (text) => (text.toUpperCase()),
            },
            {
                title: 'Section',
                dataIndex: 'BATCH_NAME',
                key: 'BATCH_NAME',
                width: 1,
                ...this.getColumnSearchProps('BATCH_NAME'),
                render: (text) => (text.toUpperCase()),
            },
            {
                title: 'Faculty',
                key: '_id',
                width: 1,
                ...this.getColumnSearchProps('FACULTY_ID'),
                render: (text, record) => (
                    record.FACULTY_NAME + " (" + record.FACULTY_ID + ")"
                ),
            },
            {
                title: 'Status',
                key: 'completion',
                dataIndex: 'completion',
                width: 1,
                sorter: (a, b) => a.completion - b.completion,
                render: (text) => (<Progress type="line" percent={text} />)
            },
            {
                title: 'Action',
                key: '_id',
                width: 1,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={this.view.bind(this, record)}
                    >
                        <Button icon={<EnterOutlined />} type="primary" onClick={this.showBtn}>VIEW</Button>
                    </Popconfirm>

                )
            },
        ];

        return (
            <div style={{ padding: '10px', minHeight: "100%" }}>
                <Spin spinning={this.state.loading}>

                    <Row gutter={[16, 16]} justify="start">
                        <Col xs={24} md={16}>
                            <Select
                                showSearch
                                placeholder="Select Student" style={{ width: "100%" }} onChange={this.handleChangeSelect} value={STUDENT_ID}
                            >
                                <Option value="" disabled>Choose One</Option>
                                {
                                    data.map(
                                        (item, val) => {
                                            return (
                                                <Option key={val} value={item.USER_ID}>
                                                    <Tag color="blue">{item.USER_ID.toUpperCase()}</Tag>
                                                    <Tag color="magenta">{item.FULL_NAME}</Tag>
                                                    <Tag color="green">{item.DEPARTMENT}</Tag>
                                                </Option>
                                            )
                                        }
                                    )
                                }
                            </Select>
                        </Col>
                        <Col xs={24} md={4}>
                            <Button disabled={!STUDENT_ID ? true : false} icon={<EnterOutlined />} type="primary" style={{ width: "100%" }} onClick={this.submit}>View Progress</Button>
                        </Col>
                        <Col xs={24} md={4}>
                            <Button icon={<CloseOutlined />} danger type="primary" style={{ width: "100%" }} onClick={() => { this.setState({ selectedCourse: {}, key: 0, selectedStudent: {}, STUDENT_ID: '', courses: [], batches: [], flag: 0 }) }} >Reset</Button>
                        </Col>
                    </Row>

                    <br /> <br />

                    {
                        flag ?
                            <Row gutter={[16, 16]} justify="start">
                                <Col xs={24}>
                                    <Descriptions
                                        bordered
                                        title={selectedStudent.FULL_NAME + " (" + selectedStudent.USER_ID + ")"}
                                        size={"small"}
                                    >
                                        <Descriptions.Item label="First Name">{selectedStudent.FIRST_NAME}</Descriptions.Item>
                                        <Descriptions.Item label="Last Name">{selectedStudent.LAST_NAME}</Descriptions.Item>
                                        <Descriptions.Item label="Gender">{selectedStudent.GENDER || "Empty"}</Descriptions.Item>
                                        <Descriptions.Item label="Department">{selectedStudent.DEPARTMENT}</Descriptions.Item>
                                        <Descriptions.Item label="Register Number">{selectedStudent.REGISTER_NUMBER || "Empty"}</Descriptions.Item>
                                        <Descriptions.Item label="Mail ID">{selectedStudent.MAIL_ID || "Empty"}</Descriptions.Item>
                                        <Descriptions.Item label="Mobile">{selectedStudent.MOBILE || "Empty"}</Descriptions.Item>
                                        <Descriptions.Item label="User Login">{selectedStudent.LOCK === 1 ?
                                            <Tag color="red">Locked</Tag> : <Tag color="green">Unlocked</Tag>}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>

                                <Col xs={24}>
                                    <br />
                                    <h3>Courses Assigned</h3>
                                    <Table dataSource={courses} columns={columns} bordered size='middle' />
                                </Col>
                            </Row>
                            : null
                    }

                    {
                        key === 1 ? <CourseHome COURSE_CODE={selectedCourse.COURSE_CODE} COURSE_NAME={selectedCourse.COURSE_NAME} COURSE_INFO={selectedCourse} USER_ID={STUDENT_ID}
                            DEPARTMENT={selectedStudent.DEPARTMENT} FULL_NAME={selectedStudent.FULL_NAME}
                            SLOT={selectedStudent.SLOT}
                        /> : null
                    }
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LearnerProgress));