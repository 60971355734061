import React, { Component } from 'react'
import { Row, Col, Card, Empty, Checkbox, Button, Select, Input, Tag, DatePicker, Tooltip, Alert, message, Upload, Descriptions } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { PlusOutlined, EditOutlined, MinusOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import lists from '../lists'
const InputGroup = Input.Group;
const { Option } = Select;
const { Title } = Typography;
const dateFormatList = 'DD/MM/YYYY';
const topic = "basic";
const languages = lists.languages;

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return isJpgOrPng && isLt2M;
}

class Profile extends Component {

    state = {
        firstName: this.props.common.user.FIRST_NAME,
        middleName: '',
        lastName: this.props.common.user.LAST_NAME,
        gender: 'Male',
        physicallyChallenged: 'NO',
        dateOfBirth: '01/01/1990',
        birthPlace: '',
        identificationMark1: '',
        identificationMark2: '',
        religion: 'BUDDHISM',
        caste: '',
        socialStatus: 'BC',
        motherTongue: '',
        languagesKnown: [],
        foodPreference: 'VEGETARIAN',
        martialStatus: 'SINGLE',
        weddingDate: '01/01/2020',
        physicalDisabilityDetails: '',
        remainingLanguages: [],
        errorMessages: [],
        disable: true,
        edit: false,
        file: '',
        loading: false,
        docStatus: 0,
    }

    initialState = this.state;

        componentDidMount() {
            let data = {
                info: this.props.common.user,
                topic: topic,
                key: config.key
            };
            axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
                .then(res => {
                    if (res.data.Status === 1 && res.data.result)
                        Object.entries(res.data.result).map(([key, value]) => this.setState({ [key]: value }));
                });

            data = {
                info: this.props.common.user,
                path: "/personal/basic/profile/photo/",
                filename: this.props.common.user.USER_ID + ".jpg",
                key: config.key
            };
            axios.post(config.curriculaserver + '/curricula/studentcircle/file/getfile', data)
                .then(res => {
                    if (res.data.Status === 1 && res.data.path)
                        this.setState({ file: config.curriculaserver + "/" + res.data.path })
                });
        }

    onClickSubmitBtn = () => {
        let { firstName, lastName, gender, physicallyChallenged, dateOfBirth, identificationMark1, identificationMark2, religion, socialStatus, languagesKnown, physicalDisabilityDetails, caste, martialStatus, weddingDate, errorMessages } = this.state;
        languagesKnown = languagesKnown.filter((item) => item.LANGUAGE !== '' && (item.READ === true || item.SPEAK === true || item.WRITE === true));
        this.setState({ errorMessages: [], languagesKnown: languagesKnown });
        if (!firstName || !/^[a-zA-Z ]+$/i.test(firstName)) errorMessages.push("Invalid First Name");
        else if (!lastName || !/^[a-zA-Z ]+$/i.test(lastName)) errorMessages.push("Invalid Last Name");
        else if (!gender) errorMessages.push("Select Gender");
        else if (!identificationMark1 || !/^[a-zA-Z ]+$/i.test(identificationMark1)) errorMessages.push("Invalid Identification Mark 1");
        else if (!identificationMark2 || !/^[a-zA-Z ]+$/i.test(identificationMark2)) errorMessages.push("Invalid Identification Mark 2");
        else if (!dateOfBirth) errorMessages.push("Select DOB");
        else if (!religion) errorMessages.push("Select Religion");
        else if (!socialStatus) errorMessages.push("Select Social Status");
        else if (!caste) errorMessages.push("Invalid Caste");
        else if (!martialStatus) errorMessages.push("Select Martial Status");
        else if (!physicallyChallenged) errorMessages.push("Select Physically Challenged");

        if (physicallyChallenged === "YES") {
            if (!physicalDisabilityDetails) errorMessages.push("Invalid Physically Challenge Details");
        }
        else if (physicallyChallenged === "NO") {
            this.setState({ physicalDisabilityDetails: '' });
        }

        let weddingDateNew;
        if (martialStatus !== "MARRIED") weddingDateNew = ""; else weddingDateNew = weddingDate;

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;
        // eslint-disable-next-line
        this.state['languagesKnown'] = languagesKnown;
        let data = {
            key: config.key,
            topic: topic,
            data: {
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                gender: this.state.gender,
                physicallyChallenged: this.state.physicallyChallenged,
                dateOfBirth: this.state.dateOfBirth,
                birthPlace: this.state.birthPlace,
                identificationMark1: this.state.identificationMark1,
                identificationMark2: this.state.identificationMark2,
                religion: this.state.religion,
                caste: this.state.caste,
                socialStatus: this.state.socialStatus,
                motherTongue: this.state.motherTongue,
                languagesKnown: this.state.languagesKnown,
                foodPreference: this.state.foodPreference,
                martialStatus: this.state.martialStatus,
                weddingDate: weddingDateNew,
                physicalDisabilityDetails: this.state.physicalDisabilityDetails,
                remainingLanguages: this.state.remainingLanguages,
                docStatus: this.state.docStatus,
            },
            info: this.props.common.user,
        }
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/update', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState({ edit: false });
                    await this.setState({ errorMessages: ["success"] });
                } else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    reset = () => {
        this.setState(this.initialState)
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        if (label === 'physicallyChallenged') {
            this.setState({ physicalDisabilityDetails: '' })
        }
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onAdddetails = () => {
        let languagesKnown = this.state.languagesKnown;
        let knownLanguages = languagesKnown.map(a => a.LANGUAGE);
        let remainingLanguages = languages.filter(function (item) {
            return !knownLanguages.includes(item);
        })
        let newDetail = {
            _id: languagesKnown.length + 1,
            LANGUAGE: '',
            SPEAK: true,
            READ: false,
            WRITE: false,
        }
        languagesKnown.push(newDetail);
        this.setState({ errorMessages: [], languagesKnown: languagesKnown, remainingLanguages: remainingLanguages })
    }

    onRemovedetails = () => {
        let languagesKnown = this.state.languagesKnown;
        languagesKnown.pop();
        this.setState({ errorMessages: [], languagesKnown: languagesKnown, disable: false })
    }

    onChangedetails = (id, name, value) => {
        var languagesKnown = this.state.languagesKnown;
        let data = languagesKnown[id];
        data[name] = value;
        languagesKnown[id] = data;
        let knownLanguages = languagesKnown.map(a => a.LANGUAGE);
        let remainingLanguages = languages.filter(function (item) {
            return !knownLanguages.includes(item);
        })
        this.setState({ errorMessages: [], languagesKnown: languagesKnown, remainingLanguages, disable: false })
    }

    onChangedetailsCheckBox = (id, name, e) => {
        var languagesKnown = this.state.languagesKnown;
        let data = languagesKnown[id];
        data[name] = e.target.checked;
        languagesKnown[id] = data;
        this.setState({ errorMessages: [], languagesKnown: languagesKnown, disable: false })
    };

    handleChangeUploadBtn = () => {

        if (this.state.file1) {
            let formData = new FormData();
            formData.append('USER_ID', this.props.common.user.USER_ID);
            formData.append('path', "/personal/basic/profile/photo/");
            formData.append('filename', (this.props.common.user.USER_ID + ".jpg"));
            formData.append('key', config.key);
            formData.append('file', this.state.file1);
            axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        message.destroy();
                        await message.success(res.data.msg, 1);
                    } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                });
        }
    };

    handleChangeRemoveBtn = () => {
        if (this.state.file) {
            let data = {
                info: this.props.common.user,
                path: "/personal/basic/profile/photo/",
                filename: this.props.common.user.USER_ID + ".jpg",
                key: config.key
            };
            axios.post(config.curriculaserver + '/curricula/studentcircle/file/delete', data)
                .then(res => {
                    if (res.data.Status === 1) {
                        this.setState({ file: '' });
                        message.info('Photo Removed');
                    }
                });
        }
    }

    handleChangeFile = info => {
        this.setState({ file1: info.file.originFileObj, file: '', loading: true });
    }

    getAge() {
        let from = moment(this.state.dateOfBirth, "DD/MM/YYYY");
        let to = moment();

        if (to > from) {
            let a = [from.year(), from.month(), from.date()];
            let b = [to.year(), to.month(), to.date()];
            let days = (moment(b).diff(moment(a), 'days', true));
            let age = Math.floor(days / 365).toFixed(0) + " Years " + Math.floor((days % 365) / 30).toFixed(0) + " Months";
            return age;
        }
        else return 0;
    }

    render() {
        const uploadButton = (
            <div>
                { this.state.loading ? <LoadingOutlined /> : <PlusOutlined /> }
                <div className="ant-upload-text">Profile Photo</div>
            </div>
        );

        let { firstName, middleName, lastName, gender, physicallyChallenged, dateOfBirth, birthPlace, identificationMark1, identificationMark2, religion, caste, socialStatus, motherTongue, foodPreference, physicalDisabilityDetails, martialStatus, weddingDate, edit, file } = this.state;

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }> Profile Information
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true, errorMessages: [] }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                            <Tag className="ml-1" color="success">Self-Verified</Tag>
                        </Title>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } style={ { textAlign: "center" } }>
                        <Descriptions bordered column={ { xxl: 2, xl: 1, sm: 1, xs: 1 } } >
                            <Descriptions.Item label="Profile Photo" className="upload" >
                                <Row>
                                    <Col xs={ 24 }>
                                        <Upload
                                            style={ { width: "auto" } }
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={ false }
                                            beforeUpload={ beforeUpload }
                                            onChange={ this.handleChangeFile }
                                        >
                                            { file ? <img src={ `${file}?${Date.now()}` } alt="avatar" style={ { width: '100%' } } /> : uploadButton }
                                        </Upload>
                                    </Col>
                                    <Col xs={ 24 } style={ { marginLeft: "-4px" } } className="mt-2">
                                        <Button className="ant-btn-blue" onClick={ this.handleChangeUploadBtn }>
                                            <UploadOutlined />
                                        </Button>
                                        &nbsp; &nbsp;&nbsp; &nbsp;
                                        <Button danger onClick={ this.handleChangeRemoveBtn }>
                                            <DeleteOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="firstName" disabled addonBefore="* First Name (Given Name)" onChange={ this.onChange } value={ firstName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="middleName" addonBefore="Middle Name" onChange={ this.onChange } value={ middleName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="lastName" disabled addonBefore="* Last Name (Initial)" onChange={ this.onChange } value={ lastName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Gender" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select showSearch id="gender" style={ { width: "100%" } } placeholder="* Gender" value={ gender }
                                        onChange={ this.handleChangeSelect.bind(this, "gender") }>
                                        <Option value="MALE">Male</Option>
                                        <Option value="FEMALE">Female</Option>
                                        <Option value="TRANSGENDER">Transgender</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="identificationMark1" addonBefore="* Physical Identification Mark 1" onChange={ this.onChange } value={ identificationMark1 } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="identificationMark2" addonBefore="* Physical Identification Mark 2" onChange={ this.onChange } value={ identificationMark2 } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Date of Birth" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="dateOfBirth" style={ { width: "100%" } } format={ dateFormatList }
                                        onChange={ this.handleChangeDate.bind(this, "dateOfBirth") }
                                        value={ moment(dateOfBirth.toString(), dateFormatList) || '' } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="age" disabled addonBefore="Age" value={ this.getAge() } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="birthPlace" addonBefore="Place of birth" onChange={ this.onChange } value={ birthPlace } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col span={ 6 }>
                                    <Input defaultValue="* Religion" disabled className="newLabel" />
                                </Col>
                                <Col span={ 18 }>
                                    <Select id="religion" showSearch style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "religion") } value={ religion }>
                                        <Option value="BUDDHISM">Buddhism</Option>
                                        <Option value="CHRISTIAN">Christian</Option>
                                        <Option value="HINDUISM">Hinduism</Option>
                                        <Option value="ISLAM">Islam</Option>
                                        <Option value="JAINISM">Jainism</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Social Strata" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="socialStatus" showSearch style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "socialStatus") } value={ socialStatus }>
                                        <Option value="BC">BC</Option>
                                        <Option value="MBC">MBC</Option>
                                        <Option value="OC">OC</Option>
                                        <Option value="SC/ST">SC/ST</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id="caste" addonBefore="* Caste" onChange={ this.onChange } value={ caste } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="Mother Tongue" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <Select showSearch id="motherTongue" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "motherTongue") } value={ motherTongue }>

                                        {
                                            languages.map(
                                                (item, val) => {
                                                    return (
                                                        <Option key={ val } value={ item }>{ item }</Option>
                                                    )
                                                }
                                            )
                                        }


                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Food Preference" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <Select showSearch id="foodPreference" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "foodPreference") } value={ foodPreference }>
                                        <Option value="NON-VEGETARIAN">Non-Vegetarian</Option>
                                        <Option value="VEGETARIAN">Vegetarian</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 12 }>
                                    <Input defaultValue="* Martial Status" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 12 }>
                                    <Select showSearch id="martialStatus" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "martialStatus") } value={ martialStatus }>
                                        <Option value="SINGLE">Single</Option>
                                        <Option value="MARRIED">Married</Option>
                                        <Option value="WIDOWED">Windowed</Option>
                                        <Option value="DIVORCED">Divorced</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    {
                        martialStatus === "MARRIED" ?
                            (<Col xs={ 24 } md={ 8 }>
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="Wedding Date" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <DatePicker id="weddingDate" style={ { width: "100%" } } format={ dateFormatList }
                                                onChange={ this.handleChangeDate.bind(this, "weddingDate") }
                                                value={ moment(weddingDate.toString(), dateFormatList) || '' } />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>) : ''
                    }

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 18 }>
                                    <Input defaultValue="* Physically / Mentally Challenged" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 6 }>
                                    <Select showSearch id="physicallyChallenged" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "physicallyChallenged") } value={ physicallyChallenged }>
                                        <Option value="YES">Yes</Option>
                                        <Option value="NO">No</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    {
                        physicallyChallenged === "YES" ?
                            (<Col xs={ 24 } md={ 8 }>
                                <Input id="physicalDisabilityDetails" addonBefore="Details" onChange={ this.onChange } value={ physicalDisabilityDetails } />
                            </Col>)
                            : ''
                    }

                    <Col xs={ 24 } >
                        <Card className={ "innerCard" } type="inner" title="* Language Details" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAdddetails }>Add</Button>  &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemovedetails }>Remove </Button>
                            </>
                        }>
                            {
                                this.state.languagesKnown.length > 0 ?
                                    (this.state.languagesKnown.map(
                                        (item, val) => {
                                            return (
                                                <Row gutter={ [8, 8] } key={ val } className="mt-1">
                                                    <Col xs={ 24 } md={ 8 }  >
                                                        <InputGroup>
                                                            <Row>
                                                                <Col xs={ 24 } md={ 12 }>
                                                                    <Input defaultValue="Language" disabled className="newLabel" />
                                                                </Col>
                                                                <Col xs={ 24 } md={ 12 }>
                                                                    <Select showSearch id={ val } style={ { width: "100%" } } onChange={ this.onChangedetails.bind(this, val, "LANGUAGE") } value={ item.LANGUAGE }>
                                                                        <Option value="" disabled>Select One</Option>
                                                                        {
                                                                            this.state.remainingLanguages.map(
                                                                                (item, val) => {
                                                                                    return (
                                                                                        <Option key={ val } value={ item }>{ item }</Option>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                            </Row>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 5 } >
                                                        <Checkbox id={ val.toString() } checked={ item.SPEAK }
                                                            onChange={ this.onChangedetailsCheckBox.bind(this, val, "SPEAK") } >Speak</Checkbox>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 5 } >
                                                        <Checkbox id={ val.toString() } checked={ item.READ }
                                                            onChange={ this.onChangedetailsCheckBox.bind(this, val, "READ") } >Read</Checkbox>
                                                    </Col>
                                                    <Col xs={ 24 } md={ 5 } >
                                                        <Checkbox id={ val.toString() } checked={ item.WRITE }
                                                            onChange={ this.onChangedetailsCheckBox.bind(this, val, "WRITE") }>Write</Checkbox>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    ) :
                                    <Empty style={ { margin: "5px 0" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            showIcon
                                            closable
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } htmlType="submit" onClick={ this.onClickSubmitBtn }
                            disabled={ !this.state.edit }>
                            Submit
                        </Button>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Profile));