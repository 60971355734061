import React, { Component } from 'react'
import { Select, Popconfirm, Descriptions, Table, Button, Space, message, Result, Tag, Avatar, notification, Alert, Input } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from '../../config';
import { DeleteOutlined, PlusOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { CSVLink } from "react-csv";
import Highlighter from 'react-highlight-words';
const { Option } = Select;
const page_name = "slot";

const openNotification = () => {
    notification.success({
        message: 'Any Queries ?',
        description:
            'For New Faculty Registration / Faculty Assign to the Course, Kindly Contact Admin.',
        top: 60,
        duration: 6
    });
};


class AddFaculty extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        loading: false,
        faculty: [],
        selectedFaculty: '',
        selectedFacultyID: '',
        selectedFacultyDept: '',
        FacultyList: [],
        error: 0
    };

    componentDidMount() {
        this.setState({ loading: true })
        this.getCourse(this.state.COURSE_CODE);
        openNotification();
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCourse(props.COURSE_CODE);
    }

    handleChangeSelectFaculty = (e) => {
        let faculty = [...this.state.faculty]
        this.setState({ selectedFaculty: faculty[e].FULL_NAME, selectedFacultyID: faculty[e].USER_ID, selectedFacultyDept: faculty[e].DEPARTMENT });
    }

    getCourse = async (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "course_learning_session"
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (Object.keys(record).length === 0) {
                            this.setState({ error: 1 })
                        }
                    }
                });

            sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/manageuser/listfaculty', sendData)
                .then(res => {

                    if (res.data.Status === 1) {
                        this.setState({ faculty: res.data.result })
                    }
                });

            sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "facultylist"
                },
                key: config.key
            };

            await axios.post(config.curriculaserver + '/curricula/admin/course/getcoursefaculty', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        this.setState({ FacultyList: res.data.result, loading: false })
                    }
                    else
                        this.setState({ FacultyList: [] })
                });
        }
        else
            message.error("Course Not Selected");
    }

    onClickAdd = () => {
        let { selectedFaculty, selectedFacultyID, selectedFacultyDept, COURSE_CODE, COURSE_NAME } = this.state;

        let FacultyList = [...this.state.FacultyList];

        let data = {
            facultyName: selectedFaculty,
            facultyID: selectedFacultyID,
            department: selectedFacultyDept,
            _id: COURSE_CODE + "_" + selectedFacultyID,
            key: COURSE_CODE + "_" + selectedFacultyID,
        }
        let temp = FacultyList.filter(function (item) {
            return item.facultyID === selectedFacultyID;
        });
        if (temp.length === 0) FacultyList.push(data);
        else message.error("Already Added !");

        if (temp.length === 0) {
            let sendData = {
                data: {
                    facultylist: FacultyList,
                },
                COURSE_CODE: COURSE_CODE,
                COURSE_NAME: COURSE_NAME,
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/addcoursefaculty', sendData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        message.success(res.data.msg);
                    } else {
                        await this.setState({ selectedFaculty: 0, selectedFacultyID: 0, selectedFacultyDept: 0 });
                        message.error(res.data.msg);
                    }
                });
        }

        this.setState({
            FacultyList: FacultyList,
            visible: false,
        });
    };

    onClickFacultyDeleteBtn = (record) => {
        this.setState({ loading: true })
        let { COURSE_CODE, COURSE_NAME } = this.state;

        let FacultyList = [...this.state.FacultyList];
        FacultyList = FacultyList.filter((el) => { return el._id !== record._id; });

        let sendData = {
            data: {
                facultylist: FacultyList,
            },
            COURSE_CODE: COURSE_CODE,
            COURSE_NAME: COURSE_NAME,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/addcoursefaculty', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState({ FacultyList: FacultyList });
                    message.success("Information Updated");
                } else
                    message.error(res.data.msg);
            });

        this.setState({ loading: false })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        let { COURSE_CODE, COURSE_NAME, FacultyList } = this.state;
        let headers = [
            { label: "Faculty ID", key: "facultyID" },
            { label: "Faculty Name", key: "facultyName" },
            { label: "Department", key: "department" }
        ];
        const columns1 = [
            {
                title: 'S.No',
                dataIndex: 'key',
                render: (text) => (FacultyList.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'ID',
                dataIndex: 'facultyID',
                key: 'facultyID',
                ...this.getColumnSearchProps('facultyID'),
                render: (text) => (text)
            },
            {
                title: 'Name',
                dataIndex: 'facultyName',
                key: 'facultyName',
                ...this.getColumnSearchProps('facultyName'),
                render: (text) => (<><Avatar shape="square">{text.charAt(0)}</Avatar> {text.toUpperCase()}</>),
            },
            {
                title: 'Action',
                render: (text, record) => (
                    <Space>
                        <Popconfirm
                            title="Are you sure?"
                            okText="Yes"
                            cancelText="No"
                            disabled={config.ReduceFeaturesFromCoordinatorAndFaculty ? true : false}
                            onConfirm={this.onClickFacultyDeleteBtn.bind(this, record)}
                        >
                            <Button disabled={config.ReduceFeaturesFromCoordinatorAndFaculty ? true : false} type="danger" icon={<DeleteOutlined />}> Delete </Button>
                        </Popconfirm>
                    </Space>
                ),
                fixed: 'right',
            },
        ];

        return (<>
            {this.state.error === 0 ?
                (
                    <>
                        <Alert message="For New Faculty Registration / Faculty Assign to the Course, Kindly Contact Batch Admin." type="info" showIcon />
                        <br />
                        <Descriptions
                            bordered
                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Course Code">
                                {COURSE_CODE}
                            </Descriptions.Item>
                            <Descriptions.Item label="Course Name">
                                {COURSE_NAME}
                            </Descriptions.Item>

                            {
                                config.ReduceFeaturesFromCoordinatorAndFaculty === 0 ?
                                    <Descriptions.Item label="Select Faculty" span={2}>
                                        <Space>
                                            <Select
                                                style={{ width: 500 }}
                                                placeholder="Search Faculty"
                                                onChange={this.handleChangeSelectFaculty}
                                                optionFilterProp="children"
                                            >
                                                {
                                                    this.state.faculty.length > 0 ? (
                                                        this.state.faculty.map(
                                                            (item, val) => {
                                                                return (
                                                                    <Option key={val} value={val}>
                                                                        <Tag color="cyan">{item.FULL_NAME}</Tag>
                                                                        <Tag color="pink">{item.USER_ID}</Tag>
                                                                    </Option>
                                                                )
                                                            }
                                                        )
                                                    )
                                                        :
                                                        ''
                                                }
                                            </Select>
                                            <Button disabled={this.state.selectedFacultyID ? false : true} type="primary" icon={<PlusOutlined />} onClick={this.onClickAdd}>Add</Button>
                                        </Space>
                                    </Descriptions.Item>
                                    : null
                            }

                            <Descriptions.Item label="Assigned Faculty" span={2}>
                                <CSVLink data={this.state.FacultyList} headers={headers} filename={COURSE_CODE + " faculty-list.csv"} >
                                    <Button size="middle" type="dashed" icon={<DownloadOutlined />}>Download EXCEL</Button>
                                </CSVLink>
                                <Table className="mt-2" size="small" bordered dataSource={this.state.FacultyList} columns={columns1} />
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                ) : <Result
                    status="404"
                    title="Course Learning Session is Empty"
                    subTitle="Unit and Session information are empty"
                />
            }
        </>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(AddFaculty));