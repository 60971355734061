import React, { Component } from 'react';
import { Pie } from '@ant-design/charts';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Page extends Component {

    render() {
        let { data } = this.props.details;
        let title = this.props.details.title || '';
        var config = {
            width: 800,
            height: 400,
            padding: 'auto',
            appendPadding: 20,
            data: data,
            angleField: this.props.details.yField,
            colorField: this.props.details.xField,
            radius: 1,
            innerRadius: 0.4,
            legend: false,
            label: {
                type: 'spider',
                labelHeight: 68,
                content: '{name}\n{percentage}',
            },
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '12px',
                    },
                    formatter: function formatter() {
                        return title;
                    },

                },
            },
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }],

        };
        return (
            <Pie {...config} />
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Page));
