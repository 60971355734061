import React, { Component, createRef } from 'react'
import { Layout } from 'antd';
import { Row, Col, Card, Select, Statistic, Alert, Progress, Input, Empty, Tooltip, Badge, message, PageHeader, Button, Modal, Breadcrumb, Descriptions, Space, Avatar, List, Tag, Popover, Typography } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    InfoCircleOutlined, EditOutlined, AimOutlined, QuestionOutlined, HomeOutlined, CalendarOutlined, DownloadOutlined,
    PoweroffOutlined, UserOutlined, AppstoreOutlined, CloseOutlined, MenuOutlined, VideoCameraOutlined, CloudOutlined, WechatOutlined,
} from '@ant-design/icons';
import BarChart from '../../chart/BarChart';
import Donut from '../../chart/Donut';
import CourseHome from './CourseHome';
import CourseInfo from './CourseInfo'
import momenttimezone from 'moment-timezone';
import moment from 'moment';
import ProgramObjective from './ProgramObjective'
import Query from './Query'
import FAQ from '../common/Faq';
import Profile from './Profile'
import store from "../../redux/store";
import { logoutUser } from '../../redux/actions/commonActions';
import Jitsi from 'react-jitsi';
import TimeTable from './TimeTable'
import Faq from '../common/Faq';
const { Option } = Select;
const { Content } = Layout;
const { Meta } = Card;
const { Link } = Typography;
const { Countdown } = Statistic;
momenttimezone.tz.setDefault("Asia/Kolkata");
const dateFormatList = 'DD/MM/YYYY HH:mm:ss';

class Home extends Component {

    state = {
        courses: [],
        loading: true,
        loading2: true,
        open: false,
        visible: false,
        visibleprofile: false, visiblemeeting: false,
        visiblesettings: false,
        PASSWORD: "",
        CONFIRMPASSWORD: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        MAIL_ID: "",
        MOBILE: "",
        DEPARTMENT: "",
        SLOT: "",
        SECTION: this.props.common.user.SLOT,
        REGISTER_NUMBER: 0,
        key: 0,
        keyChart: 0,
        COURSE_DETAILS: '',
        liveSessions: [], jitsiSessions: [],
        liveSessionsFiltered: [],
        timeStamp: Math.round(new Date().getTime() / 1000),
        GENDER_IMAGE: this.props.common.user.GENDER === 'FEMALE' ? 'student3' : 'student5',
        GENDER: this.props.common.user.GENDER,
        enableJitsi: false,
        jitsiMeeting: {},
        api: '',
        jitsipass: '',
        COURSE_INFO: {},
        batches: [],
        attendanceDetailed: {},
        //selectedSemester: 1,
        selectedSemester: JSON.parse(localStorage.getItem('flags')).CURRENT_SEMESTER || 0,
        groupedBySemester: {},
    }

    scrollDiv = createRef();

    componentDidMount = () => {
        this.getDetails();
    }

    componentWillMount() {
        //  this.getDetails();
    }

    handleChangeSelect = (value) => {
        this.setState({ GENDER: value });
    }

    getToken = () => {
        let data = {
            INFO: this.props.common.user,
            KEY: config.key
        }
        axios.post(config.curriculaserver + '/curricula/gettoken', data)
            .then(res => {
                if (res.data.result) {
                    if (res.data.result.localeCompare(localStorage.jwtToken) !== 0) {
                        return 1;
                    }
                    else return 0;
                }
            });
    }

    getDetails = async () => {
        var data = {
            USER_ID: this.props.common.user.USER_ID,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/getprofile', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState({
                        USER_ID: res.data.user.USER_ID,
                        FIRST_NAME: res.data.user.FIRST_NAME,
                        LAST_NAME: res.data.user.LAST_NAME,
                        MAIL_ID: res.data.user.MAIL_ID,
                        MOBILE: res.data.user.MOBILE,
                        DEPARTMENT: res.data.user.DEPARTMENT,
                        SLOT: res.data.user.SLOT,
                        REGISTER_NUMBER: res.data.user.REGISTER_NUMBER,
                        SECTION: res.data.user.SLOT,
                        GENDER: res.data.user.GENDER || "Male",
                    });
                }
            });

        let batches = [];

        await axios.post(config.curriculaserver + '/curricula/student/home/getcourses', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    res.data.courses.forEach(function (entry) {
                        let temp = entry.BATCH_ID;
                        batches.push(temp);
                    });

                    const groupedBySemester = await res.data.courses.reduce((groups, item) => {
                        const semester = item.SEMESTER;
                        if (!groups[semester]) {
                            groups[semester] = [];
                        }
                        groups[semester].push(item);
                        return groups;
                    }, {});


                    await this.setState({ courses: res.data.courses, groupedBySemester: groupedBySemester, batches: batches || [] })
                }
            });

        data.batches = batches;

        await axios.post(config.curriculaserver + '/curricula/student/home/getlivesessions', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState({ liveSessions: res.data.result, liveSessionsFiltered: res.data.liveSessionsFiltered, jitsiSessions: res.data.jitsiSessions, timeStamp: res.data.timeStamp, loading: false })
                }
            });
    }

    setKey_zero = async () => {
        await this.setState({ loading: true });
        await this.getDetails();
        await this.setState({ key: 0 });
    }

    StatisticTemplate = (title, value, precision, suffix, span) => {
        return (
            <Col xs={{ span: 24 }} md={{ span: span }}>
                <Card size="small" loading={this.state.loading}>
                    <img src={require('../../images/' + suffix + '.svg').default} alt="" className="verifiable-skill-card-logo" />
                    <Statistic
                        title={title}
                        value={value}
                        precision={precision}
                        groupSeparator={''}
                        valueStyle={{ color: '#3f8600' }}
                    />
                </Card>
            </Col>);
    }

    handleCancel = () => {
        this.setState({ visible: false, visibleprofile: false, visiblesettings: false, visiblemeeting: false });
    };

    showModal = () => { this.setState({ visibleprofile: true }); };
    showModalsettings = () => { this.setState({ visiblesettings: true }); };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleOk = () => {
        if (!this.state.FIRST_NAME || !this.state.LAST_NAME || !this.state.MAIL_ID || !this.state.MOBILE || !this.state.PASSWORD || !this.state.GENDER || !this.state.REGISTER_NUMBER) {
            message.error('Invalid Entry !');
            return false;
        }

        if (this.state.PASSWORD !== this.state.CONFIRMPASSWORD) {
            message.error('Password Not Matched!');
            return false;
        }

        if (isNaN(this.state.MOBILE)) {
            message.error('Invalid Mobile Number!');
            return false;
        }

        let data = {
            USER_ID: this.props.common.user.USER_ID,
            FIRST_NAME: this.state.FIRST_NAME.toUpperCase(),
            LAST_NAME: this.state.LAST_NAME.toUpperCase(),
            MAIL_ID: this.state.MAIL_ID,
            REGISTER_NUMBER: this.state.REGISTER_NUMBER,
            MOBILE: this.state.MOBILE,
            PASSWORD: this.state.PASSWORD,
            GENDER: this.state.GENDER,
            key: config.key,
        }

        axios.post(config.curriculaserver + '/curricula/profile', data)
            .then(async res => {
                if (res.data.Status === 1)
                    if (res.data.Status === 1) { message.success('Profile Updated ! Pl Logout and Login !'); }
                    else
                        message.error('Failed Entry !');
                this.setState({ visibleprofile: false });
            });
    };

    reportChart = (course) => {
        this.setState({ loading2: true });
        let COURSE_INFO = {
            COURSE_CODE: course.COURSE_CODE,
            BATCH_ID: course.BATCH_ID,
            COURSE_NAME: course.COURSE_NAME,
            USER_ID: this.props.common.user.USER_ID,
            USER_NAME: this.props.common.user.USER_NAME,
        }
        let sendData = {
            key: config.key,
            COURSE_INFO: COURSE_INFO,
        };

        axios.post(config.curriculaserver + '/curricula/admin/report/getindividualreport', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let record = res.data.result;
                    let LO = {
                        data: [
                            { Unit: 'UNIT 1', Value: record.LO.UNIT1 },
                            { Unit: 'UNIT 2', Value: record.LO.UNIT2 },
                            { Unit: 'UNIT 3', Value: record.LO.UNIT3 },
                            { Unit: 'UNIT 4', Value: record.LO.UNIT4 },
                            { Unit: 'UNIT 5', Value: record.LO.UNIT5 },
                        ],
                        title: "Learning Outcomes",
                        xField: "Unit",
                        yField: "Value"
                    };
                    let MCQ = {
                        data: [
                            { Unit: 'UNIT 1', Value: record.MCQ.UNIT1 },
                            { Unit: 'UNIT 2', Value: record.MCQ.UNIT2 },
                            { Unit: 'UNIT 3', Value: record.MCQ.UNIT3 },
                            { Unit: 'UNIT 4', Value: record.MCQ.UNIT4 },
                            { Unit: 'UNIT 5', Value: record.MCQ.UNIT5 },
                        ],
                        title: "MCQ",
                        xField: "Unit",
                        yField: "Value"
                    }
                    let PRACTICE = {
                        data: [
                            { Unit: 'UNIT 1', Value: record.PRACTICE.UNIT1 },
                            { Unit: 'UNIT 2', Value: record.PRACTICE.UNIT2 },
                            { Unit: 'UNIT 3', Value: record.PRACTICE.UNIT3 },
                            { Unit: 'UNIT 4', Value: record.PRACTICE.UNIT4 },
                            { Unit: 'UNIT 5', Value: record.PRACTICE.UNIT5 },
                        ],
                        title: "Learning Practice",
                        xField: "Unit",
                        yField: "Value"
                    }
                    let STATUS = {
                        data: [
                            { Unit: 'UNIT 1', Value: record.STATUS.UNIT1 },
                            { Unit: 'UNIT 2', Value: record.STATUS.UNIT2 },
                            { Unit: 'UNIT 3', Value: record.STATUS.UNIT3 },
                            { Unit: 'UNIT 4', Value: record.STATUS.UNIT4 },
                            { Unit: 'UNIT 5', Value: record.STATUS.UNIT5 },
                        ],
                        title: "Session Status",
                        xField: "Unit",
                        yField: "Value"
                    }
                    let SLOSTATUS = {
                        data: [
                            { Unit: 'UNIT 1', Value: parseFloat(record.SLOSTATUS.UNIT1.toFixed(2)) },
                            { Unit: 'UNIT 2', Value: parseFloat(record.SLOSTATUS.UNIT2.toFixed(2)) },
                            { Unit: 'UNIT 3', Value: parseFloat(record.SLOSTATUS.UNIT3.toFixed(2)) },
                            { Unit: 'UNIT 4', Value: parseFloat(record.SLOSTATUS.UNIT4.toFixed(2)) },
                            { Unit: 'UNIT 5', Value: parseFloat(record.SLOSTATUS.UNIT5.toFixed(2)) },
                        ],
                        title: "SLO Status",
                        xField: "Unit",
                        yField: "Value"
                    }
                    let UNIT_MARKS = {
                        data: [
                            { Unit: 'UNIT 1', Value: parseFloat(record.UNIT_MARKS.UNIT1.toFixed(2)) },
                            { Unit: 'UNIT 2', Value: parseFloat(record.UNIT_MARKS.UNIT2.toFixed(2)) },
                            { Unit: 'UNIT 3', Value: parseFloat(record.UNIT_MARKS.UNIT3.toFixed(2)) },
                            { Unit: 'UNIT 4', Value: parseFloat(record.UNIT_MARKS.UNIT4.toFixed(2)) },
                            { Unit: 'UNIT 5', Value: parseFloat(record.UNIT_MARKS.UNIT5.toFixed(2)) },
                        ],
                        title: "Unit Marks",
                        xField: "Unit",
                        yField: "Value"
                    }
                    let CLA = {
                        data: [
                            { Mark: 'MARKS', Value: record.CLA.MARKS },
                        ],
                        title: config.CLAfullName,
                        xField: "Mark",
                        yField: "Value"
                    }

                    let ATTENDANCE = {
                        data: [
                            { Unit: 'UNIT 1', Value: typeof record.ATTENDANCE != "undefined" ? parseFloat(record.ATTENDANCE.UNIT1.toFixed(2)) : 0 },
                            { Unit: 'UNIT 2', Value: typeof record.ATTENDANCE != "undefined" ? parseFloat(record.ATTENDANCE.UNIT2.toFixed(2)) : 0 },
                            { Unit: 'UNIT 3', Value: typeof record.ATTENDANCE != "undefined" ? parseFloat(record.ATTENDANCE.UNIT3.toFixed(2)) : 0 },
                            { Unit: 'UNIT 4', Value: typeof record.ATTENDANCE != "undefined" ? parseFloat(record.ATTENDANCE.UNIT4.toFixed(2)) : 0 },
                            { Unit: 'UNIT 5', Value: typeof record.ATTENDANCE != "undefined" ? parseFloat(record.ATTENDANCE.UNIT5.toFixed(2)) : 0 },
                        ],
                        title: "Attendance  ",
                        xField: "Unit",
                        yField: "Value"
                    }
                    let details = {
                        data: [
                            { Type: 'UNIT 1', Value: parseFloat(record.INTERNAL_MARKS.UNIT1.toFixed(2)) },
                            { Type: 'UNIT 2', Value: parseFloat(record.INTERNAL_MARKS.UNIT2.toFixed(2)) },
                            { Type: 'UNIT 3', Value: parseFloat(record.INTERNAL_MARKS.UNIT3.toFixed(2)) },
                            { Type: 'UNIT 4', Value: parseFloat(record.INTERNAL_MARKS.UNIT4.toFixed(2)) },
                            { Type: 'UNIT 5', Value: parseFloat(record.INTERNAL_MARKS.UNIT5.toFixed(2)) },
                        ],
                        title: "Internal Marks",
                        xField: "Type",
                        yField: "Value"
                    };

                    let total = record.INTERNAL_MARKS.OVERALL_PERCENTAGE || 0;
                    this.setState({
                        LO, MCQ, PRACTICE, STATUS, SLOSTATUS, UNIT_MARKS, CLA, ATTENDANCE, details, total,
                        attendanceDetailed: res.data.ATTENDANCE || {},
                        COURSE_DETAILS: COURSE_INFO.COURSE_NAME + " (" + COURSE_INFO.COURSE_CODE + ")",
                        keyChart: 1,
                        loading2: false
                    });
                }
            });
        this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    }

    courseCard = (course) => {
        let html = <Badge.Ribbon text={"SEMESTER " + course.SEMESTER} color="pink" >
            <Card
                size="small"
                hoverable
                style={{ width: "100%" }}
                cover={<img alt="course" height="100%" src={require('../../images/' + this.state.GENDER_IMAGE + '.svg').default}
                    onClick={this.selectCourse.bind(this, course)} />}
                actions={
                    [<Tooltip placement="bottom" title="Course Information">
                        <InfoCircleOutlined key="info" onClick={this.selectCourseInfo.bind(this, course)} />
                    </Tooltip>,
                    <Tooltip placement="bottom" title="Statistics">
                        <AppstoreOutlined key="bar" onClick={this.reportChart.bind(this, course)} />
                    </Tooltip>,
                    <Popover content={this.batchInfo(course)} placement="topRight" title="Section Info"><UserOutlined /></Popover>,
                    <Tooltip placement="bottom" title="Continue Learn"><EditOutlined key="edit" onClick={this.selectCourse.bind(this, course)} /></Tooltip>
                    ]
                }
            >
                <Meta title={course.COURSE_NAME} description={<>
                    {course.COURSE_CODE}<br />
                    <Progress percent={course.completion || 0} status="active" className="mb-1" />
                </>
                } />
            </Card></Badge.Ribbon>;
        return html;
    }

    batchInfo = (course) => {
        let html = <>
            <span> Section Name : {course.BATCH_NAME ? course.BATCH_NAME : ''}</span><br />
            <span> Section ID: {course.BATCH_ID ? course.BATCH_ID : ''}</span><br />
            <span> Faculty : {course.FACULTY_NAME ? course.FACULTY_NAME : ''}</span>
        </>;
        return html;
    }

    selectCourse = async (course) => {
        if (this.getToken()) {
            alert("You have logged in other system !")
            store.dispatch(logoutUser());
        }
        else {
            course.ENABLE ?
                (await this.setState({ COURSE_CODE: course.COURSE_CODE, COURSE_NAME: course.COURSE_NAME, COURSE_INFO: course, key: 1 })) :
                message.info(course.COURSE_NAME + " Course is Disabled !");
        }
    }

    selectCourseInfo = (course) => {
        this.setState({ COURSE_CODE: course.COURSE_CODE, COURSE_NAME: course.COURSE_NAME, COURSE_INFO: course, key: 2 })
    }

    onChangeSwitch = (flag) => {
        let timeStamp = this.state.timeStamp;
        let liveSessions = this.state.liveSessions;
        //let liveSessionsFiltered1 = this.state.liveSessions;

        let liveSessionsFiltered = [];
        if (flag) {
            liveSessions.forEach(function (entry) {
                let temp = entry.DATETIME;
                temp += (parseInt(entry.DURATION) * 60);
                if (timeStamp >= entry.DATETIME && timeStamp <= temp) entry.LIVE = 1; else entry.LIVE = 0;
                if (temp > timeStamp) liveSessionsFiltered.push(entry);
            });
        }
        else {
            liveSessions.forEach(function (entry) {
                let temp = entry.DATETIME;
                temp += (parseInt(entry.DURATION) * 60);
                if (temp < timeStamp) {
                    entry.LIVE = -1;
                    liveSessionsFiltered.push(entry);
                }
            });
        }
        this.setState({ liveSessionsFiltered });
    }

    handleAPI = (api) => {
        let password = this.state.jitsipass;

        api.on('passwordRequired', function () {
            api.executeCommand('password', password);
        });

        api.on('participantLeft', function (e) {
            //console.log(e);
        });

        api.on('readyToCLose', function (e) {
            this.setState({ visiblemeeting: false });
        });
        this.setState({ api: api });
    }

    attendJitsi = (record) => {
        let password = record.COURSE_CODE + record.facultyID + record.UNIT + record.SESSION + '';
        this.setState({
            jitsiMeeting: record, enableJitsi: true,
            jitsipass: password, visiblemeeting: true
        });
    }

    render() {
        let { key, COURSE_CODE, COURSE_NAME, COURSE_INFO, attendanceDetailed, selectedSemester, groupedBySemester } = this.state;
        let toolname = config.toolname + " " + config.totalYear;
        let newCoursesList = (selectedSemester !== 0 ? (groupedBySemester[selectedSemester]) : this.state.courses) || [];

        return (
            <div className="userPageHeader">
                <PageHeader
                    title={<span>ITMS - {toolname}</span>}
                    extra={[
                        <Tooltip key="7" placement="bottom" title={"Student Circle"} > <Button className={!config.studentProfile ? "hide" : ""} ghost icon={<CloudOutlined />} onClick={() => { this.setState({ key: 7 }) }}></Button></Tooltip>,
                        // <Button ghost icon={<DownloadOutlined />} href={"https://drive.google.com/file/d/1wfmpVo63g0UlQffY65Na2F6vXWDdaUEc/view"} target="_blank">Manual</Button>,
                        // <Tooltip key="9" placement="left" title={"FAQ"}><Button ghost icon={<QuestionOutlined />} onClick={() => { this.setState({ key: 9 }) }}></Button></Tooltip>,
                        <Tooltip key="8" placement="left" title={"Chat"}><Button ghost icon={<WechatOutlined />} href={"https://chat.google.com"} target="_blank"></Button></Tooltip>,
                        <Tooltip key="6" placement="bottom" title={"Learning TimeTable"}><Button ghost icon={<CalendarOutlined />} onClick={() => { this.setState({ key: 6 }) }}></Button></Tooltip>,
                        <Tooltip key="5" className="hide" placement="bottom" title={"FAQ"}><Button ghost icon={<QuestionOutlined />} onClick={() => { this.setState({ key: 5 }) }}></Button></Tooltip>,
                        <Tooltip key="4" className="hide" placement="bottom" title={"Help Desk Support"}><Button ghost icon={<EditOutlined />} onClick={() => { this.setState({ key: 4 }) }}></Button></Tooltip>,
                        <Tooltip key="3" placement="bottom" title={"Program Outcomes"}><Button ghost icon={<AimOutlined />} onClick={() => { this.setState({ key: 3 }) }}></Button></Tooltip>,
                        <Tooltip key="2" placement="bottom" title={"Profile"}><Button ghost icon={<UserOutlined />} onClick={this.showModal}></Button></Tooltip>,
                        <Button key="1" type="danger" icon={<PoweroffOutlined />} onClick={() => { this.props.history.push('/') }}>Logout</Button>,
                    ]}
                />

                <Layout>
                    {
                        !this.props.common.user.MAIL_ID ?
                            <Alert
                                message={"Mange your information and privacy to make " + config.toolname + " work better for you"}
                                banner
                                closable
                                action={
                                    <Button size="small" type="ghost" onClick={this.showModal}>Update</Button>
                                }
                            /> : ''
                    }
                    <Content style={{ padding: '0 30px' }}>

                        <div style={{ padding: '0 24px', minHeight: "100%", marginTop: "35px" }} className="course-card">

                            <Row gutter={[16, 16]}>
                                {this.StatisticTemplate("Username", this.props.common.user.USER_ID, '', "account", 4)}
                                {this.StatisticTemplate("Name", this.props.common.user.FIRST_NAME, '', "user", 8)}
                                {this.StatisticTemplate("Department", this.props.common.user.DEPARTMENT, '', "slot", 8)}
                                {this.StatisticTemplate("Role", 'STUDENT', '', "course", 4)}
                            </Row>
                            {
                                key === 0 && this.state.liveSessionsFiltered.length > 0 ?
                                    (<Card className="student-breadcrump mt-1" type="inner"
                                        title={<>
                                            <Breadcrumb separator=">">
                                                <Breadcrumb.Item style={{ cursor: "pointer", color: "white" }}><VideoCameraOutlined className="mr-1" />Live Sessions</Breadcrumb.Item>
                                            </Breadcrumb>
                                        </>
                                        }
                                        style={{ width: "100%" }}
                                        loading={this.state.loading}>
                                        <List
                                            itemLayout="horizontal"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    //console.log(page);
                                                },
                                                pageSize: 3,
                                            }}
                                            dataSource={this.state.liveSessionsFiltered}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={require('../../images/meet/' + item.TYPE + '.svg').default} />}
                                                        title={item.SESSION_TITLE}
                                                        description={<Row gutter={[8, 8]}>
                                                            <Col xs={24}>
                                                                {item.DESCRIPTION}
                                                            </Col>
                                                            <Col xs={24}>
                                                                <Tag color="geekblue">{item.TYPE}</Tag>
                                                                <Tag color="success">{item.COURSE_CODE + " : " + item.COURSE_NAME}</Tag>
                                                                <Tag color="processing">Faculty : {item.facultyName}</Tag>
                                                                <Tag color="warning">{moment.unix(item.DATETIME).format(dateFormatList)}</Tag>
                                                                <Tag color="cyan">{item.DURATION + " Min."}</Tag>
                                                            </Col>
                                                        </Row>}
                                                    />
                                                    <Space size="large">
                                                        {
                                                            item.LIVE === 1 ? <img className="pulse" alt="TEL" height="30" src={require('../../images/live.svg').default} /> : item.LIVE === -1 ? <Tag>Session Completed</Tag> :
                                                                <Countdown value={moment.unix(item.DATETIME)} format="DD day HH:mm:ss" />
                                                        }
                                                        <Button icon={<VideoCameraOutlined />} disabled={item.LIVE === -1 ? true : false} target="_blank" type="primary" href={item.LINK}>Go Live</Button>
                                                    </Space>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>) : ''
                            }

                            {
                                key === 0 && this.state.jitsiSessions.length > 0 ?
                                    (<Card className="student-breadcrump mt-2" type="inner"
                                        title={<>
                                            <Breadcrumb separator=">">
                                                <Breadcrumb.Item style={{ cursor: "pointer", color: "white" }}><VideoCameraOutlined className="mr-1" />Instant Meeting</Breadcrumb.Item>
                                            </Breadcrumb>
                                        </>
                                        }
                                        style={{ width: "100%" }}
                                        loading={this.state.loading}>
                                        <List
                                            itemLayout="horizontal"
                                            loading={this.state.loading}
                                            pagination={{
                                                onChange: page => {
                                                    //console.log(page);
                                                },
                                                pageSize: 3,
                                            }}
                                            dataSource={this.state.jitsiSessions}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={require('../../images/meet/Jitsi.png').default} />}
                                                        title={item.SESSION_TITLE}
                                                        description={
                                                            <Row gutter={[8, 8]}>
                                                                <Col xs={24}>
                                                                    <Tag color="success">{item.COURSE_CODE + " : " + item.COURSE_NAME}</Tag>
                                                                    <Tag color="cyan">UNIT {item.UNIT}</Tag>
                                                                    <Tag color="geekblue">SESSION {item.SESSION}</Tag>
                                                                    <Tag color="processing">Faculty : {item.facultyName}</Tag>
                                                                </Col>
                                                            </Row>}
                                                    />
                                                    <Space size="large">
                                                        {
                                                            item.ACTIVE === 1 ? <img className="pulse" alt="TEL" height="30" src={require('../../images/live.svg').default} /> : ''
                                                        }
                                                        <Button icon={<VideoCameraOutlined />}
                                                            onClick={this.attendJitsi.bind(this, item)}
                                                            disabled={item.ACTIVE !== 1 ? true : false} type="primary" >ATTEND
                                                        </Button>
                                                    </Space>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>) : ''
                            }

                            <Card className="student-breadcrump mt-2 mb-2" type="inner"
                                title={<>
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item style={{ cursor: "pointer", color: "white" }} onClick={this.setKey_zero}><Button><HomeOutlined className="mr-1" /> Home </Button></Breadcrumb.Item>
                                        {this.state.key === 1 ? <Breadcrumb.Item style={{ color: "white" }}> <Button>Course Content</Button></Breadcrumb.Item> : ''}
                                        {this.state.key === 2 ? <Breadcrumb.Item style={{ color: "white" }}><Button> Course Information</Button></Breadcrumb.Item> : ''}
                                        {this.state.key === 3 ? <Breadcrumb.Item style={{ color: "white" }}> <Button>Program Outcomes</Button></Breadcrumb.Item> : ''}
                                        {this.state.key === 4 ? <Breadcrumb.Item style={{ color: "white" }}><Button> Any Queries</Button></Breadcrumb.Item> : ''}
                                        {this.state.key === 5 ? <Breadcrumb.Item style={{ color: "white" }}><Button> FAQ</Button></Breadcrumb.Item> : ''}
                                        {this.state.key === 6 ? <Breadcrumb.Item style={{ color: "white" }}><Button> Learning TimeTable</Button></Breadcrumb.Item> : ''}
                                        {this.state.key === 7 ? <Breadcrumb.Item style={{ color: "white" }}><Button> Student Circle</Button> </Breadcrumb.Item> : ''}
                                    </Breadcrumb>
                                </>
                                }
                                style={{ width: "100%" }} extra={<>
                                    <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>
                                }
                                loading={this.state.loading}
                                bodyStyle={{ padding: "2%" }}
                            >
                                <Row type="flex" justify="center" align="middle" className="mb-3 mt-1">
                                    <Col span={24} align="middle">
                                        <Space>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 0, key: 0 }) }}>ALL</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 1, key: 0 }) }}>SEM 1</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 2, key: 0 }) }}>SEM 2</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 3, key: 0 }) }}>SEM 3</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 4, key: 0 }) }}>SEM 4</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 5, key: 0 }) }}>SEM 5</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 6, key: 0 }) }}>SEM 6</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 7, key: 0 }) }}>SEM 7</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 8, key: 0 }) }}>SEM 8</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 9, key: 0 }) }}>SEM 9</Button>
                                            <Button size='small' type='dashed' onClick={() => { this.setState({ selectedSemester: 10, key: 0 }) }}>SEM 10</Button>
                                        </Space>
                                    </Col>
                                </Row>

                                {key === 0 ?
                                    (newCoursesList.length > 0 ?
                                        (
                                            <Row type="flex" justify="start" align="middle" gutter={[16, 16]}>
                                                {
                                                    newCoursesList.map(
                                                        (item, value) => {
                                                            return (
                                                                <Col xs={24} sm={12} md={12} lg={6} key={value}>{this.courseCard(item)}</Col>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Row>
                                        ) :
                                        (
                                            <Row type="flex" justify="center" align="middle" className="mb-1">
                                                <Col span={24}>
                                                    <Empty />
                                                </Col>
                                            </Row>
                                        ))
                                    : key === 1 ?
                                        <CourseHome COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} COURSE_INFO={COURSE_INFO}
                                            USER_ID={this.props.common.user.USER_ID}
                                            FULL_NAME={this.props.common.user.FIRST_NAME + ' ' + this.props.common.user.LAST_NAME}
                                            DEPARTMENT={this.props.common.user.DEPARTMENT}
                                            SLOT={this.props.common.user.SLOT}
                                        />
                                        : key === 2 ?
                                            <CourseInfo COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                            : key === 3 ?
                                                <ProgramObjective DEPARTMENT={this.props.common.user.DEPARTMENT} />
                                                : key === 4 ?
                                                    <Query />
                                                    : key === 5 ?
                                                        <FAQ />
                                                        : key === 6 ?
                                                            <TimeTable batches={this.state.batches} />
                                                            : key === 7 ? <Profile />
                                                                : key === 9 ? <Faq />
                                                                    : ''
                                }
                            </Card>
                            {
                                this.state.keyChart === 1 ?
                                    (
                                        <Card className="student-breadcrump mb-5" type="inner"
                                            title={<><AppstoreOutlined className="mr-1" /> {this.state.COURSE_DETAILS + " OVERALL REPORT"}</>}
                                            loading={this.state.loading2}
                                            style={{ width: "100%" }} extra={<>
                                                <Button size="small" type="primary" danger icon={<CloseOutlined />} onClick={() => { this.setState({ keyChart: 0 }) }}>
                                                    Close
                                                </Button>
                                            </>
                                            }
                                            bodyStyle={{ padding: "2.1%", marginBottom: "0px" }}
                                        >
                                            <Descriptions
                                                bordered
                                                column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                                                size="middle"
                                                className="mb-2"
                                            >
                                                <Descriptions.Item label="Name">
                                                    {this.props.common.user.FULL_NAME}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="User ID">
                                                    {this.props.common.user.USER_ID}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Course">
                                                    {this.state.COURSE_DETAILS}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Internal Scored">
                                                    {<>
                                                        <Row justify="space-around" align="middle">
                                                            <Col span={4} align="middle">
                                                                <Statistic suffix=""
                                                                    title="Total" value={parseFloat(this.state.total).toFixed(2) + " / 100"}
                                                                    valueStyle={{ color: this.state.total > 40 ? '#3f8600' : this.state.total > 25 ? "#faad14" : '#cf1322' }}
                                                                />
                                                            </Col>

                                                            <Col span={20} align="middle" className="padding-5">
                                                                <Donut details={this.state.details} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    }
                                                </Descriptions.Item>

                                                <Descriptions.Item label="Attendance (U-Unit, S-Session)" span={2}>
                                                    <Row gutter={[2, 8]} justify="start" className="padding-1">
                                                        {
                                                            Object.keys(attendanceDetailed).map((item, val) => (
                                                                <Col span={2} key={val}>
                                                                    <Tooltip title={attendanceDetailed[item] === 1 ? "Present" : "Absent / Not Updated"} color={attendanceDetailed[item] === 1 ? "cyan" : "red"}>
                                                                        <Tag color={attendanceDetailed[item] === 1 ? "cyan" : "error"}>{item}</Tag>
                                                                    </Tooltip>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </Descriptions.Item>

                                                <Descriptions.Item label="Status">
                                                    <Row gutter={[16, 16]} className="justify padding-1">
                                                        <Col span={8}>
                                                            <Card title="Learning Outcomes Achived" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.LO} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title="Multiple Choice Questions Completion" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.MCQ} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title="Learning Practice Completion" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.PRACTICE} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title="Session Completion Status" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.STATUS} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title="SLO Completion Status" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.SLOSTATUS} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title={config.UnitTestName} size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.UNIT_MARKS} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title={config.CLAfullName + " Marks Earned"} size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.CLA} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title="Attendance Marks Earned" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.ATTENDANCE} />
                                                            </Card>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Card title="Internal Marks Earned" size="small" type="inner" style={{ width: "100%" }}>
                                                                <BarChart details={this.state.details} />
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    ) : ''
                            }
                            <footer ref={this.scrollDiv} style={{ opacity: 0.1 }}></footer>
                            <Row justify="center" style={{ marginTop: "0px" }}>
                                <Col span={24} align="middle">
                                    <Tag style={{ fontSize: "1.5em", lineHeight: "23px" }} className='mb-3  padding-2' color="geekblue">
                                        For any inquiries, kindly contact Admin @  'contact@ewbc.org' 
                                    </Tag>
                                </Col>
                            </Row>
                        </div>
                    </Content>

                </Layout>

                <Modal
                    title="My Profile"
                    visible={this.state.visibleprofile}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} >
                            <Input disabled value={this.state.USER_ID} addonBefore="Username" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.PASSWORD} type="password" addonBefore="New Password" id="PASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.CONFIRMPASSWORD} type="password" addonBefore="Confirm Password" id="CONFIRMPASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.REGISTER_NUMBER} addonBefore="Register Number" id="REGISTER_NUMBER" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.FIRST_NAME} addonBefore="First Name" id="FIRST_NAME" />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.LAST_NAME} addonBefore="Last Name" id="LAST_NAME" />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.DEPARTMENT} addonBefore="Department" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MAIL_ID} addonBefore="Official Mail ID" id="MAIL_ID" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MOBILE} addonBefore="Mobile" id="MOBILE" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24}>
                            <Input.Group compact>
                                <Input className="FontBook" style={{ width: '20%', color: "#262626", background: "#FAFAFA" }} defaultValue="Gender" readOnly disabled />

                                <Select style={{ width: "80%" }} onChange={this.handleChangeSelect} placeholder="Select Gender" value={this.state.GENDER}>
                                    <Option value="" >{'Choose One'}</Option>
                                    <Option value={'MALE'} >{'MALE'}</Option>
                                    <Option value={'FEMALE'}>{'FEMALE'}</Option>
                                </Select>
                            </Input.Group>
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    centered
                    title="Instant Meeting"
                    visible={this.state.visiblemeeting}
                    width={835}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Jitsi
                        config={{
                            prejoinPageEnabled: false, startWithVideoMuted: true, startWithAudioMuted: true,
                            remoteVideoMenu: {
                                disableKick: true,
                                disableGrantModerator: true,
                            },
                            disableRemoteMute: true,
                        }}
                        roomName={this.state.jitsiMeeting.roomName}
                        displayName={this.props.common.user.USER_ID + "_" + this.props.common.user.FULL_NAME}
                        onAPILoad={this.handleAPI}
                        //password={this.state.jitsipass}
                        interfaceConfig={{
                            TOOLBAR_BUTTONS: [
                                'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                                'fodeviceselection', 'hangup', 'chat',
                                'etherpad', 'raisehand',
                                'videoquality', 'filmstrip',
                                'tileview', 'select-background', 'download'
                            ],

                        }}
                    />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Home));