import React, { Component } from 'react'
import { Input, Avatar, Typography, Select, message, Table, Space, Button, Descriptions, Statistic, DatePicker, Collapse, Spin, Tag } from 'antd';
import axios from 'axios';
import config from '../../config';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import { DownloadOutlined, EnterOutlined } from '@ant-design/icons';
import momenttimezone from 'moment-timezone';
import moment from 'moment';
const { Countdown } = Statistic;
const { TextArea } = Input;
const { Option } = Select;
momenttimezone.tz.setDefault("Asia/Kolkata");
const dateFormatList = 'DD/MM/YYYY HH:mm:ss';
const { Text } = Typography;
const { Panel } = Collapse;

// eslint-disable-next-line no-unused-vars
function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment();
}

class LiveSession extends Component {

    state = {
        loading: true,
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: this.props.COURSE_INFO,
        topic: '',
        liveSessions: [], jitsiSessions: [],
        _id: 0,
        DATETIME: 0,
        key: 0
    }

    async componentDidMount() {
        let sendData = {
            key: config.key,
            COURSE_INFO: this.props.COURSE_INFO,
        };
        await axios.post(config.curriculaserver + '/curricula/faculty/livesession/get', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    let liveSessionsFiltered = [];
                    let timeStamp = res.data.timeStamp || Math.round(new Date().getTime() / 1000);
                    res.data.result.forEach(function (entry) {
                        let temp = entry.DATETIME;
                        temp += (parseInt(entry.DURATION) * 60);
                        if (timeStamp >= entry.DATETIME && timeStamp <= temp) entry.LIVE = 1; else entry.LIVE = 0;
                        liveSessionsFiltered.push(entry);
                    });
                    this.setState({ liveSessions: liveSessionsFiltered, DATETIME: moment(timeStamp) });
                }
            });

        await axios.post(config.curriculaserver + '/curricula/faculty/livesession/getjitsi', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({
                        jitsiSessions: res.data.result, loading: false
                    });
                }
            });
    }

    onOk = (date) => {
        let timeStamp = (moment(date).unix());
        this.setState({ DATETIME: timeStamp });
        //console.log(moment.unix(timeStamp).format(dateFormatList))
    }

    onChange = (value, dateString) => {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
    }

    submitBtn = async () => {
        this.setState({ loading: true });

        let { SESSION_TITLE, TYPE, DATETIME, DURATION, DESCRIPTION, LINK, OUTCOMES, REQ, _id } = this.state;

        let data = {
            _id: _id,
            COURSE_INFO: this.props.COURSE_INFO,
            SESSION_TITLE: SESSION_TITLE,
            TYPE: TYPE,
            DATETIME: DATETIME,
            DURATION: parseInt(DURATION),
            DESCRIPTION: DESCRIPTION,
            LINK: LINK,
            OUTCOMES: OUTCOMES,
            REQ: REQ
        }
        let check = {
            SESSION_TITLE: SESSION_TITLE,
            TYPE: TYPE,
            DATETIME: DATETIME,
            DURATION: parseInt(DURATION),
            DESCRIPTION: DESCRIPTION,
            LINK: LINK,
            OUTCOMES: OUTCOMES,
            REQ: REQ
        }

        let flag = 1;
        Object.keys(check).forEach(function (key) {
            if (!check[key]) {
                flag = 0;
            }
        });

        if (flag === 0) {
            message.error('Invalid Entry!');
            return false;
        }

        let sendData = {
            data: data,
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/faculty/livesession/update', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    let liveSessions = [...this.state.liveSessions];
                    if (_id === 0) liveSessions.push(res.data.result);
                    else {
                        for (let i = 0; i < liveSessions.length; i++) {
                            let element = liveSessions[i];
                            if (element._id === res.data.result._id) {
                                liveSessions[i] = res.data.result;
                                break;
                            };
                        }
                    }
                    this.setState({ liveSessions, loading: false });
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
    }

    onEditBtn = (record) => {
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
    }

    onClickDeleteBtn = (record) => {
        this.setState({ loading: true })

        let liveSessions = [...this.state.liveSessions];

        let sendData = {
            _id: record._id,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/faculty/livesession/delete', sendData)
            .then(res => {
                if (res.data.Status === 1) {
                    liveSessions = liveSessions.filter((el) => { return el._id !== record._id; });
                    this.setState({ liveSessions });
                    message.success("Successfully Deleted !");
                } else
                    message.error("Error !");
            });
        this.setState({ loading: false })
    }

    resetBtn = () => {
        this.setState({
            _id: 0,
            COURSE_INFO: "",
            SESSION_TITLE: "",
            TYPE: "",
            DATETIME: Math.round(new Date().getTime() / 1000),
            DURATION: 0,
            DESCRIPTION: "",
            LINK: "",
            OUTCOMES: "",
            REQ: ""
        });
    }

    hangup = (record) => {
        if (Object.keys(record).length !== 0) {
            let sendData = {
                info: this.props.common.user,
                MEETING_ID: record._id,
                key: config.key,
            }

            axios.post(config.curriculaserver + '/curricula/faculty/livesession/hangupjitsi', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        let jitsiSessions = this.state.jitsiSessions;
                        let index = jitsiSessions.findIndex(x => x._id === record._id);
                        jitsiSessions[index].ACTIVE = 0;
                        this.setState({ jitsiSessions: jitsiSessions });
                        message.success("Session Closed !");
                    }
                    else
                        message.error("Operation Failed");
                });
        }
    }

    secondsToHms = (record) => {
        let SECONDS = parseInt(record.END_TIME) - parseInt(record.DATETIME);
        return new Date(SECONDS * 1000).toISOString().substr(11, 8)
    }

    render() {
        let { liveSessions, jitsiSessions } = this.state;
        let headers = [
            { label: "User ID", key: "facultyID" },
            { label: "User Name", key: "facultyName" },
            { label: "Course Code", key: "COURSE_CODE" },
            { label: "Course Name", key: "COURSE_NAME" },
            { label: "Department", key: "department" },
            { label: "Section", key: "section" },
            { label: "Session Title", key: "SESSION_TITLE" },
            { label: "Date-Time", key: "DATETIME" },
            { label: "Streaming App", key: "TYPE" },
            { label: "Duration", key: "DURATION" },
            { label: "Description", key: "DESCRIPTION" },
            { label: "Link", key: "LINK" },
            { label: "Outcomes", key: "OUTCOMES" },
            { label: "Requirements", key: "REQ" }
        ];

        let headers2 = [
            { label: "Course Code", key: "COURSE_CODE" },
            { label: "Course Name", key: "COURSE_NAME" },
            { label: "Unit", key: "UNIT" },
            { label: "Session", key: "SESSION" },
            { label: "Session Title", key: "SESSION_TITLE" },
            { label: "Date-Time", key: "DATETIME" },
        ];

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (liveSessions.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'SESSION TITLE',
                dataIndex: 'SESSION_TITLE',
                key: 'SESSION_TITLE',
                render: (text) => <Text strong>{ text }</Text>,
                width: 3,
            },
            {
                title: 'COUNTDOWN',
                dataIndex: 'DATETIME',
                key: 'DATETIME',
                render: (text, record) => (
                    record.LIVE === 1 ? <img className="pulse" alt="TEL" height="30" src={ require('../../images/live.svg').default } /> :
                        text < Math.round(new Date().getTime() / 1000) ? <img alt="TEL" height="30" src={ require('../../images/closed.svg').default } />
                            : <Countdown value={ moment.unix(text) } format="DD day HH:mm:ss" />
                ),
                width: 2,
            },
            {
                title: 'STREAMING APP',
                dataIndex: 'TYPE',
                key: 'TYPE',
                render: (text, record) => (
                    <Space><Avatar src={ require('../../images/meet/' + text + '.svg').default } />{ text }</Space>
                ),
                width: 2,
            },
            {
                title: 'DURATION (Min.)',
                dataIndex: 'DURATION',
                key: 'DURATION',
                render: (text, record) => (parseInt(text) || 0),
                width: 1,
            },
            {
                title: 'ACTION',
                fixed: 'right',
                render: (text, record) => (
                    <Space>
                        <Button icon={ <EditOutlined /> } target="_blank" href={ record.LINK } size="small">View</Button>
                        <Button size="small" icon={ <EditOutlined /> } onClick={ this.onEditBtn.bind(this, record) } disabled={ record.DATETIME < Math.round(new Date().getTime() / 1000) ? true : false }
                        >Edit</Button>
                        <Button size="small" danger icon={ <DeleteOutlined /> } onClick={ this.onClickDeleteBtn.bind(this, record) } disabled={ record.DATETIME < Math.round(new Date().getTime() / 1000) ? true : false }
                        > Delete </Button>
                    </Space>
                ),
                width: 1,
            },
        ];

        const columns2 = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (jitsiSessions.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'COURSE',
                dataIndex: 'SESSION_TITLE',
                key: 'SESSION_TITLE',
                render: (text, record) => (<><Tag color="geekblue">{ record.COURSE_NAME }</Tag><Tag color="green">{ record.COURSE_CODE }</Tag></>),
                width: 3,
            },
            {
                title: 'SESSION TITLE',
                dataIndex: 'SESSION_TITLE',
                key: 'SESSION_TITLE',
                render: (text) => <Text strong>{ text }</Text>,
                width: 3,
            },
            {
                title: 'SESSION',
                dataIndex: 'UNIT',
                key: 'UNIT',
                render: (text, record) => (<><Tag color="blue">Unit { record.UNIT }</Tag><Tag color="magenta">Session { record.SESSION }</Tag></>),
                width: 2,
            },
            {
                title: 'STREAMING APP',
                dataIndex: 'TYPE',
                key: 'TYPE',
                render: (text, record) => (
                    <Space><Avatar src={ require('../../images/meet/Jitsi.png').default } />{ text }</Space>
                ),
                width: 1,
            },
            {
                title: 'FROM',
                dataIndex: 'DATETIME',
                key: 'DATETIME',
                render: (text, record) => (moment(text * 1000).format('MMM Do YYYY, h:mm:ss a')),
                width: 1,
            },
            {
                title: 'DURATION',
                dataIndex: 'DURATION',
                key: 'DURATION',
                render: (text, record) => (this.secondsToHms(record)),
                width: 1,
            },
            {
                title: 'ACTION',
                dataIndex: 'ACTIVE',
                key: 'ACTIVE',
                fixed: 'right',
                render: (text, record) => (
                    <Space>
                        <Button type="primary" size="small" danger icon={ <DeleteOutlined /> } onClick={ this.hangup.bind(this, record) }
                            disabled={ text === 0 ? true : false }
                        > Hangup </Button>
                    </Space>
                ),
                width: 1,
            },
        ];
        return (
            <Spin spinning={ this.state.loading }>
                <Collapse defaultActiveKey={ ['1', '2'] } ghost>
                    <Panel header="Instant Live Sessions" key="1">
                        <CSVLink data={ jitsiSessions } headers={ headers2 } filename={ "jitsi-sessions.csv" } >
                            <Button size="middle" icon={ <DownloadOutlined /> }>Download EXCEL</Button>
                        </CSVLink>

                        <Table className="mt-2" loading={ this.state.loading } size="middle" bordered dataSource={ jitsiSessions } columns={ columns2 } />

                    </Panel>
                    <Panel header="Live Sessions" key="2">
                        <CSVLink data={ liveSessions } headers={ headers } filename={ "live-sessions.csv" } >
                            <Button size="middle" icon={ <DownloadOutlined /> }>Download EXCEL</Button>
                        </CSVLink>

                        <Table className="mt-2" loading={ this.state.loading } size="middle" bordered dataSource={ liveSessions } columns={ columns } />

                        <Descriptions
                            bordered
                            column={ { sm: 1, md: 1, lg: 1, xs: 1 } }
                            size="middle"
                            className="mt-2 mb-2"
                            span={ 2 }
                        >
                            <Descriptions.Item label="Session Title" span={ 2 }>
                                <Input
                                    id="SESSION_TITLE" onChange={ this.handleChange } value={ this.state.SESSION_TITLE }
                                    placeholder="Type Here" autoComplete="new-password"
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Type" span={ 2 }>
                                <Select
                                    showSearch
                                    style={ { width: 400 } }
                                    placeholder="Choose One"
                                    value={ this.state.TYPE }
                                    onChange={ this.handleChangeSelect.bind(this, "TYPE") }>
                                    <Option value="Zoom">
                                        <Space><Avatar size="small" src={ require('../../images/meet/Zoom.svg').default } />Zoom</Space>
                                    </Option>
                                    <Option value="Google_Meet">
                                        <Space><Avatar size="small" src={ require('../../images/meet/Google_Meet.svg').default } />Google_Meet</Space>
                                    </Option>
                                    <Option value="Youtube">
                                        <Space><Avatar size="small" src={ require('../../images/meet/Youtube.svg').default } />Youtube</Space>
                                    </Option>
                                    <Option value="Microsoft_Teams">
                                        <Space><Avatar size="small" src={ require('../../images/meet/Microsoft_Teams.svg').default } />Microsoft_Teams</Space>
                                    </Option>
                                    <Option value="Facebook">
                                        <Space><Avatar size="small" src={ require('../../images/meet/Facebook.svg').default } />Facebook</Space>
                                    </Option>
                                    <Option value="Instagram">
                                        <Space><Avatar size="small" src={ require('../../images/meet/Instagram.svg').default } />Instagram</Space>
                                    </Option>
                                </Select>
                            </Descriptions.Item>

                            <Descriptions.Item label="Link" span={ 2 }>
                                <Input
                                    id="LINK" onChange={ this.handleChange } value={ this.state.LINK }
                                    placeholder="Type Here" autoComplete="new-password"
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Session Time" span={ 2 }>
                                <Space size="large">
                                    <DatePicker id="DATETIME" showTime
                                        //  disabledDate={disabledDate}
                                        format={ dateFormatList } onChange={ this.onChange } onOk={ this.onOk }
                                        value={ moment.unix(this.state.DATETIME, dateFormatList) }
                                    />
                                    {
                                        <span style={ { color: "#C51D7F" } } className="monoFont">{ moment.unix(this.state.DATETIME).format('LLLL') }
                                        </span>
                                    }
                                </Space>
                            </Descriptions.Item>

                            <Descriptions.Item label="Duration" span={ 2 }>
                                <Input
                                    id="DURATION" onChange={ this.handleChange } value={ this.state.DURATION }
                                    autoComplete="new-password" type="number"
                                    placeholder="In minutes"
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Description" span={ 2 }>
                                <TextArea rows={ 4 } id="DESCRIPTION" onChange={ this.handleChange } value={ this.state.DESCRIPTION } placeholder="Type Here" />
                            </Descriptions.Item>

                            <Descriptions.Item label="System Requirements" span={ 2 }>
                                <Input
                                    id="REQ" onChange={ this.handleChange } value={ this.state.REQ }
                                    placeholder="Type Here" autoComplete="new-password"
                                />
                            </Descriptions.Item>

                            <Descriptions.Item label="Session Outcomes" span={ 2 }>
                                <TextArea rows={ 4 } id="OUTCOMES" onChange={ this.handleChange } value={ this.state.OUTCOMES } placeholder="Type Here" />
                            </Descriptions.Item>

                            <Descriptions.Item label="Action" span={ 2 }>
                                <Space>
                                    <Button type="primary" onClick={ this.submitBtn } icon={ <EnterOutlined /> }
                                        disabled={ this.props.common.user.ROLE !== 'TF' && this.props.common.user.ROLE !== 'A' ? true : false }
                                    >
                                        { this.state._id ? "Update" : "Submit" }
                                    </Button>
                                    <Button danger onClick={ this.resetBtn }>
                                        Reset
                                    </Button>
                                </Space>
                            </Descriptions.Item>
                        </Descriptions>

                    </Panel>
                </Collapse>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(LiveSession));