import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, DatePicker, Tooltip, notification, Table, Popconfirm, Alert, Tag } from 'antd';
import { Upload, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const InputGroup = Input.Group;
const { Title, Text } = Typography;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const topic = "award";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

class Award extends Component {
    state = {
        awardName: '',
        purpose: '',
        issuedBy: '',
        date: '01/01/2020',
        issuedCategory: '',
        agencyLink: '',
        cashAward: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        _id: '',
        key: '',
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0

    };

    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickSubmitBtn = () => {
        let { awardName, purpose, issuedBy, date, issuedCategory, agencyLink, cashAward, _id, key } = this.state;
        let errorMessages = [];

        if (!awardName || !/^[a-zA-Z ]+$/i.test(awardName)) errorMessages.push("Invalid Award Name");
        else if (!purpose || !/^[a-zA-Z ]+$/i.test(purpose)) errorMessages.push("Invalid purpose");
        else if (!issuedBy) errorMessages.push("Invalid Issued By");
        else if (!date) errorMessages.push("Select Issued Date");
        else if (!issuedCategory) errorMessages.push("Select Issued Category");
        else if (!agencyLink) errorMessages.push("Invalid Agency Link");
        //else if (!cashAward) errorMessages.push("Invalid Cash Amount");

        //File Check Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Check  End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                awardName: awardName,
                purpose: purpose,
                issuedBy: issuedBy,
                date: date,
                issuedCategory: issuedCategory,
                agencyLink: agencyLink,
                cashAward: cashAward,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/professional/award/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    await this.setState(this.initialState);
                    await this.setState({ update: false });
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }


    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File get Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/award/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/award/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/award/' + record.key + ".pdf"
        });
        //File get End
    }

    //File list Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        this.setState({ fileList: fileList });
    }
    //File list End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: "/personal/professional/award/",
            filename: record.key + ".pdf"
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });
                }
            });
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }


    render() {
        let { awardName, purpose, issuedBy, date, issuedCategory, agencyLink, cashAward, edit } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (
                    (text)
                ),
                fixed: 'left',
            },
            {
                title: 'Award Name',
                dataIndex: 'awardName',
                key: 'awardName',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 20,
                fixed: 'left',
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage } defaultVisible={ text === 2 ? true : false }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },

            {
                title: 'Action',
                key: 'certificateNumber',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 20,
            },
        ];

        //File props Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File props End

        return (
            <div style={ { padding: '0px', minHeight: "100%", marginTop: "0px" } }>

                <Row gutter={ [16, 16] } className="mt-1">
                    <Col xs={ 24 }>
                        <Title level={ 3 }>Award Information
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='awardName' addonBefore="* Award Name" onChange={ this.onChange } value={ awardName } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='purpose' addonBefore="* Purpose" onChange={ this.onChange } value={ purpose } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='issuedBy' addonBefore="* Issued By" onChange={ this.onChange } value={ issuedBy } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Issued Date" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id='date' style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "date") } value={ moment(date, dateFormatList) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col xs={ 24 } md={ 8 }>
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 7 }>
                                    <Input defaultValue="* Category" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 17 }>
                                    <Select id='issuedCategory' style={ { width: "100%" } } placeholder="* Category" onChange={ this.handleChangeSelect.bind(this, "issuedCategory") } value={ issuedCategory }>
                                        <Option value="INTERNATINAL_GOVERNMENT">International Government</Option>
                                        <Option value="INTERNATINAL_PRIVATE">International Private</Option>
                                        <Option value="INSTITUTIONAL_LEVEL_GOVERNMENT">Institutional Level Government</Option>
                                        <Option value="INSTITUTIONAL_LEVEL_PRIVATE">Institutional Level Private</Option>
                                        <Option value="NATINAL_GOVERNMENT">National Government</Option>
                                        <Option value="NATINAL_PRIVATE">National Private</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>


                    <Col xs={ 24 } md={ 8 }>
                        <Input id='agencyLink' addonBefore="* Agency Link" onChange={ this.onChange } value={ agencyLink } />
                    </Col>

                    <Col xs={ 24 } md={ 8 }>
                        <Input id='cashAward' addonBefore="Cash Amount" onChange={ this.onChange } type="number" value={ cashAward } />
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>

                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn }
                            disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset } disabled={ !this.state.edit }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } bordered />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Award));