import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import AdditionalQualifications from './Certification'
import Award from './Award'
import Book from './Book'
import Membership from './Membership'
const { Title } = Typography;
const { TabPane } = Tabs;

class ProfessionalDevelopment extends Component {
    state = {
        loading: false,
    };

    render() {

        let tabName = ["Additional Qualifications", "Award", "Membership", "Professional Publications"];

        return (
            <div style={{ padding: "24px" }}>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Title level={3}>Professional Development</Title>
                    </Col>
                    <Col xs={24}>
                        <Tabs tabPosition="top" style={{ height: "100%", textAlign: "left" }} animated={false} >
                            <TabPane tab={tabName[0]} key="1"><AdditionalQualifications /> </TabPane>
                            <TabPane tab={tabName[1]} key="2"><Award /> </TabPane>
                            <TabPane tab={tabName[2]} key="3"><Membership /> </TabPane>
                            <TabPane tab={tabName[3]} key="4"><Book /> </TabPane>

                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ProfessionalDevelopment));