import React, { Component } from 'react'
import { Row, Col, Button, Select, Input, Tag, DatePicker, Tooltip, Table, Popconfirm, Alert, notification } from 'antd';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import lists from '../lists'
const { Option } = Select;
const InputGroup = Input.Group;
const { Title, Text } = Typography;
const dateFormat = ['YYYY'];
const topic = "qualification";
var qualificationFull = lists.qualification;
var qualification = lists.qualification;
var qualifyingExaminationList = [];
var universityList = [];
var states = lists.states;
const ModeOfStudy = ["Full Time", "Part Time", "Part Time (Evening)", "Part time (Week End)", "Distance", "Online"];

const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('File must smaller than 500KB!');
    }
    return temp && isLt2M;
}

class Qualification extends Component {
    state = {
        degree: '',
        specialization: '',
        qualifyingExamination: '',
        institutionName: '',
        university: '',
        universityType: '',
        statename: '',
        mode: '',
        certificateNumber: '',
        completionStatus: '',
        durationFrom: '2016',
        durationTo: "2020",
        obtainedScore: '',
        grade: '',
        gradingSystem: '',
        mediumOfStudy: '',
        degreeList: [],
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        add: true,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    componentDidMount = async () => {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        await axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(async res => {
                if (res.data.Status === 1 && res.data.result) {
                    this.setState({ data: res.data.result });
                    var filteredQualification = [];
                    if (res.data.result.length > 0)// eslint-disable-next-line
                        res.data.result.map(item2 => {
                            if (item2.degree === "SSLC" || item2.degree === "HSC" || item2.degree === "ITI") {
                                filteredQualification = qualification.filter((item) => item2.degree !== item.degree);
                                qualification = filteredQualification;
                            }
                        })
                }
                this.setState({ degreeList: qualification });
                qualification = qualificationFull;
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        if (label === 'degree') {
            for (let i = 0; i < qualification.length; i++) {
                let element = qualification[i];
                if (element.degree === value) {
                    qualifyingExaminationList = element.qualifyingExamination;
                    universityList = element.boardUniversity;
                }
            }
        }
        if (label === 'degree') {
            this.setState({ qualifyingExamination: qualifyingExaminationList[0], universityType: universityList[0].value, mode: ModeOfStudy[0], gradingSystem: "%", completionStatus: "COMPLETED" });
        }
        this.setState({ [label]: value, errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    reset = () => {
        let d = this.state.data
        let degreeList = this.state.degreeList;
        // document.getElementById("edit").classList.remove("disableAllitems");
        this.setState(this.initialState)
        this.setState({ add: true, data: d, degreeList: degreeList });
    }


    onClickSubmitBtn = () => {
        let { degree, specialization, qualifyingExamination, institutionName, mediumOfStudy, universityType, mode, completionStatus, durationFrom, durationTo, obtainedScore, degreeList } = this.state;
        let errorMessages = [];

        if (!degree) errorMessages.push("Select Degree");
        else if (degree !== "SSLC" && degree !== "HSC") {
            if (!qualifyingExamination || !/^[a-zA-Z. ]+$/i.test(qualifyingExamination)) errorMessages.push("Invalid Qualifying Examination");
            else if (!specialization || !/^[a-zA-Z ]+$/i.test(specialization)) errorMessages.push("Invalid Specialization");
        }
        else if (!institutionName || !/^[a-zA-Z ]+$/i.test(institutionName)) errorMessages.push("Invalid Institution Name");
        else if (degree !== "SSLC" && degree !== "HSC" && degree !== "ITI" && degree !== "DIPLOMA") {
            if (!universityType) errorMessages.push("Invalid University Type");
        }
        else if (!mediumOfStudy) errorMessages.push("Invalid Medium of Study");

        // mediumOfStudy else if (!university || !/^[a-zA-Z ]+$/i.test(university)) errorMessages.push("Invalid University");
        else if (!mode) errorMessages.push("Select Mode of Study");
        else if (!durationFrom) errorMessages.push("Select Duration From");
        else if (!durationTo) errorMessages.push("Select Duration To");
        else if (!completionStatus) errorMessages.push("Select Completion Status");
        else if (completionStatus !== "PURSUING")
            if (isNaN(obtainedScore) || !obtainedScore) errorMessages.push("Invalid Obtained Percentage");

        //File Upload Start
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        //File Upload End

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                degree: this.state.degree,
                specialization: this.state.specialization,
                qualifyingExamination: this.state.qualifyingExamination,
                institutionName: this.state.institutionName,
                university: this.state.university,
                universityType: this.state.universityType,
                statename: this.state.statename,
                mode: this.state.mode,
                certificateNumber: this.state.certificateNumber,
                completionStatus: this.state.completionStatus,
                durationFrom: this.state.durationFrom,
                durationTo: this.state.durationTo,
                obtainedScore: this.state.obtainedScore,
                grade: this.state.grade,
                gradingSystem: this.state.gradingSystem,
                mediumOfStudy: this.state.mediumOfStudy,
                degreeList: this.state.degreeList,
                _id: this.state._id,
                key: this.state.key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    let d = [];
                    if (degree === "SSLC" || degree === "HSC" || degree === "ITI")
                        d = degreeList.filter((item) => item.degree !== degree);
                    else
                        d = degreeList;

                    //File Upload Start
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/professional/qualification/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }
                    //File Upload End

                    this.setState(this.initialState);
                    await this.setState({ edit: true, data: res.data.result, add: true, degreeList: d });
                    await this.setState({ errorMessages: ["success"] });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });

    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, add: false, disable: false, fileList: [] });
        if (record.degree) {
            for (let i = 0; i < qualification.length; i++) {
                let element = qualification[i];
                if (element.degree === record.degree) {
                    qualifyingExaminationList = element.qualifyingExamination;
                    universityList = element.boardUniversity;
                }
            }
        }
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File Upload Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/qualification/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/qualification/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/qualification/' + record.key + ".pdf"
        });
        //File Upload End

    }

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/professional/qualification/',
            filename: record.key + '.pdf'
        };

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    let degreeList = this.state.degreeList;
                    let SSLC = qualificationFull[0];
                    let HSC = qualificationFull[1];
                    let ITI = qualificationFull[2];
                    if (record.degree === "SSLC")
                        degreeList.splice(0, 0, SSLC);
                    else if (record.degree === "HSC")
                        degreeList.splice(1, 0, HSC);
                    else if (record.degree === "ITI")
                        degreeList.splice(2, 0, ITI);
                    await this.setState(this.initialState)
                    await this.setState({ data: res.data.result, degreeList: degreeList });
                    await openNotificationWithIcon('success');

                }
            });

    }

    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { degree, specialization, qualifyingExamination, institutionName, mediumOfStudy, university, universityType, statename, mode, certificateNumber, completionStatus, durationFrom, durationTo, obtainedScore, grade, gradingSystem, edit, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Degree',
                dataIndex: 'degree',
                key: 'degree',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 20,
                fixed: 'left',
            },
            {
                title: 'Institution Name',
                dataIndex: 'institutionName',
                key: 'institutionName',
                width: 40,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'University',
                dataIndex: 'university',
                key: 'university',
                render: (text, record) => (
                    text ? text : record.universityType
                ),
                width: 30,
            },
            {
                title: 'Certificate Number',
                dataIndex: 'certificateNumber',
                key: 'certificateNumber',
                render: (text, record) => (
                    text ? text : 'None'
                ),
                width: 50,
            },
            {
                title: 'Action',
                key: 'certificateNumber',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File Upload Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({ file: file }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File Upload End

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }> Professional Qualification
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    <Col xs={ 24 } md={ 8 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Degree" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    { this.state.add === false && this.state.edit === true ?
                                        <Select showSearch id="degree" style={ { width: "100%" } }
                                            onChange={ this.handleChangeSelect.bind(this, "degree") } value={ degree }>
                                            <Option value={ this.state.degree }>{ this.state.degree }</Option>
                                        </Select>
                                        : <Select showSearch id="degree" style={ { width: "100%" } }
                                            onChange={ this.handleChangeSelect.bind(this, "degree") } value={ degree } >
                                            {
                                                this.state.degreeList.map(
                                                    (item, val) => {
                                                        return (
                                                            <Option key={ val } value={ item.degree }>{ item.degree }</Option>
                                                        )
                                                    }
                                                )
                                            }
                                        </Select> }
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    { !degree ? "" : <>
                        {
                            degree !== "SSLC" && degree !== "HSC" ?
                                (<>
                                    <Col xs={ 24 } md={ 8 }  >
                                        <InputGroup>
                                            <Row>
                                                <Col xs={ 24 } md={ 12 }>
                                                    <Input defaultValue="Qualifying Examination" disabled className="newLabel" />
                                                </Col>
                                                <Col xs={ 24 } md={ 12 }>
                                                    <Select showSearch id="qualifyingExamination" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "qualifyingExamination") } value={ qualifyingExamination }>
                                                        {
                                                            qualifyingExaminationList.map(
                                                                (value, key) => {
                                                                    return (
                                                                        <Option key={ key } value={ value }>{ value }</Option>
                                                                    )
                                                                })
                                                        }
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </InputGroup>
                                    </Col>

                                    <Col xs={ 24 } md={ 8 }>
                                        <Input id="specialization" addonBefore="Specialization / Branch" onChange={ this.onChange } value={ specialization } />
                                    </Col>
                                </>
                                )
                                : ''
                        }

                        <Col xs={ 24 } md={ 8 }>
                            <Input id="institutionName" addonBefore="* Institution Name" onChange={ this.onChange } value={ institutionName } />
                        </Col>
                        {
                            (this.state.degree === "SSLC" || this.state.degree === "HSC" || this.state.degree === "DIPLOMA" || this.state.degree === 'ITI') ? '' :
                                (<Col xs={ 24 } md={ 8 }>
                                    <Input id="university" addonBefore="* University Name" onChange={ this.onChange } value={ university } />
                                </Col>)
                        }
                        { this.state.degree === 'PDF' ? "" :
                            <Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            { (this.state.degree === "SSLC" || this.state.degree === "HSC" || this.state.degree === "DIPLOMA" || this.state.degree === 'ITI') ?
                                                <Input value="* Board" disabled className="newLabel" />
                                                : (this.state.degree === "UG" || this.state.degree === "PG" || this.state.degree === "PHD" || this.state.degree === 'PDF') ? <Input value="* University Type" disabled className="newLabel" /> :
                                                    <Input defaultValue="* University Type" disabled className="newLabel" />
                                            }
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <Select showSearch id="universityType" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "universityType") } value={ universityType }>
                                                {
                                                    universityList.map(
                                                        (value, key) => {
                                                            return (
                                                                <Option key={ key } value={ value.value }>{ value.name }</Option>
                                                            )
                                                        })
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>
                        }
                        { this.state.degree === 'PDF' ? "" :
                            <Col xs={ 24 } md={ 8 }>
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* State" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <Select showSearch id="statename" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "statename") } value={ statename }>
                                                {
                                                    states.map(
                                                        (item, val) => {
                                                            return (
                                                                <Option key={ val } value={ item }>{ item }</Option>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Select>
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>
                        }
                        { this.state.degree === 'PDF' ? "" :
                            <Col xs={ 24 } md={ 8 }>
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* Mode of Study" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            { this.state.degree === 'PHD' || this.state.degree === 'PDF' ? <Select showSearch id="mode" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "mode") } value={ mode }>
                                                {
                                                    ModeOfStudy.map(
                                                        (value, key) => {
                                                            return (key <= 1 ?
                                                                <Option key={ key } value={ value }>{ value }</Option>
                                                                : "");
                                                        })
                                                }
                                            </Select> :
                                                <Select showSearch defaultValue="FULL_TIME" id="mode" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "mode") } value={ mode }>
                                                    {
                                                        ModeOfStudy.map(
                                                            (value, key) => {
                                                                return (
                                                                    <Option key={ key } value={ value }>{ value }</Option>
                                                                )
                                                            })
                                                    }
                                                </Select>
                                            }
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>
                        }
                        <Col xs={ 24 } md={ 8 }>
                            <Input id="mediumOfStudy" addonBefore="* Medium of Study" onChange={ this.onChange } value={ mediumOfStudy } />
                        </Col>
                        <Col xs={ 24 } md={ 8 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 8 }>
                                        <Input defaultValue="* Duration From" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 16 }>
                                        <DatePicker id="durationFrom" style={ { width: "100%" } } picker="year" onChange={ this.handleChangeDate.bind(this, "durationFrom") } format={ dateFormat } value={ moment(durationFrom, dateFormat) } />
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col>
                        <Col xs={ 24 } md={ 8 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 8 }>
                                        <Input defaultValue="* Duration To" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 16 }>
                                        <DatePicker id="durationTo" style={ { width: "100%" } } picker="year" onChange={ this.handleChangeDate.bind(this, "durationTo") } format={ dateFormat } value={ moment(durationTo, dateFormat) } />
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col>

                        <Col xs={ 24 } md={ 8 }  >
                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 10 }>
                                        <Input defaultValue="* Completion Status" disabled className="newLabel" />
                                    </Col>
                                    <Col xs={ 24 } md={ 14 }>
                                        <Select showSearch id="completionStatus" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "completionStatus") } value={ completionStatus } >
                                            <Option value="PURSUING">Pursuing</Option>
                                            <Option value="COMPLETED">Completed</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col>


                        { completionStatus === "COMPLETED" ? (this.state.degree === "SSLC" || this.state.degree === "HSC" || this.state.degree === "PHD" || this.state.degree === 'PDF') ? "" : this.state.degree === "ITI" ?
                            <Col xs={ 24 } md={ 8 }>
                                <Input id="grade" addonBefore="* Grade" onChange={ this.onChange } value={ grade } />
                            </Col> :
                            <Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* Grade" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <Select showSearch id="grade" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "grade") } value={ grade } >
                                                <Option value="1ST_CLASS_WITH_DISTINCTION">1st Class with Distinction</Option>
                                                <Option value="1ST_CLASS">1st Class</Option>
                                                <Option value="2ND_CLASS">2nd Class</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col> : ''
                        }
                        { completionStatus === "COMPLETED" ? this.state.degree === 'PDF' ? "" :
                            <Col xs={ 24 } md={ 8 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* Grading System" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <Select showSearch id="gradingSystem" style={ { width: "100%" } } onChange={ this.handleChangeSelect.bind(this, "gradingSystem") } value={ gradingSystem } >
                                                <Option value="%">Percentage</Option>
                                                <Option value="CGPA">CGPA</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col> : ''
                        }
                        { completionStatus === "COMPLETED" ? this.state.degree === 'PDF' ? "" : (this.state.degree === "PHD") ? <Col xs={ 24 } md={ 8 }>
                            <Input id="obtainedScore" addonBefore={ gradingSystem ? `Coursework Score (${gradingSystem}) ` : 'Coursework Score' } onChange={ this.onChange } type="number" max="100" value={ obtainedScore } />
                        </Col> : <Col xs={ 24 } md={ 8 }>
                            <Input id="obtainedScore" addonBefore={ gradingSystem ? `Score Obtained (${gradingSystem})` : 'Score Obtained' } onChange={ this.onChange } type="number" max="100" value={ obtainedScore } />
                        </Col> : ''
                        }


                        { completionStatus === "COMPLETED" ?

                            <Col xs={ 24 } md={ 8 }>
                                <Input id="certificateNumber" addonBefore="Certification Number" onChange={ this.onChange } value={ certificateNumber } />
                            </Col> : '' }

                        <Col xs={ 24 }>
                            <Upload { ...props } >
                                <Button>
                                    <UploadOutlined /> Select File
                                </Button>
                            </Upload>
                        </Col>
                    </> }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset } disabled={ !this.state.edit }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500, y: 300 } } bordered />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Qualification));