import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Input, Button, Tag, Typography, Space, message, Select, Descriptions, Table, Alert } from 'antd';
import axios from 'axios';
import config from '../../../config';
import Highlighter from 'react-highlight-words';
import { CSVLink } from "react-csv";
import {
    DownloadOutlined, SearchOutlined
} from '@ant-design/icons';
const { Option } = Select;
const InputGroup = Input.Group;
const { Text } = Typography;
const type1 = ["journal", "conference", "patent", "project", "industrialconsultancy", "researchcentre"];
const type2 = ["researchguideship", "researchguidance", "reviewer", "researchdomain", "researchidentity"];

class ResearchReport extends Component {

    state = {
        collapsed: false,
        topic: '',
        data: [],
        searchText: '',
        searchedColumn: '',
        header_list: [],
        banner: false,
        topic_selected: '',
    }

    handleChangeSelect = (label, value, x) => {
        this.setState({ [label]: value, data: [], loading: true, banner: false, topic_selected: x.children });
        let data = {
            info: this.props.common.user,
            key: config.key,
            topic: value
        };

        if (type1.includes(value)) {
            this.setState({ open: true });
            axios.post(config.curriculaserver + '/curricula/studentcircle/researchreport/getreport1', data)
                .then(res => {
                    if (res.data.Status === 1 && res.data.result) {
                        this.headListGenerate(res.data.result[0])
                        this.setState({ data: res.data.result || [], loading: false, banner: true });
                        message.success('Information loaded');
                    }
                });
        }
        else if (type2.includes(value)) {
            this.setState({ open: true });
            axios.post(config.curriculaserver + '/curricula/studentcircle/researchreport/getreport2', data)
                .then(res => {
                    if (res.data.Status === 1 && res.data.result) {
                        this.headListGenerate(res.data.result[0])
                        let result = res.data.result; let count = 0;
                        result.forEach(function (entry) {
                            entry.key = ++count;
                        });
                        this.setState({ data: result || [], loading: false, banner: true });
                        message.success('Information loaded');
                    }
                });
        }

    }

    headListGenerate = (data) => {
        let header_list = [];
        if (data)
            Object.keys(data).forEach(function (key) {
                header_list.push({ label: key, key: key });
            })
        this.setState({ header_list: header_list || [] })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={ { padding: 8 } }>
                <Input
                    ref={ node => {
                        this.searchInput = node;
                    } }
                    placeholder={ `Search ${dataIndex}` }
                    value={ selectedKeys[0] }
                    onChange={ e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                    onPressEnter={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                    style={ { width: 188, marginBottom: 8, display: 'block' } }
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={ () => this.handleSearch(selectedKeys, confirm, dataIndex) }
                        icon={ <SearchOutlined /> }
                        size="small"
                        style={ { width: 90 } }
                    >
                        Search
                    </Button>
                    <Button onClick={ () => this.handleReset(clearFilters) } size="small" style={ { width: 90 } }>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={ { color: filtered ? '#1890ff' : undefined } } />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={ { backgroundColor: '#ffc069', padding: 0 } }
                    searchWords={ [this.state.searchText] }
                    autoEscape
                    textToHighlight={ text.toString() }
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "default" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { topic, data } = this.state;

        let columns = [
            {
                title: 'S.No',
                width: 1,
                dataIndex: 'key',
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: "User ID",
                dataIndex: "employee_id",
                key: "employee_id",
                width: 1,
                ...this.getColumnSearchProps('employee_id'),
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                fixed: 'left',
            },
            {
                title: "User Name",
                dataIndex: "FULL_NAME",
                key: "FULL_NAME",
                ...this.getColumnSearchProps('FULL_NAME'),
                width: 1
            },
            {
                title: "Department",
                dataIndex: "DEPARTMENT",
                key: "DEPARTMENT",
                ...this.getColumnSearchProps('DEPARTMENT'),
                width: 3
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                width: 1,
                render: (text) => (
                    <Tag color={ this.getStatusColor(text) } key={ text }>
                        { this.getStatusText(text) }
                    </Tag>
                ),
                fixed: 'right',
            },
            {
                title: 'Action',
                dataIndex: 'employee_id',
                render: (text, record) => (
                    <>
                        <a target="_blank" rel="noopener noreferrer" href={ config.curriculaserver + '/uploads/student/' + (type2.includes(this.state.topic) ? text + "/" : "") + 'personal/research/' + this.state.topic + '/' + record.file + ".pdf" } > <Button disabled={ this.state.topic === "researchdomain" ? true : false } type="primary" icon={ <DownloadOutlined /> } > View
                        </Button></a>
                    </>

                ),
                fixed: 'right',
                width: 1,
            },
        ];

        return (
            <Row style={ { overflow: "auto" } }>

                <Col xs={ 24 } className="mt-1 mb-1" style={ { padding: "1%" } }>
                    <Descriptions bordered>
                        <Descriptions.Item label={
                            "Select Topic"
                        }>

                            <InputGroup>
                                <Row>
                                    <Col xs={ 24 } md={ 24 }>
                                        <Select showSearch id="topic" style={ { width: "100%" } } value={ topic }
                                            onChange={ this.handleChangeSelect.bind(this, "topic") }
                                        >
                                            <Option value="" disabled>Choose One</Option>
                                            <Option value="journal">Journal</Option>
                                            <Option value="conference">Conference</Option>
                                            <Option value="patent">Patent</Option>
                                            <Option value="project">Project</Option>
                                            <Option value="industrialconsultancy">Industrial Consultancy</Option>
                                            <Option value="researchdomain">Research Domain</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Descriptions.Item>

                    </Descriptions>
                </Col>


                <Col xs={ 24 } style={ { padding: "1%" } }>
                    <CSVLink data={ data } headers={ this.state.header_list } filename={ this.state.topic + "-full-report.csv" }>
                        <Row gutter={ [16, 16] } >
                            {
                                this.state.banner ?
                                    (<Col xs={ 24 }>
                                        <Alert message={ this.state.topic_selected + " Excel file is ready" } type="success" showIcon closable />
                                    </Col>) : ''
                            }
                            <Col xs={ 24 }>
                                <Button icon={ <DownloadOutlined /> } disabled={ !this.state.header_list ? true : false }>
                                    Download EXCEL
                                </Button>
                            </Col>
                        </Row>
                    </CSVLink>
                    <Table className="mt-1" loading={ this.state.loading } dataSource={ this.state.data } size="small" columns={ columns } scroll={ { x: 1500 } }
                        bordered
                    />
                </Col>

            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ResearchReport));