import React, { Component } from 'react'
import { Row, Col, Button, Input, Tooltip, Tag, Table, notification, Alert, Popconfirm } from 'antd';
import { message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
const topic = "bankdetails";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

class BankDetails extends Component {
    state = {
        bankName: '',
        branchName: '',
        ifsc: '',
        accNo: '',
        reaccNo: '',
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
    }
    initialState = this.state;

    reset = () => {
        let d = this.state.data
        this.setState(this.initialState)
        this.setState({ data: d });
    }

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(res => {
                if (res.data.Status === 1 && res.data.result)
                    this.setState({ data: res.data.result });
            })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        this.setState({ errorMessages: [], disable: false });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
    }

    onClickEditBtn = (record) => {
        this.setState({ errorMessages: [], update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))
    }

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    openNotificationWithIcon('success');
                    await this.setState(this.initialState);
                    await this.setState({ data: res.data.result });

                }
            });
    }


    onClickSubmitBtn = () => {
        let { bankName, branchName, ifsc, accNo, reaccNo, _id } = this.state;
        let errorMessages = [];
        if (!bankName) errorMessages.push("Invalid Bank Name");
        else if (!branchName) errorMessages.push("Invalid Branch Name");
        else if (!accNo) errorMessages.push("Invalid Account Number");
        else if (accNo !== reaccNo) errorMessages.push("Account Number Not Same");
        else if (!ifsc) errorMessages.push("Invalid ifsc");

        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;

        let data = {
            key: config.key,
            topic: topic,
            data: {
                bankName: bankName,
                branchName: branchName,
                ifsc: ifsc,
                accNo: accNo,
                _id: _id,
                key: ''
            },
            info: this.props.common.user,
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    this.setState(this.initialState)
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });

    }

    render() {
        let { bankName, branchName, ifsc, accNo, reaccNo, edit, data } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 1,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Bank Name',
                dataIndex: 'bankName',
                key: 'bankName',
                render: (text) => (
                    <Text strong>{text}</Text>
                ),
                width: 5,
            },
            {
                title: 'Action',
                key: 'key',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={<EditOutlined />} onClick={this.onClickEditBtn.bind(this, record)}> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={this.onClickDeleteBtn.bind(this, record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={<DeleteOutlined />}> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 5,
            },
        ];

        return (
            <div style={{ padding: '0px', minHeight: "100%", marginTop: "0px" }}>

                <Row gutter={[16, 16]} className="mt-1">
                    <Col xs={24}>
                        <Title level={3}>Bank Details
                        {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={{ marginLeft: "10px" }}
                                            onClick={() => { this.setState({ edit: true }) }} />
                                    </Tooltip>
                                ) : ''
                            }
                            <Tag className="ml-1" color="success">Self-Verified</Tag>
                        </Title>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className={edit ? "" : "disableAllitems"}>

                    <Col xs={24} md={8}>
                        <Input id='bankName' addonBefore="* Bank Name" onChange={this.onChange} value={bankName} />
                    </Col>

                    <Col xs={24} md={8}>
                        <Input id='branchName' addonBefore="Branch Name" onChange={this.onChange} value={branchName} />
                    </Col>

                    <Col xs={24} md={8}>
                        <Input.Password id='accNo' addonBefore="* Account Number" onChange={this.onChange} value={accNo} />
                    </Col>

                    <Col xs={24} md={8}>
                        <Input id='reaccNo' addonBefore="* Confirm Account Number" onChange={this.onChange} value={reaccNo} />
                    </Col>

                    <Col xs={24} md={8}>
                        <Input id='ifsc' addonBefore="* IFSC" onChange={this.onChange} value={ifsc} />
                    </Col>

                </Row>

                <Row gutter={[16, 16]} className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={24} key={val}>
                                        <Alert
                                            message={item === "success" ? "Information Updated" : item}
                                            closable showIcon
                                            type={item === "success" ? "success" : "error"}
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={[16, 16]} className="mt-1 mb-1">
                    <Col xs={24} md={4}>
                        <Button type="primary" style={{ width: "100%" }} icon={<PlusOutlined />} htmlType="submit" onClick={this.onClickSubmitBtn} disabled={!this.state.edit}>
                            {this.state.update === false ? "Add" : "Update"}
                        </Button>
                    </Col>

                    <Col xs={24} md={4}>
                        <Button htmlType="button" style={{ width: "100%" }} onClick={this.reset} disabled={!this.state.edit}>
                            Reset
                                </Button>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="mt-2 mb-1">
                    <Col xs={24}>
                        <Table dataSource={this.state.data} size="middle" columns={columns} scroll={{ x: 1500, y: 300 }} bordered />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(BankDetails));