import React, { Component } from 'react'
import { Row, Col, Card, Statistic, Button, Select, Input, Tag, DatePicker, Popconfirm, Table, Tooltip, Alert, notification, Empty } from 'antd';
import { Upload, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Title, Text } = Typography;
const InputGroup = Input.Group;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const dateFormat = ['DD/MM/YYYY'];
const topic = "experience";
const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Status',
        description:
            'Information Deleted',
    });
};

function beforeUploadCheck(file) {
    const temp = file.type === 'application/pdf';
    if (!temp) {
        message.error('You can only upload PDF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
        message.error('Image must smaller than 500KB!');
    }
    return temp && isLt2M;
}


class Experience extends Component {
    state = {
        institutionName: '',
        designation: '',
        type: '',
        role: '',
        department: '',
        dateOfJoining: '01/01/2020',
        dateOfRelieving: '01/01/2020',
        duration: '',
        currentJob: false,
        natureOfWork: [],
        _id: '',
        key: '',
        errorMessages: [],
        disable: true,
        edit: true,
        update: false,
        data: [],
        file: '',
        loading: '',
        fileList: [],
        docStatus: 0
    };
    initialState = this.state;

    componentDidMount() {
        let data = {
            info: this.props.common.user,
            topic: topic,
            key: config.key
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/getdata', data)
            .then(async res => {
                if (res.data.Status === 1 && res.data.result) {
                    let days = 0;
                    for (var k in res.data.result) {
                        if (res.data.result[k].currentJob === true) {
                            res.data.result[k].duration = await this.getDuration(res.data.result[k].dateOfJoining, moment().format("DD/MM/YYYY"))
                        }
                        days = days + parseInt(res.data.result[k].duration);
                    }
                    days = Math.floor(days / 365).toFixed(0) + " Years " + Math.floor((days % 365) / 30).toFixed(0) + " Months " + Math.floor((days % 365) % 30).toFixed(0) + " Days"
                    this.setState({ days: days, data: res.data.result });
                    return res.data.result
                }
            });
    }

    onChange = async (e) => {
        this.setState({ errorMessages: [], disable: false });
        if (e.target.id === 'currentJob' && e.target.checked === true && this.state.dateOfJoining) {
            this.setState({
                institutionName: JSON.parse(localStorage.getItem('flags')).COLLEGE_NAME || '',
                designation: this.props.common.user.DESIGNATION,
                type: this.props.common.user.ROLE === 'TF' || this.props.common.user.ROLE === 'HOD' || this.props.common.user.ROLE === 'PR' ? "TEACHING" : '',
                role: 'FULL_TIME',
                department: this.props.common.user.DEPARTMENT,
                dateOfJoining: this.props.common.user.JOINING_DATE,
                duration: await this.getDuration(this.props.common.user.JOINING_DATE, moment().format("DD/MM/YYYY"))
            });
        }
        if (e.target.id === 'currentJob' && e.target.checked === false) {
            this.setState({ duration: 0, institutionName: '', designation: '', })
        }
        if (e.target.id === 'currentJob') this.setState({ [e.target.id]: e.target.checked });
        else
            this.setState({ [e.target.id]: e.target.value });
    };

    handleChangeSelect = (label, value) => {
        this.setState({ [label]: value });
        this.setState({ errorMessages: [], disable: false });
    }

    handleChangeDate = async (label, date, dateString) => {
        if (dateString)
            this.setState({ [label]: dateString });
        this.setState({ errorMessages: [], disable: false });
        if (label === 'dateOfJoining' && this.state.currentJob === true) {
            var a = dateString;
            var b = moment().format("DD/MM/YYYY")
        }
        else if (label === 'dateOfJoining') {
            a = dateString;
            b = this.state.dateOfRelieving

        }
        if (label === 'dateOfRelieving') {
            a = this.state.dateOfJoining
            b = dateString;
        }
        this.setState({ duration: await this.getDuration(a, b) })
    }

    getDuration = async (a, b) => {
        let momentJoining = moment(a, "DD/MM/YYYY");
        let momentRelieving = moment(b, "DD/MM/YYYY");
        let now = moment();
        let duration = 0;
        let check = momentRelieving > momentJoining && momentRelieving <= now && momentJoining <= now;
        if (check) {
            let dateOfJoining = [momentJoining.year(), momentJoining.month(), momentJoining.date()];
            let dateOfRelieving = [momentRelieving.year(), momentRelieving.month(), momentRelieving.date()];
            duration = (moment(dateOfRelieving).diff(moment(dateOfJoining), 'days', true))
            return duration;
        }
        else {
            this.setState({ duration: 0, errorMessages: ["Invalid Dates"] });
            return 0;
        }
    }


    onClickSubmitBtn = () => {
        let { institutionName, designation, key, currentJob, type, role, department, dateOfJoining, dateOfRelieving, duration, _id, natureOfWork } = this.state;
        let errorMessages = [];
        if (!institutionName || !/^[a-zA-Z ]+$/i.test(institutionName)) errorMessages.push("Invalid Institution Name");
        else if (!designation || !/^[a-zA-Z ]+$/i.test(designation)) errorMessages.push("Invalid Designation");
        else if (!type) errorMessages.push("Invalid Type");
        else if (!role) errorMessages.push("Invalid Role");
        else if (!department || !/^[a-zA-Z ]+$/i.test(department)) errorMessages.push("Invalid Department");
        else if (!dateOfJoining) errorMessages.push("Select Date of Joining");
        if (currentJob === false) if (!dateOfRelieving) errorMessages.push("Select Date of Relieving");
        else if (isNaN(duration) || !duration) errorMessages.push("Invalid duration");
        if (!this.state.file) {
            errorMessages.push("Select File Upload");
        }
        this.setState({ errorMessages: errorMessages, disable: true });
        if (errorMessages.length > 0) return false;
        let data = {
            key: config.key,
            topic: topic,
            data: {
                institutionName: institutionName,
                designation: designation,
                type: type,
                role: role,
                natureOfWork: natureOfWork,
                department: department,
                dateOfJoining: dateOfJoining,
                dateOfRelieving: currentJob ? '' : dateOfRelieving,
                duration: currentJob ? '' : duration,
                currentJob: currentJob,
                _id: _id,
                key: key,
                docStatus: this.state.docStatus === 0 ? 0 : 3,
            },
            info: this.props.common.user,
        }

        if (currentJob) {
            let data = this.state.data;
            var flag = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].currentJob && data[i].institutionName === institutionName && data[i].key !== key) {
                    flag = 1;
                    break;
                }
            }
            if (flag === 1) {
                message.error('Current Institution experience is already added.. !!')
                return false
            }
        }

        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/addarray', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    if (this.state.file) {
                        message.loading('Action in progress..', 10);
                        let formData = new FormData();
                        formData.append('USER_ID', this.props.common.user.USER_ID);
                        formData.append('path', "/personal/professional/experience/");
                        formData.append('filename', (res.data.key + ".pdf"));
                        formData.append('key', config.key);
                        formData.append('file', this.state.file);
                        await axios.post(config.curriculaserver + `/curricula/studentcircle/file/upload`, formData)
                            .then(async res => {
                                if (res.data.Status === 1) {
                                    message.destroy();
                                    await message.success(res.data.msg, 1);
                                } else if (res.data.Status === 0) message.error(res.data.msg, 1);
                            });
                    }

                    await this.setState(this.initialState);
                    this.setState({ update: false, natureOfWork: [] });
                    await this.setState({ errorMessages: ["success"], data: res.data.result });
                    let days = 0;
                    res.data.result.forEach(element => {
                        days = days + parseInt(element.duration);
                    });
                    days = Math.floor(days / 365).toFixed(0) + " Years " + Math.floor((days % 365) / 30).toFixed(0) + " Months " + Math.floor((days % 365) % 30).toFixed(0) + " Days"
                    await this.setState({ days: days })
                }
                else if (res.data.Status === 0)
                    await message.error(res.data.msg);
            });
    }

    onClickEditBtn = (record) => {

        this.setState({ errorMessages: [], edit: true, update: true, disable: false });
        if (record)
            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }))

        //File Upload Start
        this.setState({
            fileList: [{
                uid: record.key,
                name: record.key + ".pdf",
                status: 'done',
                url: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/experience/' + record.key + ".pdf",
                thumbUrl: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/experience/' + record.key + ".pdf",
            }],
            file: config.curriculaserver + '/uploads/student/' + this.props.common.user.USER_ID + '/personal/professional/experience/' + record.key + ".pdf"
        });
        //File Upload End
    }


    //File Upload Start
    onFileChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        if (beforeUploadCheck(info.file))
            this.setState({ fileList: fileList });
        else
            this.setState(() => ({ file: '' }));
    }
    //File Upload End

    onClickDeleteBtn = (record) => {
        let data = {
            _id: record._id,
            topic: topic,
            key: config.key,
            info: this.props.common.user,
            path: '/personal/professional/experience/',
            filename: record.key + '.pdf'
        };
        axios.post(config.curriculaserver + '/curricula/studentcircle/profile/delete', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState(this.initialState);
                    openNotificationWithIcon('success');
                    await this.setState({ data: res.data.result });
                }
                else {
                    message.error("Operation Failed !");
                }
            });
    }


    onAddNatureOfWork = () => {
        let natureOfWork = this.state.natureOfWork;
        natureOfWork.push('');
        this.setState({ natureOfWork: natureOfWork })
    }

    onRemoveNatureOfWork = () => {
        let natureOfWork = this.state.natureOfWork;
        natureOfWork.pop();
        this.setState({ natureOfWork: natureOfWork })
    }

    onChangeNatureOfWork = (id, e) => {
        var natureOfWork = this.state.natureOfWork;
        natureOfWork[id - 1] = e.target.value;
        this.setState({ natureOfWork: natureOfWork })
    }

    reset = () => {
        let data = this.state.data;
        this.setState(this.initialState)
        this.setState({ data: data })
    }

    getStatusColor = (value) => {
        return !value ? "red" : value === 1 ? "green" : value === 2 ? "red" : value === 3 ? "orange" : "red";
    }

    getStatusText = (value) => {
        return !value ? "Not Verified" : value === 1 ? "Verified" : value === 2 ? "Rejected" : value === 3 ? "Re-submission" : "Not Verified";
    }

    render() {
        let { institutionName, designation, currentJob, type, role, department, dateOfJoining, dateOfRelieving, duration, data, edit } = this.state;
        let columns = [
            {
                title: 'S.No',
                dataIndex: 'key',
                width: 10,
                render: (text) => (data.findIndex(i => i.key === text) + 1),
                fixed: 'left',
            },
            {
                title: 'Institution Name',
                dataIndex: 'institutionName',
                key: 'institutionName',
                render: (text) => (
                    <Text strong>{ text }</Text>
                ),
                width: 40,
            },
            {
                title: 'Status',
                dataIndex: 'docStatus',
                render: (text, record) => (
                    <Tooltip title={ record.docMessage }>
                        <Tag color={ this.getStatusColor(text) } key={ text }>
                            { this.getStatusText(text) }
                        </Tag>
                    </Tooltip>
                ),
                width: 20,
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 20,
            },
            {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration',
                render: (totalDays) => (
                    <>
                        { Math.floor(totalDays / 365).toFixed(0) + " Years " +
                            Math.floor((totalDays % 365) / 30).toFixed(0) + " Months " +
                            Math.floor((totalDays % 365) % 30).toFixed(0) + " Days" }
                    </>
                ),
                width: 100,
            },
            {
                title: 'Action',
                key: 'certificateNumber',
                render: (text, record) => (
                    <>
                        <Button type="primary" icon={ <EditOutlined /> } onClick={ this.onClickEditBtn.bind(this, record) }> Edit </Button> &nbsp;
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={ this.onClickDeleteBtn.bind(this, record) }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" icon={ <DeleteOutlined /> }> Delete </Button>
                        </Popconfirm>
                    </>
                ),
                fixed: 'right',
                width: 50,
            },
        ];

        //File Upload Start
        const props = {
            listType: 'picture',
            showUploadList: { showRemoveIcon: false },
            beforeUpload: file => {
                this.setState(() => ({
                    file: file,
                }));
                return false;
            },
            multiple: false,
            fileList: this.state.fileList,
            onChange: this.onFileChange
        };
        //File Upload End

        return (
            <div style={ { padding: "24px" } }>

                <Row gutter={ [16, 16] }>
                    <Col xs={ 24 }>
                        <Title level={ 3 }>  Institutional / Corporate Experience
                            {
                                edit === false ? (
                                    <Tooltip title="Click to edit">
                                        <EditOutlined style={ { marginLeft: "10px" } }
                                            onClick={ () => { this.setState({ edit: true }) } } />
                                    </Tooltip>
                                ) : ''
                            }
                        </Title>

                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className={ edit ? "" : "disableAllitems" }>

                    { /*
                    <Col xs={ 24 }>
                            <Checkbox id="currentJob" addonBefore="* Current Job" onChange={ this.onChange } checked={ currentJob } >* Current Job</Checkbox>
                    </Col>
                    */
                    }

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="institutionName" addonBefore="* Institution / Corporate Name" onChange={ this.onChange } value={ institutionName } />
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="designation" addonBefore="* Designation" onChange={ this.onChange } value={ designation } />
                    </Col>

                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Type" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="type" style={ { width: "100%" } } placeholder="* Type" onChange={ this.handleChangeSelect.bind(this, "type") } value={ type }>
                                        <Option value="TEACHING">Teaching</Option>
                                        <Option value="RESEARCH">Research</Option>
                                        <Option value="INDUSTRY">Industry</Option>
                                        <Option value="ADMINISTRATIVE">Administrative</Option>
                                        <Option value="SUPPORT">Support</Option>
                                        <Option value="OTHERS">Others</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* Role" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <Select id="role" style={ { width: "100%" } } placeholder="* Role" onChange={ this.handleChangeSelect.bind(this, "role") } value={ role }>
                                        <Option value="FULL_TIME">Full Time</Option>
                                        <Option value="PART_TIME">Part Time</Option>
                                        <Option value="VISITING">Visiting</Option>
                                        <Option value="ADJUNT">Adjunt</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>

                    <Col xs={ 24 } md={ 12 }>
                        <Input id="department" addonBefore="* Department / Division" onChange={ this.onChange } value={ department } />
                    </Col>

                    <Col xs={ 24 } md={ 6 }  >
                        <InputGroup>
                            <Row>
                                <Col xs={ 24 } md={ 8 }>
                                    <Input defaultValue="* From" disabled className="newLabel" />
                                </Col>
                                <Col xs={ 24 } md={ 16 }>
                                    <DatePicker id="dateOfJoining" placeholder="* Date of Joining" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "dateOfJoining") } value={ moment(dateOfJoining, dateFormat) } />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    {
                        currentJob ? '' :
                            <Col xs={ 24 } md={ 6 }  >
                                <InputGroup>
                                    <Row>
                                        <Col xs={ 24 } md={ 8 }>
                                            <Input defaultValue="* To" disabled className="newLabel" />
                                        </Col>
                                        <Col xs={ 24 } md={ 16 }>
                                            <DatePicker id="dateOfRelieving" placeholder="* Date of Relieving" style={ { width: "100%" } } format={ dateFormatList } onChange={ this.handleChangeDate.bind(this, "dateOfRelieving") } value={ moment(dateOfRelieving, dateFormat) } />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Col>
                    }

                    <Col xs={ 24 } md={ currentJob ? 6 : 12 }>
                        <Input id="duration" disabled addonBefore="Duration (in days)" onChange={ this.onChange } type="number" value={ duration } />
                    </Col>

                    <Col xs={ 24 } >
                        <Card className="innerCard" type="inner" title="* Nature of work" style={ { width: "100%" } } size="small" extra={
                            <>
                                <Button type="primary" icon={ <PlusOutlined /> } size="small" onClick={ this.onAddNatureOfWork }>Add</Button> &nbsp;
                                <Button type="danger" icon={ <MinusOutlined /> } size="small" onClick={ this.onRemoveNatureOfWork }>Remove</Button>
                            </>
                        }>
                            {
                                this.state.natureOfWork.length > 0 ?
                                    (this.state.natureOfWork.map(
                                        (item, val) => {
                                            return (
                                                <Col xs={ 12 } key={ val }>
                                                    <Input addonBefore={ val + 1 } id={ val + 1 } value={ item } onChange={ this.onChangeNatureOfWork.bind(this, val + 1) } />
                                                </Col>
                                            )
                                        }
                                    )
                                    ) :
                                    <Empty style={ { margin: "5px 0", textAlign: "center" } } image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                            }
                        </Card>
                    </Col>

                    <Col xs={ 24 }>
                        <Upload { ...props }>
                            <Button>
                                <UploadOutlined /> Select File
                            </Button>
                        </Upload>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    {
                        this.state.errorMessages.map(
                            (item, val) => {
                                return (
                                    <Col xs={ 24 } key={ val }>
                                        <Alert
                                            message={ item === "success" ? "Information Updated" : item }
                                            closable showIcon
                                            type={ item === "success" ? "success" : "error" }
                                        />
                                    </Col>
                                )
                            }
                        )
                    }
                </Row>

                <Row gutter={ [16, 16] } className="mt-1 mb-1">
                    <Col xs={ 24 } md={ 4 }>
                        <Button type="primary" style={ { width: "100%" } } icon={ <PlusOutlined /> } htmlType="submit" onClick={ this.onClickSubmitBtn } disabled={ !this.state.edit }>
                            { this.state.update === false ? "Add" : "Update" }
                        </Button>
                    </Col>

                    <Col xs={ 24 } md={ 4 }>
                        <Button htmlType="button" style={ { width: "100%" } } onClick={ this.reset } disabled={ !this.state.edit }>
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Row gutter={ [16, 16] } className="mt-2 mb-1">
                    <Col xs={ 24 }>
                        <Table dataSource={ this.state.data } size="middle" columns={ columns } scroll={ { x: 1500 } } bordered
                            footer={ () => <Statistic className="mt-1" title="Total Experience" valueStyle={ { color: '#3f8600' } } value={ this.state.days } /> }
                        />
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Experience));