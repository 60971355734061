import React, { Component, createRef } from 'react'
import { Row, Col } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sunburst from '../../chart/sunburst';

import Profile from './PersonalDetails/Profile'
import Family from './PersonalDetails/Family'
import Contact from './PersonalDetails/Contact'
import AdmissionDetails from './PersonalDetails/AdmissionDetails'

import CEP from './PersonalDetails/CEP'
import Experience from './PersonalDetails/Experience'
import Qualification from './PersonalDetails/Qualification'
import ProfessionalDevelopment from './PersonalDetails/ProfessionalDevelopment'

import Patent from './PersonalDetails/Patent'
import Publication from './PersonalDetails/Publication'
import ResearchActivities from './PersonalDetails/ResearchActivities'
import ResearchConsultancy from './PersonalDetails/ResearchConsultancy'

import OutreachMembership from './PersonalDetails/OutreachMembership'
import OutreachExaminer from './PersonalDetails/OutreachExaminer'
import OutreachSocial from './PersonalDetails/OutreachSocial'

class MyProfile extends Component {

    scrollDiv = createRef();

    state = {
        page: '',
        parent: '',
        data: {
            "name": "Personal  Info",
            "children": [
                {
                    "name": "Basic",
                    "children": [
                        {
                            "name": "Profile",
                            "parent": "Basic",
                            size: "110000",
                        },
                        {
                            "name": "Contact",
                            "parent": "Basic",
                            size: "110000",
                        },
                        {
                            "name": "Family",
                            "parent": "Basic",
                            size: "110000"
                        },
                        {
                            "name": "Admission",
                            "parent": "Basic",
                            size: "110000"
                        },
                    ]
                },
                {
                    "name": "Professional",
                    "children": [
                        {
                            "name": "Qualification",
                            "parent": "Professional",
                            size: "130000",
                        },
                        {
                            "name": "Experience",
                            "parent": "Professional",
                            size: "110000",
                        },
                        {
                            "name": "Development",
                            "parent": "Professional",
                            size: "110000"
                        },
                        {
                            "name": "CEP",
                            "parent": "Professional",
                            size: "110000"
                        },
                    ]
                },
                {
                    "name": "Research",
                    "children": [
                        {
                            "name": "Publication",
                            "parent": "Research",
                            size: "110000",
                        },
                        {
                            "name": "Patent",
                            "parent": "Research",
                            size: "110000",
                        },
                        {
                            "name": "Consultancy",
                            "parent": "Research",
                            size: "110000"
                        },
                        {
                            "name": "Activities",
                            "parent": "Research",
                            size: "110000"
                        },
                    ]
                },
            ]
        }
    }

    onClickNode = (node) => {
        this.setState({ page: node.data.name, parent: node.data.parent });
        this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });

    }

    render() {
        let { page } = this.state;
        return (
            <Row style={ { overflow: "auto" } } >

                <Col span={ 24 }>

                    <Row style={ { overflow: "auto", textAlign: "center" } } >
                        <Col xs={ 24 } className="student-circle">
                            <Sunburst
                                data={ this.state.data }
                                userName={ this.props.common.user.FIRST_NAME }
                                width="580"
                                height="580"
                                count_member="size"
                                labelFunc={ (node) => node.data.name }
                                onClick={ this.onClickNode }
                            />
                        </Col>
                    </Row>

                </Col>

                <Col xs={ 24 } className="mt-3">
                    <span ref={ this.scrollDiv }>
                        {
                            page === "Profile" ? (<Profile />)
                                : page === "Family" ? (<Family />)
                                    : page === "Contact" ? (<Contact />)
                                        : page === "Admission" ? (<AdmissionDetails />)
                                            : page === "CEP" ? (<CEP />)
                                                : page === "Experience" ? (<Experience />)
                                                    : page === "Qualification" ? (<Qualification />)
                                                        : page === "Development" ? (<ProfessionalDevelopment />)
                                                            : page === "Patent" ? (<Patent />)
                                                                : page === "Publication" ? (<Publication />)
                                                                    : page === "Activities" ? (<ResearchActivities />)
                                                                        : page === "Consultancy" ? (<ResearchConsultancy />)
                                                                            : page === "Membership" ? (<OutreachMembership />)
                                                                                : page === "Examiner" ? (<OutreachExaminer />)
                                                                                    : page === "Social" ? (<OutreachSocial />)
                                                                                        :
                                                                                        ""
                        }
                    </span>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(MyProfile));