import React, { Component } from 'react'
import { Layout } from 'antd';
import { Row, Col, Card, Statistic, Input, Tooltip, Badge, message, PageHeader, Button, Menu, Dropdown, Modal, Breadcrumb, Alert, Tag, Typography } from 'antd';
import axios from 'axios';
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    CaretDownOutlined, PoweroffOutlined, UserOutlined, MenuOutlined, HomeOutlined, WechatOutlined, DownloadOutlined
} from '@ant-design/icons';
import CourseInformation from './CourseHome';
import AddFaculty from './AddFaculty';
import Report from './Report';
import FacultyRegister from '../admin/ManageUsers/UserCreate';
import ManageUsers from '../admin/ManageUsers/ManageUsers';
import Faq from '../common/Faq';
const { Content } = Layout;
const { Link } = Typography;
class Home extends Component {

    state = {
        open: false,
        visible: false,
        visibleprofile: false,
        visiblesettings: false,
        PASSWORD: "",
        CONFIRMPASSWORD: "",
        FIRST_NAME: "",
        LAST_NAME: this.props.common.user.USER_ID.split('_')[1],
        MAIL_ID: "",
        MOBILE: "",
        DEPARTMENT: "",
        SLOT: "",
        COLLEGE_NAME: JSON.parse(localStorage.getItem('flags')).COLLEGE_NAME || '',
        key: 1,
        COURSE_CODE: this.props.common.user.USER_ID.split('_')[1],
        COURSE_NAME: this.props.common.user.FIRST_NAME.toUpperCase() || "COURSE",
    }

    componentDidMount() {
        let data = {
            USER_ID: this.props.common.user.USER_ID,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/getprofile', data)
            .then(res => {
                if (res.data.Status === 1) {
                    this.setState({
                        USER_ID: res.data.user.USER_ID,
                        FIRST_NAME: res.data.user.FIRST_NAME,
                        MAIL_ID: res.data.user.MAIL_ID,
                        MOBILE: res.data.user.MOBILE,
                        DEPARTMENT: res.data.user.DEPARTMENT,
                        SLOT: res.data.user.SLOT,
                    });
                }
            });

    }

    handleMenuClick = (e) => {
        if (parseInt(e.key) === 1) { this.setState({ key: 1 }) }
        else this.setState({ key: parseInt(e.key) })

    }

    menu = (
        <Menu onClick={this.handleMenuClick}>
            <Menu.Item key="1">Content Management</Menu.Item>
            {
                //<Menu.Item key="2">Faculty Register</Menu.Item>
            }
            <Menu.Item key="3">Faculty Assign</Menu.Item>
            <Menu.Item key="4">Course Reports</Menu.Item>
            <Menu.Item key="5">Users List</Menu.Item>
            <Menu.Item key="6">FAQ</Menu.Item>
        </Menu>
    );

    StatisticTemplate(title, value, precision, suffix) {
        return (
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Card size="small">
                    <img src={require('../../images/' + suffix + '.svg').default} alt="" className="verifiable-skill-card-logo" />
                    <Statistic
                        title={title}
                        value={value}
                        precision={precision}
                        valueStyle={{ color: '#3f8600' }}
                    />
                </Card>
            </Col>);
    }

    handleCancel = () => {
        this.setState({ visible: false, visibleprofile: false, visiblesettings: false });
    };

    showModal = () => { this.setState({ visibleprofile: true }); };
    showModalsettings = () => { this.setState({ visiblesettings: true }); };

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleOk = () => {
        if (!this.state.FIRST_NAME || !this.state.LAST_NAME || !this.state.MAIL_ID || !this.state.MOBILE || !this.state.PASSWORD) {
            message.error('Invalid Entry !');
            return false;
        }

        if (this.state.PASSWORD !== this.state.CONFIRMPASSWORD) {
            message.error('Password Not Matched!');
            return false;
        }

        if (isNaN(this.state.MOBILE)) {
            message.error('Invalid Mobile Number!');
            return false;
        }

        let data = {
            USER_ID: this.props.common.user.USER_ID,
            FIRST_NAME: this.state.FIRST_NAME.toUpperCase(),
            LAST_NAME: this.state.COURSE_CODE,
            MAIL_ID: this.state.MAIL_ID,
            MOBILE: this.state.MOBILE,
            PASSWORD: this.state.PASSWORD,
            key: config.key,
        }

        axios.post(config.curriculaserver + '/curricula/profile', data)
            .then(async res => {
                if (res.data.Status === 1)
                    message.success('Profile Updated !');
                else
                    message.error('Failed Entry !');
                this.setState({ visibleprofile: false });
            });
    };

    render() {
        let { COURSE_CODE, COURSE_NAME } = this.state;
        let toolname = config.toolname + " " + config.totalYear;
        return (
            <div className="userPageHeader">
                <PageHeader
                    title={<span> ITMS - {toolname}</span>}
                    extra={[
                        // <Button ghost icon={<DownloadOutlined />} href={"https://drive.google.com/file/d/1Uv0u31MdE2EzDJd9vxhrYxZC5MwuLji8/view"} target="_blank">Manual</Button>,
                        <Tooltip key="5" placement="left" title={"Chat"}><Button ghost icon={<WechatOutlined />} href={"https://chat.google.com"} target="_blank"></Button></Tooltip>,
                        <Tooltip key="3" placement="bottom" title={"Profile"}><Button ghost icon={<UserOutlined />} onClick={this.showModal}></Button></Tooltip>,
                        <Dropdown key="2" overlay={this.menu}>
                            <Button type="primary">
                                More Actions <CaretDownOutlined />
                            </Button>
                        </Dropdown>,
                        <Button key="1" type="danger" icon={<PoweroffOutlined />} onClick={() => { this.props.history.push('/') }}>Logout</Button>,
                    ]}
                />

                <Layout>
                    <Alert message="The course coordinator is responsible for the all materials, course outcomes, and other. For any queries please contact the batch admin/coordinator." type="info" showIcon closable />

                    <Content style={{ padding: '0 30px' }}>

                        <div style={{ padding: '0 24px', minHeight: "100%", marginTop: "35px" }} className="course-card">

                            <Row gutter={[16, 16]}>
                                {this.StatisticTemplate("Course Code", COURSE_CODE, '', "code")}
                                {this.StatisticTemplate("Course Name", COURSE_NAME, '', "course")}
                                {this.StatisticTemplate("Role", 'COURSE COORDINATOR', '', "slot")}
                            </Row>

                            <Card type="inner" className="student-breadcrump mt-2 mb-2" style={{ width: "100%" }} bodyStyle={{ padding: "1.5%" }}
                                title={<>
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item style={{ cursor: "pointer", color: "white" }} onClick={() => { this.setState({ key: 1 }) }}><Button><HomeOutlined className="mr-1" />Home</Button></Breadcrumb.Item>
                                        {this.state.key === 1 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Content Management</Button></Breadcrumb.Item>
                                            : this.state.key === 2 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Faculty Register</Button></Breadcrumb.Item>
                                                : this.state.key === 3 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Faculty Assign</Button></Breadcrumb.Item>
                                                    : this.state.key === 4 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Reports</Button></Breadcrumb.Item>
                                                        : this.state.key === 5 ? <Breadcrumb.Item style={{ color: "white" }}><Button>Users List</Button></Breadcrumb.Item> :
                                                            this.state.key === 6 ? <Breadcrumb.Item style={{ color: "white" }}><Button>FAQ</Button></Breadcrumb.Item> :
                                                                ''}
                                    </Breadcrumb>
                                </>}
                                loading={this.state.loading}
                                extra={<>
                                    <Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>
                                }>
                                {
                                    this.state.key === 1 ? <CourseInformation COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                        : this.state.key === 2 ? <FacultyRegister ROLE='TF' />
                                            : this.state.key === 3 ? <AddFaculty COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                : this.state.key === 4 ? <Report COURSE_CODE={COURSE_CODE} COURSE_NAME={COURSE_NAME} />
                                                    : this.state.key === 5 ? <ManageUsers />
                                                        : this.state.key === 6 ? <Faq />
                                                            : ''
                                }
                            </Card>
                            <br />
                            {/* <Row justify="center" style={{ marginTop: "-30px" }}>
                                <Col span={24} align="middle">
                                    <Tag style={{ fontSize: "1.5em", lineHeight: "23px" }} className='mb-3  padding-2' color="geekblue">
                                        To submit inquiries, kindly utilize the query form found on the DLD website at <Link href="https://dld.srmist.edu.in" target="_blank">
                                            https://dld.srmist.edu.in.
                                        </Link><br /> and you can also access tutorial videos on the website under the Initiatives -&gt; eCurricula section.
                                    </Tag>
                                </Col>
                            </Row> */}
                        </div>
                    </Content>
                </Layout>

                <Modal
                    title="My Profile"
                    visible={this.state.visibleprofile}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} >
                            <Input value={this.state.FIRST_NAME} disabled addonBefore="Course Name" id="FIRST_NAME" />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.COURSE_CODE} addonBefore="Course Code" />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.USER_ID} addonBefore="Username" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.PASSWORD} type="password" addonBefore="New Password" id="PASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.CONFIRMPASSWORD} type="password" addonBefore="Confirm Password" id="CONFIRMPASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input value={this.state.LAST_NAME} addonBefore="Name" id="LAST_NAME" />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.DEPARTMENT} addonBefore="Department" />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.SLOT} addonBefore="Slot" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MAIL_ID} addonBefore="Mail ID" id="MAIL_ID" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MOBILE} addonBefore="Mobile" id="MOBILE" onChange={this.handleChange} />
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Home));