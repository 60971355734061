import React, { Component } from 'react'
import { Descriptions, Card, Badge, Button, message, Checkbox, Row, Col, Image, Alert } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from '../../config';
import axios from 'axios';
import { EditOutlined } from '@ant-design/icons';
const { Meta } = Card;

class Apps extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        COURSE_INFO: '',
        checkedValues: [],
        loading: false,
    };

    async componentDidMount() {
        await this.setState({ loading: true })
        await this.getCourse(this.props.COURSE_CODE)
    }

    async UNSAFE_componentWillReceiveProps(props) {
        await this.setState({ loading: true })
        await this.getCourse(this.props.COURSE_CODE)
    }

    onChangeCheckBox = (checkedValues) => {
        this.setState({ checkedValues });

        let sendData = {
            data: { apps: checkedValues },
            COURSE_CODE: this.state.COURSE_CODE,
            key: config.key
        };

        axios.post(config.curriculaserver + '/curricula/admin/course/addcourseinfo', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
    }

    getCourse = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: "apps"
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(res => {
                    if (res.data.Status === 1) {
                        if (res.data.selectedCourseData.length === 0)
                            this.setState({ checkedValues: [] });
                        else
                            this.setState({ checkedValues: res.data.selectedCourseData })

                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("Course Not Selected");
    }

    render() {
        let { COURSE_CODE, COURSE_NAME, checkedValues } = this.state;
        return (
            <Card type="inner"
                title={<><EditOutlined className="mr-1" />Teaching Learning Tools</>}
                className="mt-1 mb-1"
                style={{ width: "100%" }}
                loading={this.state.loading}
                extra={<><Badge color="red" /><Badge color="yellow" /><Badge color="green" /></>}> <br />

                <Alert message="Teaching learning tools refer to resources, methods, or technologies used in education to facilitate effective knowledge transfer and skill acquisition between educators and learners. These tools can encompass traditional classroom aids, digital platforms, interactive software, and other materials designed to enhance the teaching and learning experience.Please select the apps that you are using for teaching and learning in this course." type="info" closable />

                <br />

                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    className="mb-3"
                >
                    <Descriptions.Item label="Course Code">
                        {COURSE_CODE}
                    </Descriptions.Item>
                    <Descriptions.Item label="Course Name">
                        {COURSE_NAME}
                    </Descriptions.Item>

                    <Descriptions.Item label="Apps" span={2}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeCheckBox} value={checkedValues.length ? checkedValues : []}>
                            <Row gutter={[16, 16]} className="mt-1" align="middle" justify="start">
                                {
                                    config.apps.map(
                                        (item, val) => {
                                            return (
                                                <Col span={4} key={val} align="middle">
                                                    <Checkbox value={item.label} style={{ width: "100%" }}>
                                                        <Card hoverable bordered={false} style={{ boxShadow: "none", width: "100%" }}
                                                            actions={[<a target="_blank" href={item.link} rel="noreferrer"><Button type="ghost" block size="small">View</Button></a>]}
                                                        >
                                                            <Image
                                                                preview={false}
                                                                width={100}
                                                                src={require('../../images/folder.svg').default}
                                                            />
                                                            <Meta style={{ textAlign: "center", fontSize: "12px", fontWeight: 600 }} description={item.label} />
                                                        </Card>
                                                    </Checkbox>
                                                </Col>
                                            )
                                        }
                                    )
                                }
                            </Row>
                        </Checkbox.Group>
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(Apps));